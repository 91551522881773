import React, {useState, useMemo, useEffect, useRef, useContext} from 'react'
import ThingsContext from '../../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../../modules/apps/masters/commonicons/ValidateContext'
import {useForm} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import ProjectPop from '../../../modules/apps/masters/modal/ProjectPop'
import CreateInvoicePop from '../../../modules/apps/masters/modal/CreateInvoicePop'
import {default as Apiservices} from '../../../../../src/common/index'
import moment from 'moment'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import clsx from 'clsx'
import ButtonCal from '../../../../localMedia/buttonCal.svg'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import {PageTitle} from '../../../../_metronic/layout/core'
import ReactPaginate from 'react-paginate'
import {Container, Row, Col} from 'react-bootstrap'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import InvoiceHistoryViewPop from '../../../modules/apps/masters/modal/InvoiceHistoryViewPop'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import DatePicker from 'react-datepicker'
import {KTCardBody, KTSVG} from '../../../../_metronic/helpers'
import MultiDatePicker from 'react-multi-date-picker'
import {Tooltip} from '../../../modules/apps/timesheetentry/ComponentTooltip'
import {Link} from 'react-router-dom'
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import DatePickerIcon from '../../../modules/apps/masters/commonicons/DatePickerIcon'
import ProjectQBPop from '../../../modules/apps/masters/modal/ProjectQBPop'
import {usersColumns} from '../../../modules/apps/user-management/users-list/table/columns/_columns'
import {useTable, ColumnInstance} from 'react-table'
import {ListProjects} from '../../../modules/apps/user-management/users-list/table/columns/_columns'
import {UsersListLoading} from '../../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {UsersListPagination} from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import ProjectHoursDrawer from '../../../modules/apps/timesheetentry/ProjectHoursDrawer'
import ConsultantQBPop from '../../../modules/apps/masters/modal/ConsultantQBPop'

type FormData = {
  status: string
  work_country: string
  work_city: string
  placement_type: string
  project_type: string
  project_name: string
  candidate: string
  // bill_rate: string
  // pay_rate: string
  start_date: any
  expected_hours: number
  effective_date: any
  ot_type: string
  // threshold: number
  business_unit_id: any
  joining_date: any
  end_date: any
  cancel_date: any
  job_title: string
  client_name: string
  placement_code: string
  end_customer: string
  primary_project: any
  direct_customer_engagement: boolean
  record_type_status: string
  client_manager_name: string
  client_manager_email: string
  reasons: string
}
type FormData1 = {
  qb_project_name: string
  qb_project_id: string
  qb_product_name: string
  ot_qb_product_name: string
  qb_customer_name: string
  qb_employee_name: string
  qb_employee_id: string
  qb_customer_id: string
  qb_product_id: string
  ot_qb_product_id: string
}
type FormData2 = {
  netSuite_project_name: string
  netSuite_project_id: string
  netSuite_customer_name: string
  netSuite_vendor_name: string
  netSuite_vendor_id: string
  netSuite_product_name: string
  netSuite_customer_id: string
  netSuite_employee_id: string
  netSuite_first_name: string
  netSuite_middle_name: string
  netSuite_last_name: string
  netSuite_product_id: string
  netSuite_ot_product_name: string
  netSuite_ot_product_id: string
}

function AddProject() {
  const location = useLocation()

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormData>()
  const {
    register: register1,
    setValue: setValue1,
    reset: reset1,
    getValues: getValues1,
    handleSubmit: handleSubmit1,
    formState: {errors: errors1},
  } = useForm<FormData1>()
  const {
    register: register2,
    setValue: setValue2,
    reset: reset2,
    getValues: getValues2,
    handleSubmit: handleSubmit2,
    formState: {errors: errors2},
  } = useForm<FormData2>()
  const [clientname, setClientName] = useState<any | []>([])
  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()

  const data = useMemo(() => users, [users])
  const columns = useMemo(() => ListProjects, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [ThresholdList, setThresholdList] = useState<any | []>([])
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [productName, setProductName] = useState<any | []>([])
  const [OTproductName, setOTProductName] = useState<any | []>([])
  const [NetSuiteOTproductName, setNetSuiteOTProductName] = useState<any | []>([])
  const [projectid, setProjectid] = useState<any | []>([])
  const [consultantoption, setConsultantOption] = useState<any | []>([])
  const [displayqb, setDisplayQB] = useState(false)
  const [client, setClient] = useState<any | []>([])
  const [list, setList] = useState(false)
  const [projectstatuspopup, setProjectStatusPopup] = useState(false)
  const [UserData, setUserData] = useState<any>([])
  const [perPage, setPerPage] = useState(25)
  const [pages, setPages] = useState(1)
  const [page, setPage] = useState(1)
  const [CreateInvoice, setCreateInvoice] = useState<any>([])
  const [CreateNSInvoice, setCreateNSInvoice] = useState<any>([])
  const [InvoiceTimesheets, setInvoiceTimesheets] = useState<any>([])
  const [InvoiceNSTimesheets, setInvoiceNSTimesheets] = useState<any>([])
  const [dailyThresholdError, setDailyThresholdError] = useState<any>('')
  const [weeklyThresholdError, setWeeklyThresholdError] = useState<any>('')
  const [HistoryInvoice, setHistoryInvoice] = useState<any>([])
  const [NetSuiteHistoryInvoice, setNetSuiteHistoryInvoice] = useState<any>([])
  const [ViewHistory, setViewHistory] = useState<any>([])
  const [AllCreateInvoiceCount, setAllCreateInvoiceCount] = useState<any | []>([])
  const [InvoiceViewPopOpen, setInvoiceViewPopOpen] = useState(false)
  const [flag, setFlag] = useState(false)
  const [dateRange, setDateRange] = useState<any | []>([null, null])
  const [startDateInvoice, endDateInvoice] = dateRange
  const [NetSuiteDateRange, setNetSuiteDateRange] = useState<any | []>([null, null])
  const [startDateNetSuiteInvoice, endDateNetSuiteInvoice] = NetSuiteDateRange
  const [DateValue1, setDateValue1] = useState<any>([])
  const [NSDateValue1, setNSDateValue1] = useState<any>([])
  const [NSDateValueSetting, setNSDateValueSetting] = useState<any>([])
  const calendarRef: any = useRef<HTMLInputElement>(null)
  const [MultipleYears, setMultipleYears] = useState<any>([])
  const [MultipleMonths, setMultipleMonths] = useState<any>([])
  const [ProjectDetails, setProjectDetails] = useState<any>()
  const [key, setKey] = useState<any>('project')
  const [keyQB, setKeyQB] = useState<any>('Configuration')
  const [keyNS, setKeyNS] = useState<any>('Configuration')
  const [InvoiceValidation, setInvoiceValidation] = useState(false)
  const [NS_InvoiceValidation, setNS_InvoiceValidation] = useState(false)
  const [InvoiceDateValidation, setInvoiceDateValidation] = useState(false)
  const [NSInvoiceDateValidation, setNSInvoiceDateValidation] = useState(false)
  const [HistoryValidation, setHistoryValidation] = useState(false)
  const [NSHistoryValidation, setNSHistoryValidation] = useState(false)
  const [candidate, setCandidate] = useState<any | []>([])
  const [disableNetSuiteConsultant, setDisableNetSuiteConsultant] = useState(false)
  // const [candidate, setCandidate] = useState<any | []>([])
  const [Placement, setPlacement] = useState<any | []>('')
  const [candidateList, setCandidateList] = useState<any | []>([])
  const [VendorName, setVendorName] = useState<any>('')
  const [VendorId, setVendorId] = useState<any>('')
  const [clientList, setClientList] = useState<any | []>([])
  const [customerList, setCustomerList] = useState<any | []>([])
  const [ProjectWorkLocationHistory, setProjectWorkLocationHistory] = useState<any | []>([])
  const [ProjectPaymentHistory, setProjectPaymentHistory] = useState<any | []>([])
  const [customer, setCustomer] = useState<any | []>([])
  const [country, setCountry] = useState<any | []>([])
  const [state, setState] = useState<any | []>([])
  const [countries, setCountries] = useState<any | []>([])
  const [useStateCountryId, setUseStateCountryId] = useState<any | []>([])
  const [states, setStates] = useState<any | []>([])
  const [select_consultant, setSelectConsultant] = useState<any | []>([])
  const [placementData, setPlacementData] = useState<any | []>([])
  const [select_client, setSelectClient] = useState<any | []>([])
  const [select_customer, setSelectCustomer] = useState<any | []>([])
  const [statusreasons, setStatusReasons] = useState<any | []>([])
  const [statusname, setStatusName] = useState('')
  const [thresholdType, setThresholdType] = useState('Daily')
  const [dateValue, setDateValue] = useState(null)
  const [proj_type, setProjType] = useState('')
  const [clientopen, setClientOpen] = useState(false)
  const [employeeopen, setEmployeeOpen] = useState(false)
  const [netopen, setnetOpen] = useState(false)
  const [netVendoropen, setnetVendorOpen] = useState(false)
  const [netconsultantopen, setnetconsultantOpen] = useState(false)
  const [netproductopen, setnetproductOpen] = useState(false)
  const [OldObj, setOldObj] = useState<any>({})
  const [OldObj1, setOldObj1] = useState<any>({})
  const [OldProjectObj, setOldProjectObj] = useState<any>({})
  const [disableNetSuiteClient, setDisableNetSuiteClient] = useState(false)
  const [disableNetSuiteVendor, setDisableNetSuiteVendor] = useState(false)
  const [disableNetSuiteProduct, setDisableNetSuiteProduct] = useState(false)
  const [disableNetSuite, setDisableNetSuite] = useState(false)
  const [disableNetProject, setDisableNetProject] = useState(false)
  const [netprojectopen, setNetProjectOpen] = useState(false)
  const [PlacementProjectID, setPlacementProjectID] = useState<any | []>([])
  const [BenchProjectStartDate, setBenchProjectStartDate] = useState(false)
  const [projectopen, setProjectOpen] = useState(false)
  const [disableQuickBooks, setDisableQuickBooks] = useState(false)
  const [IndividualBU, setIndividualBU] = useState(false)
  const [DateRun, setDateRun] = useState(false)
  const [NS_DateRun, setNS_DateRun] = useState(false)
  const [PrevDate, setPrevDate] = useState<any | []>([null, null])
  const [PrevNSDate, setPrevNSDate] = useState<any | []>([null, null])
  const [InvoicePopOpen, setInvoicePopOpen] = useState(false)
  const [NSInvoicePopOpen, setNSInvoicePopOpen] = useState(false)
  const [disablecustomerdatabynameQuickBooks, setDisablecustomerdatabynameQuickBooks] =
    useState(false)
  const [disableitemdatabynameQuickBooks, setDisableitemdatabynameQuickBooks] = useState(false)
  const [disableemployeedatabynameQuickBooks, setDisableemployeedatabynameQuickBooks] =
    useState(false)
  const [message, setMessage] = useState('')
  const [Settings, setSettings] = useState('')
  const [message_placement_validation, setMessagePlacementValidation] = useState('')
  const [SubmitMessage, setSubmitMessage] = useState('')
  const [ExpectedHoursHistory, setExpectedHoursHistory] = useState<any>([])
  const [RecordsNotMatchedMessage, setRecordsNotMatchedMessage] = useState('')
  const [customermessage, setCustomerMessage] = useState('')
  const [custerror, setCustError] = useState('')
  const [clienterror, setClientError] = useState('')
  const [productopen, setProductOpen] = useState(false)
  const [OTProductOpen, setOTProductOpen] = useState(false)
  const [NetSuiteOTProductOpen, setNetSuiteOTProductOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [QBClientPop, setQBClientPop] = useState(false)
  const [QBVendorPop, setQBVendorPop] = useState(false)
  const [QBEmployeePop, setQBEmployeePop] = useState(false)
  const [QBProjectPop, setQBProjectPop] = useState(false)
  const [QBProductPop, setQBProductPop] = useState(false)
  const [OTQBProductPop, setOTQBProductPop] = useState(false)
  const [disable, setDisable] = useState(false)
  const [disable_placement, setDisablePlacement] = useState(true)
  const [disableQBClient, setDisableQBClient] = useState(false)
  const [disableQBEmployee, setDisableQBEmployee] = useState(false)
  const [disableQBProduct, setDisableQBProduct] = useState(false)
  const [DisableOTQBProduct, setDisableOTQBProduct] = useState(false)
  const [DisableNetSuiteOTQBProduct, setDisableNetSuiteOTQBProduct] = useState(false)
  const [disableQBProject, setDisableQBProject] = useState(false)
  const [disablePrimaryProject, setDisablePrimaryProject] = useState(false)
  const [AllFieldsToDisabled, setAllFieldsToDisabled] = useState(false)
  const [projectConsultantDisabled, setprojectConsultantDisabled] = useState(false)
  const [StatusDisabledField, setStatusDisabledField] = useState(true)
  const [AllFieldsToDisabledByPlacement, setAllFieldsToDisabledByPlacement] = useState(false)
  const [directcustomer, setDirectCustomer] = useState(true)
  const [primary, setPrimary] = useState(false)
  const [UserProjectBuPop, setUserProjectBuPop] = useState(false)
  const [UserProjectBUDisabledButton, setUserProjectBUDisabledButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [billerror, setBillError] = useState('')
  const [payerror, setPayError] = useState('')
  const [edit, setEdit] = useState(true)
  const [startDate, setStartDate] = useState(null)
  const [effectiveDate, setEffectiveDate] = useState(null)
  const [ExpectedHoursData, setExpectedHoursData] = useState<any | []>([])
  const [endDate, setEndDate] = useState(null)
  const [InvoiceDate, setInvoiceDate] = useState(null)
  const [NSInvoiceDate, setNSInvoiceDate] = useState(null)
  const [cancelDate, setCancelDate] = useState(null)
  const [history_open_per_week, setHistoryOpenPerWeek] = useState(false)
  const [show, setShow] = useState(false)
  const [OTProductHours, setOTProductHours] = useState(false)
  const [NetSuiteOTProductHours, setNetSuiteOTProductHours] = useState(false)
  const [OTProduct, setOTProduct] = useState(false)
  const [NetSuiteOTProduct, setNetSuiteOTProduct] = useState(false)
  const [StateEdit, setStateEdit] = useState<any>('')
  const [QBConsultant, setQBConsultant] = useState(false)
  type ButtonProps = React.HTMLProps<HTMLButtonElement>
  const ExampleCustomInput = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({value, onClick}, ref: any) => (
      <button className='btn btn-icon btn-light btn-sm  ms-2 ' onClick={onClick} ref={ref}>
        <img src={ButtonCal} className='buttonCal' />
      </button>
    )
  )
  const intl = useIntl()
  const navigate = useNavigate()

  const modalPop = () => {
    setList(false)
    // setPrimary(!primary)
    setPrimary(true)
    setValue('primary_project', true)
    // setValue('primary_project', primary)
  }

  const qbCustName = (qbname: any, qbid: any, client_name: any, blur_event: any) => {
    setShowBackDrop(true)
    setValue1('qb_customer_name', qbname)
    setClientName(qbname)
    if (location.state || projectid?.id) {
      if (qbname && client_name) {
        if (blur_event === 'Blur') {
          if (qbname != client_name) {
            // setList(true)
            setCustomerMessage('QB Customer and client name should be same value')
          }
        }
      }
    }

    if (qbid) {
      setValue1('qb_customer_id', '')
      setClientOpen(false)
      setMessage('')
    } else {
      setMessage('')
    }
    setShowBackDrop(false)
  }
  const handleCancel = () => {
    // if (customermessage == 'QB Customer and client name should be same value') {
    //   setValue('qb_customer_name', '')
    // } else {
    //   setValue('qb_project_name', '')
    // }
    setList(false)
  }

  const handleClick = async () => {
    setShowBackDrop(true)
    // const data: any = await Apiservices.getitemdatabyname({name: productName})
    let productname = getValues1('qb_product_name')
      ? getValues1('qb_product_name')
      : getValues('placement_code')
    if (projectid?.user_business_unit_name != projectid?.project_business_unit_name) {
      setUserProjectBuPop(true)
      setUserProjectBUDisabledButton(true)
    } else {
      const data: any = await Apiservices.getitemdatabyname({
        name: productname,
        business_unit_id: projectid.business_unit_id,
      })
      if (data.data.isError == false) {
        toast.success('Product ID fetched Successfully')
        setProductOpen(true)
        setDisableQBProduct(true)
        setValue1('qb_product_id', data.data.data.QueryResponse.Item[0].Id)
      } else if (data.data.isError == true) {
        toast.error('Enter valid QB Product name')
      }
    }
    setShowBackDrop(false)
  }

  const otProductHoursPopupAddClick = () => {
    // setOTProductHours(true)
    setOTProduct(true)
  }
  const otProductHoursNetSuitePopupAddClick = () => {
    setNetSuiteOTProduct(true)
  }
  const otProductHoursPopupCancelClick = () => {
    setOTProduct(false)
  }
  const otProductHoursNetSuitePopupCancelClick = () => {
    setNetSuiteOTProduct(false)
  }

  // const handleRun = async (page: any, perPage: any) => {
  const handleRun = async () => {
    setShowBackDrop(true)
    let startDate2 = moment(startDateInvoice, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let endDate2 = moment(endDateInvoice, ' DD/MM/YYYY').format('YYYY-MM-DD')
    const invoicePayload = {
      user_id: projectid.user_id,
      project_id: projectid.id,
      start_date: startDate2,
      end_date: endDate2,
      // pagenumber: page,
      // pagesize: Number(perPage),
    }
    if (startDateInvoice == null || endDateInvoice == null) {
      setInvoiceValidation(true)
    } else {
      setInvoiceValidation(false)
      setInvoiceDate(null)
      const response: any = await Apiservices.get_timesheet_entries_to_create_invoice(
        invoicePayload
      )
      if (response && response.isError == false) {
        setInvoiceTimesheets(response?.available_timesheets)
        if (response && response.data && response.data.length > 0) {
          setFlag(true)
          setCreateInvoice(response.data)
          setDateRun(false)
          // setAllCreateInvoiceCount(response.count[0].count)
        } else {
          setFlag(false)
          setCreateInvoice([])
          // setAllCreateInvoiceCount([])
        }
      }
    }
    setShowBackDrop(false)
  }

  const handleNetSuiteRun = async () => {
    setShowBackDrop(true)
    let startDate2 = moment(startDateNetSuiteInvoice, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let endDate2 = moment(endDateNetSuiteInvoice, ' DD/MM/YYYY').format('YYYY-MM-DD')
    const invoicePayload = {
      user_id: projectid.user_id,
      project_id: projectid.id,
      start_date: startDate2,
      end_date: endDate2,
      // pagenumber: page,
      // pagesize: Number(perPage),
    }
    if (startDateNetSuiteInvoice == null || endDateNetSuiteInvoice == null) {
      setNS_InvoiceValidation(true)
    } else {
      setNS_InvoiceValidation(false)
      setNSInvoiceDate(null)
      const response: any = await Apiservices.get_timesheet_entries_to_create_netsuite_invoice(
        invoicePayload
      )
      if (response && response.isError == false) {
        setInvoiceNSTimesheets(response?.available_timesheets)
        if (response && response.data && response.data.length > 0) {
          setCreateNSInvoice(response.data)
          setNS_DateRun(false)
          // setAllCreateInvoiceCount(response.count[0].count)
        } else {
          setCreateNSInvoice([])
          // setAllCreateInvoiceCount([])
        }
      }
    }
    setShowBackDrop(false)
  }

  const handleHistoryRun = async (page: any, perPage: any) => {
    setShowBackDrop(true)
    // const handleHistoryRun = async () => {
    var ProjObj1: any = []
    for (let i = 0; i < ProjectDetails?.year?.length; i++) {
      ProjObj1.push({
        year: ProjectDetails.year[i],
        months: ProjectDetails.months[i],
      })
    }
    let pay = {
      project_id: [projectid.id],
      user_id: [projectid.user_id],
      business_unit_id: [projectid.business_unit_id],
      pagenumber: page,
      pagesize: Number(perPage),
      date: ProjObj1,
    }
    if (DateValue1 == null || DateValue1?.length == 1) {
      setHistoryValidation(true)
    } else if (DateValue1?.length == 2) {
      setHistoryValidation(false)
      const response: any = await Apiservices.getProjectWiseCreatedHistory(pay)
      if (response.isError == false) {
        if (response && response.data && response.data.length > 0) {
          let history_map: any = []
          let AllResp = response.data.map((hist: any) => {
            hist.timesheet_data.map((inside: any) => {
              history_map.push(inside)
            })
          })
          const abc = history_map.filter((obj: any, index: any) => {
            return (
              index ===
              history_map.findIndex(
                (o: any) => obj.start_date === o.start_date && obj.qb_invoice_id === o.qb_invoice_id
              )
            )
          })
          const totals: any = []
          history_map.forEach((x: any) => {
            const Amount_Calculation = totals.find((o: any) => o.qb_invoice_id === x.qb_invoice_id)
            if (Amount_Calculation) {
              // Amount_Calculation.total = Amount_Calculation.amount + x.amount
              // Amount_Calculation.total_regular_hours =
              //   Amount_Calculation.regular_hours +
              //   x.regular_hours +
              //   Amount_Calculation.regular_minutes / 60 +
              //   x.regular_minutes / 60
              // Amount_Calculation.total_ot_hours =
              //   Amount_Calculation.ot_hours +
              //   x.ot_hours +
              //   Amount_Calculation.ot_minutes / 60 +
              //   x.ot_minutes / 60
              if (Amount_Calculation.bill_rate != x.bill_rate) {
                Amount_Calculation.total_bill_rate = true
              }
              if (Amount_Calculation.ot_bill_rate != x.ot_bill_rate) {
                Amount_Calculation.total_ot_bill_rate = true
              }
              if (
                Amount_Calculation.bill_rate == x.bill_rate &&
                Amount_Calculation.ot_bill_rate == x.ot_bill_rate
              ) {
                Amount_Calculation.total = Amount_Calculation.amount
                Amount_Calculation.total_regular_hours =
                  Amount_Calculation.regular_hours + Amount_Calculation.regular_minutes / 60
                Amount_Calculation.total_ot_hours =
                  Amount_Calculation.ot_hours + Amount_Calculation.ot_minutes / 60
              } else {
                Amount_Calculation.total = Amount_Calculation.amount + x.amount
                Amount_Calculation.total_regular_hours =
                  Amount_Calculation.regular_hours +
                  x.regular_hours +
                  Amount_Calculation.regular_minutes / 60 +
                  x.regular_minutes / 60
                Amount_Calculation.total_ot_hours =
                  Amount_Calculation.ot_hours +
                  x.ot_hours +
                  Amount_Calculation.ot_minutes / 60 +
                  x.ot_minutes / 60
              }
            } else {
              x.total = x.amount
              x.total_regular_hours = x.regular_hours + x.regular_minutes / 60
              x.total_ot_hours = x.ot_hours + x.ot_minutes / 60
              x.total_bill_rate = false
              x.total_ot_bill_rate = false
              totals.push(x)
            }
          })
          let b1 = abc.map((item: any) => {
            let b2 = totals.filter((tot: any) => tot.qb_invoice_id == item.qb_invoice_id)
            let obj = {
              ...item,
              amount1: b2[0].total,
              total_regular_hours: b2[0].total_regular_hours,
              total_ot_hours: b2[0].total_ot_hours,
              total_bill_rate: b2[0].total_bill_rate,
              total_ot_bill_rate: b2[0].total_ot_bill_rate,
            }
            return obj
          })
          // setHistoryInvoice(abc)
          setHistoryInvoice(b1)

          setAllCreateInvoiceCount(response.count.count)
        } else {
          setHistoryInvoice([])
        }
      }
    }
    setShowBackDrop(false)
  }
  const handleNetSuiteHistoryRun = async (page: any, perPage: any) => {
    setShowBackDrop(true)
    let pay = {
      project_id: [projectid.id],
      user_id: [projectid.user_id],
      business_unit_id: [projectid.business_unit_id],
      pagenumber: page,
      pagesize: Number(perPage),
      start_date: NSDateValueSetting[0],
      end_date: NSDateValueSetting[1],
    }

    if (NSDateValue1 == null || NSDateValue1?.length == 1) {
      setNSHistoryValidation(true)
    } else if (NSDateValue1?.length == 2) {
      setNSHistoryValidation(false)
      const response: any = await Apiservices.getProjectWiseNetSuiteCreatedHistory(pay)
      if (response.isError == false) {
        if (response && response.data && response.data.length > 0) {
          setNetSuiteHistoryInvoice(response.data)
          // setAllCreateInvoiceCount(response.count.count)
        } else {
          setNetSuiteHistoryInvoice([])
        }
      }
    }
    setShowBackDrop(false)
  }
  const exporthandleClick = async () => {
    setShowBackDrop(true)
    var ProjObj1: any = []
    for (let i = 0; i < ProjectDetails?.year?.length; i++) {
      ProjObj1.push({
        year: ProjectDetails.year[i],
        months: ProjectDetails.months[i],
      })
    }

    let pay = {
      project_id: [projectid.id],
      user_id: [projectid.user_id],
      business_unit_id: [projectid.business_unit_id],
      date: ProjObj1,
    }
    const response: any = await Apiservices.export_invoices_history(pay)
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = response.data.blobUrl
    document.body.appendChild(link)
    link.click()
    link.remove()
    setShowBackDrop(false)
  }
  const handleView = async (invoice_id: any, bu_id: any, start_date: any, end_date: any) => {
    setShowBackDrop(true)
    const response: any = await Apiservices.get_invoice_details_by_invoice_id({
      invoice_id: invoice_id,
      business_unit_id: bu_id,
      start_date: start_date,
      end_date: end_date,
    })
    if (response.data.isError == false) {
      if (response && response.data && response.data.data && response.data.data.length > 0) {
        setViewHistory(response.data.data)
        setInvoiceViewPopOpen(true)
      }
    }
    setShowBackDrop(false)
  }
  const handleDownload = async (
    invoice_id: any,
    bu_id: any,
    start_date: any,
    end_date: any,
    project_id: any
  ) => {
    setShowBackDrop(true)
    const response: any = await Apiservices.downloadInvoice({
      invoice_id: invoice_id,
      business_unit_id: bu_id,
      start_date: start_date,
      end_date: end_date,
      project_id: project_id,
    })

    if (response?.data?.isError == false) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response?.data?.data?.data).buffer])
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Invoice-${invoice_id}.pdf`)
      document.body.appendChild(link)
      link.click()
    }
    setShowBackDrop(false)
  }

  const handleNetSuitePDFDownload = async (invoice_id: any) => {
    setShowBackDrop(true)
    const response: any = await Apiservices.invoiceNetSuite(invoice_id, {})
    if (response?.data?.error == false) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response?.data?.data?.data).buffer])
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Invoice-${invoice_id}.pdf`)
      document.body.appendChild(link)
      link.click()
    } else {
      toast.error(response?.response?.data?.message)
    }
    setShowBackDrop(false)
  }
  const handleInvoiceClose = () => {
    setInvoiceViewPopOpen(false)
  }
  const InvoiceOkPop = (val: any) => {
    handleInvoice(val)
  }
  const InvoiceClosePop = () => {
    setInvoicePopOpen(false)
  }
  const handleInvoice = async (fromVal: any) => {
    setShowBackDrop(true)
    let startDate2 = moment(startDateInvoice, ' DD/MM/YYYY').format('M')
    let endDate2 = moment(endDateInvoice, ' DD/MM/YYYY').format('YYYY')

    let ProjectInvoice = []
    for (let each of CreateInvoice) {
      let timesheets: any = {
        timesheet_date: each.timesheet_date,
        qb_timesheet_id: each.qb_timesheet_id,
        qb_status: each.qb_status,
        week_start_date: each.week_start_date,
        week_end_date: each.week_end_date,
        qb_ot_timesheet_id: each.qb_ot_timesheet_id,
        bill_rate: each.bill_rate,
        ot_bill_rate: each.ot_bill_rate,
        regular_hours: each.regular_hours,
        regular_minutes: each.regular_minutes,
        ot_hours: each.ot_hours,
        ot_minutes: each.ot_minutes,
      }
      ProjectInvoice.push(timesheets)
    }
    const invoicePayload = {
      user_id: projectid.user_id,
      business_unit_id: projectid.business_unit_id,
      project_id: projectid.id,
      year: endDate2,
      month: startDate2,
      timesheet_data: ProjectInvoice,
      invoice_date: InvoiceDate ? moment(InvoiceDate).format('YYYY-MM-DD') : null,
      start_date: moment(startDateInvoice, ' DD/MM/YYYY').format('YYYY-MM-DD'),
      end_date: moment(endDateInvoice, ' DD/MM/YYYY').format('YYYY-MM-DD'),
    }
    if (InvoiceDate == null) {
      setInvoiceDateValidation(true)
    } else {
      setInvoicePopOpen(true)
      setInvoiceDateValidation(false)
      if (fromVal == 'invoice') {
        setInvoicePopOpen(false)
        setShowBackDrop(true)
        const response: any = await Apiservices.create_invoice_project_wise_timesheet(
          invoicePayload
        )
        if (response.isError == false) {
          toast.success('Invoice Created Successfully')
          // handleRun(page, perPage)
          handleRun()
        } else if (response?.error?.fault?.type == 'AUTHENTICATION') {
          toast.error('Token expired.please login')
        } else if (response.isError == true) {
          toast.warn(response.message)
          // handleRun(page, perPage)
        }
        setShowBackDrop(false)
      }
    }
    setShowBackDrop(false)
  }
  const InvoiceNetSuiteOkPop = (val: any) => {
    handleNetSuiteInvoice(val)
  }
  const InvoiceNetSuiteClosePop = () => {
    setNSInvoicePopOpen(false)
  }
  const handleNetSuiteInvoice = async (fromVal: any) => {
    setShowBackDrop(true)
    let startDate2 = moment(startDateNetSuiteInvoice, ' DD/MM/YYYY').format('M')
    let endDate2 = moment(endDateNetSuiteInvoice, ' DD/MM/YYYY').format('YYYY')

    let ProjectInvoice = []
    for (let each of CreateNSInvoice) {
      let timesheets: any = {
        // timesheet_date: each.timesheet_date,
        // qb_timesheet_id: each.qb_timesheet_id,
        // qb_status: each.qb_status,
        // week_start_date: each.week_start_date,
        // week_end_date: each.week_end_date,
        // qb_ot_timesheet_id: each.qb_ot_timesheet_id,
        // bill_rate: each.bill_rate,
        // ot_bill_rate: each.ot_bill_rate,
        // regular_hours: each.regular_hours,
        // regular_minutes: each.regular_minutes,
        // ot_hours: each.ot_hours,
        // ot_minutes: each.ot_minutes,
        absent_hours: each.absent_hours,
        absent_minutes: each.absent_minutes,
        absent_type: each.absent_type,
        approver_id: each.approver_id,
        approver_notes: each.approver_notes,
        day_name: each.day_name,
        id: each.id,
        month: each.month,
        netsuite_invoice_id: each.netsuite_invoice_id,
        netsuite_ot_timesheet_id: each.netsuite_ot_timesheet_id,
        netsuite_timesheet_id: each.netsuite_timesheet_id,
        org_id: each.org_id,
        ot_hours: each.ot_hours,
        ot_minutes: each.ot_minutes,
        ot_product: each.ot_product,
        payment_terms: each.payment_terms,
        payroll_synced: each.payroll_synced,
        project_id: each.project_id,
        qb_invoice_id: each.qb_invoice_id,
        qb_ot_timesheet_id: each.qb_ot_timesheet_id,
        qb_status: each.qb_status,
        qb_timesheet_id: each.qb_timesheet_id,
        record_type_status: each.record_type_status,
        regular_hours: each.regular_hours,
        regular_minutes: each.regular_minutes,
        sent_to_netsuite: each.sent_to_netsuite,
        sent_to_quickbook: each.sent_to_quickbook,
        status: each.status,
        timesheet_date: each.timesheet_date,
        user_id: each.user_id,
        user_notes: each.user_notes,
        week_end_date: each.week_end_date,
        week_start_date: each.week_start_date,
        year: each.year,
      }
      ProjectInvoice.push(timesheets)
    }
    const invoicePayload = {
      netsuite_project_id: projectid.netsuite_project_id,
      netsuite_customer_id: projectid.netsuite_customer_id,
      // locationName : projectid.netsuite_consultant_id,
      subsidiaryName: projectid.subsidary_name,
      timesheet_data: ProjectInvoice,
      project_id: projectid.id,
      invoice_date: moment(NSInvoiceDate).format('YYYY-MM-DD'),
    }
    if (NSInvoiceDate == null) {
      setNSInvoiceDateValidation(true)
    } else {
      setNSInvoicePopOpen(true)
      setNSInvoiceDateValidation(false)
      if (fromVal == 'netsuite_invoice') {
        setNSInvoicePopOpen(false)
        setShowBackDrop(true)
        const response: any = await Apiservices.create_NetSuite_invoice_project_wise_timesheet(
          invoicePayload
        )
        if (response.isError == false) {
          toast.success('Invoice Created Successfully')
          // handleRun(page, perPage)
          handleNetSuiteRun()
        } else if (response?.error?.fault?.type == 'AUTHENTICATION') {
          toast.error('Token expired.please login')
        } else if (response.isError == true) {
          toast.warn(response.message)
          // handleRun(page, perPage)
        }
        setShowBackDrop(false)
      }
    }
    setShowBackDrop(false)
  }
  const EditQBClientOkPop = (qb: any) => {
    setQBClientPop(false)
    setDisableQBClient(false)
    setDisableNetSuiteClient(false)
    setDisabled(false)
    setClientOpen(false)
    setnetOpen(false)
    setValue1('qb_customer_id', '')
    setValue2('netSuite_customer_id', '')
  }
  const EditQBVendorOkPop = (qb: any) => {
    setQBVendorPop(false)
    // setDisableQBClient(false)
    setDisableNetSuiteVendor(false)
    setDisabled(false)
    // setClientOpen(false)
    setnetVendorOpen(false)
    // setValue1('qb_customer_id', '')
    setValue2('netSuite_vendor_id', '')
  }
  const EditQBConsultantOkPop = (qb: any) => {
    setQBEmployeePop(false)
    setDisableNetSuiteConsultant(false)
    setDisableQBEmployee(false)
    setDisabled(false)
    setEmployeeOpen(false)
    setnetconsultantOpen(false)
    setValue1('qb_employee_id', '')
    setValue2('netSuite_employee_id', '')
  }
  const EditQBClientPop = (Val: any) => {
    setSettings(Val)
    setQBClientPop(true)
  }
  const EditQBVendorPop = (Val: any) => {
    setSettings(Val)
    setQBVendorPop(true)
  }
  const EditQBConsultantPop = (Val: any) => {
    setSettings(Val)
    setQBEmployeePop(true)
  }

  const EditQBClientCancelPop = (qb: any) => {
    setQBClientPop(false)
  }
  const EditQBVendorCancelPop = (qb: any) => {
    setQBVendorPop(false)
  }
  const EditQBConsultantCancelPop = (qb: any) => {
    setQBEmployeePop(false)
  }
  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      // handleRun(1, value)
      handleHistoryRun(1, value)
    }
  }
  const handleNetSuiteRowChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      // handleRun(1, value)
      handleNetSuiteHistoryRun(1, value)
    }
  }
  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)

    // handleRun(selected, perPage)
    handleHistoryRun(selected, perPage)
  }
  const handleNetSuitePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)
    // handleRun(selected, perPage)
    handleNetSuiteHistoryRun(selected, perPage)
  }
  const EditQBProductOkPop = (qb: any) => {
    setQBProductPop(false)
    setDisableQBProduct(false)
    setDisableNetSuiteProduct(false)
    setDisabled(false)
    setProductOpen(false)
    setnetproductOpen(false)
    setValue1('qb_product_id', '')
    setValue2('netSuite_product_id', '')
  }
  const EditQBProductCancelPop = (qb: any) => {
    setQBProductPop(false)
  }

  const EditQBProductPop = (Val: any) => {
    setSettings(Val)
    setQBProductPop(true)
  }
  const EditOTQBProductPop = (Val: any) => {
    setSettings(Val)
    setOTQBProductPop(true)
  }
  const EditOTQBProductOkPop = (qb: any) => {
    setOTQBProductPop(false)
    setDisableOTQBProduct(false)
    setDisableNetSuiteOTQBProduct(false)
    setDisabled(false)
    setOTProductOpen(false)
    setNetSuiteOTProductOpen(false)
    setValue1('ot_qb_product_id', '')
    setValue2('netSuite_ot_product_id', '')
  }
  const EditOTQBProductCancelPop = (qb: any) => {
    setOTQBProductPop(false)
  }

  const EditQBProjectPop = (Val: any) => {
    setSettings(Val)
    setQBProjectPop(true)
  }

  const EditQBProjectOkPop = () => {
    setQBProjectPop(false)
    setDisableQBProject(false)
    setDisableNetProject(false)
    setDisabled(false)
    setProjectOpen(false)
    setNetProjectOpen(false)
    setValue1('qb_project_id', '')
    setValue2('netSuite_project_id', '')
  }
  const OTProductHoursOk = async () => {
    let art = {
      project_id: projectid.id,
      isOtProduct: true,
    }
    // const Response: any = await Apiservices.otProductProject(art)
    // if (Response.isError == false) {
    //   setOTProduct(true)
    // }
    setOTProductHours(false)
  }

  const OTProductHoursCancel = async () => {
    setShowBackDrop(true)
    let art = {
      project_id: projectid.id,
      isOtProduct: false,
    }
    const Response: any = await Apiservices.otProductProject(art)
    if (Response.isError == false) {
      setOTProduct(false)
    }
    setOTProductHours(false)
    setShowBackDrop(false)
  }
  const EditQBProjectCancelPop = () => {
    setQBProjectPop(false)
  }

  const handleCustomerName = async (client_name: any) => {
    setShowBackDrop(true)
    if (projectid?.user_business_unit_name != projectid?.project_business_unit_name) {
      setUserProjectBuPop(true)
      setUserProjectBUDisabledButton(true)
    } else {
      const data: any = await Apiservices.getcustomerdatabyname({
        name: getValues1('qb_customer_name'),
        business_unit_id: projectid.business_unit_id,
      })
      if (data.data.isError == false) {
        setLoading(false)
        toast.success('Customer ID fetched Successfully')
        setValue1('qb_customer_id', data.data.data.QueryResponse.Customer[0].Id)
        setDisableQBClient(true)
        setClientOpen(true)
        // disableSpinner()
      } else if (data.data.isError == true) {
        setLoading(false)
        setMessage(data.message)
        toast.error('Enter valid QB Client name')
      }
    }
    setShowBackDrop(false)
  }
  const SuccessToast = (message: any) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
    })
  }

  const ErrorToast = (message: any) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    })
  }
  const ConsultantQBOk = (val: any) => {
    handleEmployeeName('', val)
  }
  const ConsultantQBClose = (val: any) => {
    setQBConsultant(false)
  }
  const handleEmployeeName = async (client_name: any, fromVal: any) => {
    setShowBackDrop(true)
    setQBConsultant(true)
    if (fromVal == 'OK') {
      let data5: any = {
        GivenName: projectid?.first_name,
        FamilyName: projectid?.last_name,
        DisplayName: getValues1('qb_employee_name'),
      }
      if (projectid?.placement_type == 'W2' || projectid?.placement_type == 'Internal') {
        setShowBackDrop(true)
        const res: any = await Apiservices.getcustomerdatabyname1(projectid.business_unit_id, data5)
        setQBConsultant(false)
        setShowBackDrop(false)
        if (res.isError == false) {
          if (res.message == 'No Records Found') {
            setDisableQBEmployee(false)
            ErrorToast('Please check if the consultant is available in QB.')
          } else {
            setDisableQBEmployee(true)
            setEmployeeOpen(true)
            SuccessToast('Consultant ID fetched Successfully')
            setValue1('qb_employee_id', res.data.QueryResponse.Employee[0].Id)
          }
        } else if (res.isError == true) {
          ErrorToast('No Records Found for QB Consultant')
        }
      } else {
        let data15: any = {
          GivenName: projectid?.first_name,
          FamilyName: projectid?.last_name,
          DisplayName: getValues1('qb_employee_name'),
          placement_type: projectid?.placement_type,
        }

        setShowBackDrop(true)
        const res: any = await Apiservices.getvendordatabyname1(projectid.business_unit_id, data15)
        setQBConsultant(false)
        setShowBackDrop(false)
        if (res.isError == false) {
          if (res.message == 'No Records Found') {
            setDisableQBEmployee(false)
            ErrorToast('Please check if the consultant is available in QB.')
          } else {
            setDisableQBEmployee(true)
            setEmployeeOpen(true)
            SuccessToast('Consultant ID fetched Successfully')
            setValue1('qb_employee_id', res.data.QueryResponse.Vendor[0].Id)
          }
        } else if (res.isError == true) {
          ErrorToast('No Records Found for QB Consultant')
        }
      }
    }
    setShowBackDrop(false)
  }
  const handleProjectName = async () => {
    setShowBackDrop(true)
    if (projectid?.user_business_unit_name != projectid?.project_business_unit_name) {
      setUserProjectBuPop(true)
      setUserProjectBUDisabledButton(true)
    } else {
      const data: any = await Apiservices.getcustomerdatabyname({
        name: getValues1('qb_project_name'),
        business_unit_id: projectid.business_unit_id,
      })
      if (data.data.isError == false) {
        toast.success('Project ID fetched Successfully')
        setValue1('qb_project_id', data.data.data.QueryResponse.Customer[0].Id)
        setProjectOpen(true)
        setDisableQBProject(true)
        // disableSpinner()
      } else if (data.data.isError == true) {
        setMessage(data.message)
        toast.error('Enter valid QB Project name')
      }
    }
    setShowBackDrop(false)
  }

  const handleCustomerName1 = async (client_name: any) => {
    setShowBackDrop(true)
    let data5: any = {
      id: 'CR' + projectid?.customer_table_id,
      client_name: getValues2('netSuite_customer_name'),
      subsidiaryName: localStorage.getItem('subsidary_name'),
      entitystatus: 13,
      client_code: projectid?.client_code,
    }
    if (projectid.netsuite_customer_id) {
      let NewObj: any = {
        id: 'CR' + projectid?.customer_table_id,
        client_name: getValues2('netSuite_customer_name'),
        subsidiaryName: localStorage.getItem('subsidary_name'),
        entitystatus: 13,
        client_code: projectid?.client_code,
      }
      let keys1 = Object.keys(OldObj)
      let arr_obj = []
      let arr11: any = {}
      for (let key of keys1) {
        if (OldObj[key] !== NewObj[key]) {
          let arr = key
          arr11[arr] = NewObj[key]
        }
      }
      if (Object.keys(arr11).length > 0) {
        arr_obj.push(arr11) // Push arr11 into arr_obj if it contains changes
      } else {
        arr11['client_name'] = getValues2('netSuite_customer_name')
        arr_obj.push(arr11)
      }
      if (arr_obj.length > 0) {
        setShowBackDrop(true)
        const upda: any = await Apiservices.updateNetSuiteCustomer(projectid.netsuite_customer_id, {
          arr_obj,
        })
        setShowBackDrop(false)
        if (upda.statusCode == 200) {
          if (upda.message == 'SUCCESS') {
            setnetOpen(true)
            // setDisableNetSuiteClient(true)
            setDisableNetSuiteClient(true)
            setValue2('netSuite_customer_id', upda.netsuiteId)
            toast.success('NetSuite details are updated successfully')
          }
        } else {
          toast.error(upda.message)
        }
      }
    } else {
      setShowBackDrop(true)
      const res: any = await Apiservices.getCustomer(data5)
      setShowBackDrop(false)
      if (res && res.statusCode == 200) {
        if (res && res.message == 'SUCCESS') {
          setValue2('netSuite_customer_id', res.netsuiteId)
        } else if (res && res.message == 'This entity already exists') {
          setValue2('netSuite_customer_id', res.data.netsuiteId)
        }
        toast.success('NetSuite ID fetched Successfully')
        setnetOpen(true)
        setDisableNetSuiteClient(true)
      } else {
        toast.error(res.message)
      }
    }
    setShowBackDrop(false)
  }
  const handleGetCustomerName1 = async () => {
    setShowBackDrop(true)
    const customer: any = await Apiservices.customerNetSuite({
      clientName: `'${getValues2('netSuite_customer_name')}'`,
    })
    if (customer?.data?.statusCode == 200) {
      if (customer?.data?.data?.netsuiteId) {
        setValue2('netSuite_customer_id', customer.data.data.netsuiteId)
        toast.success('NetSuite ID fetched Successfully')
        setnetOpen(true)
        setDisableNetSuiteClient(true)
      } else {
        toast.error(customer?.data?.message)
      }
    } else if (customer?.response?.status == 406) {
      toast.error(customer?.response?.data?.message)
    } else {
      toast.error(customer?.response?.data?.message)
    }
    setShowBackDrop(false)
  }
  const handleGetVendorName1 = async () => {
    setShowBackDrop(true)
    const vendor: any = await Apiservices.vendorNetSuite({
      name: getValues2('netSuite_vendor_name') ? getValues2('netSuite_vendor_name') : '',
    })
    if (vendor?.data?.statusCode == 200) {
      if (vendor?.data?.data?.netsuiteId) {
        setValue2('netSuite_vendor_id', vendor.data.data.netsuiteId)
        toast.success('NetSuite ID fetched Successfully')
        setnetVendorOpen(true)
        setDisableNetSuiteVendor(true)
      } else {
        toast.error(vendor?.data?.message)
      }
    } else if (vendor?.response?.status == 406) {
      toast.error(vendor?.response?.data?.message)
    } else {
      toast.error(vendor?.response ? vendor?.response?.data?.message : vendor?.data?.message)
    }
    setShowBackDrop(false)
  }
  const handleGetProjectName1 = async () => {
    setShowBackDrop(true)
    const project: any = await Apiservices.projectNetSuite({
      projectName: `'${getValues2('netSuite_project_name')}'`,
    })
    if (project?.data?.statusCode == 200) {
      if (project?.data?.data?.netsuiteId) {
        setNetProjectOpen(true)
        setDisableNetProject(true)
        setValue2('netSuite_project_id', project.data.data.netsuiteId)
        toast.success('NetSuite details are updated successfully')
      } else {
        toast.error(project?.data?.message)
      }
    } else if (project?.response?.status == 406) {
      toast.error(project?.response?.data?.message)
    } else {
      toast.error(project?.response?.data?.message)
    }
    setShowBackDrop(false)
  }
  const handleGetProduct = async () => {
    setShowBackDrop(true)
    const product: any = await Apiservices.serviceitemNetSuite({
      serviceitemName: `'${getValues2('netSuite_product_name')}'`,
    })
    if (product?.data?.statusCode == 200) {
      if (product?.data?.data?.itemid) {
        setnetproductOpen(true)
        setDisableNetSuiteProduct(true)
        setValue2('netSuite_product_id', product?.data?.data?.itemid)
        toast.success('NetSuite ID fetched Successfully')
      } else {
        toast.error(product?.data?.message)
      }
    } else if (product?.response?.status == 406) {
      toast.error(product?.response?.data?.message)
    } else {
      toast.error(product?.response?.data?.message)
    }
    setShowBackDrop(false)
  }
  const handleProductClick = async () => {
    setShowBackDrop(true)
    let productname = getValues1('ot_qb_product_name')
    // ? getValues('ot_qb_product_name')
    // : getValues('placement_code')
    const data: any = await Apiservices.getitemdatabyname({
      name: productname,
      business_unit_id: projectid.business_unit_id,
    })
    if (data.data.isError == false) {
      if (data?.data?.data?.QueryResponse?.Item[0]?.Id) {
        toast.success('Product ID fetched Successfully')
        setOTProductOpen(true)
        setDisableOTQBProduct(true)
        setValue1('ot_qb_product_id', data.data.data.QueryResponse.Item[0].Id)
        setOTProductOpen(true)
      } else {
        toast.error(data?.data?.message)
      }
    } else if (data.data.isError == true) {
      toast.error(data?.data?.message)
    }
    setShowBackDrop(false)
  }

  const handleNetSuiteOTProductClick = async () => {
    setShowBackDrop(true)
    const product: any = await Apiservices.serviceitemNetSuite({
      serviceitemName: `'${getValues2('netSuite_ot_product_name')}'`,
    })
    if (product?.data?.statusCode == 200) {
      if (product?.data?.data?.itemid) {
        setNetSuiteOTProductOpen(true)
        setDisableNetSuiteOTQBProduct(true)
        setValue2('netSuite_ot_product_id', product.data.data.itemid)
        toast.success('NetSuite OT ID fetched Successfully')
      } else {
        toast.error(product?.data?.message)
      }
    } else if (product?.response?.status == 406) {
      toast.error(product?.response?.data?.message)
    } else {
      toast.error(product?.response?.data?.message)
    }
    setShowBackDrop(false)
  }
  const handleGetConsultantName1 = async (client_name: any) => {
    setShowBackDrop(true)
    if (getValues2('netSuite_first_name') && getValues2('netSuite_last_name')) {
      const consultant: any = await Apiservices.employeeNetSuite({
        // name: `'${
        //   projectid?.netsuite_employee_name
        //     ? projectid?.netsuite_employee_name
        //     : projectid?.user_full_name
        // }'`,
        // name: getValues2('netSuite_first_name'),
        firstName: getValues2('netSuite_first_name') ? getValues2('netSuite_first_name') : null,
        middleName: getValues2('netSuite_middle_name') ? getValues2('netSuite_middle_name') : '',
        lastName: getValues2('netSuite_last_name') ? getValues2('netSuite_last_name') : '',
        subsidiaryId: `'${projectid?.subsidary_id}'`,
        classificationId: `'${projectid?.netsuite_business_unit_id}'`,
      })
      if (consultant?.data?.statusCode == 200) {
        if (consultant?.data?.data?.netsuiteId) {
          setnetconsultantOpen(true)
          setDisableNetSuiteConsultant(true)
          setValue2('netSuite_employee_id', consultant.data.data.netsuiteId)
          SuccessToast('NetSuite ID fetched Successfully')
        } else {
          toast.error(consultant?.data?.message)
        }
      } else {
        toast.error(
          consultant?.response ? consultant?.response?.data?.message : consultant.data?.message
        )
      }
      // else if (consultant?.response?.status == 406) {
      //   toast.error(consultant?.response?.data?.message)
      // } else {
      //   toast.error(consultant?.response?.data?.message)
      // }
    } else {
      toast.warn('first name and last name should not be empty')
    }
    setShowBackDrop(false)
  }
  const handleConsultantName1 = async (client_name: any) => {
    let data5: any = {
      employee_type: projectid?.placement_type,
      first_name: getValues2('netSuite_first_name'),
      // middle_name: getValues2('netSuite_middle_name'),
      // last_name: getValues2('netSuite_last_name'),
      subsidiaryName: localStorage.getItem('subsidary_name'),
      email: projectid?.email,
      phone_number: projectid?.phone_number,
      department_name: projectid?.department_name,
      country_name: countries[0]?.name ? countries[0]?.name : null,
      city: getValues('work_city'),
      state_name: states[0]?.name ? states[0]?.name : null,
      joining_date: projectid?.joining_date,
      zipcode: projectid?.zipcode,
      employee_id: projectid?.employee_id,
      isjobresource: true,
      classificationName: projectid?.project_business_unit_name,
    }

    if (getValues2('netSuite_employee_id')) {
      let NewObj: any = {
        employee_type: projectid?.placement_type,
        first_name: getValues2('netSuite_first_name'),
        // middle_name: getValues2('netSuite_middle_name'),
        // last_name: getValues2('netSuite_last_name'),
        subsidiaryName: localStorage.getItem('subsidary_name'),
        email: projectid?.email,
        phone_number: projectid?.phone_number,
        department_name: projectid?.department_name,
        country_name: countries[0]?.name ? countries[0]?.name : null,
        city: getValues('work_city'),
        state_name: states[0]?.name ? states[0]?.name : null,
        joining_date: projectid?.joining_date,
        zipcode: projectid?.zipcode,
        employee_id: projectid?.employee_id,
        isjobresource: true,
        classificationName: projectid?.project_business_unit_name,
      }

      let keys1 = Object.keys(OldObj1)
      let arr_obj = []
      let arr11: any = {}
      for (let key of keys1) {
        if (OldObj1[key] !== NewObj[key]) {
          let arr = key
          arr11[arr] = NewObj[key]
        }
      }
      if (Object.keys(arr11).length > 0) {
        arr_obj.push(arr11) // Push arr11 into arr_obj if it contains changes
      }

      if (arr_obj.length > 0) {
        const upda: any = await Apiservices.updateNetSuite(getValues2('netSuite_employee_id'), {
          arr_obj,
        })
        if (upda.statusCode == 200) {
          toast.success('NetSuite details are updated successfully')
        }
      }
    } else {
      setShowBackDrop(true)
      const res: any = await Apiservices.getEmployee(data5)
      setShowBackDrop(false)
      if (res && res.statusCode == 200) {
        if (res.message == 'SUCCESS') {
          setValue2('netSuite_employee_id', res.netsuiteId)
        } else if (res.message == 'This entity already exists') {
          setValue2('netSuite_employee_id', res.data.netsuiteId)
        }
        SuccessToast('NetSuite ID fetched Successfully')
        setnetconsultantOpen(true)
        setDisableNetSuiteConsultant(true)
      } else {
        ErrorToast(res.message)
      }
    }
  }
  const handleNetSuiteProduct = async () => {
    let data5: any = {
      itemid: getValues2('netSuite_product_name'), //employeename-projectname
      departmentName: projectid?.department_name,
      subsidiaryName: localStorage.getItem('subsidary_name'),
      includechildren: true,
    }
    setShowBackDrop(true)
    const res: any = await Apiservices.getService(data5)
    setShowBackDrop(false)
    if (res && res.statusCode == 200) {
      if (res && res.message == 'SUCCESS') {
        setValue2('netSuite_product_id', res.netsuiteId)
        // } else if (res && res.message == 'This Service Item is Already existing') {
      } else if (res && res.errorMessage == 'This Service Item is Already existing') {
        setValue2('netSuite_product_id', res.netsuiteId)
      }
      toast.success('NetSuite ID fetched Successfully')
      setnetproductOpen(true)
      setDisableNetSuiteProduct(true)
    } else {
      toast.error(res.message)
    }
  }

  const handleProjectName1 = async () => {
    let projectdata5: any = {
      id: 'PR' + projectid?.id,
      project_name: getValues2('netSuite_project_name'),
      project_status: statusname,
      // start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      end_date: endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : cancelDate
        ? moment(cancelDate).format('YYYY-MM-DD')
        : null,
      department_name: projectid?.department_name,
      customerId: getValues2('netSuite_customer_id'),
    }

    if (projectid.netsuite_project_id) {
      let NewObj: any = {
        id: 'PR' + projectid?.id,
        project_name: getValues2('netSuite_project_name'),
        project_status: statusname,
        start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        end_date: endDate
          ? moment(endDate).format('YYYY-MM-DD')
          : cancelDate
          ? moment(cancelDate).format('YYYY-MM-DD')
          : null,
        department_name: projectid?.department_name,
        customerId: getValues2('netSuite_customer_id'),
      }
      let keys1 = Object.keys(OldProjectObj)
      let arr_obj = []
      let arr11: any = {}
      for (let key of keys1) {
        if (OldProjectObj[key] !== NewObj[key]) {
          let arr = key
          arr11[arr] = NewObj[key]
        }
      }
      if (Object.keys(arr11).length > 0) {
        arr_obj.push(arr11) // Push arr11 into arr_obj if it contains changes
      } else {
        arr11['id'] = 'PR' + projectid?.id
        arr_obj.push(arr11)
      }
      if (arr_obj.length > 0) {
        setShowBackDrop(true)
        const upda: any = await Apiservices.updateNetSuiteProject(projectid?.netsuite_project_id, {
          arr_obj,
        })
        setShowBackDrop(false)
        if (upda.statusCode == 200) {
          if (upda.message == 'SUCCESS') {
            setNetProjectOpen(true)
            setDisableNetProject(true)
            setValue2('netSuite_project_id', upda.netsuiteId)
            toast.success('NetSuite details are updated successfully')
          }
        } else {
          toast.error(upda.message)
        }
      }
    } else {
      setShowBackDrop(true)
      const res: any = await Apiservices.getProject(projectdata5)
      setShowBackDrop(false)
      if (res && res.statusCode == 200) {
        if (res && res.message == 'SUCCESS') {
          setValue2('netSuite_project_id', res.netsuiteId)
          toast.success('NetSuite ID fetched Successfully')
          setNetProjectOpen(true)
          setDisableNetProject(true)
          if (projectid.netsuite_consultant_id && res.netsuiteId) {
            let empproj = {
              netsuite_consultant_id: projectid.netsuite_consultant_id,
              netsuite_project_id: res.netsuiteId,
              project_id: projectid?.id,
            }
            const projEmpMap: any = await Apiservices.NetSuiteMapProjectEmployee(empproj)
          }
        } else if (res && res.message == 'This entity already exists') {
          setValue2('netSuite_project_id', res.data.netsuiteId)
          toast.success('NetSuite ID fetched Successfully')
          setNetProjectOpen(true)
          setDisableNetProject(true)

          if (projectid.netsuite_consultant_id && !projectid.netsuite_project_id) {
            let empproj = {
              netsuite_consultant_id: projectid.netsuite_consultant_id,
              netsuite_project_id: res.data.netsuiteId,
              project_id: projectid?.id,
            }
            const projEmpMap: any = await Apiservices.NetSuiteMapProjectEmployee(empproj)
          }
        }
      } else {
        toast.error(res.message)
      }
    }
  }

  const StatusOptions: any = [
    {
      label: 'In Progress',
      value: 'CANDIDATE_JOINED',
    },
    {
      label: 'Initiated',
      value: 'PENDING_WITH_PAPER_WORK',
    },

    {
      label: 'Completed',
      value: 'PLACEMENT_ENDED',
    },

    {
      label: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      label: 'Rejected',
      value: 'REJECTED',
    },
  ]
  const handleBusinessUnit = async (business_id: any) => {
    setValue('business_unit_id', business_id)
  }

  const handleThresholdType = async (type: any) => {
    setValue('ot_type', type)
    setThresholdType(type)
  }

  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }

  useEffect(() => {
    renderThings()
  }, [])

  const tokenHeader1: any = useContext(ValidContext)
  const renderThings1 = async () => {
    tokenHeader1.getValidate()
  }

  // useEffect(() => {
  //   renderThings1()
  // }, [])

  const getOrganizationSetting = async () => {
    setShowBackDrop(true)
    let get_org_id = localStorage.getItem('org_id')
    const res: any = await Apiservices.getOrganizationSetting({org_id: get_org_id})
    if (res.data.isError == false) {
      setDisableQuickBooks(res.data.data[0].enable_quickbooks)
      setDisableNetSuite(res.data.data[0].enable_netsuite)
      localStorage.setItem('enable_quickbooks', res.data.data[0].enable_quickbooks)
      localStorage.setItem('enable_netsuite', res.data.data[0].enable_netsuite)
      localStorage.setItem('subsidary_name', res.data.data[0].subsidary_name)
      localStorage.setItem('enable_email', res && res?.data?.data[0]?.enable_email)
    }
    setShowBackDrop(false)
  }
  const getQBTransactionsSettingByName = async () => {
    const res: any = await Apiservices.getQBTransactionsSettingByName({
      name: 'getcustomerdatabyname',
    })
    setDisablecustomerdatabynameQuickBooks(res?.data?.data?.[0]?.is_enabled)
    const res1: any = await Apiservices.getQBTransactionsSettingByName({
      name: 'getitemdatabyname',
    })
    setDisableitemdatabynameQuickBooks(res1?.data?.data?.[0].is_enabled)
  }

  useEffect(() => {
    getOrganizationSetting()
    get_business_units_list()
    // getQBTransactionsSettingByName()
  }, [])

  const get_business_units_list = async () => {
    setShowBackDrop(true)
    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      setBusinessUnitsList(res.data.data)
    }
    setShowBackDrop(false)
  }
  const viewEmployee = async (id: any) => {
    setShowBackDrop(true)
    let state: any = location.state
    let pro_id = projectid?.id
    // primary ? true : false ||
    if (location.state || pro_id) {
      setShowBackDrop(true)
      setStateEdit(state?.view ? state?.view : '')
      // setDisablePrimaryProject(primary ? true : false)
      const response: any = await Apiservices.getprojectbyid({id: state?.id ? state?.id : pro_id})
      if (response.data.isError == false) {
        const {
          placement_type,
          netsuite_consultant_id,
          netsuite_vendor_id,
          netsuite_project_id,
          project_employee_netsuite,
          id,
        } = response?.data?.data

        const isW2OrInternal = placement_type == 'W2' || placement_type == 'Internal'
        const hasRequiredIds = isW2OrInternal
          ? netsuite_consultant_id && netsuite_project_id
          : netsuite_vendor_id && netsuite_project_id

        if (hasRequiredIds && project_employee_netsuite !== true) {
          let empproj = {
            netsuite_consultant_id: isW2OrInternal ? netsuite_consultant_id : netsuite_vendor_id,
            netsuite_project_id,
            project_id: id,
          }
          const projEmpMap: any = await Apiservices.NetSuiteMapProjectEmployee(empproj)
        }

        // if (
        //   response?.data?.data?.netsuite_consultant_id &&
        //   response?.data?.data?.netsuite_project_id
        // ) {
        //   if (response?.data?.data?.project_employee_netsuite != true) {
        //     let empproj = {
        //       netsuite_consultant_id:
        //         response.data.data.placement_type == 'W2' ||
        //         response.data.data.placement_type == 'Internal'
        //           ? response?.data?.data?.netsuite_consultant_id
        //           : response?.data?.data?.netsuite_vendor_id,
        //       netsuite_project_id: response?.data?.data?.netsuite_project_id,
        //       project_id: response?.data?.data?.id,
        //     }
        //     const projEmpMap: any = await Apiservices.NetSuiteMapProjectEmployee(empproj)
        //   }
        // }

        let netSuiteConsultantResponse: any = {
          employee_type: response?.data?.data?.placement_type,
          first_name: response?.data?.data?.netsuite_first_name,
          middle_name: response?.data?.data?.netsuite_middle_name,
          last_name: response?.data?.data?.netsuite_last_name,
          subsidiaryName: localStorage.getItem('subsidary_name'),
          email: response?.data?.data?.email,
          phone_number: response?.data?.data?.phone_number,
          department_name: response?.data?.data?.department_name,
          country_name: response?.data?.data?.work_country,
          city: response?.data?.data?.work_city,
          state_name: response?.data?.data?.work_state,
          joining_date: response?.data?.data?.joining_date,
          zipcode: response?.data?.data?.zipcode,
          employee_id: response?.data?.data?.employee_id,
          isjobresource: true,
          classificationName: response?.data?.data?.project_business_unit_name,
        }
        setOldObj1(netSuiteConsultantResponse)

        const res: any = await Apiservices.getQBTransactionsSettingByName({
          name: 'getcustomerdatabyname',
          business_unit_id: response?.data?.data?.business_unit_id,
        })
        setDisablecustomerdatabynameQuickBooks(res?.data?.data?.[0]?.is_enabled)
        const res1: any = await Apiservices.getQBTransactionsSettingByName({
          name: 'getitemdatabyname',
          business_unit_id: response?.data?.data?.business_unit_id,
        })
        setDisableitemdatabynameQuickBooks(res1?.data?.data?.[0].is_enabled)
        const res2: any = await Apiservices.qbTransastion({
          name: 'getemployeedatabyname',
          business_unit_id: response?.data?.data?.business_unit_id,
        })
        setDisableemployeedatabynameQuickBooks(res2?.data?.data?.[0]?.is_enabled)

        const business1: any = await Apiservices.get_business_units_list()
        if (business1 && business1.status == 200) {
          if (response?.data?.data?.business_unit_id) {
            let businessValueValidating = business1?.data?.data.filter(
              (bus: any) => bus.business_unit_id == response?.data?.data?.business_unit_id
            )
            if (businessValueValidating?.length > 0) {
              setIndividualBU(
                businessValueValidating &&
                  businessValueValidating[0] &&
                  businessValueValidating[0].enable_quickbooks
              )
            }
            if (businessValueValidating.length == 0) {
              setValue('business_unit_id', response?.data?.data?.business_unit_id)

              let abc1 = {
                business_unit_id: response?.data?.data?.business_unit_id,
                // name: response?.data?.data?.business_unit_name,
                name: response?.data?.data?.project_business_unit_name,
              }
              let abc = [...BusinessUnitsList, abc1]
              setBusinessUnitsList(abc)
            }
          }
        }
        setValue2('netSuite_customer_id', response?.data?.data?.netsuite_customer_id)
        setValue2('netSuite_vendor_id', response?.data?.data?.netsuite_vendor_id)
        setValue2('netSuite_product_id', response?.data?.data?.netsuite_item_id)
        let data5: any = {
          id: 'CR' + response?.data?.data?.customer_table_id,
          client_name: response?.data?.data?.netsuite_customer_name,
          subsidiaryName: localStorage.getItem('subsidary_name'),
          entitystatus: 13,
          client_code: response?.data?.data?.client_code,
        }
        setOldObj(data5)

        let ChangedProjectStatus =
          response?.data?.data?.project_status == 'IN_PROGRESS'
            ? 'In Progress'
            : response?.data?.data?.project_status == 'INITIATED'
            ? 'Initiated'
            : response?.data?.data?.project_status == 'COMPLETED'
            ? 'Completed'
            : response?.data?.data?.project_status == 'CANCELLED'
            ? 'Cancelled'
            : response?.data?.data?.project_status == 'REJECTED'
            ? 'Rejected'
            : null

        let project5: any = {
          id: 'PR' + response?.data?.data?.id,
          project_name: response?.data?.data?.project_name,
          project_status: ChangedProjectStatus,
          start_date: response?.data?.data?.start_date,
          end_date: response?.data?.data?.end_date
            ? response?.data?.data?.end_date
            : response?.data?.data?.cancel_date,
          department_name: response?.data?.data?.department_name,
          customerId: getValues2('netSuite_customer_id'),
        }
        setOldProjectObj(project5)
        setShowBackDrop(false)
        setAllFieldsToDisabled(true)
        setStatusDisabledField(false)
        if (response?.data?.data?.is_primary_project) {
          setDisablePrimaryProject(true)
        } else {
          setDisablePrimaryProject(false)
        }
        if (response.data.data.qb_customer_id) {
          setClientOpen(true)
          setDisableQBClient(true)
          setDisabled(true)
        }
        if (response?.data?.data?.netsuite_customer_id) {
          setnetOpen(true)
          setDisableNetSuiteClient(true)
          setDisabled(true)
        }
        if (response?.data?.data?.netsuite_vendor_id) {
          setnetVendorOpen(true)
          setDisableNetSuiteVendor(true)
          setDisabled(true)
        }
        if (response?.data?.data?.netsuite_item_id) {
          setnetproductOpen(true)
          setDisableNetSuiteProduct(true)
          setDisabled(true)
        }
        if (response?.data?.data?.qb_employee_id) {
          setEmployeeOpen(true)
          setDisableQBEmployee(true)
        }
        if (response?.data?.data?.netsuite_consultant_id) {
          setnetconsultantOpen(true)
          setDisableNetSuiteConsultant(true)
        }
        if (edit == true) {
          setDisabled(true)
        }
        if (response?.data?.data?.qb_product_id) {
          setProductOpen(true)
          setDisableQBProduct(true)
          setDisable(true)
        }
        // if (response.data.data.qb_ot_product_id) {
        if (response?.data?.data?.ot_product) {
          setOTProductOpen(true)
          setDisableOTQBProduct(true)
          setDisable(true)
          setOTProduct(true)
        }
        if (response?.data?.data?.netsuite_ot_product) {
          setNetSuiteOTProductOpen(true)
          setDisableNetSuiteOTQBProduct(true)
          setDisable(true)
          setNetSuiteOTProduct(true)
        }
        if (response?.data?.data?.qb_project_id) {
          setProjectOpen(true)
          setDisableQBProject(true)
          setDisable(true)
        }
        if (response?.data?.data?.netsuite_project_id) {
          setNetProjectOpen(true)
          setDisableNetProject(true)
        }
        setVendorName(
          response?.data?.data?.project_subvendors &&
            response?.data?.data?.project_subvendors[0]?.subvendor_name
        )
        setVendorId(
          response?.data?.data?.project_subvendors &&
            response?.data?.data?.project_subvendors[0]?.subvendor_id
        )
        setProjectid(response?.data?.data)
        setExpectedHoursHistory(response?.data?.data?.project_expected_hours)
        setDisplayQB(true)
        //   let start_date_format: any = response?.data?.data?.joining_date
        //   ? new Date(response.data.data.joining_date)
        //   : response?.data?.data?.start_date
        //   ? new Date(response.data.data.start_date)
        //   : null
        // let end_date_format: any = response?.data?.data?.end_date
        //   ? new Date(response?.data?.data?.end_date)
        //   : null
        // let cancel_date_format: any = response?.data?.data?.cancel_date
        //   ? new Date(response?.data?.data?.cancel_date)
        //   : null

        let start_date_format: any = response?.data?.data?.joining_date
          ? new Date(moment(response?.data?.data?.joining_date, 'YYYY-MM-DD').format('MM-DD-YYYY'))
          : response?.data?.data?.start_date
          ? new Date(moment(response?.data?.data?.start_date, 'YYYY-MM-DD').format('MM-DD-YYYY'))
          : null
        let end_date_format: any = response?.data?.data?.end_date
          ? new Date(moment(response?.data?.data?.end_date, 'YYYY-MM-DD').format('MM-DD-YYYY'))
          : null
        let cancel_date_format: any = response?.data?.data?.cancel_date
          ? new Date(moment(response?.data?.data?.cancel_date, 'YYYY-MM-DD').format('MM-DD-YYYY'))
          : null

        // let effective_date_format: any =
        //   response &&
        //   response.data &&
        //   response.data.data &&
        //   response.data.data.project_expected_hours &&
        //   response.data.data.project_expected_hours[0] &&
        //   response.data.data.project_expected_hours[0].effective_date &&
        //   response?.data?.data?.project_expected_hours[0]?.effective_date
        //     ? new Date(response?.data?.data?.project_expected_hours[0]?.effective_date)
        //     : null
        // moment(response.data.data.start_date, 'YYYY-MM-dd').format('dd/MM/yyyy')
        // )
        // setValue('start_date', start_date_format)
        // setValue('effective_date', effective_date_format)
        let project_expected_hours_array = response?.data?.data?.project_expected_hours
        let ProjDetailsArray = []

        for (var i = 0; i < project_expected_hours_array?.length; i++) {
          let ProjObj: any = {
            effective_date: project_expected_hours_array[i].effective_date,
            expected_hours: project_expected_hours_array[i].expected_hours,
            id: project_expected_hours_array[i].id,
          }
          ProjDetailsArray.push(ProjObj)
        }
        setExpectedHoursData([...ProjDetailsArray])
        setStartDate(start_date_format)
        setEndDate(end_date_format)
        setCancelDate(cancel_date_format)
        let dataSet = {
          ...response.data.data,
          qb_product_name: response?.data?.data?.qb_product_name
            ? response?.data?.data?.qb_product_name
            : response?.data?.data?.placement_code,
          qb_employee_name: response.data.data.qb_employee_name
            ? response.data.data.qb_employee_name
            : response.data.data.user_full_name
            ? response.data.data.user_full_name
            : '',
          ot_qb_product_name: response?.data?.data?.qb_ot_product_name
            ? response?.data?.data?.qb_ot_product_name
            : '',
          netSuite_ot_product_name: response?.data?.data?.netsuite_ot_product_name
            ? response?.data?.data?.netsuite_ot_product_name
            : '',
          status:
            response.data.data.project_status == 'IN_PROGRESS'
              ? 'In Progress'
              : response.data.data.project_status == 'INITIATED'
              ? 'Initiated'
              : response.data.data.project_status == 'COMPLETED'
              ? 'Completed'
              : response.data.data.project_status == 'CANCELLED'
              ? 'Cancelled'
              : response.data.data.project_status == 'REJECTED'
              ? 'Rejected'
              : null,
          reasons: response.data.data.reason,
          project_type:
            // response.data.data.is_placement_project == false ? 'Local' : 'Placement',
            response?.data?.data?.is_placement_project
              ? response?.data?.data?.is_placement_project == 1
                ? '1'
                : response?.data?.data?.is_placement_project == 2
                ? '2'
                : response?.data?.data?.is_placement_project == 3
                ? '3'
                : '-'
              : '-',
          placement_type: response?.data?.data?.placement_type
            ? response?.data?.data?.placement_type
            : '-',
          direct_customer_engagement: response.data.data.direct_customer_engagement,
          primary_project: response.data.data.is_primary_project,
          qb_customer_name: response?.data?.data?.qb_customer_name
            ? response?.data?.data?.qb_customer_name
            : response &&
              response.data &&
              response.data.data &&
              response.data.data.project_vendors &&
              response.data.data.project_vendors[0].client_name
            ? `${response?.data?.data?.project_vendors[0]?.client_name}`
            : `${response?.data?.data?.end_customer_name}`,
          netSuite_customer_name: response?.data?.data?.netsuite_customer_name
            ? response?.data?.data?.netsuite_customer_name
            : response &&
              response.data &&
              response.data.data &&
              response.data.data.project_vendors &&
              response.data.data.project_vendors[0].client_name
            ? `${response?.data?.data?.project_vendors[0]?.client_name}`
            : `${response?.data?.data?.end_customer_name}`,
          netSuite_vendor_name: response?.data?.data?.netsuite_vendor_name
            ? response?.data?.data?.netsuite_vendor_name
            : response?.data?.data?.subvendor_name
            ? response?.data?.data?.subvendor_name
            : null,
          netSuite_product_name: response?.data?.data?.netsuite_item_name
            ? response?.data?.data?.netsuite_item_name
            : response && response.data && response.data.data && response.data.data.project_name,
          netSuite_employee_id: response?.data?.data?.netsuite_consultant_id,
          netSuite_customer_id: response?.data?.data?.netsuite_customer_id,
          netSuite_vendor_id: response?.data?.data?.netsuite_vendor_id,
          netSuite_product_id: response?.data?.data?.netsuite_item_id,
          netSuite_project_id: response?.data?.data?.netsuite_project_id,
          ot_qb_product_id: response?.data?.data?.qb_ot_product_id,
          netSuite_ot_product_id: response?.data?.data?.netsuite_ot_product_id,
          qb_project_name: response?.data?.data?.qb_project_name
            ? response?.data?.data?.qb_project_name
            : response &&
              response.data &&
              response.data.data &&
              response.data.data.project_vendors &&
              response.data.data.project_vendors[0].client_name
            ? `${response?.data?.data?.project_vendors[0]?.client_name}:${response?.data?.data?.user_full_name}`
            : `${response?.data?.data?.end_customer_name}:${response?.data?.data?.user_full_name}`,
          netSuite_project_name: response?.data?.data?.netsuite_project_name
            ? response?.data?.data?.netsuite_project_name
            : response &&
              response.data &&
              response.data.data &&
              response.data.data.project_vendors &&
              response.data.data.project_vendors[0].client_name
            ? `${response?.data?.data?.project_vendors[0]?.client_name}:${response?.data?.data?.user_full_name}`
            : `${response?.data?.data?.end_customer_name}:${response?.data?.data?.user_full_name}`,
          netSuite_first_name: response?.data?.data?.netsuite_first_name
            ? response?.data?.data?.netsuite_first_name
            : response?.data?.data?.netsuite_employee_name
            ? response?.data?.data?.netsuite_employee_name
            : response?.data?.data?.user_full_name
            ? response?.data?.data?.user_full_name
            : '',
          netSuite_middle_name: response?.data?.data?.netsuite_middle_name
            ? response?.data?.data?.netsuite_middle_name
            : null,
          netSuite_last_name: response?.data?.data?.netsuite_last_name
            ? response?.data?.data?.netsuite_last_name
            : null,
          // currency: 'USD',
          placement_code: response.data.data.placement_code,
          project_name: response.data.data.project_name,
          effective_date:
            response &&
            response.data &&
            response.data.data &&
            response.data.data.project_expected_hours &&
            response.data.data.project_expected_hours[0] &&
            response.data.data.project_expected_hours[0].effective_date &&
            response?.data?.data?.project_expected_hours[0]?.effective_date
              ? new Date(response?.data?.data?.project_expected_hours[0]?.effective_date)
              : null,
        }
        setEffectiveDate(dataSet.effective_date)
        setValue('effective_date', dataSet.effective_date)
        setValue2('netSuite_customer_id', response?.data?.data?.netsuite_customer_id)
        setValue2('netSuite_vendor_id', response?.data?.data?.netsuite_vendor_id)
        setValue2('netSuite_product_id', response?.data?.data?.netsuite_item_id)
        setValue(
          'expected_hours',
          response &&
            response.data &&
            response.data.data &&
            response.data.data.project_expected_hours &&
            response.data.data.project_expected_hours[0] &&
            response.data.data.project_expected_hours[0].expected_hours
        )
        setPlacement(dataSet.placement_code)
        setStatusName(dataSet.status)
        setProjType(dataSet.project_type)
        setConsultantOption(dataSet.placement_type)
        setStatusReasons([{reason: dataSet.reasons}])
        setDirectCustomer(dataSet.direct_customer_engagement)
        setPrimary(dataSet.primary_project)
        setProjectWorkLocationHistory(response?.data?.data?.project_work_location_history)
        setProjectPaymentHistory(response?.data?.data?.project_bill_rates)
        const response5: any = await Apiservices.getallcountries()
        if (response && response.data && response.data.data && response?.data?.data?.work_country) {
          let stateName1 = response5?.data?.data.filter(
            (temp: any) => temp?.name == response?.data?.data?.work_country
          )
          if (stateName1) {
            setUseStateCountryId(stateName1[0]?.id)
            const response: any = await Apiservices.getstatebyname({
              country_id: stateName1[0]?.id,
            })
            if (response?.data?.data) {
              setState(response.data.data ? response.data.data : '')
            } else {
              setState([])
            }
          }
        }
        const data = [
          {
            name:
              response && response.data && response.data.data && response.data.data.work_country
                ? response?.data?.data?.work_country
                : '',
          },
        ]
        const client_name = [
          {
            client_name:
              response &&
              response.data &&
              response.data.data &&
              response.data.data.project_vendors &&
              response.data.data.project_vendors[0] &&
              response.data.data.project_vendors[0].client_name
                ? response?.data?.data?.project_vendors[0]?.client_name
                : '',
          },
        ]
        const end_customer = [
          {
            client_name:
              response &&
              response.data &&
              response.data.data &&
              response.data.data &&
              response.data.data.end_customer_name
                ? response?.data?.data?.end_customer_name
                : '',
          },
        ]
        const candidate = [
          {
            full_name:
              response &&
              response.data &&
              response.data.data &&
              response.data.data &&
              response.data.data.user_full_name
                ? response?.data?.data?.user_full_name
                : '',
            id:
              response &&
              response.data &&
              response.data.data &&
              response.data.data &&
              response.data.data.user_id
                ? response?.data?.data?.user_id
                : '',
          },
        ]
        const state1 = [
          {
            name:
              response &&
              response.data &&
              response.data.data &&
              response.data.data &&
              response.data.data.work_state
                ? response?.data?.data?.work_state
                : '',
          },
        ]

        setSelectConsultant(candidate)
        setSelectCustomer(end_customer)
        setSelectClient(client_name)
        setCountries(data)
        setStates(state1)
        if (response?.data?.data?.ot_type) {
          setThresholdType(response?.data?.data?.ot_type)
          dataSet.ot_type = response?.data?.data?.ot_type
        } else {
          setThresholdType('Daily')
          dataSet.ot_type = 'Daily'
        }
        reset(dataSet)
        reset1(dataSet)
        reset2(dataSet)
        if (
          response?.data?.data?.user_business_unit_name ==
          response?.data?.data?.project_business_unit_name
        ) {
          if (!response?.data?.data?.qb_customer_id) {
            let qb_client_id_checking_payload =
              response &&
              response.data &&
              response.data.data &&
              response.data.data.project_vendors &&
              response.data.data.project_vendors[0].customer_id
                ? response?.data?.data?.project_vendors[0]?.customer_id
                : response?.data?.data?.end_customer_id

            const qb_client_id_checking: any = await Apiservices.getclientbyid({
              id: qb_client_id_checking_payload,
            })
            if (qb_client_id_checking.data.isError == false) {
              if (qb_client_id_checking?.data?.data?.qb_customer_id) {
                setValue1('qb_customer_id', qb_client_id_checking?.data?.data?.qb_customer_id)
                setClientOpen(true)
                setDisableQBClient(true)
                setDisabled(true)
              }
            }
          }
        }
      }
    }
    setShowBackDrop(false)
  }

  useEffect(() => {
    let state: any = location.state
    if (location.state) {
      viewEmployee(state.id)
    }
  }, [])

  useEffect(() => {
    handleConsultant()
    handleClient()
  }, [])

  const userName = (client_manager_name: any) => {
    return client_manager_name?.charAt(0).toUpperCase() + client_manager_name?.slice(1)
  }
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  const onSubmit = async (data: FormData) => {
    setShowBackDrop(true)
    let organization = localStorage.getItem('org_id')
    // let data1 = getValues1()
    // let data2 = getValues2()

    let qb_data = getValues1()
    let netSuite_data = getValues2()

    let data1 = formTwoData
    let data2 = formThreeData
    // const project_type = proj_type == 'Local' ? false : true
    const project_type = proj_type == '1' ? 1 : proj_type == '2' ? 2 : proj_type == '3' && 3
    let end_customer_id = client?.filter(
      (each: any) => each.client_name === select_customer[0]?.client_name
    )
    let client_id = client?.filter(
      (each: any) => each.client_name === select_client[0]?.client_name
    )
    let reason_id = statusreasons?.filter((each: any) => each.reason == getValues('reasons'))
    const article = {
      bill_rate_currency: null,
      vendors: [
        {
          id: client_id?.[0]?.id ? client_id?.[0]?.id : null,
        },
      ],
      // cancel_date: data.cancel_date ? data.cancel_date : null,
      ot_type: data?.ot_type ? data?.ot_type : null,
      // threshold: data?.threshold ? Number(data?.threshold) : null,
      cancel_date: cancelDate ? moment(cancelDate).format('YYYY-MM-DD') : null,
      business_unit_id: Number(data.business_unit_id),
      reason: data.reasons ? data.reasons : null,
      subvendor_id: VendorId ? VendorId : null,
      subvendor_name: VendorName ? VendorName : null,
      placement_project_id: placementData?.id ? placementData?.id : null,
      direct_customer_engagement: data.direct_customer_engagement
        ? data.direct_customer_engagement
        : false,

      end_customer_id: end_customer_id?.[0]?.id ? end_customer_id?.[0]?.id : null,
      // end_customer_id: 255,
      user_id: select_consultant?.[0]?.id ? select_consultant?.[0]?.id : null,
      work_country: countries[0]?.name ? countries[0]?.name : null,
      work_state: states[0]?.name ? states[0]?.name : null,
      placement_type: data.placement_type ? data.placement_type : null,
      is_placement_project: project_type,
      project_name: data.project_name ? data.project_name : null,
      // bill_rate: Number(data.bill_rate) ? Number(data.bill_rate) : null,
      // pay_rate: Number(data.pay_rate) ? Number(data.pay_rate) : null,
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      // end_date: data.end_date ? data.end_date : null,
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      job_title: data.job_title ? data.job_title : null,
      placement_code: data.placement_code ? data.placement_code : null,
      project_status: statusname
        ? statusname == 'In Progress'
          ? 'IN_PROGRESS'
          : statusname == 'Initiated'
          ? 'INITIATED'
          : statusname == 'Completed'
          ? 'COMPLETED'
          : statusname == 'Cancelled'
          ? 'CANCELLED'
          : statusname == 'Rejected'
          ? 'REJECTED'
          : null
        : 'IN_PROGRESS',
      client_manager_name: userName(data.client_manager_name)
        ? userName(data.client_manager_name)
        : null,
      client_manager_email: data.client_manager_email ? data.client_manager_email : null,
      work_email: null,
      work_phone: null,
      // work_state: null,
      work_city: data.work_city ? data.work_city : null,
      qb_status: null,
      qb_customer_name: null,
      netSuite_customer_name: null,
      netSuite_vendor_name: null,
      netsuite_item_name: null,
      qb_customer_id: null,
      netSuite_customer_id: null,
      netSuite_vendor_id: null,
      netsuite_consultant_id: null,
      netsuite_item_id: null,
      qb_project_name: null,
      netsuite_project_name: null,
      qb_project_id: null,
      netsuite_project_id: null,
      qb_product_id: null,
      qb_ot_product_id: null,
      qb_product_name: null,
      qb_employee_name: null,
      qb_employee_id: null,
      qb_ot_product_name: null,
      is_primary_project: primary,
      is_updated: false,
      reason_id: reason_id[0]?.id ? reason_id[0]?.id : null,
      project_expected_hours: [
        {
          effective_date: effectiveDate ? moment(effectiveDate).format('YYYY-MM-DD') : null,
          expected_hours: data.expected_hours ? Number(data.expected_hours) : null,
        },
      ],
    }
    const article1 = {
      id: projectid?.id ? projectid?.id : null,
      user_id: projectid?.user_id ? projectid?.user_id : null,
      project_name: data.project_name ? data.project_name : null,
      subvendor_id: VendorId ? VendorId : null,
      ot_type: data?.ot_type ? data?.ot_type : null,
      // threshold: data?.threshold ? Number(data?.threshold) : null,
      subvendor_name: VendorName ? VendorName : null,
      joining_date: projectid?.joining_date ? projectid?.joining_date : null,
      // project_status: data.status ? data.status : null,
      project_status: statusname
        ? statusname == 'In Progress'
          ? 'IN_PROGRESS'
          : statusname == 'Initiated'
          ? 'INITIATED'
          : statusname == 'Completed'
          ? 'COMPLETED'
          : statusname == 'Cancelled'
          ? 'CANCELLED'
          : statusname == 'Rejected'
          ? 'REJECTED'
          : null
        : 'IN_PROGRESS',
      // start_date: data.start_date ? moment(data.start_date).format('YYYY-MM-DD') : null,
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      // end_date: data.end_date ? data.end_date : null,
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      client_manager_name: userName(data.client_manager_name)
        ? userName(data.client_manager_name)
        : null,
      client_manager_email: data.client_manager_email ? data.client_manager_email : null,
      job_title: data.job_title ? data.job_title : null,
      is_placement_project: project_type,
      placement_type: data.placement_type ? data.placement_type : null,
      placement_project_id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
      placement_code: data.placement_code ? data.placement_code : null,
      // bill_rate: Number(data.bill_rate) ? Number(data.bill_rate) : null,
      // pay_rate: Number(data.pay_rate) ? Number(data.pay_rate) : null,
      bill_rate_currency: null,
      // is_primary_project: data.primary_project,
      is_primary_project: primary,
      direct_customer_engagement: data.direct_customer_engagement
        ? data.direct_customer_engagement
        : projectid
        ? projectid.direct_customer_engagement
        : false,
      business_unit_id: Number(data.business_unit_id),
      end_customer_id: end_customer_id?.[0]?.id ? end_customer_id?.[0]?.id : null,
      qb_customer_name: data1.qb_customer_name
        ? data1.qb_customer_name
        : qb_data.qb_customer_name
        ? qb_data.qb_customer_name
        : null,
      netsuite_customer_name: data2.netSuite_customer_name
        ? data2.netSuite_customer_name
        : netSuite_data.netSuite_customer_name
        ? netSuite_data.netSuite_customer_name
        : null,
      netsuite_vendor_name: data2.netSuite_vendor_name
        ? data2.netSuite_vendor_name
        : netSuite_data.netSuite_vendor_name
        ? netSuite_data.netSuite_vendor_name
        : null,
      netsuite_first_name: getValues2('netSuite_first_name')
        ? getValues2('netSuite_first_name')
        : netSuite_data.netSuite_first_name
        ? netSuite_data.netSuite_first_name
        : null,
      netsuite_middle_name: getValues2('netSuite_middle_name')
        ? getValues2('netSuite_middle_name')
        : netSuite_data.netSuite_middle_name
        ? netSuite_data.netSuite_middle_name
        : null,
      netsuite_last_name: getValues2('netSuite_last_name')
        ? getValues2('netSuite_last_name')
        : netSuite_data.netSuite_last_name
        ? netSuite_data.netSuite_last_name
        : null,
      netsuite_item_name: data2.netSuite_product_name
        ? data2.netSuite_product_name
        : netSuite_data.netSuite_product_name
        ? netSuite_data.netSuite_product_name
        : null,
      qb_customer_id: data1.qb_customer_id
        ? data1.qb_customer_id
        : qb_data.qb_customer_id
        ? qb_data.qb_customer_id
        : null,
      netsuite_customer_id: getValues2('netSuite_customer_id')
        ? getValues2('netSuite_customer_id')
        : netSuite_data.netSuite_customer_id
        ? netSuite_data.netSuite_customer_id
        : null,
      netsuite_vendor_id: getValues2('netSuite_vendor_id')
        ? getValues2('netSuite_vendor_id')
        : netSuite_data.netSuite_vendor_id
        ? netSuite_data.netSuite_vendor_id
        : null,
      netsuite_consultant_id: getValues2('netSuite_employee_id')
        ? Number(getValues2('netSuite_employee_id'))
        : netSuite_data.netSuite_employee_id
        ? netSuite_data.netSuite_employee_id
        : null,
      netsuite_item_id: getValues2('netSuite_product_id')
        ? getValues2('netSuite_product_id')
        : netSuite_data.netSuite_product_id
        ? netSuite_data.netSuite_product_id
        : null,
      qb_project_name: data1.qb_project_name
        ? data1.qb_project_name
        : qb_data.qb_project_name
        ? qb_data.qb_project_name
        : null,
      netsuite_project_name: data2.netSuite_project_name
        ? data2.netSuite_project_name
        : netSuite_data.netSuite_project_name
        ? netSuite_data.netSuite_project_name
        : null,
      qb_project_id: data1.qb_project_id
        ? data1.qb_project_id
        : qb_data.qb_project_id
        ? qb_data.qb_project_id
        : null,
      netsuite_project_id: getValues2('netSuite_project_id')
        ? getValues2('netSuite_project_id')
        : netSuite_data.netSuite_project_id
        ? netSuite_data.netSuite_project_id
        : null,
      qb_product_id: data1.qb_product_id
        ? data1.qb_product_id
        : qb_data.qb_product_id
        ? qb_data.qb_product_id
        : null,
      qb_ot_product_id: data1.ot_qb_product_id
        ? data1.ot_qb_product_id
        : qb_data.ot_qb_product_id
        ? qb_data.ot_qb_product_id
        : null,
      netsuite_ot_product_id: getValues2('netSuite_ot_product_id')
        ? getValues2('netSuite_ot_product_id')
        : netSuite_data.netSuite_ot_product_id
        ? netSuite_data.netSuite_ot_product_id
        : null,
      qb_product_name: data1.qb_product_name
        ? data1.qb_product_name
        : qb_data.qb_product_name
        ? qb_data.qb_product_name
        : null,
      qb_employee_name: data1.qb_employee_name
        ? data1.qb_employee_name
        : qb_data.qb_employee_name
        ? qb_data.qb_employee_name
        : null,
      // qb_employee_id: data1.qb_employee_id ? data1.qb_employee_id : null,
      qb_employee_id: data1.qb_employee_id
        ? data1.qb_employee_id
        : qb_data.qb_employee_id
        ? qb_data.qb_employee_id
        : null,

      qb_ot_product_name: data1.ot_qb_product_name
        ? data1.ot_qb_product_name
        : qb_data.ot_qb_product_name
        ? qb_data.ot_qb_product_name
        : null,
      netsuite_ot_product_name: getValues2('netSuite_ot_product_name')
        ? getValues2('netSuite_ot_product_name')
        : netSuite_data.netSuite_ot_product_name
        ? netSuite_data.netSuite_ot_product_name
        : null,

      work_country: countries[0]?.name ? countries[0]?.name : null,
      work_state: states[0]?.name ? states[0]?.name : null,
      work_email: null,
      work_phone: null,
      // work_state: null,
      // work_city: null,
      work_city: data.work_city ? data.work_city : null,
      is_updated: false,
      // cancel_date: data.cancel_date ? data.cancel_date : null,
      cancel_date: cancelDate ? moment(cancelDate).format('YYYY-MM-DD') : null,
      reason: data.reasons ? data.reasons : null,
      reason_id: reason_id[0]?.id ? reason_id[0]?.id : null,
      qb_status: null,
      record_type_status: 'Active',
      vendors: [
        {
          id: client_id?.[0]?.id ? client_id?.[0]?.id : null,
        },
      ],
      project_bill_rates: [
        {
          effective_date: null,
          // bill_rate: Number(data.bill_rate),
          // agreed_pay_rate: Number(data.pay_rate),
          bill_rate_currency: null,
        },
      ],
      project_expected_hours: ExpectedHoursData,
    }

    const ecdbid = localStorage.getItem('ecdb_user_id')
    const article2 = {
      // id: projectid.id ? projectid.id : null,
      id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
      updatedBy: ecdbid ? ecdbid : null,
      // closedDate: data.end_date ? data.end_date : null,
      closedDate: endDate ? moment(endDate).format('MM-DD-YYYY') : null,
      reasonName: data.reasons ? data.reasons : null,
      reasonId: reason_id[0]?.id ? reason_id[0]?.id : null,
      placementStatusId: 9,
    }

    const article3 = {
      id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
      reasonId: reason_id[0]?.id ? reason_id[0]?.id : null,
      // terminateDate: moment(data.cancel_date).format('MM-DD-YYYY')
      //   ? moment(data.cancel_date).format('MM-DD-YYYY')
      //   : null,
      terminateDate: cancelDate ? moment(cancelDate).format('MM-DD-YYYY') : null,
      remarks: 'timetracker',
      userId: ecdbid ? ecdbid : null,
      orgId: organization,
    }

    const article4 = {
      org_id: organization,
      placement_end_date: cancelDate
        ? moment(cancelDate).format('MM-DD-YYYY')
        : endDate
        ? moment(endDate).format('MM-DD-YYYY')
        : null,
      project_status: statusname
        ? statusname == 'In Progress'
          ? 'IN_PROGRESS'
          : statusname == 'Initiated'
          ? 'INITIATED'
          : statusname == 'Completed'
          ? 'COMPLETED'
          : statusname == 'Cancelled'
          ? 'CANCELLED'
          : statusname == 'Rejected'
          ? 'REJECTED'
          : null
        : null,
      reason: data.reasons ? data.reasons : null,
      placement_project_id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
    }
    if (!projectid?.id) {
      setShowBackDrop(true)

      const response: any = await Apiservices.insertProject(article)
      setShowBackDrop(false)
      setProjectid(response.data)
      if (response.statuscode == 200) {
        const res: any = await Apiservices.getQBTransactionsSettingByName({
          name: 'getcustomerdatabyname',
          business_unit_id: Number(data.business_unit_id),
        })
        setDisablecustomerdatabynameQuickBooks(res?.data?.data?.[0]?.is_enabled)
        const res1: any = await Apiservices.getQBTransactionsSettingByName({
          name: 'getitemdatabyname',
          business_unit_id: Number(data.business_unit_id),
        })
        setDisableitemdatabynameQuickBooks(res1?.data?.data?.[0].is_enabled)
        const res2: any = await Apiservices.qbTransastion({
          name: 'getemployeedatabyname',
          business_unit_id: Number(data.business_unit_id),
        })
        setDisableemployeedatabynameQuickBooks(res2?.data?.data?.[0]?.is_enabled)
        if (statusname == 'Completed') {
          setShowBackDrop(true)
          const response1: any = await Apiservices.getPlacementCompleted(article2)
          setShowBackDrop(false)
          // } else if (proj_type == 'Placement' && statusname == 'Cancelled') {
        } else if (proj_type == '1' && statusname == 'Cancelled') {
          setShowBackDrop(true)
          const response2: any = await Apiservices.getPlacementCancel(article3)
          setShowBackDrop(false)
        }

        if (localStorage.getItem('refresh_Token') == 'false') {
          if (
            // (localStorage.getItem('role_Name') === 'superadmin' ||
            //   localStorage.getItem('role_Name') === 'admin' ||
            //   localStorage.getItem('role_Name') === 'accounts') &&
            (numArray?.includes('superadmin') ||
              numArray?.includes('admin') ||
              numArray?.includes('accounts')) &&
            (disableQuickBooks || disableNetSuite)
          ) {
            setDisplayQB(true)
          } else {
            navigate('/masters/manageprojects')
          }
        } else {
          navigate('/masters/manageprojects')
        }
      }
      if (response.message === 'Added Successfully') {
        toast.success('Inserted Successfully')
      } else {
        toast.warn('Validation Failed')
      }
      return response.data
    } else if (projectid.id) {
      setShowBackDrop(true)
      let art = {
        project_id: projectid.id,
        // isOtProduct: true,
        isOtProduct: OTProduct ? true : false,
        netsuite_ot_product: NetSuiteOTProduct ? true : false,
      }
      const OTResponse: any = await Apiservices.otProductProject(art)
      // if (OTResponse.isError == false) {
      //   setOTProduct(true)
      // }
      const Response: any = await Apiservices.updateProject(article1)
      setShowBackDrop(false)
      if (Response.isError == false) {
        if (statusname == 'Completed') {
          setShowBackDrop(true)
          const response1: any = await Apiservices.getPlacementCompleted(article2)
          setShowBackDrop(false)
          // } else if (proj_type == 'Placement' && statusname == 'Cancelled') {
        } else if (proj_type == '1' && statusname == 'Cancelled') {
          setShowBackDrop(true)
          const response2: any = await Apiservices.getPlacementCancel(article3)
          setShowBackDrop(false)
        }
        if (proj_type == '1' && (statusname == 'Completed' || statusname == 'Cancelled')) {
          const update_project_status: any = await Apiservices.update_C2C_user_project_status(
            article4
          )
          if (update_project_status.isError) {
            console.log(update_project_status)
          }
        }
        toast.success('Updated Successfully')
        navigate('/masters/manageprojects')
      } else {
        toast.error('Failed to update ')
      }
      return Response.data
    }
    setShowBackDrop(false)
  }

  const onSubmit1 = async (data1: FormData1) => {
    setShowBackDrop(true)
    // let data = getValues()
    // let data2 = getValues2()
    let data = formOneData
    let data2 = formThreeData
    let organization = localStorage.getItem('org_id')
    // const project_type = proj_type == 'Local' ? false : true
    const project_type = proj_type == '1' ? 1 : proj_type == '2' ? 2 : proj_type == '3' && 3
    let end_customer_id = client?.filter(
      (each: any) => each.client_name === select_customer[0]?.client_name
    )
    let client_id = client?.filter(
      (each: any) => each.client_name === select_client[0]?.client_name
    )
    let reason_id = statusreasons?.filter((each: any) => each.reason == getValues('reasons'))

    const article1 = {
      id: projectid?.id ? projectid?.id : null,
      user_id: projectid?.user_id ? projectid?.user_id : null,
      project_name: data.project_name ? data.project_name : null,
      subvendor_id: VendorId ? VendorId : null,
      subvendor_name: VendorName ? VendorName : null,
      joining_date: projectid?.joining_date ? projectid?.joining_date : null,
      // project_status: data.status ? data.status : null,
      project_status: statusname
        ? statusname == 'In Progress'
          ? 'IN_PROGRESS'
          : statusname == 'Initiated'
          ? 'INITIATED'
          : statusname == 'Completed'
          ? 'COMPLETED'
          : statusname == 'Cancelled'
          ? 'CANCELLED'
          : statusname == 'Rejected'
          ? 'REJECTED'
          : null
        : 'IN_PROGRESS',
      // start_date: data.start_date ? moment(data.start_date).format('YYYY-MM-DD') : null,
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      // end_date: data.end_date ? data.end_date : null,
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      client_manager_name: userName(data.client_manager_name)
        ? userName(data.client_manager_name)
        : null,
      client_manager_email: data.client_manager_email ? data.client_manager_email : null,
      job_title: data.job_title ? data.job_title : null,
      is_placement_project: project_type,
      placement_type: data.placement_type ? data.placement_type : null,
      placement_project_id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
      placement_code: data.placement_code ? data.placement_code : null,
      // bill_rate: Number(data.bill_rate) ? Number(data.bill_rate) : null,
      // pay_rate: Number(data.pay_rate) ? Number(data.pay_rate) : null,
      bill_rate_currency: null,
      // is_primary_project: data.primary_project,
      is_primary_project: primary,
      direct_customer_engagement: data.direct_customer_engagement
        ? data.direct_customer_engagement
        : projectid
        ? projectid.direct_customer_engagement
        : false,
      business_unit_id: Number(data.business_unit_id),
      end_customer_id: end_customer_id?.[0]?.id ? end_customer_id?.[0]?.id : null,
      qb_customer_name: data1.qb_customer_name ? data1.qb_customer_name : null,
      netsuite_customer_name: data2.netSuite_customer_name ? data2.netSuite_customer_name : null,
      netsuite_vendor_name: data2.netSuite_vendor_name ? data2.netSuite_vendor_name : null,
      netsuite_first_name: getValues2('netSuite_first_name')
        ? getValues2('netSuite_first_name')
        : null,
      netsuite_middle_name: getValues2('netSuite_middle_name')
        ? getValues2('netSuite_middle_name')
        : null,
      netsuite_last_name: getValues2('netSuite_last_name')
        ? getValues2('netSuite_last_name')
        : null,
      netsuite_item_name: data2.netSuite_product_name ? data2.netSuite_product_name : null,
      qb_customer_id: data1.qb_customer_id ? data1.qb_customer_id : null,
      netsuite_customer_id: getValues2('netSuite_customer_id')
        ? getValues2('netSuite_customer_id')
        : null,
      netsuite_vendor_id: getValues2('netSuite_vendor_id')
        ? getValues2('netSuite_vendor_id')
        : null,
      netsuite_consultant_id: getValues2('netSuite_employee_id')
        ? Number(getValues2('netSuite_employee_id'))
        : null,
      netsuite_item_id: getValues2('netSuite_product_id')
        ? getValues2('netSuite_product_id')
        : null,
      qb_project_name: data1.qb_project_name ? data1.qb_project_name : null,
      netsuite_project_name: data2.netSuite_project_name ? data2.netSuite_project_name : null,
      qb_project_id: data1.qb_project_id ? data1.qb_project_id : null,
      netsuite_project_id: getValues2('netSuite_project_id')
        ? getValues2('netSuite_project_id')
        : null,
      qb_product_id: data1.qb_product_id ? data1.qb_product_id : null,
      qb_ot_product_id: data1.ot_qb_product_id ? data1.ot_qb_product_id : null,
      netsuite_ot_product_id: getValues2('netSuite_ot_product_id')
        ? getValues2('netSuite_ot_product_id')
        : null,
      qb_product_name: data1.qb_product_name ? data1.qb_product_name : null,
      qb_employee_name: data1.qb_employee_name ? data1.qb_employee_name : null,
      qb_employee_id: data1.qb_employee_id ? data1.qb_employee_id : null,
      qb_ot_product_name: data1.ot_qb_product_name ? data1.ot_qb_product_name : null,
      netsuite_ot_product_name: getValues2('netSuite_ot_product_name')
        ? getValues2('netSuite_ot_product_name')
        : null,
      work_country: countries[0]?.name ? countries[0]?.name : null,
      work_state: states[0]?.name ? states[0]?.name : null,
      work_email: null,
      work_phone: null,
      // work_state: null,
      // work_city: null,
      work_city: data.work_city ? data.work_city : null,
      is_updated: false,
      // cancel_date: data.cancel_date ? data.cancel_date : null,
      cancel_date: cancelDate ? moment(cancelDate).format('YYYY-MM-DD') : null,
      reason: data.reasons ? data.reasons : null,
      reason_id: reason_id[0]?.id ? reason_id[0]?.id : null,
      qb_status: null,
      record_type_status: 'Active',
      vendors: [
        {
          id: client_id?.[0]?.id ? client_id?.[0]?.id : null,
        },
      ],
      project_bill_rates: [
        {
          effective_date: null,
          // bill_rate: Number(data.bill_rate),
          // agreed_pay_rate: Number(data.pay_rate),
          bill_rate_currency: null,
        },
      ],
      project_expected_hours: ExpectedHoursData,
    }

    const ecdbid = localStorage.getItem('ecdb_user_id')
    const article2 = {
      // id: projectid.id ? projectid.id : null,
      id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
      updatedBy: ecdbid ? ecdbid : null,
      // closedDate: data.end_date ? data.end_date : null,
      closedDate: endDate ? moment(endDate).format('MM-DD-YYYY') : null,
      reasonName: data.reasons ? data.reasons : null,
      reasonId: reason_id[0]?.id ? reason_id[0]?.id : null,
      placementStatusId: 9,
    }

    const article3 = {
      id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
      reasonId: reason_id[0]?.id ? reason_id[0]?.id : null,
      // terminateDate: moment(data.cancel_date).format('MM-DD-YYYY')
      //   ? moment(data.cancel_date).format('MM-DD-YYYY')
      //   : null,
      terminateDate: cancelDate ? moment(cancelDate).format('MM-DD-YYYY') : null,
      remarks: 'timetracker',
      userId: ecdbid ? ecdbid : null,
      orgId: organization,
    }

    const article4 = {
      org_id: organization,
      placement_end_date: cancelDate
        ? moment(cancelDate).format('MM-DD-YYYY')
        : endDate
        ? moment(endDate).format('MM-DD-YYYY')
        : null,
      project_status: statusname
        ? statusname == 'In Progress'
          ? 'IN_PROGRESS'
          : statusname == 'Initiated'
          ? 'INITIATED'
          : statusname == 'Completed'
          ? 'COMPLETED'
          : statusname == 'Cancelled'
          ? 'CANCELLED'
          : statusname == 'Rejected'
          ? 'REJECTED'
          : null
        : null,
      reason: data.reasons ? data.reasons : null,
      placement_project_id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
    }
    if (projectid.id) {
      setShowBackDrop(true)
      let art = {
        project_id: projectid.id,
        // isOtProduct: true,
        isOtProduct: OTProduct ? true : false,
        netsuite_ot_product: NetSuiteOTProduct ? true : false,
      }
      const OTResponse: any = await Apiservices.otProductProject(art)
      // if (OTResponse.isError == false) {
      //   setOTProduct(true)
      // }
      const Response: any = await Apiservices.updateProject(article1)
      setShowBackDrop(false)
      if (Response.isError == false) {
        if (statusname == 'Completed') {
          setShowBackDrop(true)
          const response1: any = await Apiservices.getPlacementCompleted(article2)
          setShowBackDrop(false)
          // } else if (proj_type == 'Placement' && statusname == 'Cancelled') {
        } else if (proj_type == '1' && statusname == 'Cancelled') {
          setShowBackDrop(true)
          const response2: any = await Apiservices.getPlacementCancel(article3)
          setShowBackDrop(false)
        }
        if (proj_type == '1' && (statusname == 'Completed' || statusname == 'Cancelled')) {
          const update_project_status: any = await Apiservices.update_C2C_user_project_status(
            article4
          )
          if (update_project_status.isError) {
            console.log(update_project_status)
          }
        }
        toast.success('Updated Successfully')
        navigate('/masters/manageprojects')
      } else {
        toast.error('Failed to update ')
      }
      return Response.data
    }
    setShowBackDrop(false)
  }

  const onSubmit2 = async (data2: FormData2) => {
    // let data = getValues()
    // let data1 = getValues1()
    setShowBackDrop(true)
    let data = formOneData
    let data1 = formTwoData
    let organization = localStorage.getItem('org_id')
    // const project_type = proj_type == 'Local' ? false : true
    const project_type = proj_type == '1' ? 1 : proj_type == '2' ? 2 : proj_type == '3' && 3
    let end_customer_id = client?.filter(
      (each: any) => each.client_name === select_customer[0]?.client_name
    )
    let client_id = client?.filter(
      (each: any) => each.client_name === select_client[0]?.client_name
    )
    let reason_id = statusreasons?.filter((each: any) => each.reason == getValues('reasons'))

    const article1 = {
      id: projectid?.id ? projectid?.id : null,
      user_id: projectid?.user_id ? projectid?.user_id : null,
      project_name: data.project_name ? data.project_name : null,
      subvendor_id: VendorId ? VendorId : null,
      subvendor_name: VendorName ? VendorName : null,
      joining_date: projectid?.joining_date ? projectid?.joining_date : null,
      // project_status: data.status ? data.status : null,
      project_status: statusname
        ? statusname == 'In Progress'
          ? 'IN_PROGRESS'
          : statusname == 'Initiated'
          ? 'INITIATED'
          : statusname == 'Completed'
          ? 'COMPLETED'
          : statusname == 'Cancelled'
          ? 'CANCELLED'
          : statusname == 'Rejected'
          ? 'REJECTED'
          : null
        : 'IN_PROGRESS',
      // start_date: data.start_date ? moment(data.start_date).format('YYYY-MM-DD') : null,
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      // end_date: data.end_date ? data.end_date : null,
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      client_manager_name: userName(data.client_manager_name)
        ? userName(data.client_manager_name)
        : null,
      client_manager_email: data.client_manager_email ? data.client_manager_email : null,
      job_title: data.job_title ? data.job_title : null,
      is_placement_project: project_type,
      placement_type: data.placement_type ? data.placement_type : null,
      placement_project_id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
      placement_code: data.placement_code ? data.placement_code : null,
      // bill_rate: Number(data.bill_rate) ? Number(data.bill_rate) : null,
      // pay_rate: Number(data.pay_rate) ? Number(data.pay_rate) : null,
      bill_rate_currency: null,
      // is_primary_project: data.primary_project,
      is_primary_project: primary,
      direct_customer_engagement: data.direct_customer_engagement
        ? data.direct_customer_engagement
        : projectid
        ? projectid.direct_customer_engagement
        : false,
      business_unit_id: Number(data.business_unit_id),
      end_customer_id: end_customer_id?.[0]?.id ? end_customer_id?.[0]?.id : null,
      qb_customer_name: data1.qb_customer_name ? data1.qb_customer_name : null,
      netsuite_customer_name: data2.netSuite_customer_name ? data2.netSuite_customer_name : null,
      netsuite_vendor_name: data2.netSuite_vendor_name ? data2.netSuite_vendor_name : null,
      netsuite_first_name: getValues2('netSuite_first_name')
        ? getValues2('netSuite_first_name')
        : null,
      netsuite_middle_name: getValues2('netSuite_middle_name')
        ? getValues2('netSuite_middle_name')
        : null,
      netsuite_last_name: getValues2('netSuite_last_name')
        ? getValues2('netSuite_last_name')
        : null,
      netsuite_item_name: data2.netSuite_product_name ? data2.netSuite_product_name : null,
      qb_customer_id: data1.qb_customer_id ? data1.qb_customer_id : null,
      netsuite_customer_id: getValues2('netSuite_customer_id')
        ? getValues2('netSuite_customer_id')
        : null,
      netsuite_vendor_id: getValues2('netSuite_vendor_id')
        ? getValues2('netSuite_vendor_id')
        : null,
      netsuite_consultant_id: getValues2('netSuite_employee_id')
        ? Number(getValues2('netSuite_employee_id'))
        : null,
      netsuite_item_id: getValues2('netSuite_product_id')
        ? getValues2('netSuite_product_id')
        : null,
      qb_project_name: data1.qb_project_name ? data1.qb_project_name : null,
      netsuite_project_name: data2.netSuite_project_name ? data2.netSuite_project_name : null,
      qb_project_id: data1.qb_project_id ? data1.qb_project_id : null,
      netsuite_project_id: getValues2('netSuite_project_id')
        ? getValues2('netSuite_project_id')
        : null,
      qb_product_id: data1.qb_product_id ? data1.qb_product_id : null,
      qb_ot_product_id: data1.ot_qb_product_id ? data1.ot_qb_product_id : null,
      netsuite_ot_product_id: getValues2('netSuite_ot_product_id')
        ? getValues2('netSuite_ot_product_id')
        : null,
      qb_product_name: data1.qb_product_name ? data1.qb_product_name : null,
      qb_employee_name: data1.qb_employee_name ? data1.qb_employee_name : null,
      qb_employee_id: data1.qb_employee_id ? data1.qb_employee_id : null,
      qb_ot_product_name: data1.ot_qb_product_name ? data1.ot_qb_product_name : null,
      netsuite_ot_product_name: getValues2('netSuite_ot_product_name')
        ? getValues2('netSuite_ot_product_name')
        : null,
      work_country: countries[0]?.name ? countries[0]?.name : null,
      work_state: states[0]?.name ? states[0]?.name : null,
      work_email: null,
      work_phone: null,
      // work_state: null,
      // work_city: null,
      work_city: data.work_city ? data.work_city : null,
      is_updated: false,
      // cancel_date: data.cancel_date ? data.cancel_date : null,
      cancel_date: cancelDate ? moment(cancelDate).format('YYYY-MM-DD') : null,
      reason: data.reasons ? data.reasons : null,
      reason_id: reason_id[0]?.id ? reason_id[0]?.id : null,
      qb_status: null,
      record_type_status: 'Active',
      vendors: [
        {
          id: client_id?.[0]?.id ? client_id?.[0]?.id : null,
        },
      ],
      project_bill_rates: [
        {
          effective_date: null,
          // bill_rate: Number(data.bill_rate),
          // agreed_pay_rate: Number(data.pay_rate),
          bill_rate_currency: null,
        },
      ],
      project_expected_hours: ExpectedHoursData,
    }

    const ecdbid = localStorage.getItem('ecdb_user_id')
    const article2 = {
      // id: projectid.id ? projectid.id : null,
      id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
      updatedBy: ecdbid ? ecdbid : null,
      // closedDate: data.end_date ? data.end_date : null,
      closedDate: endDate ? moment(endDate).format('MM-DD-YYYY') : null,
      reasonName: data.reasons ? data.reasons : null,
      reasonId: reason_id[0]?.id ? reason_id[0]?.id : null,
      placementStatusId: 9,
    }

    const article3 = {
      id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
      reasonId: reason_id[0]?.id ? reason_id[0]?.id : null,
      // terminateDate: moment(data.cancel_date).format('MM-DD-YYYY')
      //   ? moment(data.cancel_date).format('MM-DD-YYYY')
      //   : null,
      terminateDate: cancelDate ? moment(cancelDate).format('MM-DD-YYYY') : null,
      remarks: 'timetracker',
      userId: ecdbid ? ecdbid : null,
      orgId: organization,
    }

    const article4 = {
      org_id: organization,
      placement_end_date: cancelDate
        ? moment(cancelDate).format('MM-DD-YYYY')
        : endDate
        ? moment(endDate).format('MM-DD-YYYY')
        : null,
      project_status: statusname
        ? statusname == 'In Progress'
          ? 'IN_PROGRESS'
          : statusname == 'Initiated'
          ? 'INITIATED'
          : statusname == 'Completed'
          ? 'COMPLETED'
          : statusname == 'Cancelled'
          ? 'CANCELLED'
          : statusname == 'Rejected'
          ? 'REJECTED'
          : null
        : null,
      reason: data.reasons ? data.reasons : null,
      placement_project_id: placementData?.id
        ? placementData?.id
        : projectid?.placement_project_id
        ? projectid?.placement_project_id
        : null,
    }
    if (projectid.id) {
      setShowBackDrop(true)
      const {netSuite_employee_id, netSuite_vendor_id, netSuite_project_id} = data2
      const {placement_type, project_employee_netsuite, id} = projectid

      const isW2OrInternal = placement_type == 'W2' || placement_type == 'Internal'
      const hasRequiredIds = isW2OrInternal
        ? netSuite_employee_id && netSuite_project_id
        : netSuite_vendor_id && netSuite_project_id
      // if (hasRequiredIds && project_employee_netsuite !== true) {
      if (hasRequiredIds) {
        let empproj = {
          netsuite_consultant_id: isW2OrInternal ? netSuite_employee_id : netSuite_vendor_id,
          netsuite_project_id: netSuite_project_id,
          project_id: id,
        }
        const projEmpMap: any = await Apiservices.NetSuiteMapProjectEmployee(empproj)
      }
      let art = {
        project_id: projectid.id,
        // isOtProduct: true,
        isOtProduct: OTProduct ? true : false,
        netsuite_ot_product: NetSuiteOTProduct ? true : false,
      }
      const OTResponse: any = await Apiservices.otProductProject(art)
      // if (OTResponse.isError == false) {
      //   setOTProduct(true)
      // }
      const Response: any = await Apiservices.updateProject(article1)
      setShowBackDrop(false)
      if (Response.isError == false) {
        if (statusname == 'Completed') {
          setShowBackDrop(true)
          const response1: any = await Apiservices.getPlacementCompleted(article2)
          setShowBackDrop(false)
          // } else if (proj_type == 'Placement' && statusname == 'Cancelled') {
        } else if (proj_type == '1' && statusname == 'Cancelled') {
          setShowBackDrop(true)
          const response2: any = await Apiservices.getPlacementCancel(article3)
          setShowBackDrop(false)
        }
        if (proj_type == '1' && (statusname == 'Completed' || statusname == 'Cancelled')) {
          const update_project_status: any = await Apiservices.update_C2C_user_project_status(
            article4
          )
          if (update_project_status.isError) {
            console.log(update_project_status)
          }
        }
        toast.success('Updated Successfully')
        navigate('/masters/manageprojects')
      } else {
        toast.error('Failed to update ')
      }
      return Response.data
    }
    setShowBackDrop(false)
  }
  const FailedToast = () => {
    toast.warn('Failed update', {
      position: 'top-center',
    })
  }

  const handleDirect = () => {
    setClientError('')
    setCustError('')
    setDirectCustomer(!directcustomer)
    // setValue('client_name', '')
    setSelectClient([])
    setProjectName(
      '',
      select_customer?.[0]?.client_name ? select_customer?.[0]?.client_name : '',
      select_consultant && select_consultant[0] && select_consultant[0]?.full_name
        ? select_consultant && select_consultant[0] && select_consultant[0]?.full_name
        : ''
    )
  }

  const handlePrimary = () => {
    setList(true)
    // setPrimary(!primary)
  }
  // const handleClient = async (client_name: any, end_customer_name: any) => {
  //   setClientError('')
  //   setValue('client_name', client_name)
  //   setQBClientName(client_name, end_customer_name)

  //   if (client_name && end_customer_name) {
  //     if (end_customer_name === client_name) {
  //       setClientError('End Customer and client name should not be same value')
  //     } else if (custerror) {
  //       setCustError('')
  //     } else {
  //       setClientError('')
  //     }
  //   }

  //   setProjectName(
  //     client_name,
  //     end_customer_name,
  //     select_consultant?.[0]?.full_name ? select_consultant[0].full_name : ''
  //   )

  //   if (client_name && client_name.length > 2) {
  //     const response: any = await Apiservices.getclientbyname({client: client_name})
  //     setClient(response.data.data ? response.data.data : '')
  //     // if (response.data && response.data.length > 0) {
  //     //   setClient(response.data.data)
  //     // } else {
  //     //   setClient([])
  //     //   // toast.error('No Data based on Your Search')
  //     // }
  //   }
  // }

  const setProjectName = (client_name: any, end_customer_name: any, consult_name: any) => {
    if (client_name) {
      setValue('project_name', consult_name + '-' + client_name)
      setValue1('qb_project_name', client_name + ':' + consult_name)
      setValue2('netSuite_project_name', client_name + ':' + consult_name)
      // setValue('project_name', consult_name + '-' + client_name)
    } else {
      setValue('project_name', consult_name + '-' + end_customer_name)
      setValue1('qb_project_name', end_customer_name + ':' + consult_name)
      setValue2('netSuite_project_name', end_customer_name + ':' + consult_name)

      // setValue('project_name', consult_name + '-' + end_customer_name)
    }
    // if ((client_name = '')) {
    //   setValue('project_name', '')
    // } else {
    //   setValue('project_name', '')
    // }
  }

  // const setQBClientName = (end_customer_name: any, consult_name: any) => {
  const setQBClientName = (client: any, end_customer_name: any) => {
    // setValue('qb_customer_name', customer + ':' + consult)
    // setValue('qb_customer_name', end_customer_name + ':' + consult_name)
    if (client) {
      setValue1('qb_customer_name', client)
      setValue2('netSuite_customer_name', client)
    } else {
      setValue1('qb_customer_name', end_customer_name)
      setValue2('netSuite_customer_name', end_customer_name)
    }
  }

  const handleCustomer = async (cli: any, customer_name: any) => {
    setValue('end_customer', customer_name)
    setCustError('')
    if (cli && customer_name) {
      if (customer_name === cli) {
        setCustError('End Customer and client name should not be same value')
      } else if (clienterror) {
        setClientError('')
      } else {
        setCustError('')
      }
    }

    setProjectName(
      cli,
      customer_name,
      select_consultant && select_consultant[0] && select_consultant[0].full_name
        ? select_consultant && select_consultant[0] && select_consultant[0].full_name
        : ''
    )
    // setQBClientName(customer_name, consultant)
    setQBClientName(cli, customer_name)

    if (customer_name && customer_name.length > 2) {
      const response: any = await Apiservices.getcustomerbyname({end_customer: customer_name})
      setCustomer(response.data.data ? response.data.data : '')
      // if (response.data && response.data.length > 0) {
      //   setCustomer(response.data.data)
      // } else {
      //   setCustomer([])
      //   // toast.error('No Data based on Your Search')
      // }
    }
  }

  // const handleConsultant = async (consultant_name: any) => {
  //   setConsultant(consultant_name)
  //   setValue('candidate', consultant_name)
  //   setValue('qb_product_name', getValues('placement_code'))
  //   setProjectName(getValues('client_name'), getValues('end_customer'), consultant_name)
  //   // setQBClientName(getValues('end_customer'), consultant_name)
  //   if (consultant_name && consultant_name.length > 2) {
  //     const response: any = await Apiservices.getconsultantbyname({candidate: consultant_name})
  //     setCandidate(response.data.data ? response.data.data : '')

  //     // if (response.data.data && response.data.data.length > 0) {
  //     //   setCandidate(response.data.data)
  //     // } else {
  //     //   setCandidate([])
  //     //   // toast.error('No Data based on Your Search')
  //     // }
  //   }
  // }
  const handleConsultant = async (consult_Name?: any) => {
    setShowBackDrop(true)
    const response: any = await Apiservices.getconsultantbyname({
      employee_type: consult_Name,
    })
    setCandidate(response?.data?.data)
    setShowBackDrop(false)
  }

  const handleClient = async (client_name?: any) => {
    setShowBackDrop(true)
    const response: any = await Apiservices.getCustomersList()
    setClient(response.data.data)
    setShowBackDrop(false)
  }

  const selectedConsultant = async (consult_name: any) => {
    if (proj_type == '2') {
      setValue('placement_type', 'W2')
    }
    // setDisablePlacement(false)
    if (consult_name == '') {
      setDisablePlacement(true)
      setAllFieldsToDisabledByPlacement(false)
    } else {
      setDisablePlacement(false)
      // setAllFieldsToDisabledByPlacement(true)
    }
    // setValue('candidate', consult_name)
    // setValue('qb_product_name', getValues('placement_code'))
    // setQBClientName(getValues('end_customer'), consult_name)
    // setDisablePlacement(false)
    setSelectConsultant(consult_name)

    setProjectName(
      select_client?.[0]?.client_name ? select_client?.[0]?.client_name : '',
      select_customer?.[0]?.client_name ? select_customer?.[0]?.client_name : '',
      consult_name[0]?.full_name ? user(consult_name[0]?.full_name) : ''
    )
    if (consult_name && consult_name[0]) {
      let consult_id = consult_name[0]?.id
      const data: any = await Apiservices.get_primary_project_by_user_id({
        user_id: consult_name[0]?.id,
      })

      const response: any = await Apiservices.getemployeebyid({id: consult_id})
      if (response && response.status == 200) {
        if (response && response.data && response.data.data) {
          let abc = [response.data.data]
          setUserData([...abc])
          // if (proj_type == '2' || proj_type == '3') {
          //   if (
          //     response?.data?.data?.visa_status == 'USC' ||
          //     response?.data?.data?.visa_status == 'CITIZEN'
          //   ) {
          //     setSubmitMessage('Records Found')
          //     toast.warn(
          //       // `Unable to create Local project for ${response?.data?.data?.visa_status} Visa Status`
          //       'System not allowing to create bench project for US Citizen'
          //     )
          //   }
          // }
          if (proj_type == '2') {
            if (response?.data?.data.employee_type == 'Internal') {
              let dataSet = {
                ...response.data.data,
                project_type: '3',
              }
              setProjType(dataSet.project_type)
              reset(dataSet)
            }
          }
          setValue('business_unit_id', response?.data?.data?.business_unit_id)
          // let businessss = BusinessUnitsList.filter(
          //   (temp: any) => temp.business_unit_id == response?.data?.data?.business_unit_id
          // )
          // setBusinessUnitsList(businessss)
        }
      }

      // if (proj_type == 'Local') {
      if (proj_type == '2' || proj_type == '3') {
        const data1: any = await Apiservices.get_active_local_projects_by_user_id({
          user_id: consult_name[0]?.id,
        })
        if (data1 && data1.data && data1.data.isError == false) {
          if (data1.data.message == 'Records Found') {
            toast.warn('Consultant is already having local project')
          } else if (proj_type == '2' && getValues('placement_type') == 'W2') {
            const placement_checked: any =
              await Apiservices.get_active_placement_projects_by_user_id({
                user_id: consult_name[0]?.id,
              })
            if (placement_checked.data.isError == false) {
              if (placement_checked.data.message == 'Records Found') {
                setPlacementProjectID(placement_checked.data.data)
                toast.warn('Consultant is having active placement project')
              }
            }
            // setMessagePlacementValidation(placement_checked.data.message)
          }
          setMessage(data1.data.message)
        }
        // const response: any = await Apiservices.getemployeebyid({id: consult_id})
        // if (response && response.status == 200) {
        //   if (response && response.data && response.data.data) {
        //     if (
        //       response?.data?.data?.visa_status == 'USC' ||
        //       response?.data?.data?.visa_status == 'CITIZEN'
        //     ) {
        //       setSubmitMessage('Records Found')
        //       toast.warn(
        //         // `Unable to create Local project for ${response?.data?.data?.visa_status} Visa Status`
        //         'System not allowing to create bench project for US Citizen'
        //       )
        //     }
        //     if (proj_type == '2') {
        //       if (response?.data?.data.employee_type == 'Internal') {
        //         let dataSet = {
        //           ...response.data.data,
        //           project_type: '3',
        //         }
        //         setProjType(dataSet.project_type)
        //         reset(dataSet)
        //       }
        //     }

        //     let businessss = BusinessUnitsList.filter(
        //       (temp: any) => temp.business_unit_id == response?.data?.data?.business_unit_id
        //     )
        //     setBusinessUnitsList(businessss)
        //   }
        // }
      }

      if (data?.data?.message == 'No Records Found') {
        setPrimary(true)
        setDisablePrimaryProject(true)
        // setValue('primary_project', true)
      } else if (data?.data?.message == 'Records Found') {
        setPrimary(false)
        // setValue('primary_project', false)
        // setDisablePrimaryProject(true)
      }
    }
  }
  const selectedClient = async (client_name: any) => {
    // setValue('candidate', consult_name)
    // setValue('qb_product_name', getValues('placement_code'))
    // setQBClientName(getValues('end_customer'), consult_name)
    // setDisablePlacement(false)
    setSelectClient(client_name)
    setQBClientName(client_name?.[0]?.client_name, select_customer?.[0]?.client_name)
    setClientError('')

    if (client_name && select_customer?.[0]?.client_name) {
      if (select_customer?.[0]?.client_name === client_name?.[0]?.client_name) {
        setClientError('End Customer and client name should not be same value')
      } else if (custerror) {
        setCustError('')
      } else {
        setClientError('')
      }
    }

    setProjectName(
      client_name?.[0]?.client_name ? client_name?.[0]?.client_name : '',
      select_customer?.[0]?.client_name ? select_customer?.[0]?.client_name : '',
      select_consultant && select_consultant[0] && select_consultant[0].full_name
        ? user(select_consultant && select_consultant[0] && select_consultant[0].full_name)
        : ''
    )
  }
  const selectedEndCustomer = async (customer_name: any) => {
    // setValue('candidate', consult_name)
    // setValue('qb_product_name', getValues('placement_code'))
    // setQBClientName(getValues('end_customer'), consult_name)
    // setDisablePlacement(false)
    setSelectCustomer(customer_name)
    setQBClientName(select_client?.[0]?.client_name, customer_name?.[0]?.client_name)
    setCustError('')
    if (select_client?.[0]?.client_name && customer_name) {
      if (customer_name?.[0]?.client_name === select_client?.[0]?.client_name) {
        setCustError('End Customer and client name should not be same value')
      } else if (clienterror) {
        setClientError('')
      } else {
        setCustError('')
      }
    }

    setProjectName(
      select_client?.[0]?.client_name ? select_client?.[0]?.client_name : '',
      customer_name?.[0]?.client_name ? customer_name?.[0]?.client_name : '',
      select_consultant && select_consultant[0] && select_consultant[0].full_name
        ? user(select_consultant && select_consultant[0] && select_consultant[0].full_name)
        : ''
    )
  }
  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const handleFilterConsultant = async (consult_Name: any) => {
    setValue('project_name', consult_Name)
    setDisablePlacement(false)
    // if (proj_type == 'Local') {
    if (proj_type == '2' || proj_type == '3') {
      const end_customer = [
        {
          client_name:
            select_customer && select_customer[0] && select_customer?.[0]?.client_name
              ? select_customer?.[0]?.client_name
              : '',
        },
      ]
      setSelectCustomer(end_customer)
      setQBClientName(select_client?.[0]?.client_name, select_customer?.[0]?.client_name)
    } else {
      const end_customer = [
        {
          client_name: '',
        },
      ]
      setSelectCustomer(end_customer)
    }
    if (consult_Name == '') {
      setDisablePlacement(true)
      setAllFieldsToDisabledByPlacement(false)
      setValue('project_name', '')
      setValue('placement_code', '')
      // setAllFieldsToDisabled(false)
      let dataSet2 = {
        // bill_rate: '',
        // pay_rate: '',
        direct_customer_engagement: true,
        // status: '',
        // placement_type: '',
        project_name: '',
        client_manager_name: '',
        client_manager_email: '',
        // currency: '',
        placement_code: '',
        work_country: '',
        // setSelectConsultant: '',
        client_name: '',
        name: '',
        full_name: '',
        job_title: '',
        work_city: '',
        placementData: '',
        // project_type: '',
        start_date: null,
      }
      const data = [{name: ''}]
      const data1 = [{name: ''}]
      const client = [{client_name: ''}]

      // const end_customer = [{client_name: ''}]
      // const consultant = [{full_name: ''}]

      setCountries(data)
      setStates(data1)
      setSelectClient(client)
      // setSelectCustomer(end_customer)
      // setSelectConsultant(consultant)
      reset(dataSet2)
    }
    // setValue('project_name', consult_Name)

    // if (consult_Name.length > 2) {
    let filteredConsultant = candidate?.filter((each: any) =>
      each?.full_name?.toLowerCase()?.includes(consult_Name?.toLowerCase())
    )
    setCandidateList(filteredConsultant)
    setProjectName(
      select_client?.[0]?.client_name ? select_client?.[0]?.client_name : '',
      select_customer?.[0]?.client_name ? select_customer?.[0]?.client_name : '',
      consult_Name ? consult_Name : ''
    )
  }
  const handleFilterClient = async (client_Name: any) => {
    if (client_Name == '') {
      setValue('project_name', '')
    }
    setValue('project_name', client_Name)
    setQBClientName(client_Name, select_customer?.[0]?.client_name)
    let filteredConsultant = client.filter((each: any) =>
      each?.client_name?.toLowerCase()?.includes(client_Name?.toLowerCase())
    )
    setClientList(filteredConsultant)
    setProjectName(
      client_Name,
      select_customer?.[0]?.client_name ? select_customer?.[0]?.client_name : '',
      select_consultant && select_consultant[0] && select_consultant[0].full_name
        ? select_consultant && select_consultant[0] && select_consultant[0].full_name
        : ''
    )
  }
  const handleFilterEndCustomer = async (customer_Name: any) => {
    if (customer_Name == '') {
      setValue('project_name', '')
    }
    setValue('project_name', customer_Name)
    setQBClientName(select_client?.[0]?.client_name, customer_Name)

    let filteredConsultant = client.filter((each: any) =>
      each?.client_name?.toLowerCase()?.includes(customer_Name?.toLowerCase())
    )
    setCustomerList(filteredConsultant)
    setProjectName(
      select_client?.[0]?.client_name ? select_client?.[0]?.client_name : '',
      customer_Name,
      select_consultant && select_consultant[0] && select_consultant[0].full_name
        ? select_consultant && select_consultant[0] && select_consultant[0].full_name
        : ''
    )
  }
  const orgID = localStorage.getItem('org_id')
  const handlePlacement = async (placement_id: any, blur_event: any) => {
    setShowBackDrop(true)
    // setPlacement(placement_id == 'emptyPlacement' ? setValue('placement_code', '') : placement_id)
    setPlacement(placement_id)
    if (placement_id && placement_id.length > 2) {
      const response1: any = await Apiservices.getProjectByPlacementCode({
        placement_code: placement_id,
      })

      if (response1.data.message == 'No Records Found') {
        setMessage(response1.data.message)
        if (blur_event === 'Blur') {
          const response: any = await Apiservices.getPlacementUrl({
            placementId: placement_id,
            orgId: orgID,
            empId: select_consultant?.[0]?.ecdb_user_id,
            candidateId: select_consultant?.[0]?.prospective_user_id
              ? select_consultant?.[0]?.prospective_user_id
              : '',
          })

          setPlacementData(response.data.placements)
          if (response.data.statusCode == 200) {
            // if (proj_type == 'Placement') {
            //   setAllFieldsToDisabled(true)
            // }
            // setAllFieldsToDisabled(false)

            if (response.data.placements) {
              // if (proj_type == 'Local') {
              if (proj_type == '2' || proj_type == '3') {
                toast.warn('Records Found in Placement')
              }
              // setAllFieldsToDisabled(true)

              // setDirectCheck(
              //   response && response.data.placements.directCustomerEngagement
              //     ? response.data.placements.directCustomerEngagement
              //     : ''
              // )
              let Apiresponse = response && response.data && response.data.placements

              let dataSet = {
                ...response?.data?.placements,
                // bill_rate:
                //   Apiresponse && response.data.placements.billRate
                //     ? response.data.placements.billRate
                //     : '',
                // pay_rate:
                //   Apiresponse && response.data.placements.agreedPayRate
                //     ? response.data.placements.agreedPayRate
                //     : '',
                direct_customer_engagement:
                  Apiresponse && response.data.placements.directCustomerEngagement
                    ? response.data.placements.directCustomerEngagement
                    : false,
                // status:
                //   Apiresponse.placementStatus &&
                //   (Apiresponse.placementStatus == 'PENDING_WITH_PAPER_WORK' ||
                //     Apiresponse.placementStatus == 'UNDER_CONTRACT_REVIEW' ||
                //     Apiresponse.placementStatus == 'AWAITING_FOR_LEGAL_APPROVAL' ||
                //     Apiresponse.placementStatus == 'PENDING_FOR_SIGNATURE' ||
                //     Apiresponse.placementStatus == 'INITIATION_FOR_CLOSURE' ||
                //     Apiresponse.placementStatus == 'TERMINATION_INITIATED')
                //     ? 'Initiated'
                //     : Apiresponse.placementStatus === 'CANDIDATE_JOINED' ||
                //       Apiresponse.placementStatus == 'PLACED'
                //     ? 'In Progress'
                //     : Apiresponse.placementStatus == 'PLACEMENT_ENDED'
                //     ? 'Completed'
                //     : Apiresponse.placementStatus == 'REJECTED' ||
                //       Apiresponse.placementStatus == 'PLACEMENT_CANCELLED' ||
                //       Apiresponse.placementStatus == 'PLACEMENT_CLOSURE' ||
                //       Apiresponse.placementStatus == 'TERMINATED'
                //     ? 'Cancelled'
                //     : '',
                status:
                  Apiresponse.placementStatus &&
                  (Apiresponse.placementStatus == 'PENDING_WITH_PAPER_WORK' ||
                    Apiresponse.placementStatus == 'UNDER_CONTRACT_REVIEW' ||
                    Apiresponse.placementStatus == 'AWAITING_FOR_LEGAL_APPROVAL' ||
                    Apiresponse.placementStatus == 'PENDING_FOR_SIGNATURE' ||
                    Apiresponse.placementStatus == 'INITIATION_FOR_CLOSURE' ||
                    Apiresponse.placementStatus == 'TERMINATION_INITIATED')
                    ? 'Initiated'
                    : Apiresponse.placementStatus === 'CANDIDATE_JOINED' ||
                      Apiresponse.placementStatus == 'PLACED'
                    ? 'In Progress'
                    : Apiresponse.placementStatus == 'PLACEMENT_ENDED'
                    ? 'Completed'
                    : Apiresponse.placementStatus == 'PLACEMENT_CANCELLED' ||
                      Apiresponse.placementStatus == 'PLACEMENT_CLOSURE' ||
                      Apiresponse.placementStatus == 'TERMINATED'
                    ? 'Cancelled'
                    : Apiresponse.placementStatus == 'REJECTED'
                    ? 'Rejected'
                    : '',

                // start_date: response.data.placements.estStartDate
                //   ? moment(response.data.placements.estStartDate).format('YYYY-MM-DD')
                //   : '',
                // start_date: response.data.placements.estStartDate
                //   ? new Date(response.data.placements.estStartDate)
                //   : '',
                start_date: response.data.placements.candidateJoinedDate
                  ? new Date(response.data.placements.candidateJoinedDate)
                  : response.data.placements.estStartDate
                  ? new Date(response.data.placements.estStartDate)
                  : '',
                effective_date: response.data.placements.candidateJoinedDate
                  ? new Date(response.data.placements.candidateJoinedDate)
                  : response.data.placements.estStartDate
                  ? new Date(response.data.placements.estStartDate)
                  : '',
                placement_type:
                  Apiresponse && response.data.placements.placementType
                    ? response.data.placements.placementType
                    : '',
                business_unit_id:
                  Apiresponse && response?.data?.placements?.buId
                    ? response.data.placements.buId
                    : '',
                job_title:
                  Apiresponse && response?.data?.placements?.jobTitle
                    ? response?.data?.placements?.jobTitle
                    : '',

                qb_customer_name: response?.data?.placements?.vendors?.[0]?.name
                  ? response?.data?.placements?.vendors?.[0]?.name
                  : response?.data?.placements?.customerName,
                netsuite_customer_name: response?.data?.placements?.vendors?.[0]?.name
                  ? response?.data?.placements?.vendors?.[0]?.name
                  : response?.data?.placements?.customerName,
                project_name: response?.data?.placements?.vendors?.[0]?.name
                  ? `${response?.data?.placements?.candidateName}-${response?.data?.placements?.vendors[0]?.name}`
                  : `${response?.data?.placements?.candidateName}-${response?.data?.placements?.customerName}`,
                qb_project_name: response?.data?.placements?.vendors?.[0]?.name
                  ? `${response?.data?.placements?.vendors[0]?.name}:${response?.data?.placements?.candidateName}`
                  : `${response?.data?.placements?.customerName}:${response?.data?.placements?.candidateName}`,
                netSuite_project_name: response?.data?.placements?.vendors?.[0]?.name
                  ? `${response?.data?.placements?.vendors[0]?.name}:${response?.data?.placements?.candidateName}`
                  : `${response?.data?.placements?.customerName}:${response?.data?.placements?.candidateName}`,
              }
              setVendorName(response?.data?.placements?.subVendorName)
              setVendorId(response?.data?.placements?.subVendorId)

              const response5: any = await Apiservices.getallcountries()
              if (
                response &&
                response.data &&
                response.data.placements &&
                response?.data?.placements?.workCountry
              ) {
                let stateName1 = response5?.data?.data.filter(
                  (temp: any) => temp?.name == response?.data?.placements?.workCountry
                )
                if (stateName1) {
                  setUseStateCountryId(stateName1[0]?.id)
                  const response: any = await Apiservices.getstatebyname({
                    country_id: stateName1[0]?.id,
                  })
                  if (response?.data?.data) {
                    setState(response.data.data ? response.data.data : '')
                  } else {
                    setState([])
                  }
                }
              }

              // let start_date_format1: any = new Date(response.data.placements.estStartDate)
              // setStartDate(start_date_format1)
              // setValue('start_date', start_date_format1)
              setValue('start_date', dataSet.start_date)
              setValue('effective_date', dataSet.effective_date)
              setValue('business_unit_id', dataSet.business_unit_id)
              const country_Name = [
                {
                  name:
                    response &&
                    response.data &&
                    response.data.placements &&
                    response.data.placements.workCountry
                      ? response?.data?.placements?.workCountry
                      : '',
                },
              ]
              const client_name = [
                {
                  client_name:
                    response &&
                    response.data &&
                    response.data.placements &&
                    response.data.placements.vendors &&
                    response.data.placements.vendors[0] &&
                    response.data.placements.vendors[0].name
                      ? response?.data?.placements?.vendors[0]?.name
                      : '',
                },
              ]
              const end_customer = [
                {
                  client_name:
                    response &&
                    response.data &&
                    response.data.placements &&
                    response.data.placements.customerName
                      ? response?.data?.placements?.customerName
                      : '',
                },
              ]
              setSelectClient(client_name)
              setSelectCustomer(end_customer)
              setCountries(country_Name)
              setDirectCustomer(dataSet.direct_customer_engagement)
              setStartDate(dataSet.start_date)
              setEffectiveDate(dataSet.effective_date)
              reset(dataSet)

              setAllFieldsToDisabledByPlacement(true)
              // setDirectCustomerCheck(true)
            } else {
              toast.warn('No records')
            }
          } else if (response.data.statusCode == 400) {
            let dataSet1 = {
              // bill_rate: '',
              // pay_rate: '',
              direct_customer_engagement: true,
              // status: '',
              // placement_type: '',
              project_name: '',
              client_manager_name: '',
              client_manager_email: '',
              // currency: '',
              // placement_code: '',
              work_country: '',
              // setSelectConsultant: '',
              client_name: '',
              work_city: '',
              name: '',
              full_name: '',
              job_title: '',
              placementData: '',
              setDateValue: null,
              dateValue: null,
              start_date: null,
            }
            const data = [{name: ''}]
            const data1 = [{name: ''}]
            const client = [{client_name: ''}]
            const end_customer = [{client_name: ''}]
            // const consultant = [{full_name: ''}]

            setCountries(data)
            setStates(data1)
            setSelectClient(client)
            setSelectCustomer(end_customer)
            // setSelectConsultant(consultant)
            reset(dataSet1)
            // if (proj_type == 'Placement') {
            if (proj_type == '1') {
              setMessage(response.data.status)
              toast.error('Placement ID not matched')
              // setAllFieldsToDisabled(true)
            }
          }
        }
      } else {
        // if (proj_type == 'Local' || 'Placement') {
        //   setAllFieldsToDisabled(true)
        // }
        setMessage(response1.data.message)
        setRecordsNotMatchedMessage('')
        toast.error('Records Found in timetracker')
        // setAllFieldsToDisabled(true)
      }
    }

    setValue1('qb_product_name', placement_id)
    setShowBackDrop(false)
  }
  const handleProjectSelect = async (consultant_Name: any) => {
    setSelectConsultant(consultant_Name)
  }

  const handleCountries = async (country: any) => {
    setShowBackDrop(true)
    if (country && country.length >= 2) {
      const response: any = await Apiservices.getcountriesbyname({country_name: country})
      if (response.data.data) {
        setCountry(response.data.data ? response.data.data : '')
      } else {
        setCountry([])
      }
    }
    setShowBackDrop(false)
  }
  const selectedCountries = async (selected_country_name: any) => {
    setShowBackDrop(true)
    setCountries(selected_country_name)
    setUseStateCountryId(selected_country_name[0]?.id)
    const response: any = await Apiservices.getstatebyname({
      country_id: selected_country_name[0]?.id,
    })
    if (response?.data?.data) {
      setState(response.data.data ? response.data.data : '')
    } else {
      setState([])
    }
    setShowBackDrop(false)
  }

  const handleStates = async (state: any) => {
    setShowBackDrop(true)
    if (state && state.length >= 1) {
      if (useStateCountryId) {
        const response: any = await Apiservices.getstatebyname({country_id: useStateCountryId})
        if (response?.data?.data) {
          setState(response.data.data ? response.data.data : '')
        } else {
          setState([])
        }
      }
    }
    setShowBackDrop(false)
  }
  const selectedStates = async (selected_state_name: any) => {
    setStates(selected_state_name)
  }

  // const qbProject = (qbprojectid:any) =>{
  //   if(qbprojectid>0){
  //     return qbprojectid
  //   }
  //   else {
  //     return 0
  //   }
  // }

  const handleStatus = async (status_name: any) => {
    setShowBackDrop(true)
    setStatusName(status_name)
    setValue('reasons', '')
    // setValue('cancel_date', '')
    // setValue('end_date', '')
    setEndDate(null)
    setCancelDate(null)
    if (status_name == 'Completed' || status_name == 'Cancelled' || location.state) {
      const response: any = await Apiservices.getReasons()
      if (response.data.resonsList && response.data.resonsList.length > 0) {
        setStatusReasons(response.data.resonsList)
      }
      // if (status_name == 'Completed' || status_name == 'Cancelled') {
      //   setProjectStatusPopup(true)
      // }
    }
    setShowBackDrop(false)
  }
  const handleDate = async (date: any) => {
    setDateValue(date)
    setStartDate(date)
  }

  const handleProjectStartDate = async (date: any) => {
    setShowBackDrop(true)
    setDateValue(date)
    setStartDate(date)
    setValue('start_date', date)
    setEffectiveDate(date)
    setValue('effective_date', date)
    if (proj_type == '2' && getValues('placement_type') == 'W2') {
      const article = {
        user_id: select_consultant?.[0]?.id ? select_consultant?.[0]?.id : null,
        placement_project_id: PlacementProjectID[0]?.placement_project_id,
        start_date: moment(date).format('YYYY-MM-DD'),
        is_placement_project: 2,
      }
      const response: any = await Apiservices.get_bench_project_based_on_start_date(article)
      if (response.isError) {
        toast.warn(
          `Please pick another date for which the consultant isn't having an active project`
        )
        setBenchProjectStartDate(true)
      } else {
        setBenchProjectStartDate(false)
      }
    }
    setShowBackDrop(false)
  }
  const getHistoryPerWeek = () => {
    setShow(true)
    setHistoryOpenPerWeek(true)
  }
  const handleEffectiveDate = async (date: any) => {
    setValue('effective_date', date)
    setEffectiveDate(date)
    let effective =
      projectid.project_expected_hours[0].effective_date == moment(date).format('YYYY-MM-DD')

    if (effective) {
      ExpectedHoursData[0].expected_hours = Number(getValues('expected_hours'))

      setExpectedHoursData([...ExpectedHoursData])
    } else {
      var projDetails: any = {
        effective_date: moment(date).format('YYYY-MM-DD'),
        expected_hours: Number(getValues('expected_hours')),
      }
      ExpectedHoursData.push(projDetails)
    }
    ExpectedHoursData[0].expected_hours = projectid.project_expected_hours[0].expected_hours
    setExpectedHoursData([...ExpectedHoursData])
  }

  const handleEffectiveTableDate = async (date: any, index: any) => {
    ExpectedHoursData[index].effective_date = moment(date).format('YYYY-MM-DD')
    ExpectedHoursData[index].id = projectid.project_expected_hours[index].id

    setExpectedHoursData([...ExpectedHoursData])
    ExpectedHoursHistory[index].effective_date = moment(date).format('YYYY-MM-DD')
    setExpectedHoursHistory([...ExpectedHoursHistory])
  }
  const handleExpectedHours = (expected: any) => {
    setValue('expected_hours', expected)
    if (location.state) {
      let effective =
        projectid?.project_expected_hours &&
        projectid?.project_expected_hours[0]?.effective_date &&
        projectid.project_expected_hours[0].effective_date ==
          moment(effectiveDate).format('YYYY-MM-DD')
      if (effective) {
        ExpectedHoursData[0].expected_hours = Number(expected)
        setExpectedHoursData([...ExpectedHoursData])
      } else {
        let abc = ExpectedHoursData.filter(
          (temp: any) => temp.effective_date == moment(effectiveDate).format('YYYY-MM-DD')
        )
        abc[0].expected_hours = Number(expected)
        setExpectedHoursData([...ExpectedHoursData])
      }
    }
  }

  const handleExpectedTableHours = (expected: any, index: any) => {
    // setValue('expected_hours', expected)
    ExpectedHoursData[index].expected_hours = Number(expected)
    setExpectedHoursData([...ExpectedHoursData])

    ExpectedHoursHistory[index].expected_hours = Number(expected)
    setExpectedHoursHistory([...ExpectedHoursHistory])
  }
  const handleEndDate = async (date: any) => {
    // setDateValue(date)
    setEndDate(date)
  }
  const handleCancelDate = async (date: any) => {
    // setDateValue(date)
    setCancelDate(date)
  }

  const handleConsultantType = (consultant: any) => {
    setConsultantOption(consultant)
  }
  let organization_name = localStorage.getItem('org_name')

  const handleProjectType = async (proj: any) => {
    setShowBackDrop(true)
    // if (proj == 'Local') {
    if (proj == 2 || proj == 3) {
      setMessage(' No Records Found')
      const data: any = await Apiservices.getDefaultOrgAsCustomer()
      let DefaultOrgAsCustomer = [data.data.data]
      // setSelectClient(DefaultOrgAsCustomer)
      setSelectCustomer(DefaultOrgAsCustomer)
      setQBClientName(select_client?.[0]?.client_name, DefaultOrgAsCustomer?.[0]?.client_name)
      // const end_customer = [
      //   {
      //     client_name: organization_name ? organization_name : '',
      //   },
      // ]
      // setSelectCustomer(end_customer)
      // setValue('qb_customer_name', organization_name)
    } else {
      const end_customer = [
        {
          client_name: '',
        },
      ]
      setSelectCustomer(end_customer)
    }
    setAllFieldsToDisabledByPlacement(false)
    setProjType(proj)
    // setAllFieldsToDisabled(false)
    let dataSet1 = {
      // bill_rate: '',
      // pay_rate: '',
      direct_customer_engagement: true,
      // status: '',
      // placement_type: '',
      project_name: '',
      client_manager_name: '',
      client_manager_email: '',
      // currency: '',
      placement_code: '',
      work_country: '',
      // setSelectConsultant: '',
      client_name: '',
      name: '',
      work_city: '',
      full_name: '',
      job_title: '',
      placementData: '',
      setDateValue: null,
      dateValue: null,
      start_date: null,
    }
    setStartDate(null)
    setProjectName(
      select_client?.[0]?.client_name ? select_client?.[0]?.client_name : '',
      select_customer?.[0]?.client_name ? select_customer?.[0]?.client_name : '',
      select_consultant && select_consultant[0] && select_consultant[0].full_name
    )
    const data = [{name: ''}]
    const data1 = [{name: ''}]
    const client = [{client_name: ''}]
    // const end_customer = [{client_name: ''}]
    const consultant = [{full_name: ''}]

    setCountries(data)
    setStates(data1)
    setSelectClient(client)
    // setSelectCustomer(end_customer)
    setSelectConsultant(consultant)
    reset(dataSet1)

    if (proj == 2) {
      setValue('placement_type', 'W2')
      handleConsultant('W2')
      setprojectConsultantDisabled(true)
    } else {
      setValue('placement_type', '')
      handleConsultant()
      setprojectConsultantDisabled(false)
    }
    if (proj == 3) {
      handleConsultant('Internal')
    }
    setShowBackDrop(false)
  }

  // const qbProjectName = (project_name: any, project: any, blur_event: any) => {
  //   if (location.state) {
  //     if (project_name && project) {
  //       if (blur_event === 'Blur') {
  //         if (project_name != project) {
  //           setList(true)
  //           setCustomerMessage('QB Project name and project should be same names')
  //           // alert("QB Project name and project should be same names")
  //         }
  //       }
  //     }
  //   }
  // }

  const handleBillRate = (bill_number: any, pay: any) => {
    setBillError('')
    // setValue('bill_rate', bill_number)
    if (bill_number && pay) {
      if (Number(bill_number) < Number(pay)) {
        setBillError('Bill Rate should not be less than pay Rate')
      } else if (payerror) {
        setPayError('')
      } else {
        setBillError('')
      }
    }
  }

  const handlePayRate = (bill: any, pay_number: any) => {
    setPayError('')
    // setValue('pay_rate', pay_number)
    if (bill && pay_number) {
      if (Number(bill) < Number(pay_number)) {
        setPayError('Pay Rate should not be greater than bill Rate')
      } else if (billerror) {
        setBillError('')
      } else {
        setPayError('')
      }
    }
  }

  const handleDailyThreshold = (daily: any) => {
    setPayError('')
    // setValue('pay_rate', pay_number)
    if (daily) {
      if (Number(daily) > 24) {
        setDailyThresholdError('Daily Threshold should not be greater than 24')
      } else {
        setDailyThresholdError('')
      }
    }
  }

  const handleWeeklyThreshold = (weekly: any) => {
    setPayError('')
    // setValue('pay_rate', pay_number)
    if (weekly) {
      if (Number(weekly) > 168) {
        setWeeklyThresholdError('Weekly Threshold should not be greater than 168')
      } else {
        setWeeklyThresholdError('')
      }
    }
  }

  const getMaxDate = (date: any) => {
    if (!date) {
      return date
    }
    const startTime = moment(date).add(1, 'd').format('YYYY-MM-DD')
    return new Date(startTime)
  }

  const minDate = React.useMemo(
    () => getMaxDate(ExpectedHoursHistory && ExpectedHoursHistory[0]?.effective_date),
    [ExpectedHoursHistory]
  )

  const UserProjectClose = () => {
    setUserProjectBuPop(false)
  }

  const handleChange1 = (newStartDate: any) => {
    let MultipleYears = []
    let MultipleMonths = []

    setDateValue1(newStartDate)
    // setValue('start_date', newStartDate)
    let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    if (newStartDate?.length == 2) {
      setHistoryValidation(false)
      calendarRef.current.closeCalendar()
      let a1 = newStartDate[0]?.year
      let b1 = newStartDate[1]?.year

      let a = newStartDate[0]?.month?.index
      let abc = arr.slice(a)

      let b = newStartDate[1]?.month?.number
      let abc1 = arr.slice(0, b)

      let a11 = arr.slice(a, b)

      if (a1 == b1) {
        //for same year condition
        MultipleYears.push(a1)
        MultipleMonths.push(a11)
      } else {
        //for different year condition
        MultipleYears.push(a1)
        MultipleYears.push(b1)
        MultipleMonths.push(abc)
        MultipleMonths.push(abc1)
      }
      setMultipleYears(MultipleYears)
      setMultipleMonths(MultipleMonths)

      let projDetails: any = {
        year: MultipleYears,
        months: MultipleMonths,
      }

      setProjectDetails(projDetails)
    }
  }
  const handleNSChange1 = (newStartDate: any) => {
    setNSDateValue1(newStartDate)
    if (newStartDate?.length == 2) {
      setNSHistoryValidation(false)
      let m1 =
        newStartDate[0]?.month?.number < 10
          ? '0' + newStartDate[0]?.month?.number
          : newStartDate[0]?.month?.number
      let m2 =
        newStartDate[1]?.month?.number < 10
          ? '0' + newStartDate[1]?.month?.number
          : newStartDate[1]?.month?.number

      let start_end = [
        `${newStartDate[0]?.year}-${m1}-${'01'}`,
        `${newStartDate[1]?.year}-${m2}-${newStartDate[1]?.month?.length}`,
      ]
      setNSDateValueSetting(start_end)
      calendarRef.current.closeCalendar()
    }
  }
  const getMinDate = (date: any) => {
    if (!date) {
      return date
    }
    if (DateValue1.length == 1) {
      let month = date[0]?.month?.name
      let year = date[0]?.year
      let new_date = moment(`${year}-${month}-01`)
      const startTime = moment(new_date).subtract(11, 'months').format('MMMM YYYY')
      return new Date(startTime)
    }
  }
  const getMinDate1 = (date: any) => {
    if (!date) {
      return date
    }
    if (NSDateValue1.length == 1) {
      let month = date[0]?.month?.name
      let year = date[0]?.year
      let new_date = moment(`${year}-${month}-01`)
      const startTime = moment(new_date).subtract(11, 'months').format('MMMM YYYY')
      return new Date(startTime)
    }
  }
  const minDateValue = React.useMemo(() => getMinDate(DateValue1), [DateValue1])
  const minDateValue1 = React.useMemo(() => getMinDate1(NSDateValue1), [NSDateValue1])

  const getMaxHistoryDate = (date: any) => {
    if (!date) {
      return date
    }
    let month = moment().endOf('month').format('MMMM')
    let year = moment().endOf('month').format('YYYY')
    let day = moment().endOf('month').format('DD')
    let new_date = moment(`${year}-${month}-${day}`)
    const startTime = moment(new_date).format('MMMM YYYY')
    return new Date(startTime)
  }
  const getMaxNSHistoryDate = (date: any) => {
    if (!date) {
      return date
    }
    let month = moment().endOf('month').format('MMMM')
    let year = moment().endOf('month').format('YYYY')
    let day = moment().endOf('month').format('DD')
    let new_date = moment(`${year}-${month}-${day}`)
    const startTime = moment(new_date).format('MMMM YYYY')
    return new Date(startTime)
  }
  const maxHistoryDate = React.useMemo(
    () => getMaxHistoryDate(moment(new Date())),
    [moment(new Date())]
  )
  const maxNSHistoryDate = React.useMemo(
    () => getMaxNSHistoryDate(moment(new Date())),
    [moment(new Date())]
  )
  const getMaxInvoiceDate = (date: any) => {
    if (!date) {
      return date
    }
    const startTime = moment().endOf('month').format('YYYY-MM-DD')
    return new Date(startTime)
  }
  const getMaxNetSuiteInvoiceDate = (date: any) => {
    if (!date) {
      return date
    }
    const startTime = moment().endOf('month').format('YYYY-MM-DD')
    return new Date(startTime)
  }

  const maxInvoiceDate = React.useMemo(
    () => getMaxInvoiceDate(moment(new Date())),
    [moment(new Date())]
  )
  const maxNetSuiteInvoiceDate = React.useMemo(
    () => getMaxNetSuiteInvoiceDate(moment(new Date())),
    [moment(new Date())]
  )
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const [formOneData, setFormOneData] = useState<any | {}>({})
  const [formTwoData, setFormTwoData] = useState<any | {}>({})
  const [formThreeData, setFormThreeData] = useState<any | {}>({})

  const handleTabs = async (keys1: any) => {
    setShowBackDrop(true)
    setFormOneData(getValues())
    setFormTwoData(getValues1())
    setFormThreeData(getValues2())
    setKey(keys1)
    setDateRange([])
    setNetSuiteDateRange([])
    setCreateInvoice([])
    setCreateNSInvoice([])
    setInvoiceDate(null)
    setNSInvoiceDate(null)
    if (keys1 == 'history') {
      let MultipleYears = []
      let MultipleMonths = []
      let month = moment(new Date()).format('MMMM')
      let year = moment(new Date()).format('YYYY')
      let new_date = moment(`${year}-${month}-01`)
      const startTime = moment(new_date).subtract(1, 'months').format('MMMM YYYY')
      let a = [new Date(startTime), new Date(startTime)]

      setDateValue1(a)

      MultipleYears.push(moment(new Date(startTime)).format('YYYY'))
      MultipleMonths.push([moment(new Date(startTime)).format('M')])
      setMultipleYears(MultipleYears)
      setMultipleMonths(MultipleMonths)
      let projDetails: any = {
        year: MultipleYears,
        months: MultipleMonths,
      }
      setProjectDetails(projDetails)

      var ProjObj2: any = []
      ProjObj2.push({
        year: moment(new Date()).subtract(1, 'month').format('YYYY'),
        months: [moment(new Date()).subtract(1, 'month').format('M')],
      })
      let pay = {
        project_id: [projectid.id],
        user_id: [projectid.user_id],
        business_unit_id: [projectid.business_unit_id],
        date: ProjObj2,
      }
      const response: any = await Apiservices.getProjectWiseCreatedHistory(pay)
      if (response.isError == false) {
        if (response && response.data && response.data.length > 0) {
          let history_map: any = []
          let AllResp = response.data.map((hist: any) => {
            hist.timesheet_data.map((inside: any) => {
              history_map.push(inside)
            })
          })

          const abc = history_map.filter((obj: any, index: any) => {
            return (
              index ===
              history_map.findIndex(
                (o: any) => obj.start_date === o.start_date && obj.qb_invoice_id === o.qb_invoice_id
              )
            )
          })
          const totals: any = []
          history_map.forEach((x: any) => {
            const Amount_Calculation = totals.find((o: any) => o.qb_invoice_id === x.qb_invoice_id)
            if (Amount_Calculation) {
              if (Amount_Calculation.bill_rate != x.bill_rate) {
                Amount_Calculation.total_bill_rate = true
              }
              if (Amount_Calculation.ot_bill_rate != x.ot_bill_rate) {
                Amount_Calculation.total_ot_bill_rate = true
              }
              if (
                Amount_Calculation.bill_rate == x.bill_rate &&
                Amount_Calculation.ot_bill_rate == x.ot_bill_rate
              ) {
                Amount_Calculation.total = Amount_Calculation.amount
                Amount_Calculation.total_regular_hours =
                  Amount_Calculation.regular_hours + Amount_Calculation.regular_minutes / 60
                Amount_Calculation.total_ot_hours =
                  Amount_Calculation.ot_hours + Amount_Calculation.ot_minutes / 60
              } else {
                Amount_Calculation.total = Amount_Calculation.amount + x.amount
                Amount_Calculation.total_regular_hours =
                  Amount_Calculation.regular_hours +
                  x.regular_hours +
                  Amount_Calculation.regular_minutes / 60 +
                  x.regular_minutes / 60
                Amount_Calculation.total_ot_hours =
                  Amount_Calculation.ot_hours +
                  x.ot_hours +
                  Amount_Calculation.ot_minutes / 60 +
                  x.ot_minutes / 60
              }
            } else {
              x.total = x.amount
              x.total_regular_hours = x.regular_hours + x.regular_minutes / 60
              x.total_ot_hours = x.ot_hours + x.ot_minutes / 60
              x.total_bill_rate = false
              x.total_ot_bill_rate = false
              totals.push(x)
            }
          })
          let b1 = abc.map((item: any) => {
            let b2 = totals.filter((tot: any) => tot.qb_invoice_id == item.qb_invoice_id)
            let obj = {
              ...item,
              amount1: b2[0].total,
              total_regular_hours: b2[0].total_regular_hours,
              total_ot_hours: b2[0].total_ot_hours,
              total_bill_rate: b2[0].total_bill_rate,
              total_ot_bill_rate: b2[0].total_ot_bill_rate,
            }
            return obj
          })
          // setHistoryInvoice(history_map)
          setHistoryInvoice(b1)
          setAllCreateInvoiceCount(response.count.count)
        } else {
          setHistoryInvoice([])
        }
      }
    } else if (keys1 == 'netsuite_history') {
      let month = moment(new Date()).format('MMMM')
      let year = moment(new Date()).format('YYYY')
      let new_date = moment(`${year}-${month}-01`)
      const startTime = moment(new_date).subtract(1, 'months').format('MMMM YYYY')
      const startingMonth = moment(new_date).subtract(1, 'months').format('YYYY-MM')
      const endingMonth = moment(startTime).clone().endOf('month').format('YYYY-MM-DD')

      let a = [new Date(startTime), new Date(startTime)]
      setNSDateValue1(a)
      let start_end = [`${startingMonth}-${'01'}`, endingMonth]
      setNSDateValueSetting(start_end)
      let pay = {
        project_id: [projectid.id],
        user_id: [projectid.user_id],
        business_unit_id: [projectid.business_unit_id],
        start_date: `${startingMonth}-${'01'}`,
        end_date: endingMonth,
      }
      const response: any = await Apiservices.getProjectWiseNetSuiteCreatedHistory(pay)
      if (response.isError == false) {
        if (response && response.data && response.data.length > 0) {
          setNetSuiteHistoryInvoice(response.data)
          setAllCreateInvoiceCount(response.count.count)
        } else {
          setNetSuiteHistoryInvoice([])
        }
      }
    } else if (keys1 == 'invoice') {
      let month = moment(new Date()).format('MMMM')
      let year = moment(new Date()).format('YYYY')
      let new_date = moment(`${year}-${month}-01`)
      const startDate2 = moment(new_date).subtract(1, 'months').format('YYYY-MM-DD')
      let endDate2 = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      let a = [
        new Date(moment(new_date).subtract(1, 'months').format('DD-MMM-YYYY')),
        new Date(moment().subtract(1, 'months').endOf('month').format('DD-MMM-YYYY')),
      ]
      setDateRange(a)
      setPrevDate(a)
      const invoicePayload = {
        user_id: projectid.user_id,
        project_id: projectid.id,
        start_date: startDate2,
        end_date: endDate2,
      }
      const response: any = await Apiservices.get_timesheet_entries_to_create_invoice(
        invoicePayload
      )
      if (response && response.isError == false) {
        setInvoiceTimesheets(response?.available_timesheets)
        if (response && response.data && response.data.length > 0) {
          setFlag(true)
          setCreateInvoice(response.data)
          // setAllCreateInvoiceCount(response.count[0].count)
        } else {
          setFlag(false)
          setCreateInvoice([])
          // setAllCreateInvoiceCount([])
        }
      }
    } else if (keys1 == 'netsuite_invoice') {
      let month = moment(new Date()).format('MMMM')
      let year = moment(new Date()).format('YYYY')
      let new_date = moment(`${year}-${month}-01`)
      const startDate2 = moment(new_date).subtract(1, 'months').format('YYYY-MM-DD')
      let endDate2 = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      let a = [
        new Date(moment(new_date).subtract(1, 'months').format('DD-MMM-YYYY')),
        new Date(moment().subtract(1, 'months').endOf('month').format('DD-MMM-YYYY')),
      ]
      setNetSuiteDateRange(a)
      setPrevNSDate(a)
      const invoicePayload = {
        user_id: projectid.user_id,
        project_id: projectid.id,
        start_date: startDate2,
        end_date: endDate2,
      }
      const response: any = await Apiservices.get_timesheet_entries_to_create_netsuite_invoice(
        invoicePayload
      )
      if (response && response.isError == false) {
        setInvoiceNSTimesheets(response?.available_timesheets)
        if (response && response.data && response.data.length > 0) {
          setFlag(true)
          setCreateNSInvoice(response.data)
          // setAllCreateInvoiceCount(response.count[0].count)
        } else {
          setFlag(false)
          setCreateNSInvoice([])
          // setAllCreateInvoiceCount([])
        }
      }
    } else if (keys1 == 'work') {
      let state: any = location.state
      if (location.state) {
        viewEmployee(state.id)
      } else {
        viewEmployee(projectid?.id)
      }
    } else if (keys1 == 'payment') {
      let state: any = location.state
      if (location.state) {
        viewEmployee(state.id)
      } else {
        viewEmployee(projectid?.id)
      }
    }
    setShowBackDrop(false)
  }
  const handleTabsQB = async (keys1: any) => {
    setShowBackDrop(true)
    setFormOneData(getValues())
    setFormTwoData(getValues1())
    setFormThreeData(getValues2())
    setKeyQB(keys1)
    setDateRange([])
    setNetSuiteDateRange([])
    setCreateInvoice([])
    setCreateNSInvoice([])
    setInvoiceDate(null)
    setNSInvoiceDate(null)
    if (keys1 == 'history') {
      let MultipleYears = []
      let MultipleMonths = []
      let month = moment(new Date()).format('MMMM')
      let year = moment(new Date()).format('YYYY')
      let new_date = moment(`${year}-${month}-01`)
      const startTime = moment(new_date).subtract(1, 'months').format('MMMM YYYY')
      let a = [new Date(startTime), new Date(startTime)]

      setDateValue1(a)

      MultipleYears.push(moment(new Date(startTime)).format('YYYY'))
      MultipleMonths.push([moment(new Date(startTime)).format('M')])
      setMultipleYears(MultipleYears)
      setMultipleMonths(MultipleMonths)
      let projDetails: any = {
        year: MultipleYears,
        months: MultipleMonths,
      }
      setProjectDetails(projDetails)

      var ProjObj2: any = []
      ProjObj2.push({
        year: moment(new Date()).subtract(1, 'month').format('YYYY'),
        months: [moment(new Date()).subtract(1, 'month').format('M')],
      })
      let pay = {
        project_id: [projectid.id],
        user_id: [projectid.user_id],
        business_unit_id: [projectid.business_unit_id],
        date: ProjObj2,
      }
      const response: any = await Apiservices.getProjectWiseCreatedHistory(pay)
      if (response.isError == false) {
        if (response && response.data && response.data.length > 0) {
          let history_map: any = []
          let AllResp = response.data.map((hist: any) => {
            hist.timesheet_data.map((inside: any) => {
              history_map.push(inside)
            })
          })

          const abc = history_map.filter((obj: any, index: any) => {
            return (
              index ===
              history_map.findIndex(
                (o: any) => obj.start_date === o.start_date && obj.qb_invoice_id === o.qb_invoice_id
              )
            )
          })
          const totals: any = []
          history_map.forEach((x: any) => {
            const Amount_Calculation = totals.find((o: any) => o.qb_invoice_id === x.qb_invoice_id)
            if (Amount_Calculation) {
              if (Amount_Calculation.bill_rate != x.bill_rate) {
                Amount_Calculation.total_bill_rate = true
              }
              if (Amount_Calculation.ot_bill_rate != x.ot_bill_rate) {
                Amount_Calculation.total_ot_bill_rate = true
              }
              if (
                Amount_Calculation.bill_rate == x.bill_rate &&
                Amount_Calculation.ot_bill_rate == x.ot_bill_rate
              ) {
                Amount_Calculation.total = Amount_Calculation.amount
                Amount_Calculation.total_regular_hours =
                  Amount_Calculation.regular_hours + Amount_Calculation.regular_minutes / 60
                Amount_Calculation.total_ot_hours =
                  Amount_Calculation.ot_hours + Amount_Calculation.ot_minutes / 60
              } else {
                Amount_Calculation.total = Amount_Calculation.amount + x.amount
                Amount_Calculation.total_regular_hours =
                  Amount_Calculation.regular_hours +
                  x.regular_hours +
                  Amount_Calculation.regular_minutes / 60 +
                  x.regular_minutes / 60
                Amount_Calculation.total_ot_hours =
                  Amount_Calculation.ot_hours +
                  x.ot_hours +
                  Amount_Calculation.ot_minutes / 60 +
                  x.ot_minutes / 60
              }
            } else {
              x.total = x.amount
              x.total_regular_hours = x.regular_hours + x.regular_minutes / 60
              x.total_ot_hours = x.ot_hours + x.ot_minutes / 60
              x.total_bill_rate = false
              x.total_ot_bill_rate = false
              totals.push(x)
            }
          })
          let b1 = abc.map((item: any) => {
            let b2 = totals.filter((tot: any) => tot.qb_invoice_id == item.qb_invoice_id)
            let obj = {
              ...item,
              amount1: b2[0].total,
              total_regular_hours: b2[0].total_regular_hours,
              total_ot_hours: b2[0].total_ot_hours,
              total_bill_rate: b2[0].total_bill_rate,
              total_ot_bill_rate: b2[0].total_ot_bill_rate,
            }
            return obj
          })
          // setHistoryInvoice(history_map)
          setHistoryInvoice(b1)
          setAllCreateInvoiceCount(response.count.count)
        } else {
          setHistoryInvoice([])
        }
      }
    } else if (keys1 == 'invoice') {
      let month = moment(new Date()).format('MMMM')
      let year = moment(new Date()).format('YYYY')
      let new_date = moment(`${year}-${month}-01`)
      const startDate2 = moment(new_date).subtract(1, 'months').format('YYYY-MM-DD')
      let endDate2 = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      let a = [
        new Date(moment(new_date).subtract(1, 'months').format('DD-MMM-YYYY')),
        new Date(moment().subtract(1, 'months').endOf('month').format('DD-MMM-YYYY')),
      ]
      setDateRange(a)
      setPrevDate(a)
      const invoicePayload = {
        user_id: projectid.user_id,
        project_id: projectid.id,
        start_date: startDate2,
        end_date: endDate2,
      }
      const response: any = await Apiservices.get_timesheet_entries_to_create_invoice(
        invoicePayload
      )
      if (response && response.isError == false) {
        setInvoiceTimesheets(response?.available_timesheets)
        if (response && response.data && response.data.length > 0) {
          setFlag(true)
          setCreateInvoice(response.data)
          // setAllCreateInvoiceCount(response.count[0].count)
        } else {
          setFlag(false)
          setCreateInvoice([])
          // setAllCreateInvoiceCount([])
        }
      }
    }
    setShowBackDrop(false)
  }
  const handleTabsNetSuite = async (keys1: any) => {
    setShowBackDrop(true)
    setFormOneData(getValues())
    setFormTwoData(getValues1())
    setFormThreeData(getValues2())
    setKeyNS(keys1)
    setDateRange([])
    setNetSuiteDateRange([])
    setCreateInvoice([])
    setCreateNSInvoice([])
    setInvoiceDate(null)
    setNSInvoiceDate(null)
    if (keys1 == 'netsuite_history') {
      let month = moment(new Date()).format('MMMM')
      let year = moment(new Date()).format('YYYY')
      let new_date = moment(`${year}-${month}-01`)
      const startTime = moment(new_date).subtract(1, 'months').format('MMMM YYYY')
      const startingMonth = moment(new_date).subtract(1, 'months').format('YYYY-MM')
      const endingMonth = moment(startTime).clone().endOf('month').format('YYYY-MM-DD')

      let a = [new Date(startTime), new Date(startTime)]
      setNSDateValue1(a)
      let start_end = [`${startingMonth}-${'01'}`, endingMonth]
      setNSDateValueSetting(start_end)
      let pay = {
        project_id: [projectid.id],
        user_id: [projectid.user_id],
        business_unit_id: [projectid.business_unit_id],
        start_date: `${startingMonth}-${'01'}`,
        end_date: endingMonth,
      }
      const response: any = await Apiservices.getProjectWiseNetSuiteCreatedHistory(pay)
      if (response.isError == false) {
        if (response && response.data && response.data.length > 0) {
          setNetSuiteHistoryInvoice(response.data)
          setAllCreateInvoiceCount(response.count.count)
        } else {
          setNetSuiteHistoryInvoice([])
        }
      }
    } else if (keys1 == 'netsuite_invoice') {
      let month = moment(new Date()).format('MMMM')
      let year = moment(new Date()).format('YYYY')
      let new_date = moment(`${year}-${month}-01`)
      const startDate2 = moment(new_date).subtract(1, 'months').format('YYYY-MM-DD')
      let endDate2 = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      let a = [
        new Date(moment(new_date).subtract(1, 'months').format('DD-MMM-YYYY')),
        new Date(moment().subtract(1, 'months').endOf('month').format('DD-MMM-YYYY')),
      ]
      setNetSuiteDateRange(a)
      setPrevNSDate(a)
      const invoicePayload = {
        user_id: projectid.user_id,
        project_id: projectid.id,
        start_date: startDate2,
        end_date: endDate2,
      }
      const response: any = await Apiservices.get_timesheet_entries_to_create_netsuite_invoice(
        invoicePayload
      )
      if (response && response.isError == false) {
        setInvoiceNSTimesheets(response?.available_timesheets)
        if (response && response.data && response.data.length > 0) {
          setFlag(true)
          setCreateNSInvoice(response.data)
          // setAllCreateInvoiceCount(response.count[0].count)
        } else {
          setFlag(false)
          setCreateNSInvoice([])
          // setAllCreateInvoiceCount([])
        }
      }
    }
    setShowBackDrop(false)
  }

  const singleInvoiceDate = (date: any) => {
    setDateRange(date)
    if (date[0] && date[1]) {
      if (
        moment(date[0]).format('MM-DD-YYYY') == moment(PrevDate[0]).format('MM-DD-YYYY') &&
        moment(date[1]).format('MM-DD-YYYY') == moment(PrevDate[1]).format('MM-DD-YYYY')
      ) {
        setDateRun(false)
      } else {
        setPrevDate(date)
        setDateRun(true)
      }
    } else if (date[0] == null && date[1] == null) {
      setDateRun(true)
      setCreateInvoice([])
      setInvoiceTimesheets([])
      setInvoiceDate(null)
    }
  }
  const singleNetSuiteInvoiceDate = (net_date: any) => {
    setNetSuiteDateRange(net_date)
    if (net_date[0] && net_date[1]) {
      if (
        moment(net_date[0]).format('MM-DD-YYYY') == moment(PrevNSDate[0]).format('MM-DD-YYYY') &&
        moment(net_date[1]).format('MM-DD-YYYY') == moment(PrevNSDate[1]).format('MM-DD-YYYY')
      ) {
        setNS_DateRun(false)
      } else {
        setPrevNSDate(net_date)
        setNS_DateRun(true)
      }
    } else if (net_date[0] == null && net_date[1] == null) {
      setNS_DateRun(true)
      setCreateNSInvoice([])
      setInvoiceNSTimesheets([])
      setNSInvoiceDate(null)
    }
  }
  const handleInvoiceDate = async (date: any) => {
    setInvoiceDate(date)
  }
  const handleNetSuiteInvoiceDate = (date: any) => {
    setNSInvoiceDate(date)
  }
  return (
    <>
      {list ? (
        <ProjectPop
          customermessage={customermessage}
          close={modalPop}
          cancel={handleCancel}
          closeModal={list}
        />
      ) : (
        ''
      )}
      {QBConsultant && (
        <ConsultantQBPop ConsultantQBOk={ConsultantQBOk} ConsultantQBClose={ConsultantQBClose} />
      )}
      {history_open_per_week ? (
        <ProjectHoursDrawer
          history_open_per_week={history_open_per_week}
          setHistoryOpenPerWeek={setHistoryOpenPerWeek}
          setShow={setShow}
          show={show}
          ExpectedHoursHistory={ExpectedHoursHistory}
          handleEffectiveTableDate={handleEffectiveTableDate}
          handleExpectedTableHours={handleExpectedTableHours}
          projectid={projectid}
        />
      ) : (
        ''
      )}
      {InvoicePopOpen && (
        <CreateInvoicePop
          InvoiceOkPop={InvoiceOkPop}
          InvoiceClosePop={InvoiceClosePop}
          QBName={'Invoice'}
        />
      )}
      {NSInvoicePopOpen && (
        <CreateInvoicePop
          InvoiceNetSuiteOkPop={InvoiceNetSuiteOkPop}
          InvoiceNetSuiteClosePop={InvoiceNetSuiteClosePop}
          QBName={'NetSuiteInvoice'}
        />
      )}
      {projectstatuspopup ? (
        <ProjectPop close={modalPop} cancel={handleCancel} closeModal={projectstatuspopup} />
      ) : (
        ''
      )}
      {QBClientPop ? (
        <ProjectQBPop
          Settings={Settings}
          close={EditQBClientOkPop}
          cancel={EditQBClientCancelPop}
          QBName={'ClientQB'}
        />
      ) : (
        ''
      )}
      {QBVendorPop ? (
        <ProjectQBPop
          Settings={Settings}
          close={EditQBVendorOkPop}
          cancel={EditQBVendorCancelPop}
          QBName={'VendorQB'}
        />
      ) : (
        ''
      )}
      {QBEmployeePop ? (
        <ProjectQBPop
          Settings={Settings}
          close={EditQBConsultantOkPop}
          cancel={EditQBConsultantCancelPop}
          QBName={'EmployeeQB'}
        />
      ) : (
        ''
      )}
      {InvoiceViewPopOpen && (
        <InvoiceHistoryViewPop ViewHistory={ViewHistory} handleInvoiceClose={handleInvoiceClose} />
      )}
      {UserProjectBuPop && (
        <ProjectQBPop QBName={'UserProjectBUQB'} UserProjectClose={UserProjectClose} />
      )}
      {QBProjectPop ? (
        <ProjectQBPop
          Settings={Settings}
          close={EditQBProjectOkPop}
          cancel={EditQBProjectCancelPop}
          QBName={'ProjectQB'}
        />
      ) : (
        ''
      )}
      {OTProductHours ? (
        <ProjectQBPop
          Settings={Settings}
          OTProductHoursOk={OTProductHoursOk}
          OTProductHoursCancel={OTProductHoursCancel}
          QBName={'OTProductHours'}
        />
      ) : (
        ''
      )}
      {QBProductPop ? (
        <ProjectQBPop
          Settings={Settings}
          close={EditQBProductOkPop}
          cancel={EditQBProductCancelPop}
        />
      ) : (
        ''
      )}
      {OTQBProductPop ? (
        <ProjectQBPop
          Settings={Settings}
          close={EditOTQBProductOkPop}
          cancel={EditOTQBProductCancelPop}
        />
      ) : (
        ''
      )}
      <BackDrop showBackDrop={showBackDrop} />
      {location.state ? (
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.UPDATEPROJ'})}</PageTitle>
      ) : (
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADDPROJ'})}</PageTitle>
      )}
      <Tabs
        id='controlled-tab-example'
        activeKey={key}
        // onSelect={(k) => setKey(k)}
        onSelect={(k) => handleTabs(k)}
        className='mb-3 activeColor'
      >
        <Tab eventKey='project' title='Project Details'>
          <>
            <form onSubmit={handleSubmit(onSubmit)} id='add-project'>
              <div className='card card-custom card-flush mb-6'>
                <div className='card-body py-5'>
                  <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Project Details</h3>
                  <div className='row gy-4 '>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>Project Type *</label>
                      <select
                        className={clsx('form-select', errors.project_type ? 'is-invalid' : '')}
                        // disabled={disabled}
                        disabled={AllFieldsToDisabled}
                        placeholder='Enter Project Type'
                        {...register('project_type', {
                          required: true,
                          onChange: (e) => {
                            handleProjectType(e.target.value)
                            setValue('project_type', e.target.value)
                          },
                        })}
                        aria-label='Default select example'
                      >
                        <option value=''>Select Project Type</option>
                        {/* <option value='Local'>Bench Project</option>
                  <option value='Placement'>Placement</option> */}
                        <option value='1'>Placement</option>
                        <option value='2'>Bench Project</option>
                        <option value='3'>Internal</option>
                      </select>

                      {errors.project_type &&
                        errors.project_type.type === 'required' &&
                        !getValues('project_type') && (
                          <p className='error text-danger mb-0'>Project Type is required.</p>
                        )}
                    </div>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>Consultant Type *</label>
                      <select
                        className={clsx('form-select', errors.placement_type ? 'is-invalid' : '')}
                        // disabled={disabled || AllFieldsToDisabled}
                        disabled={AllFieldsToDisabled || projectConsultantDisabled}
                        {...register('placement_type', {
                          required: true,
                          onChange: (e) => {
                            handleConsultantType(e.target.value)
                            // setConsultantOption(e.target.value)
                            setValue('placement_type', e.target.value)
                          },
                        })}
                        // className='form-select'
                        aria-label='Default select example'
                      >
                        {proj_type == '3' ? (
                          <>
                            <option value=''>Select Consultant Type</option>
                            <option value='W2'>W2</option>
                            <option value='Internal'>Internal</option>
                          </>
                        ) : (
                          <>
                            <option value=''>Select Consultant Type</option>
                            <option value='W2'>W2</option>
                            <option value='C2C'>C2C</option>
                            <option value='Internal'>Internal</option>
                            <option value='Offshore'>Offshore</option>
                            <option value='1099'>1099</option>
                          </>
                        )}
                      </select>
                      {errors.placement_type &&
                        errors.placement_type.type === 'required' &&
                        !getValues('placement_type') && (
                          <p className='error text-danger mb-0'>Consultant Type is required.</p>
                        )}
                    </div>

                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>Consultant *</label>
                      <Typeahead
                        id='basic-typeahead-single'
                        // disabled={isView}
                        onChange={(e) => selectedConsultant(e)}
                        onInputChange={(e) => handleFilterConsultant(e)}
                        // disabled={disabled}
                        disabled={AllFieldsToDisabled}
                        options={candidateList}
                        placeholder='Search Consultant'
                        selected={select_consultant}
                        labelKey={(candidateList: any) => user(candidateList.full_name)}
                        inputProps={{required: true}}
                      >
                        {({onClear, selected}) => (
                          <div className='rbt-aux'>
                            {!!selected.length && (
                              <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                            )}
                          </div>
                        )}
                      </Typeahead>
                    </div>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label '>Business Unit *</label>
                      <select
                        {...register('business_unit_id', {
                          required: true,
                          onChange: (e) => {
                            handleBusinessUnit(e.target.value)
                          },
                        })}
                        disabled={AllFieldsToDisabled}
                        className={clsx('form-select', errors.business_unit_id ? 'is-invalid' : '')}
                      >
                        <option value=''>Select Business Unit</option>
                        {BusinessUnitsList &&
                          BusinessUnitsList.length > 0 &&
                          BusinessUnitsList.map((option: any) => {
                            return (
                              <option value={option.business_unit_id} className='text-capitalize'>
                                {option.name}
                              </option>
                            )
                          })}
                      </select>
                      {errors.business_unit_id &&
                        errors.business_unit_id.type === 'required' &&
                        !getValues('business_unit_id') && (
                          <p className='error text-danger mb-0'>Business Unit is required.</p>
                        )}
                    </div>
                    {/* {proj_type != 'Local' && ( */}
                    {proj_type == '1' && (
                      <div className='col-xs-12 col-md-3 '>
                        <label className='form-label'>Placement ID {proj_type == '1' && '*'}</label>
                        <input
                          type='text'
                          id='placement_code'
                          className='form-control fs-6'
                          placeholder='Enter Placement ID'
                          disabled={disable_placement}
                          // disabled={disabled ||AllFieldsToDisabled}
                          {...register('placement_code', {
                            required: proj_type == '1' ? true : false,
                            onChange: (e) => {
                              handlePlacement(e.target.value, 'Not')
                            },
                            onBlur: (e) => {
                              handlePlacement(getValues('placement_code'), 'Blur')
                            },
                          })}
                        />
                        {proj_type == '1' && (
                          <>
                            {errors.placement_code && errors.placement_code.type === 'required' && (
                              <p className='error text-danger mb-0'>Placement Code is required.</p>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>End Customer *</label>
                      <Typeahead
                        id='basic-typeahead-single'
                        // disabled={isView}
                        // disabled={disabled}
                        onChange={(e) => selectedEndCustomer(e)}
                        onInputChange={(e) => handleFilterEndCustomer(e)}
                        options={customerList}
                        disabled={AllFieldsToDisabled || AllFieldsToDisabledByPlacement}
                        placeholder='Search End Customer'
                        selected={select_customer}
                        labelKey={(customerList: any) => customerList.client_name}
                        inputProps={{required: true}}
                      >
                        {({onClear, selected}) => (
                          <div className='rbt-aux'>
                            {!!selected.length && (
                              <ClearButton
                                disabled={AllFieldsToDisabled || AllFieldsToDisabledByPlacement}
                                onClick={onClear}
                              />
                            )}
                          </div>
                        )}
                      </Typeahead>

                      {errors.end_customer && errors.end_customer.type === 'required' && (
                        <p className='error text-danger mb-0'>End Customer is required.</p>
                      )}
                      {custerror && <p className='error text-danger mb-0 mt-2'>{custerror}</p>}
                    </div>
                    {/* {proj_type != 'Local' && ( */}
                    {proj_type == '1' && (
                      <div className='col-xs-12 col-md-3 '>
                        <label className='form-label'> Direct Customer Engagement </label>
                        <div
                          onChange={handleDirect}
                          className='form-check form-switch form-switch-sm form-check-custom form-check-solid'
                        >
                          <input
                            className='form-check-input form-control fs-6 my-2'
                            disabled={disabled || AllFieldsToDisabled}
                            {...register('direct_customer_engagement')}
                            // disabled={ AllFieldsToDisabled}

                            type='checkbox'
                            role='switch'
                            id='flexSwitchCheckDefault'
                            checked={directcustomer}
                          />
                          <span className='ps-2'>{directcustomer ? 'yes ' : 'No'}</span>
                        </div>
                      </div>
                    )}
                    {/* {proj_type != 'Local' && ( */}
                    {proj_type == '1' && (
                      <>
                        {
                          // getValues('direct_customer_engagement') == 'false' ||
                          !directcustomer && (
                            <div className='col-xs-12 col-md-3 '>
                              <label className='form-label'> Client (Billing Vendor) *</label>
                              <Typeahead
                                id='basic-typeahead-single'
                                // disabled={isView}
                                onChange={(e) => selectedClient(e)}
                                onInputChange={(e) => handleFilterClient(e)}
                                options={clientList}
                                disabled={AllFieldsToDisabled || AllFieldsToDisabledByPlacement}
                                placeholder='Search Client (Billing Vendor)'
                                selected={select_client}
                                labelKey={(clientList: any) => clientList.client_name}
                                inputProps={{required: true}}
                              >
                                {({onClear, selected}) => (
                                  <div className='rbt-aux'>
                                    {!!selected.length && (
                                      <ClearButton
                                        disabled={
                                          AllFieldsToDisabled || AllFieldsToDisabledByPlacement
                                        }
                                        onClick={onClear}
                                      />
                                    )}
                                  </div>
                                )}
                              </Typeahead>
                              {clienterror && (
                                <p className='error text-danger mb-0 mt-2'>{clienterror}</p>
                              )}
                            </div>
                          )
                        }
                      </>
                    )}
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'> Project * </label>
                      <input
                        type='text'
                        className='form-control fs-6'
                        placeholder='Enter Project Name'
                        disabled={true || AllFieldsToDisabled || AllFieldsToDisabledByPlacement}
                        {...register('project_name')}
                      />
                    </div>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>
                        {' '}
                        {/* Job Title {getValues('project_type') == 'Local' && '*'} */}
                        Job Title
                      </label>
                      <input
                        type='text'
                        id='job_title'
                        className='form-control fs-6'
                        placeholder='Enter Job Title'
                        // disabled={disabled}
                        // disabled={AllFieldsToDisabled}
                        {...register('job_title', {
                          // required: proj_type == 'Local' ? true : false,
                          // required: true,
                        })}
                      />
                      {/* {proj_type == 'Local' && (
                  <>
                    {errors.job_title && errors.job_title.type === 'required' && (
                      <p className='error text-danger mb-0'>Job Title is required.</p>
                    )}
                  </>
                )} */}
                    </div>
                    {/* <div className='col-xs-12 col-md-3 '>
                <label className='form-label'>Currency Type* </label>
                <select
                  {...register('currency', {
                    required: true,
                  })}
                  disabled={AllFieldsToDisabled}
                  className='form-select'
                  aria-label='Default select example'
                >
                  
                  <option value='USD'>USD</option>
                  <option value='INR'>INR</option>
                </select>
                {errors.currency && errors.currency.type === 'required' && (
                  <p className='error text-danger mb-0'>Currency is required.</p>
                )}
              </div> 
               <div className='col-xs-12 col-md-3 '>
                <label className='form-label'>Bill Rate * </label>
                <input
                  type='text'
                  className='form-control fs-6'
                  placeholder='Enter Bill Rate'
                  disabled={AllFieldsToDisabled || AllFieldsToDisabledByPlacement}
                  {...register('bill_rate', {
                    required: true,
                    onChange: (e) => {
                      handleBillRate(e.target.value, getValues('pay_rate'))
                    },
                  })}
                />
                {errors.bill_rate && errors.bill_rate.type === 'required' && (
                  <p className='error text-danger mb-0'>Bill Rate is required.</p>
                )}
                {billerror && <p className='error text-danger mb-0 mt-2'>{billerror}</p>}
              </div>
              <div className='col-xs-12 col-md-3 '>
                <label className='form-label'> Pay Rate* </label>
                <input
                  type='text'
                  className='form-control fs-6'
                  placeholder='Enter Pay Rate'
                  disabled={AllFieldsToDisabled || AllFieldsToDisabledByPlacement}
                  {...register('pay_rate', {
                    required: true,
                    onChange: (e) => {
                      handlePayRate(getValues('bill_rate'), e.target.value)
                    },
                  })}
                />
                {errors.pay_rate && errors.pay_rate.type === 'required' && (
                  <p className='error text-danger mb-0'>Pay Rate is required.</p>
                )}
                
                {payerror && <p className='error text-danger mb-0 mt-2'>{payerror}</p>}
              </div> */}

                    {/* Start Date Format must be DD-MM-YYYY */}
                    {/* <div className='col-xs-12 col-md-3 '>
                <label className='form-label'> Start Date *</label>
                <input
                  type='date'
                  {...register('start_date', {
                    required: true,

                    onChange: (e) => {
                      handleDate(e.target.value)
                      setValue('start_date', e.target.value)
                    },
                  })}
                  disabled={disabled || AllFieldsToDisabled || AllFieldsToDisabledByPlacement}
                  className='form-control fs-6'
                />
                {errors.start_date &&
                  errors.start_date.type === 'required' &&
                  !getValues('start_date') && (
                    <p className='error text-danger mb-0'>Start Date is required.</p>
                  )}
              </div> */}
                    {/* Date Picker */}
                    {/* <div className='col-xs-12 col-md-3 '>
                <label className='form-label'> Start Date *</label>
                <DatePicker
                  className='form-control fs-6'
                  //   showIcon
                  {...register('start_date', {
                    required: true,
                  })}
                  selected={startDate}
                  dateFormat='dd/MM/yyyy'
                  onChange={(date: any) => {
                    handleDate(date)
                    setValue('start_date', date)
                  }}
                  placeholderText='Select a date'
                />
                {errors.start_date &&
                  errors.start_date.type === 'required' &&
                  !getValues('start_date') && (
                    <p className='error text-danger mb-0'>Start Date is required.</p>
                  )}
              </div> */}

                    {/* Common component for Date Picker */}

                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'> Start Date (MM/DD/YYYY) *</label>
                      <DatePickerIcon
                        className='form-control fs-6'
                        // showIcon
                        {...register('start_date', {
                          required: true,
                        })}
                        id='start_date'
                        selected={startDate}
                        // name='startDate'
                        // disabled={AllFieldsToDisabled}
                        disabled={
                          StateEdit == 'edit'
                            ? numArray?.includes('admin')
                              ? projectid.is_placement_project == '2' ||
                                projectid.is_placement_project == '3'
                                ? false
                                : AllFieldsToDisabled
                              : AllFieldsToDisabled
                            : false
                        }
                        dateFormat='MM/dd/yyyy'
                        handleDate={(date: any) => {
                          handleProjectStartDate(date)
                          // setStartDate(date)
                          // setValue('start_date', date)
                        }}
                        min={
                          UserData
                            ? UserData && UserData[0] && new Date(UserData[0].joining_date)
                            : ''
                        }
                        placeholderText='Select date'
                      />

                      {errors.start_date &&
                        errors.start_date.type === 'required' &&
                        !getValues('start_date') && (
                          <p className='error text-danger mb-0'>Start Date is required.</p>
                        )}
                    </div>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>
                        Client Manager Name {proj_type == '1' && '*'}{' '}
                      </label>
                      <input
                        type='text'
                        className='form-control fs-6'
                        placeholder='Enter Client Manager Name'
                        {...register('client_manager_name', {
                          // required: true,
                          required: proj_type == '1' ? true : false,
                        })}
                      />
                      {proj_type == '1' && (
                        <>
                          {errors.client_manager_name &&
                            errors.client_manager_name.type === 'required' && (
                              <p className='error text-danger mb-0'>
                                Client Manager Name is required.
                              </p>
                            )}
                        </>
                      )}
                    </div>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>Client Manager Email</label>
                      <input
                        type='email'
                        className='form-control fs-6'
                        placeholder='Enter Client Manager Email'
                        {...register('client_manager_email', {
                          // required: true,
                        })}
                      />
                      {/* {errors.client_manager_email && errors.client_manager_email.type === 'required' && (
                  <p className='error text-danger mb-0'>Client Manager Email is required.</p>
                )} */}
                    </div>
                    {/* {displayqb && ( */}
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>Primary Project </label>
                      <div
                        onChange={handlePrimary}
                        className='form-check form-switch form-switch-sm form-check-custom form-check-solid'
                      >
                        <input
                          className='form-check-input form-control fs-6 my-2'
                          {...register('primary_project')}
                          type='checkbox'
                          role='switch'
                          id='flexSwitchCheckDefault'
                          checked={primary}
                          disabled={disablePrimaryProject}
                        />
                        <span className='ps-2'>{primary ? 'yes ' : 'No'}</span>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
              <div className='card card-custom card-flush mb-6'>
                <div className='card-body py-5'>
                  <div className='row gy-4 '>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>Project Status * </label>
                      <select
                        disabled={
                          statusname == 'Completed' ||
                          statusname == 'Cancelled' ||
                          statusname == 'Rejected'
                            ? AllFieldsToDisabled
                            : false
                        }
                        {...register('status', {
                          // required: true,
                          onChange: (e) => {
                            handleStatus(e.target.value)
                          },
                        })}
                        className='form-select'
                        aria-label='Default select example'
                      >
                        {StatusOptions.map((option: any) => {
                          return (
                            <option value={option.label} className='text-capitalize'>
                              {option.label}
                            </option>
                          )
                        })}
                      </select>
                      {/* {errors.status && errors.status.type === 'required' && (
                  <p className='error text-danger mb-0'>status is required.</p>
                )} */}
                    </div>

                    {/* {statusname == 'PLACEMENT_ENDED' || location.state ? ( */}
                    {statusname == 'Completed' ? (
                      <>
                        {/* <div className='col-xs-12 col-md-3 '>
                    <label className='form-label'> End Date *</label>
                    <input
                      type='date'
                      {...register('end_date', {
                        required: true,
                      })}
                      className='form-control fs-6'
                    />
                    {errors.end_date && errors.end_date.type === 'required' && (
                      <p className='error text-danger mb-0'>End Date is required.</p>
                    )}
                  </div> */}
                        <div className='col-xs-12 col-md-3 '>
                          <label className='form-label'> End Date (MM/DD/YYYY) *</label>
                          <DatePickerIcon
                            className='form-control fs-6'
                            // showIcon
                            {...register('end_date', {
                              required: true,
                            })}
                            selected={endDate}
                            dateFormat='MM/dd/yyyy'
                            handleDate={(date: any) => {
                              handleEndDate(date)
                              setValue('end_date', date)
                            }}
                            min={startDate ? startDate : ''}
                            placeholderText='Select date'
                          />

                          {errors.end_date &&
                            errors.end_date.type === 'required' &&
                            !getValues('end_date') && (
                              <p className='error text-danger mb-0'>End Date is required.</p>
                            )}
                        </div>

                        <div className='col-xs-12 col-md-3 '>
                          <label className='form-label'> Reasons * </label>
                          <select
                            {...register('reasons', {
                              required: true,
                            })}
                            className='form-select text-capitalize'
                            aria-label='Default select example'
                          >
                            <option value=''>Select Reason</option>
                            {statusreasons &&
                              statusreasons.length > 0 &&
                              statusreasons.map((item: any) => {
                                return (
                                  <option value={item.reason} className='text-capitalize'>
                                    {item.reason}
                                  </option>
                                )
                              })}
                          </select>
                          {errors.reasons && errors.reasons.type === 'required' && (
                            <p className='error text-danger mb-0'>Reasons is required.</p>
                          )}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    {statusname === 'Cancelled' && (
                      <>
                        {/* <div className='col-xs-12 col-md-3 '>
                    <label className='form-label'> Cancel Date *</label>
                    <input
                      type='date'
                      {...register('cancel_date', {
                        required: true,
                      })}
                      className='form-control fs-6'
                    />
                    {errors.cancel_date && errors.cancel_date.type === 'required' && (
                      <p className='error text-danger mb-0'>Cancel Date is required.</p>
                    )}
                  </div> */}
                        <div className='col-xs-12 col-md-3 '>
                          <label className='form-label'> Cancel Date (MM/DD/YYYY) *</label>
                          <DatePickerIcon
                            className='form-control fs-6'
                            // showIcon
                            {...register('cancel_date', {
                              required: true,
                            })}
                            selected={cancelDate}
                            dateFormat='MM/dd/yyyy'
                            handleDate={(date: any) => {
                              handleCancelDate(date)
                              setValue('cancel_date', date)
                            }}
                            min={startDate ? startDate : ''}
                            placeholderText='Select date'
                          />

                          {errors.cancel_date &&
                            errors.cancel_date.type === 'required' &&
                            !getValues('cancel_date') && (
                              <p className='error text-danger mb-0'>Cancel Date is required.</p>
                            )}
                        </div>
                        <div className='col-xs-12 col-md-3 '>
                          <label className='form-label'> Reasons *</label>
                          <select
                            {...register('reasons', {
                              required: true,
                            })}
                            className='form-select'
                            aria-label='Default select example'
                          >
                            <option value=''>Select Reason</option>
                            {statusreasons &&
                              statusreasons.length > 0 &&
                              statusreasons.map((item: any) => {
                                return <option value={item.reason}>{item.reason}</option>
                              })}
                          </select>
                          {errors.reasons && errors.reasons.type === 'required' && (
                            <p className='error text-danger mb-0'>Reasons is required.</p>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='card card-custom card-flush mb-6'>
                <div className='card-body py-5'>
                  <div className='row gy-4 '>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>Work Country {proj_type == '1' && '*'} </label>
                      <Typeahead
                        id='basic-typeahead-single'
                        // disabled={isView}
                        onChange={(e) => selectedCountries(e)}
                        onInputChange={(e) => handleCountries(e)}
                        options={country}
                        placeholder='Enter Country Name'
                        // disabled={AllFieldsToDisabled}
                        selected={countries}
                        labelKey={(country: any) => user(country.name)}
                        inputProps={{
                          // required: true
                          required: proj_type == '1' ? true : false,
                        }}
                      >
                        {({onClear, selected}) => (
                          <div className='rbt-aux'>
                            {!!selected.length && (
                              // <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                              <ClearButton onClick={onClear} />
                            )}
                          </div>
                        )}
                      </Typeahead>
                    </div>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>Work State {proj_type == '1' && '*'}</label>
                      <Typeahead
                        id='basic-typeahead-single'
                        onChange={(e) => selectedStates(e)}
                        onInputChange={(e) => handleStates(e)}
                        options={state}
                        placeholder='Enter State Name'
                        selected={states}
                        labelKey={(state: any) => user(state.name)}
                        inputProps={{
                          required: proj_type == '1' ? true : false,
                          // required: true
                        }}
                      >
                        {({onClear, selected}) => (
                          <div className='rbt-aux'>
                            {!!selected.length && (
                              // <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                              <ClearButton onClick={onClear} />
                            )}
                          </div>
                        )}
                      </Typeahead>
                    </div>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>Work City {proj_type == '1' && '*'} </label>
                      <input
                        type='text'
                        placeholder='City'
                        className='form-control fs-6 text-capitalize'
                        minLength={3}
                        {...register('work_city', {
                          required: proj_type == '1' ? true : false,
                        })}
                      ></input>
                      {proj_type == '1' && (
                        <>
                          {errors.work_city && errors.work_city.type === 'required' && (
                            <p className='error text-danger mb-0'>Work City is required.</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='card card-custom card-flush mb-6'>
                <div className='card-body py-5'>
                  <div className='row gy-4 '>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>Effective Date (MM/DD/YYYY) *</label>
                      <DatePickerIcon
                        className='form-control fs-6'
                        // showIcon
                        {...register('effective_date', {
                          required: true,
                        })}
                        id='effective_date'
                        selected={effectiveDate}
                        dateFormat='MM/dd/yyyy'
                        handleDate={(date: any) => {
                          handleEffectiveDate(date)
                          // setStartDate(date)
                          // setValue('effective_date', date)
                        }}
                        // min={effectiveDate ? effectiveDate : ''}
                        // min={
                        //   ExpectedHoursHistory &&
                        //   ExpectedHoursHistory[0] &&
                        //   ExpectedHoursHistory[0].effective_date
                        //     ? new Date(ExpectedHoursHistory[0].effective_date )
                        //     : ''
                        // }
                        min={minDate}
                        disabled={StatusDisabledField}
                        placeholderText='Select date'
                      />

                      {errors.effective_date &&
                        errors.effective_date.type === 'required' &&
                        !getValues('effective_date') && (
                          <p className='error text-danger mb-0'>Effective Date is required.</p>
                        )}
                    </div>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label'>Expected Hours * </label>
                      <input
                        type='text'
                        className='form-control fs-6'
                        placeholder='Enter Expected Hours'
                        // disabled={AllFieldsToDisabled || AllFieldsToDisabledByPlacement}
                        {...register('expected_hours', {
                          required: true,
                          onChange: (e) => {
                            handleExpectedHours(e.target.value)
                          },
                        })}
                      />
                      {errors.expected_hours && errors.expected_hours.type === 'required' && (
                        <p className='error text-danger mb-0'>Expected Hours is required.</p>
                      )}
                      {/* {billerror && <p className='error text-danger mb-0 mt-2'>{billerror}</p>} */}
                    </div>
                    {ExpectedHoursHistory?.length > 1 && (
                      <div className='col-xs-12 col-md-3 '>
                        <div className='d-flex gap-3 justify-content-bottom px-5 pb-5 py-10'>
                          <button
                            type='button'
                            className='btn btn-light-primary  btn-sm'
                            onClick={getHistoryPerWeek}
                          >
                            History
                          </button>
                        </div>
                      </div>
                    )}
                    {/* {ExpectedHoursHistory?.length > 1 && (
                <>
                  <div className='row gy-4 '>
                    <div className='col-12 m-0'></div>
                    <div className='table-responsive '>
                      <table
                        id='kt_table_users'
                        className='table table-row-bordered table-row-gray-200 gy-2 gs-4 '
                        {...getTableProps()}
                      >
                        <thead>
                          <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                            <th onClick={() => 'effective_date'} className='  py-4'>
                              Effective Date{' '}
                            </th>
                            <th onClick={() => 'expected_hours'} className='  py-4'>
                              Expected Hours{' '}
                            </th>
                          </tr>
                        </thead>
                        {ExpectedHoursHistory?.length > 0 ? (
                          <>
                            <tbody className='' {...getTableBodyProps()}>
                              {ExpectedHoursHistory.length > 0
                                ? ExpectedHoursHistory.slice(1).map((user: any, index: number) => {
                                    return (
                                      <tr
                                        key={index}
                                        className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                      >
                                        <td className=''>
                                          <DatePickerIcon
                                            className='form-control fs-6'
                                            value={
                                              user.effective_date
                                                ? moment(user.effective_date).format('MM/DD/YYYY')
                                                : '-'
                                            }
                                            dateFormat='MM/dd/yyyy'
                                            handleDate={(date: any) => {
                                              handleEffectiveTableDate(date, index + 1)
                                            }}
                                            max={
                                              ExpectedHoursHistory[0].effective_date
                                                ? new Date(ExpectedHoursHistory[0].effective_date)
                                                : ''
                                            }
                                            placeholderText='Select date'
                                          />
                                        </td>
                                        <td className=''>
                                          <input
                                            type='text'
                                            className='form-control fs-6'
                                            placeholder='Enter Expected Hours'
                                            value={user.expected_hours ? user.expected_hours : ''}
                                            onChange={(e: any) =>
                                              handleExpectedTableHours(e.target.value, index + 1)
                                            }
                                          />
                                        </td>
                                      </tr>
                                    )
                                  })
                                : ''}
                            </tbody>
                          </>
                        ) : (
                          ''
                        )}
                      </table>
                      {(projectid.project_expected_hours?.length == null ||
                        projectid.project_expected_hours?.length == 0) && (
                        <p className='text-center'> No Records Found</p>
                      )}
                    </div>
                  </div>
                </>
              )} */}
                  </div>
                </div>
              </div>
              {/* <div className='card card-custom card-flush mb-6'>
                <div className='card-body py-5'>
                  <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>OT Hours Settings</h3>
                  <div className='row gy-4 '>
                    <div className='col-xs-12 col-md-3 '>
                      <label className='form-label '> Overtime Threshold Type *</label>
                      <select
                        {...register('ot_type', {
                          required: true,
                          onChange: (e) => {
                            handleThresholdType(e.target.value)
                          },
                        })}
                        defaultValue={'Daily'}
                        // disabled={AllFieldsToDisabled}
                        className={clsx('form-select', errors.ot_type ? 'is-invalid' : '')}
                      >
                        <option value=''>Select Overtime Threshold Type</option>
                        <option value='Daily'>Daily</option>
                        <option value='Weekly'>Weekly</option>
                      </select>
                      {errors.ot_type &&
                        errors.ot_type.type === 'required' &&
                        !getValues('ot_type') && (
                          <p className='error text-danger mb-0'>
                            {' '}
                            Overtime Threshold Type is required.
                          </p>
                        )}
                    </div>
                    {thresholdType === 'Daily' && (
                      <div className='col-xs-12 col-md-3 '>
                        <label className='form-label'>Daily Threshold * </label>
                        <input
                          type='number'
                          className='form-control fs-6'
                          placeholder='Enter Daily Threshold'
                          // disabled={AllFieldsToDisabled || AllFieldsToDisabledByPlacement}
                          {...register('threshold', {
                            required: true,
                            onChange: (e) => {
                              handleDailyThreshold(e.target.value)
                            },
                          })}
                        />
                        {errors.threshold && errors.threshold.type === 'required' && (
                          <p className='error text-danger mb-0'>Daily Threshold is required.</p>
                        )}
                        {dailyThresholdError && (
                          <p className='error text-danger mb-0 mt-2'>{dailyThresholdError}</p>
                        )}
                      </div>
                    )}
                    {thresholdType === 'Weekly' && (
                      <div className='col-xs-12 col-md-3 '>
                        <label className='form-label'>Weekly Threshold * </label>
                        <input
                          type='number'
                          className='form-control fs-6'
                          placeholder='Enter Weekly Threshold'
                          // disabled={AllFieldsToDisabled || AllFieldsToDisabledByPlacement}
                          {...register('threshold', {
                            required: true,
                            onChange: (e) => {
                              handleWeeklyThreshold(e.target.value)
                            },
                          })}
                        />
                        {errors.threshold && errors.threshold.type === 'required' && (
                          <p className='error text-danger mb-0'>Weekly Threshold is required.</p>
                        )}
                        {weeklyThresholdError && (
                          <p className='error text-danger mb-0 mt-2'>{weeklyThresholdError}</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div> */}
              <div className='d-flex justify-content-end  py-1 '>
                <div>
                  <div className='d-flex gap-3 '>
                    {!displayqb && (
                      <div>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          disabled={
                            message == 'Records Found' ||
                            message == 'NOT_MATCHED' ||
                            message_placement_validation == 'Records Found' ||
                            BenchProjectStartDate
                              ? // SubmitMessage == 'Records Found'
                                true
                              : false
                          }
                        >
                          Submit
                        </button>
                      </div>
                    )}
                    {displayqb && (
                      <div>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          // disabled={message == 'Records Found' ? true : false}
                          disabled={UserProjectBUDisabledButton ? true : false}
                        >
                          Update
                        </button>
                      </div>
                    )}
                    <div>
                      {' '}
                      <button type='button' onClick={() => navigate(-1)} className='btn btn-danger'>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='card card-custom card-flush mb-6'>
                <div className='card-body py-5'>
                  <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Work Location History</h3>
                  <div className='row gy-4 '>
                    <div className='col-12 m-0'></div>
                    <div className='table-responsive '>
                      <table
                        id='kt_table_users'
                        className='table table-row-bordered table-row-gray-200 gy-2 gs-4 '
                        {...getTableProps()}
                      >
                        <thead>
                          <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                            <th onClick={() => 'work_country'} className='  py-4'>
                              Work Country{' '}
                            </th>
                            <th onClick={() => 'work_state'} className='  py-4'>
                              Work State{' '}
                            </th>
                            <th onClick={() => 'work_city'} className='  py-4'>
                              Work City{' '}
                            </th>
                            <th onClick={() => 'created_by_name'} className='  py-4'>
                              Updated by{' '}
                            </th>
                            <th onClick={() => 'createdate'} className='  py-4'>
                              Updated on (MM/DD/YYYY){' '}
                            </th>
                          </tr>
                        </thead>
                        {ProjectWorkLocationHistory?.length > 0 ? (
                          <>
                            <tbody className='' {...getTableBodyProps()}>
                              {ProjectWorkLocationHistory.length > 0
                                ? ProjectWorkLocationHistory.map((user: any, index: number) => {
                                    return (
                                      <tr
                                        key={index}
                                        className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                      >
                                        <td className='text-capitalize'>
                                          {user.work_country ? user.work_country : '-'}
                                        </td>
                                        <td className='text-capitalize'>
                                          {user.work_state ? user.work_state : '-'}
                                        </td>
                                        <td className='text-capitalize'>
                                          {user.work_city ? user.work_city : '-'}
                                        </td>
                                        <td className='text-capitalize'>
                                          {user.created_by_name ? user.created_by_name : '-'}
                                        </td>
                                        <td className=''>
                                          {user.createdate
                                            ? moment(user.createdate).format('MM/DD/YYYY')
                                            : '-'}
                                        </td>
                                      </tr>
                                    )
                                  })
                                : ''}
                            </tbody>
                          </>
                        ) : (
                          ''
                        )}
                      </table>
                      {(ProjectWorkLocationHistory?.length == null ||
                        ProjectWorkLocationHistory?.length == 0) && (
                        <p className='text-center'> No Records Found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <ToastContainer /> */}
            </form>
          </>
        </Tab>
        {(numArray?.includes('admin') || numArray?.includes('accounts')) &&
          disableQuickBooks &&
          displayqb &&
          IndividualBU && (
            <Tab eventKey='QB' title='QB Details' id='update-qb'>
              <>
                <Tabs
                  id='controlled-tab-example'
                  activeKey={keyQB}
                  onSelect={(k) => handleTabsQB(k)}
                  className='mb-3'
                >
                  <Tab eventKey='Configuration' title='Configuration' id='update-qb'>
                    {localStorage.getItem('refresh_Token') == 'false' && (
                      <>
                        {/* {(localStorage.getItem('role_Name') === 'superadmin' ||
              localStorage.getItem('role_Name') === 'admin' ||
              localStorage.getItem('role_Name') === 'accounts') && ( */}

                        {(numArray?.includes('superadmin') ||
                          numArray?.includes('admin') ||
                          numArray?.includes('accounts')) && (
                          <>
                            {disableQuickBooks && ( //Main Switch
                              <>
                                {displayqb && ( //Edit
                                  <>
                                    <form onSubmit={handleSubmit1(onSubmit1)}>
                                      <div className='card card-custom card-flush mb-6'>
                                        <div className='card-body py-5'>
                                          {/* <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>
                                      QB Details
                                    </h3> */}
                                          <div className='row gy-4 '>
                                            <div className='col-12 m-0'></div>
                                            {IndividualBU && (
                                              <>
                                                {disableemployeedatabynameQuickBooks && (
                                                  <>
                                                    <div className='col-12 m-0'></div>
                                                    <div className='col-xs-12 col-md-3 '>
                                                      <label className='form-label'>
                                                        QB Consultant Name*{' '}
                                                      </label>
                                                      <input
                                                        // disabled={true}
                                                        disabled={disableQBEmployee}
                                                        type='text'
                                                        {...register1('qb_employee_name')}
                                                        className={`form-control ${
                                                          errors1.qb_employee_name
                                                            ? 'is-invalid'
                                                            : ''
                                                        }`}
                                                      />
                                                      {errors1.qb_employee_name &&
                                                        errors1.qb_employee_name.type ===
                                                          'required' && (
                                                          <p className='error text-danger mb-0'>
                                                            QB Consultant Name is required.
                                                          </p>
                                                        )}
                                                    </div>

                                                    {employeeopen && (
                                                      <div className='col-xs-12 col-md-3 '>
                                                        <label className='form-label '>
                                                          QB Consultant ID*{' '}
                                                        </label>
                                                        <input
                                                          type='text'
                                                          className='form-control fs-6'
                                                          disabled={true}
                                                          {...register1('qb_employee_id')}
                                                        />
                                                      </div>
                                                    )}

                                                    {getValues1('qb_employee_id') ? (
                                                      <></>
                                                    ) : (
                                                      <div className='col-xs-12 col-md-3 '>
                                                        <button
                                                          className='btn btn-secondary form-group  mt-8'
                                                          type='button'
                                                          // disabled={!disableQuickBooks}
                                                          onClick={() =>
                                                            handleEmployeeName(clientname, '')
                                                          }
                                                          aria-disabled='true'
                                                        >
                                                          Get QB Consultant ID
                                                        </button>
                                                      </div>
                                                    )}

                                                    {localStorage.getItem('refresh_Token') ==
                                                      'false' && (
                                                      <>
                                                        {(numArray?.includes('superadmin') ||
                                                          numArray?.includes('admin')) && (
                                                          <>
                                                            {getValues1('qb_employee_id') ? (
                                                              <div className='col-xs-12 col-md-3 '>
                                                                <button
                                                                  className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                                  type='button'
                                                                  onClick={() =>
                                                                    EditQBConsultantPop('QB')
                                                                  }
                                                                  aria-disabled='true'
                                                                >
                                                                  Edit QB Consultant ID
                                                                </button>
                                                              </div>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}

                                            {proj_type == '1' && (
                                              <>
                                                {IndividualBU && (
                                                  <>
                                                    {disablecustomerdatabynameQuickBooks && (
                                                      <>
                                                        <div className='col-12 m-0'></div>
                                                        <div className='col-xs-12 col-md-3 '>
                                                          <label className='form-label'>
                                                            QB Client Name*{' '}
                                                          </label>

                                                          <input
                                                            type='text'
                                                            className='form-control fs-6'
                                                            disabled={disableQBClient}
                                                            {...register1('qb_customer_name', {
                                                              required: true,
                                                              onChange: (e) => {
                                                                qbCustName(
                                                                  e.target.value,
                                                                  getValues1('qb_customer_id'),
                                                                  getValues('client_name'),
                                                                  'Not'
                                                                )
                                                              },
                                                              onBlur: (e) => {
                                                                qbCustName(
                                                                  getValues1('qb_customer_name'),
                                                                  getValues1('qb_customer_id'),
                                                                  getValues('client_name'),
                                                                  'Blur'
                                                                )
                                                              },
                                                            })}
                                                          />
                                                          {errors1.qb_customer_name &&
                                                            errors1.qb_customer_name.type ===
                                                              'required' && (
                                                              <p className='error text-danger mb-0'>
                                                                QB Customer Name is required.
                                                              </p>
                                                            )}
                                                        </div>

                                                        {clientopen && (
                                                          <div className='col-xs-12 col-md-3 '>
                                                            <label className='form-label'>
                                                              QB Client ID*{' '}
                                                            </label>
                                                            <input
                                                              type='text'
                                                              className='form-control fs-6'
                                                              //  value={clientlist}
                                                              disabled={true}
                                                              {...register1('qb_customer_id')}
                                                            />
                                                          </div>
                                                        )}
                                                        {getValues1('qb_customer_id') ? (
                                                          <></>
                                                        ) : (
                                                          <div className='col-xs-12 col-md-3 '>
                                                            <button
                                                              className='btn btn-secondary form-group mt-8'
                                                              type='button'
                                                              onClick={() =>
                                                                handleCustomerName(clientname)
                                                              }
                                                              aria-disabled='true'
                                                            >
                                                              {!loading && (
                                                                <span className='indicator-label'>
                                                                  {' '}
                                                                  Get QB Client ID
                                                                </span>
                                                              )}
                                                              {loading && (
                                                                <span
                                                                  className='indicator-progress'
                                                                  style={{display: 'block'}}
                                                                >
                                                                  Get QB Client ID
                                                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                </span>
                                                              )}
                                                              {/* Get QB Customer ID */}
                                                            </button>
                                                          </div>
                                                        )}
                                                        {localStorage.getItem('refresh_Token') ==
                                                          'false' && (
                                                          <>
                                                            {/* {(localStorage.getItem('role_Name') === 'superadmin' ||
                                            localStorage.getItem('role_Name') === 'admin' ||
                                            localStorage.getItem('role_Name') === 'accounts') && ( */}

                                                            {(numArray?.includes('superadmin') ||
                                                              numArray?.includes('admin') ||
                                                              numArray?.includes('accounts')) && (
                                                              <>
                                                                {getValues1('qb_customer_id') ? (
                                                                  <div className='col-xs-12 col-md-3 '>
                                                                    <button
                                                                      className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                                      type='button'
                                                                      onClick={() =>
                                                                        EditQBClientPop('QB')
                                                                      }
                                                                      aria-disabled='true'
                                                                    >
                                                                      Edit QB Client ID
                                                                    </button>
                                                                  </div>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                            {IndividualBU && (
                                              <>
                                                {disablecustomerdatabynameQuickBooks && (
                                                  <>
                                                    <div className='col-12 m-0'></div>
                                                    <div className='col-xs-12 col-md-3 '>
                                                      <label className='form-label'>
                                                        QB Project Name*{' '}
                                                        <Tooltip
                                                          text={
                                                            'Please enter the QB Project name in the format of customer name:project name as given in QB'
                                                          }
                                                        >
                                                          <span>
                                                            <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                                          </span>
                                                        </Tooltip>
                                                      </label>
                                                      <input
                                                        type='text'
                                                        className='form-control fs-6'
                                                        disabled={disableQBProject}
                                                        {...register1('qb_project_name', {
                                                          required: true,
                                                          // onChange: (e) => {
                                                          //   qbCustName(
                                                          //     e.target.value,
                                                          //     getValues('qb_customer_id'),
                                                          //     getValues('client_name'),
                                                          //     'Not'
                                                          //   )
                                                          // },
                                                          // onBlur: (e) => {
                                                          //   qbCustName(
                                                          //     getValues('qb_project_name'),
                                                          //     getValues('qb_customer_id'),
                                                          //     getValues('client_name'),
                                                          //     'Blur'
                                                          //   )
                                                          // },
                                                        })}
                                                      />
                                                      {errors1.qb_project_name &&
                                                        errors1.qb_project_name.type ===
                                                          'required' && (
                                                          <p className='error text-danger mb-0'>
                                                            QB Project Name is required.
                                                          </p>
                                                        )}
                                                    </div>

                                                    {projectopen && (
                                                      <div className='col-xs-12 col-md-3 '>
                                                        <label className='form-label'>
                                                          QB Project ID*{' '}
                                                        </label>
                                                        <input
                                                          type='text'
                                                          className='form-control fs-6'
                                                          //  value={clientlist}
                                                          disabled={true}
                                                          {...register1('qb_project_id')}
                                                        />
                                                      </div>
                                                    )}
                                                    {getValues1('qb_project_id') ? (
                                                      <></>
                                                    ) : (
                                                      <div className='col-xs-12 col-md-3 '>
                                                        <button
                                                          className='btn btn-secondary form-group mt-8'
                                                          type='button'
                                                          onClick={() => handleProjectName()}
                                                          aria-disabled='true'
                                                        >
                                                          {/* {!loading && (
                              <span className='indicator-label'> Get QB Project ID</span>
                            )}
                            {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Get QB Project ID
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                             )}  */}
                                                          Get QB Project ID
                                                        </button>
                                                      </div>
                                                    )}

                                                    {localStorage.getItem('refresh_Token') ==
                                                      'false' && (
                                                      <>
                                                        {/* {(localStorage.getItem('role_Name') === 'superadmin' ||
                                        localStorage.getItem('role_Name') === 'admin' ||
                                        localStorage.getItem('role_Name') === 'accounts') && ( */}
                                                        {(numArray?.includes('superadmin') ||
                                                          numArray?.includes('admin') ||
                                                          numArray?.includes('accounts')) && (
                                                          <>
                                                            {getValues1('qb_project_id') ? (
                                                              <div className='col-xs-12 col-md-3 '>
                                                                <button
                                                                  className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                                  type='button'
                                                                  onClick={() =>
                                                                    EditQBProjectPop('QB')
                                                                  }
                                                                  aria-disabled='true'
                                                                >
                                                                  Edit QB Project ID
                                                                </button>
                                                              </div>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}

                                            {proj_type == '1' && (
                                              <>
                                                {IndividualBU && (
                                                  <>
                                                    {disableitemdatabynameQuickBooks && (
                                                      <>
                                                        <div className='col-12 m-0'></div>
                                                        <div className='col-xs-12 col-md-3 '>
                                                          <label className='form-label'>
                                                            QB Product Name{' '}
                                                            <Tooltip
                                                              text={
                                                                'Please enter the Service name provided in QB for the consultant'
                                                              }
                                                            >
                                                              <span>
                                                                <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                                              </span>
                                                            </Tooltip>
                                                          </label>
                                                          <input
                                                            type='text'
                                                            className='form-control fs-6'
                                                            disabled={disableQBProduct}
                                                            {...register1('qb_product_name', {
                                                              onChange: (e) => {
                                                                setProductName(e.target.value)
                                                              },
                                                            })}
                                                          />
                                                        </div>
                                                        {productopen && (
                                                          <div className='col-xs-12 col-md-3 '>
                                                            <label className='form-label'>
                                                              QB Product ID{' '}
                                                            </label>
                                                            <input
                                                              type='text'
                                                              className='form-control fs-6'
                                                              disabled={true}
                                                              {...register1('qb_product_id')}
                                                            />
                                                          </div>
                                                        )}
                                                        {!getValues1('qb_product_id') && (
                                                          <div className='col-xs-12 col-md-3 '>
                                                            <button
                                                              className='btn btn-secondary form-group mt-8'
                                                              type='button'
                                                              onClick={handleClick}
                                                              aria-disabled='true'
                                                            >
                                                              Get QB Product ID
                                                            </button>
                                                          </div>
                                                        )}
                                                        {localStorage.getItem('refresh_Token') ==
                                                          'false' && (
                                                          <>
                                                            {/* {(localStorage.getItem('role_Name') === 'superadmin' ||
                                            localStorage.getItem('role_Name') === 'admin' ||
                                            localStorage.getItem('role_Name') === 'accounts') && ( */}
                                                            {(numArray?.includes('superadmin') ||
                                                              numArray?.includes('admin') ||
                                                              numArray?.includes('accounts')) && (
                                                              <>
                                                                {getValues1('qb_product_id') && (
                                                                  <div className='col-xs-12 col-md-3 '>
                                                                    <button
                                                                      className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                                      type='button'
                                                                      onClick={() =>
                                                                        EditQBProductPop('QB')
                                                                      }
                                                                      aria-disabled='true'
                                                                    >
                                                                      Edit QB Product ID
                                                                    </button>
                                                                  </div>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                        {/* <Tooltip text={'Add OT Product'}>
                                                  <button
                                                    className='btn btn-icon  btn-active-color-primary btn-sm my-14'
                                                    type='button'
                                                    onClick={otProductHoursPopupAddClick}
                                                    disabled={OTProduct ? true : false}
                                                  >
                                                    <i className='bi bi-plus-circle fs-2 '></i>
                                                  </button>
                                                </Tooltip> */}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}

                                            {!OTProduct && (
                                              <>
                                                {proj_type == '1' && (
                                                  <>
                                                    {IndividualBU && (
                                                      <>
                                                        {disableitemdatabynameQuickBooks && (
                                                          <>
                                                            <div className='col-12 m-0'>
                                                              <div className='mt-2'>
                                                                <a
                                                                  onClick={
                                                                    otProductHoursPopupAddClick
                                                                  }
                                                                  // className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                                  className='fs-7 text-primary fw-bold fw-regular mt-1 p-1 text-right'
                                                                  role='button'
                                                                >
                                                                  Do you want to create another
                                                                  product for OT hours?
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}

                                            {OTProduct && (
                                              <>
                                                {proj_type == '1' && (
                                                  <>
                                                    {IndividualBU && (
                                                      <>
                                                        {disableitemdatabynameQuickBooks && (
                                                          <>
                                                            <div className='col-12 m-0'></div>
                                                            <div className='col-xs-12 col-md-3 '>
                                                              <label className='form-label'>
                                                                QB Product Name - OT *{' '}
                                                                {/* <Tooltip
                                                          text={
                                                            'Please enter the Service name provided in QB for the consultant'
                                                          }
                                                        >
                                                          <span>
                                                            <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                                          </span>
                                                        </Tooltip> */}
                                                              </label>
                                                              <input
                                                                type='text'
                                                                className='form-control fs-6'
                                                                disabled={DisableOTQBProduct}
                                                                {...register1(
                                                                  'ot_qb_product_name',
                                                                  {
                                                                    onChange: (e) => {
                                                                      setOTProductName(
                                                                        e.target.value
                                                                      )
                                                                    },
                                                                    required: OTProduct
                                                                      ? true
                                                                      : false,
                                                                  }
                                                                )}
                                                              />
                                                              {OTProduct && (
                                                                <>
                                                                  {errors1.ot_qb_product_name &&
                                                                    errors1.ot_qb_product_name
                                                                      .type === 'required' && (
                                                                      <p className='error text-danger mb-0'>
                                                                        OT Product Name is Required
                                                                      </p>
                                                                    )}
                                                                </>
                                                              )}
                                                            </div>
                                                            {OTProductOpen &&
                                                              getValues1('ot_qb_product_id') && (
                                                                <div className='col-xs-12 col-md-3 '>
                                                                  <label className='form-label'>
                                                                    QB Product ID - OT{' '}
                                                                  </label>
                                                                  <input
                                                                    type='text'
                                                                    className='form-control fs-6'
                                                                    disabled={true}
                                                                    {...register1(
                                                                      'ot_qb_product_id'
                                                                    )}
                                                                  />
                                                                </div>
                                                              )}
                                                            {!getValues1('ot_qb_product_id') && (
                                                              <div className='col-xs-12 col-md-auto '>
                                                                <button
                                                                  className='btn btn-secondary form-group mt-8'
                                                                  type='button'
                                                                  onClick={handleProductClick}
                                                                  aria-disabled='true'
                                                                >
                                                                  Get QB Product ID - OT
                                                                </button>
                                                              </div>
                                                            )}
                                                            {localStorage.getItem(
                                                              'refresh_Token'
                                                            ) == 'false' && (
                                                              <>
                                                                {/* {(localStorage.getItem('role_Name') === 'superadmin' ||
                                            localStorage.getItem('role_Name') === 'admin' ||
                                            localStorage.getItem('role_Name') === 'accounts') && ( */}
                                                                {(numArray?.includes(
                                                                  'superadmin'
                                                                ) ||
                                                                  numArray?.includes('admin') ||
                                                                  numArray?.includes(
                                                                    'accounts'
                                                                  )) && (
                                                                  <>
                                                                    {getValues1(
                                                                      'ot_qb_product_id'
                                                                    ) && (
                                                                      <div className='col-xs-12 col-md-auto '>
                                                                        <button
                                                                          className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                                          type='button'
                                                                          onClick={() =>
                                                                            EditOTQBProductPop('QB')
                                                                          }
                                                                          aria-disabled='true'
                                                                        >
                                                                          Edit QB Product ID - OT
                                                                        </button>
                                                                      </div>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                            <div className='col-xs-12 col-md-auto '>
                                                              <Tooltip text={'Cancel OT Product'}>
                                                                <button
                                                                  className='btn btn-icon btn-active-color-primary btn-icon-primary mt-8  '
                                                                  type='button'
                                                                  onClick={
                                                                    otProductHoursPopupCancelClick
                                                                  }
                                                                  // disabled={OTProduct ? true : false}
                                                                >
                                                                  <i className='bi bi-x-circle fs-3 '></i>
                                                                </button>
                                                              </Tooltip>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className='d-flex justify-content-end  py-1 '>
                                        <div>
                                          <div className='d-flex gap-3 '>
                                            {displayqb && (
                                              <div>
                                                <button
                                                  type='submit'
                                                  className='btn btn-primary'
                                                  // disabled={message == 'Records Found' ? true : false}
                                                  disabled={
                                                    UserProjectBUDisabledButton ? true : false
                                                  }
                                                >
                                                  Update
                                                </button>
                                              </div>
                                            )}
                                            <div>
                                              {' '}
                                              <button
                                                type='button'
                                                onClick={() => navigate(-1)}
                                                className='btn btn-danger'
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Tab>
                  {(numArray?.includes('admin') ||
                    localStorage.getItem('invoice_access') == 'true') &&
                    disableQuickBooks && //Main Switch
                    displayqb && //Edit
                    IndividualBU && (
                      <Tab
                        eventKey='invoice'
                        title='Invoice Details'
                        disabled={StatusDisabledField}
                      >
                        <>
                          <div className='card card-custom card-flush mb-6'>
                            <div className='card-body py-5'>
                              <div className='fs-6 text-gray-500 text-transform-unset'></div>
                              <div className='row gy-4 '>
                                <div className='col-xs-12 col-md-3 col-lg-3'>
                                  <label className='form-label fw-semibold'>
                                    Date Range * <span className='fs-9'>(DD-MMM-YYYY)</span>
                                    <Tooltip text={'Select date range for generating invoice'}>
                                      <span>
                                        <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                      </span>
                                    </Tooltip>
                                  </label>
                                  <DatePicker
                                    // showYearDropdown
                                    placeholderText='Select Date Range'
                                    selectsRange={true}
                                    startDate={startDateInvoice}
                                    endDate={endDateInvoice}
                                    dateFormat='dd-MMM-yyyy'
                                    calendarStartDay={1}
                                    // onChange={(update: any) => {
                                    //   // setDateRange(update)

                                    // }}
                                    onChange={(update: any) => {
                                      singleInvoiceDate(update)
                                    }}
                                    isClearable={true}
                                    maxDate={maxInvoiceDate}
                                    monthsShown={2}
                                    className={clsx('form-control')}
                                  />
                                  {startDateInvoice == null || endDateInvoice == null
                                    ? InvoiceValidation && (
                                        <span className='error text-danger mb-0'>
                                          Date Range is required
                                        </span>
                                      )
                                    : ''}
                                </div>
                                {/* {!InvoiceValidation && CreateInvoice && CreateInvoice.length == 0 && ( */}
                                {(DateRun || (CreateInvoice && CreateInvoice.length == 0)) && (
                                  <div className='col-auto pt-md-0 pt-lg-8'>
                                    <button
                                      className='btn btn-sm btn-primary'
                                      // onClick={() => handleRun(page, perPage)}
                                      onClick={() => handleRun()}
                                    >
                                      Run
                                    </button>
                                  </div>
                                )}
                                {CreateInvoice && CreateInvoice.length > 0 && !DateRun && (
                                  <div className='col-xs-12 col-md-2'>
                                    <label className='form-label fw-semibold'>
                                      {' '}
                                      Invoice Date * <span className='fs-9'>(DD-MMM-YYYY)</span>
                                    </label>
                                    <DatePickerIcon
                                      className='form-control fs-6'
                                      selected={InvoiceDate}
                                      // dateFormat='MM/dd/yyyy'
                                      dateFormat='dd-MMM-yyyy'
                                      handleDate={(date: any) => {
                                        handleInvoiceDate(date)
                                      }}
                                      min={endDateInvoice ? endDateInvoice : ''}
                                      placeholderText='Select invoice date'
                                    />
                                    {InvoiceDate == null && InvoiceDateValidation && (
                                      <span className='error text-danger mb-0'>
                                        Invoice Date is required
                                      </span>
                                    )}
                                  </div>
                                )}
                                <div className='col-auto pt-md-0 pt-lg-8 '>
                                  <button
                                    className='btn btn-sm btn-primary'
                                    // onClick={() => handleClick(page, perPage)}
                                    onClick={() => handleInvoice('')}
                                    disabled={
                                      !InvoiceValidation &&
                                      CreateInvoice &&
                                      CreateInvoice.length > 0 &&
                                      !DateRun
                                        ? false
                                        : true
                                    }
                                  >
                                    Create Invoice
                                  </button>
                                </div>
                                {/* <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      // onClick={resetData}
                    >
                      Reset
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      // onClick={exporthandleClick}
                    >
                      Export
                    </button>
                  </div> */}
                              </div>
                            </div>
                          </div>
                          <div className='fs-6 text-gray-500 text-transform-unset'>
                            Note: Invoice can be generated only for timesheets submitted through our
                            timesheets portal.
                          </div>
                          <div className='pt-3'>
                            <div className='card card-custom card-flush '>
                              <div className='card-body p-3 py-3'>
                                <div className='text-gray-900 fs-5 fw-semibold text-transform-unset'>
                                  Count{' '}
                                  <Tooltip
                                    text={'Total no. of available timesheets to create invoice'}
                                  >
                                    <span>
                                      <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                    </span>
                                  </Tooltip>
                                  : {InvoiceTimesheets[0]?.count && InvoiceTimesheets[0]?.count}
                                </div>
                                <KTCardBody className='py-1 '>
                                  <div className='pt-3'>
                                    <div className='card card-custom card-flush '>
                                      <div className='card-body p-3 py-3'>
                                        <div className='table-responsive reports-table'>
                                          <table
                                            id='kt_table_users'
                                            className='table table-row-bordered table-row-gray-300 gy-4'
                                          >
                                            <>
                                              <thead>
                                                <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                                  <th className='  py-4'>Date </th>
                                                  <th className='  py-4'>Regular Hours </th>
                                                  <th className='  py-4'>OT Hours </th>
                                                  <th className='  py-4'>Bill Rate </th>
                                                  <th className='  py-4'>OT Bill Rate </th>
                                                  <th className='  py-4'>Amount</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {CreateInvoice?.length > 0 ? (
                                                  <>
                                                    {CreateInvoice?.map((each: any, index: any) => {
                                                      let hr_min =
                                                        each.regular_hours +
                                                        each.regular_minutes / 60
                                                      let hr_min1 =
                                                        each.ot_hours + each.ot_minutes / 60
                                                      return (
                                                        <tr
                                                          className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                                          key={index}
                                                        >
                                                          <td className=''>
                                                            {each.timesheet_date
                                                              ? moment(each.timesheet_date).format(
                                                                  'DD-MMM-YYYY'
                                                                )
                                                              : '-'}
                                                          </td>
                                                          <td className=''>
                                                            {hr_min ? hr_min.toFixed(2) : '-'}
                                                          </td>
                                                          <td className=''>
                                                            {hr_min1 ? hr_min1.toFixed(2) : '-'}
                                                          </td>
                                                          <td className=''>
                                                            {each.bill_rate
                                                              ? '$' +
                                                                Number(
                                                                  each.bill_rate
                                                                ).toLocaleString(undefined, {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                })
                                                              : '-'}
                                                          </td>
                                                          <td className=''>
                                                            {each.ot_bill_rate
                                                              ? '$' +
                                                                Number(
                                                                  each.ot_bill_rate
                                                                ).toLocaleString(undefined, {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                })
                                                              : '-'}
                                                          </td>
                                                          <td className=''>
                                                            {each.amount
                                                              ? '$' +
                                                                Number(each.amount).toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )
                                                              : '-'}
                                                          </td>
                                                        </tr>
                                                      )
                                                    })}
                                                  </>
                                                ) : (
                                                  ''
                                                )}
                                              </tbody>
                                            </>
                                          </table>
                                          {(CreateInvoice?.length == null ||
                                            CreateInvoice?.length == 0) && (
                                            <div className='text-center  fw-bold text-gray-800'>
                                              No Records found{' '}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <UsersListPagination />
                                </KTCardBody>
                              </div>
                            </div>
                          </div>
                        </>
                      </Tab>
                    )}

                  {(numArray?.includes('admin') ||
                    localStorage.getItem('invoice_access') == 'true') &&
                    disableQuickBooks &&
                    displayqb &&
                    IndividualBU && (
                      <Tab
                        eventKey='history'
                        title='Invoice History'
                        disabled={StatusDisabledField}
                      >
                        <>
                          <div className='card card-custom card-flush '>
                            <div className='card-body p-2 py-3'>
                              <div className='row gy-3 align-items-center py-2'>
                                <div className='col-xs-12 col-md-3 col-lg-3'>
                                  <label className='form-label fw-semibold'>
                                    Date Range * <span className='fs-9'>(DD-MMM-YYYY)</span>
                                  </label>
                                  <MultiDatePicker
                                    value={DateValue1}
                                    format='MMM YYYY'
                                    // multiple
                                    numberOfMonths={2}
                                    range={true}
                                    sort
                                    highlightToday={false}
                                    rangeHover
                                    ref={calendarRef}
                                    // minDate={DateValue1 ? DateValue1[0] : []}
                                    minDate={minDateValue}
                                    maxDate={maxHistoryDate}
                                    // showOtherDays
                                    // showMonthYearPicker
                                    onlyMonthPicker
                                    hideMonth
                                    months={months}
                                    onChange={handleChange1}
                                    dateSeparator=' - '
                                    inputClass='form-control'
                                    // showWeekend
                                    // showMonthArrow
                                    // months={[
                                    //   {year: 2022, month: 1},
                                    //   {year: 2023, month: 2},
                                    // ]}
                                  />
                                  {DateValue1 == null || DateValue1?.length < 2
                                    ? HistoryValidation && (
                                        <span className='error text-danger mb-0'>
                                          Select from month and to month
                                        </span>
                                      )
                                    : ''}
                                </div>
                                <div className='col-auto pt-md-0 pt-lg-8'>
                                  <button
                                    className='btn btn-sm btn-primary'
                                    onClick={() => handleHistoryRun(page, perPage)}
                                    // onClick={() => handleHistoryRun()}
                                  >
                                    Run
                                  </button>
                                </div>
                                <div className='col-auto pt-md-0 pt-lg-8'>
                                  <button
                                    // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                                    className='btn btn-light-primary   btn-sm'
                                    onClick={exporthandleClick}
                                  >
                                    Export
                                    {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <KTCardBody className='py-1 '>
                            <div className='pt-3'>
                              <div className='card card-custom card-flush '>
                                <div className='card-body p-3 py-3'>
                                  <div className='table-responsive reports-table'>
                                    <table
                                      id='kt_table_users'
                                      className='table table-row-bordered table-row-gray-300 gy-4'
                                    >
                                      <>
                                        <thead>
                                          <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                            <th className='  py-4'>Week </th>
                                            <th className='  py-4'>Raised on </th>
                                            <th className='  py-4'>Invoice Date Range </th>
                                            <th className='  py-4'>#Invoice</th>
                                            <th className='  py-4'>Regular Hours </th>
                                            <th className='  py-4'>OT Hours </th>
                                            <th className='  py-4'>Bill Rate </th>
                                            <th className='  py-4'>OT Bill Rate </th>
                                            <th className='  py-4'>Amount</th>
                                            <th className='  py-4'>Actions</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {HistoryInvoice?.length > 0 ? (
                                            <>
                                              {HistoryInvoice?.map((each: any, index: any) => {
                                                let hr_min =
                                                  each.regular_hours + each.regular_minutes / 60
                                                let hr_min1 = each.ot_hours + each.ot_minutes / 60

                                                return (
                                                  <tr
                                                    className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                                    key={index}
                                                  >
                                                    <td className=''>
                                                      {each.start_date
                                                        ? moment(each.start_date).format('MMMM DD')
                                                        : '-'}{' '}
                                                      -{' '}
                                                      {each.end_date
                                                        ? moment(each.end_date).format('MMMM DD')
                                                        : '-'}
                                                    </td>
                                                    <td className=''>
                                                      {each.invoice_date
                                                        ? moment(each.invoice_date).format(
                                                            'DD-MMM-YYYY'
                                                          )
                                                        : '-'}
                                                    </td>
                                                    <td className=''>
                                                      {each.invoice_start_date
                                                        ? moment(each.invoice_start_date).format(
                                                            'DD-MMM-YYYY'
                                                          )
                                                        : '-'}{' '}
                                                      -{' '}
                                                      {each.invoice_end_date
                                                        ? moment(each.invoice_end_date).format(
                                                            'DD-MMM-YYYY'
                                                          )
                                                        : '-'}
                                                    </td>
                                                    <td className=''>
                                                      {each.invoice_id ? each.invoice_id : '-'}
                                                    </td>
                                                    {/* <td className=''>{hr_min ? hr_min.toFixed(2) : ''}</td>
                                          <td className=''>{hr_min1 ? hr_min1.toFixed(2) : ''}</td> */}
                                                    <td className=''>
                                                      {each.total_regular_hours
                                                        ? each.total_regular_hours.toFixed(2)
                                                        : '-'}
                                                    </td>
                                                    <td className=''>
                                                      {each.total_ot_hours
                                                        ? each.total_ot_hours.toFixed(2)
                                                        : '-'}
                                                    </td>
                                                    <td className=''>
                                                      {each.bill_rate
                                                        ? '$' +
                                                          Number(each.bill_rate).toLocaleString(
                                                            undefined,
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )
                                                        : '-'}
                                                      {each.total_bill_rate &&
                                                      each.total_bill_rate == true ? (
                                                        <Tooltip
                                                          text={'Please check bill rate details'}
                                                        >
                                                          <span>
                                                            <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                                          </span>
                                                        </Tooltip>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </td>
                                                    <td className=''>
                                                      {each.ot_bill_rate
                                                        ? '$' +
                                                          Number(each.ot_bill_rate).toLocaleString(
                                                            undefined,
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )
                                                        : '-'}

                                                      {each.total_ot_bill_rate &&
                                                      each.total_ot_bill_rate == true ? (
                                                        <Tooltip
                                                          text={'Please check OT bill rate details'}
                                                        >
                                                          <span>
                                                            <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                                          </span>
                                                        </Tooltip>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </td>
                                                    <td className=''>
                                                      {each.amount1
                                                        ? '$' +
                                                          Number(each.amount1).toLocaleString(
                                                            undefined,
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )
                                                        : '-'}
                                                    </td>

                                                    <td>
                                                      <div className='d-flex nowrap'>
                                                        <Tooltip text={'View'}>
                                                          <a
                                                            onClick={() =>
                                                              handleView(
                                                                each.qb_invoice_id,
                                                                each.business_unit_id,
                                                                each.start_date,
                                                                each.end_date
                                                              )
                                                            }
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                          >
                                                            <i
                                                              className='fa fa-eye'
                                                              aria-hidden='true'
                                                            ></i>
                                                          </a>
                                                        </Tooltip>
                                                        <Tooltip text={'Download PDF'}>
                                                          <a
                                                            onClick={() =>
                                                              handleDownload(
                                                                each.qb_invoice_id,
                                                                each.business_unit_id,
                                                                each.start_date,
                                                                each.end_date,
                                                                each.project_id
                                                              )
                                                            }
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                          >
                                                            <i className='bi bi-file-earmark-arrow-down fs-2'></i>
                                                          </a>
                                                        </Tooltip>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              })}
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </tbody>
                                      </>
                                    </table>
                                    {(HistoryInvoice?.length == null ||
                                      HistoryInvoice?.length == 0) && (
                                      <div className='text-center  fw-bold text-gray-800'>
                                        No Records found{' '}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <UsersListPagination />
                          </KTCardBody>

                          <>
                            <div className='d-flex flex-stack py-2'>
                              {HistoryInvoice?.length > 0 ? (
                                <div>
                                  <Container className='p-0'>
                                    <Row className='align-items-center'>
                                      <Col xs='auto' className='pe-0'>
                                        {' '}
                                        <label className='form-label fw-semibold'>
                                          Rows Per Page
                                        </label>
                                      </Col>

                                      <Col>
                                        <select
                                          name='perPage'
                                          className='form-select'
                                          onChange={onChange}
                                          value={perPage}
                                        >
                                          <option value='25'>25</option>
                                          <option value='50'>50</option>
                                          <option value='75'>75</option>
                                          <option value={AllCreateInvoiceCount}>All</option>
                                        </select>
                                      </Col>
                                    </Row>
                                  </Container>
                                </div>
                              ) : null}

                              <div>
                                {HistoryInvoice?.length > 0 ? (
                                  <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageCount={pages}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination justify-content-right'}
                                    activeClassName={'active'}
                                    breakLabel={'...'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </>
                        </>
                      </Tab>
                    )}
                </Tabs>
              </>
            </Tab>
          )}

        {(numArray?.includes('admin') || numArray?.includes('accounts')) &&
          disableNetSuite &&
          displayqb && (
            <Tab eventKey='NetSuite' title='NetSuite Details'>
              <>
                <Tabs
                  id='controlled-tab-example'
                  activeKey={keyNS}
                  onSelect={(k) => handleTabsNetSuite(k)}
                  className='mb-3'
                >
                  <Tab eventKey='Configuration' title='Configuration' id='update-qb'>
                    {(numArray?.includes('superadmin') ||
                      numArray?.includes('admin') ||
                      numArray?.includes('accounts')) && (
                      <>
                        {disableNetSuite && (
                          <>
                            {displayqb && (
                              <>
                                {/* {proj_type == '1' && (
                                <> */}
                                <form onSubmit={handleSubmit2(onSubmit2)} id='update-netsuite'>
                                  <div className='card card-custom card-flush mb-6'>
                                    <div className='card-body py-5'>
                                      <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>
                                        Consultant Details
                                      </h3>
                                      <div className='row gy-4 '>
                                        <div className='col-12 m-0'></div>
                                        <div className='col-md-2 col-xs-12'>
                                          <label className='form-label '>
                                            NetSuite First Name *{' '}
                                          </label>
                                          <input
                                            // disabled={true}
                                            disabled={disableNetSuiteConsultant}
                                            type='text'
                                            {...register2('netSuite_first_name')}
                                            // className={`form-control ${
                                            //   errors2.netSuite_first_name ? 'is-invalid' : ''
                                            // }`}
                                            className='form-control fs-6'
                                          />
                                          {/* {errors2.netSuite_first_name &&
                                            errors2.netSuite_first_name.type === 'required' && (
                                              <p className='error text-danger mb-0'>
                                                NetSuite First Name is required.
                                              </p>
                                            )} */}
                                          {/* <div className='invalid-feedback'>
                                          {errors2.netSuite_first_name?.message}
                                        </div> */}
                                        </div>
                                        <div className='col-md-2 col-xs-12'>
                                          <label className='form-label '>
                                            NetSuite Middle Name{' '}
                                          </label>
                                          <input
                                            // disabled={true}
                                            disabled={disableNetSuiteConsultant}
                                            type='text'
                                            {...register2('netSuite_middle_name')}
                                            className={`form-control ${
                                              errors2.netSuite_middle_name ? 'is-invalid' : ''
                                            }`}
                                          />
                                          <div className='invalid-feedback'>
                                            {errors2.netSuite_middle_name?.message}
                                          </div>
                                        </div>
                                        <div className='col-md-2 col-xs-12'>
                                          <label className='form-label '>
                                            NetSuite Last Name *{' '}
                                          </label>
                                          <input
                                            // disabled={true}
                                            disabled={disableNetSuiteConsultant}
                                            type='text'
                                            {...register2('netSuite_last_name')}
                                            // className={`form-control ${
                                            //   errors2.netSuite_last_name ? 'is-invalid' : ''
                                            // }`}
                                            className='form-control fs-6'
                                          />
                                          {/* {errors2.netSuite_last_name &&
                                            errors2.netSuite_last_name.type === 'required' && (
                                              <p className='error text-danger mb-0'>
                                                NetSuite Last Name is required.
                                              </p>
                                            )} */}
                                          {/* <div className='invalid-feedback'>
                                          {errors2.netSuite_last_name?.message}
                                        </div> */}
                                        </div>

                                        {netconsultantopen && (
                                          <div className='col-xs-12 col-md-2 '>
                                            <label className='form-label '>
                                              NetSuite Consultant ID*{' '}
                                            </label>
                                            <input
                                              type='text'
                                              className='form-control fs-6'
                                              disabled={true}
                                              {...register2('netSuite_employee_id')}
                                            />
                                          </div>
                                        )}
                                        {getValues2('netSuite_employee_id') ? (
                                          <></>
                                        ) : (
                                          <div className='col-xs-12 col-md-2 '>
                                            <button
                                              // className='btn btn-light-primary form-group  mt-8'
                                              className='btn btn-secondary form-group mt-8'
                                              type='button'
                                              disabled={!disableNetSuite}
                                              // onClick={() =>
                                              //   handleConsultantName1(clientname)
                                              // }
                                              onClick={() => handleGetConsultantName1(clientname)}
                                              aria-disabled='true'
                                            >
                                              Get NetSuite Consultant ID
                                            </button>
                                          </div>
                                        )}
                                        {(numArray?.includes('superadmin') ||
                                          numArray?.includes('admin') ||
                                          numArray?.includes('accounts')) && (
                                          <>
                                            {getValues2('netSuite_employee_id') ? (
                                              <div className='col-xs-12 col-md-2 '>
                                                <button
                                                  className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                  type='button'
                                                  onClick={() => EditQBConsultantPop('NetSuite')}
                                                  aria-disabled='true'
                                                >
                                                  Edit NetSuite Consultant ID
                                                </button>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className='card card-custom card-flush mb-6'>
                                    <div className='card-body py-5'>
                                      <div className='row gy-4 '>
                                        {proj_type == '1' && (
                                          <>
                                            <div className='col-12 m-0'></div>
                                            <div className='col-xs-12 col-md-3 '>
                                              <label className='form-label'>
                                                NetSuite Client Name*{' '}
                                              </label>

                                              <input
                                                type='text'
                                                className='form-control fs-6'
                                                disabled={disableNetSuiteClient}
                                                {...register2('netSuite_customer_name', {
                                                  required: true,
                                                  // onChange: (e) => {
                                                  //   qbCustName(
                                                  //     e.target.value,
                                                  //     getValues('qb_customer_id'),
                                                  //     getValues('client_name'),
                                                  //     'Not'
                                                  //   )
                                                  // },
                                                })}
                                              />
                                              {errors2.netSuite_customer_name &&
                                                errors2.netSuite_customer_name.type ===
                                                  'required' && (
                                                  <p className='error text-danger mb-0'>
                                                    NetSuite Customer Name is required.
                                                  </p>
                                                )}
                                            </div>

                                            {netopen && (
                                              <div className='col-xs-12 col-md-3 '>
                                                <label className='form-label'>
                                                  NetSuite Client ID*{' '}
                                                </label>
                                                <input
                                                  type='text'
                                                  className='form-control fs-6'
                                                  //  value={clientlist}
                                                  disabled={true}
                                                  {...register2('netSuite_customer_id')}
                                                />
                                              </div>
                                            )}
                                            {getValues2('netSuite_customer_id') ? (
                                              <></>
                                            ) : (
                                              <div className='col-xs-12 col-md-3 '>
                                                <button
                                                  className='btn btn-secondary form-group mt-8'
                                                  type='button'
                                                  // onClick={handleCustomerName1}
                                                  onClick={handleGetCustomerName1}
                                                  aria-disabled='true'
                                                >
                                                  {!loading && (
                                                    <span className='indicator-label'>
                                                      {' '}
                                                      Get NetSuite Client ID
                                                    </span>
                                                  )}
                                                  {loading && (
                                                    <span
                                                      className='indicator-progress'
                                                      style={{display: 'block'}}
                                                    >
                                                      Get NetSuite Client ID
                                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                  )}
                                                  {/* Get QB Customer ID */}
                                                </button>
                                              </div>
                                            )}

                                            {(numArray?.includes('superadmin') ||
                                              numArray?.includes('admin') ||
                                              numArray?.includes('accounts')) && (
                                              <>
                                                {getValues2('netSuite_customer_id') ? (
                                                  <div className='col-xs-12 col-md-3 '>
                                                    <button
                                                      className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                      type='button'
                                                      onClick={() => EditQBClientPop('NetSuite')}
                                                      aria-disabled='true'
                                                    >
                                                      Edit NetSuite Client ID
                                                    </button>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}

                                        {/* {getValues2('netSuite_customer_id') && (
                                        <> */}
                                        {proj_type == '1' && (
                                          <>
                                            {(consultantoption == 'C2C' ||
                                              consultantoption == 'Offshore' ||
                                              consultantoption == '1099') && (
                                              <>
                                                <div className='col-12 m-0'></div>
                                                <div className='col-xs-12 col-md-3 '>
                                                  <label className='form-label'>
                                                    NetSuite Vendor Name*{' '}
                                                  </label>

                                                  <input
                                                    type='text'
                                                    className='form-control fs-6'
                                                    disabled={disableNetSuiteVendor}
                                                    {...register2('netSuite_vendor_name', {
                                                      required: true,
                                                    })}
                                                  />
                                                  {errors2.netSuite_vendor_name &&
                                                    errors2.netSuite_vendor_name.type ===
                                                      'required' && (
                                                      <p className='error text-danger mb-0'>
                                                        NetSuite Vendor Name is required.
                                                      </p>
                                                    )}
                                                </div>

                                                {netVendoropen && (
                                                  <div className='col-xs-12 col-md-3 '>
                                                    <label className='form-label'>
                                                      NetSuite Vendor ID*{' '}
                                                    </label>
                                                    <input
                                                      type='text'
                                                      className='form-control fs-6'
                                                      //  value={clientlist}
                                                      disabled={true}
                                                      {...register2('netSuite_vendor_id')}
                                                    />
                                                  </div>
                                                )}
                                                {getValues2('netSuite_vendor_id') ? (
                                                  <></>
                                                ) : (
                                                  <div className='col-xs-12 col-md-3 '>
                                                    <button
                                                      className='btn btn-secondary form-group mt-8'
                                                      type='button'
                                                      // onClick={handleCustomerName1}
                                                      onClick={handleGetVendorName1}
                                                      aria-disabled='true'
                                                    >
                                                      {!loading && (
                                                        <span className='indicator-label'>
                                                          {' '}
                                                          Get NetSuite Vendor ID
                                                        </span>
                                                      )}
                                                      {loading && (
                                                        <span
                                                          className='indicator-progress'
                                                          style={{display: 'block'}}
                                                        >
                                                          Get NetSuite Vendor ID
                                                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                      )}
                                                      {/* Get QB Customer ID */}
                                                    </button>
                                                  </div>
                                                )}

                                                {(numArray?.includes('superadmin') ||
                                                  numArray?.includes('admin') ||
                                                  numArray?.includes('accounts')) && (
                                                  <>
                                                    {getValues2('netSuite_vendor_id') ? (
                                                      <div className='col-xs-12 col-md-3 '>
                                                        <button
                                                          className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                          type='button'
                                                          onClick={() =>
                                                            EditQBVendorPop('NetSuite')
                                                          }
                                                          aria-disabled='true'
                                                        >
                                                          Edit NetSuite Vendor ID
                                                        </button>
                                                      </div>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}

                                        <div className='col-12 m-0'></div>
                                        <div className='col-xs-12 col-md-3 '>
                                          <label className='form-label'>
                                            NetSuite Project Name*{' '}
                                          </label>

                                          <input
                                            type='text'
                                            className='form-control fs-6'
                                            disabled={disableNetProject}
                                            {...register2('netSuite_project_name', {
                                              required: true,
                                            })}
                                          />
                                          {errors2.netSuite_project_name &&
                                            errors2.netSuite_project_name.type === 'required' && (
                                              <p className='error text-danger mb-0'>
                                                NetSuite Project Name is required.
                                              </p>
                                            )}
                                        </div>

                                        {netprojectopen && (
                                          <div className='col-xs-12 col-md-3 '>
                                            <label className='form-label'>
                                              NetSuite Project ID*{' '}
                                            </label>
                                            <input
                                              type='text'
                                              className='form-control fs-6'
                                              //  value={clientlist}
                                              disabled={true}
                                              {...register2('netSuite_project_id')}
                                            />
                                          </div>
                                        )}
                                        {getValues2('netSuite_project_id') ? (
                                          <></>
                                        ) : (
                                          <div className='col-xs-12 col-md-3 '>
                                            <button
                                              className='btn btn-secondary form-group mt-8'
                                              type='button'
                                              // onClick={() => handleProjectName1()}
                                              onClick={() => handleGetProjectName1()}
                                              aria-disabled='true'
                                            >
                                              Get NetSuite Project ID
                                            </button>
                                          </div>
                                        )}

                                        {(numArray?.includes('superadmin') ||
                                          numArray?.includes('admin') ||
                                          numArray?.includes('accounts')) && (
                                          <>
                                            {getValues2('netSuite_project_id') ? (
                                              <div className='col-xs-12 col-md-3 '>
                                                <button
                                                  className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                  type='button'
                                                  onClick={() => EditQBProjectPop('NetSuite')}
                                                  aria-disabled='true'
                                                >
                                                  Edit NetSuite Project ID
                                                </button>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )}
                                        {/* </>
                                      )} */}
                                        {proj_type == '1' && (
                                          <>
                                            <div className='col-12 m-0'></div>
                                            <div className='col-xs-12 col-md-3 '>
                                              <label className='form-label'>
                                                NetSuite Product Name*{' '}
                                              </label>
                                              <input
                                                type='text'
                                                className='form-control fs-6'
                                                disabled={disableNetSuiteProduct}
                                                {...register2('netSuite_product_name', {
                                                  required: true,
                                                  // onChange: (e) => {
                                                  //   qbCustName(
                                                  //     e.target.value,
                                                  //     getValues('qb_customer_id'),
                                                  //     getValues('client_name'),
                                                  //     'Not'
                                                  //   )
                                                  // },
                                                })}
                                              />
                                              {errors2.netSuite_product_name &&
                                                errors2.netSuite_product_name.type ===
                                                  'required' && (
                                                  <p className='error text-danger mb-0'>
                                                    NetSuite Product Name is required.
                                                  </p>
                                                )}
                                            </div>

                                            {netproductopen && (
                                              <div className='col-xs-12 col-md-3 '>
                                                <label className='form-label'>
                                                  NetSuite Product ID*{' '}
                                                </label>
                                                <input
                                                  type='text'
                                                  className='form-control fs-6'
                                                  //  value={clientlist}
                                                  disabled={true}
                                                  {...register2('netSuite_product_id')}
                                                />
                                              </div>
                                            )}
                                            {getValues2('netSuite_product_id') ? (
                                              <></>
                                            ) : (
                                              <div className='col-xs-12 col-md-3 '>
                                                <button
                                                  className='btn btn-secondary form-group mt-8'
                                                  type='button'
                                                  // onClick={handleNetSuiteProduct}
                                                  onClick={() => handleGetProduct()}
                                                  aria-disabled='true'
                                                >
                                                  {!loading && (
                                                    <span className='indicator-label'>
                                                      {' '}
                                                      Get NetSuite Product ID
                                                    </span>
                                                  )}
                                                  {loading && (
                                                    <span
                                                      className='indicator-progress'
                                                      style={{display: 'block'}}
                                                    >
                                                      Get NetSuite Product ID
                                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                  )}
                                                </button>
                                              </div>
                                            )}

                                            {(numArray?.includes('superadmin') ||
                                              numArray?.includes('admin') ||
                                              numArray?.includes('accounts')) && (
                                              <>
                                                {getValues2('netSuite_product_id') ? (
                                                  <div className='col-xs-12 col-md-3 '>
                                                    <button
                                                      className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                      type='button'
                                                      // onClick={() => EditNetSuiteProductPop('NetSuite')}
                                                      onClick={() => EditQBProductPop('NetSuite')}
                                                      aria-disabled='true'
                                                    >
                                                      Edit NetSuite Product ID
                                                    </button>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}

                                        {!NetSuiteOTProduct && (
                                          <>
                                            {proj_type == '1' && (
                                              <>
                                                <div className='col-12 m-0'>
                                                  <div className='mt-2'>
                                                    <a
                                                      onClick={otProductHoursNetSuitePopupAddClick}
                                                      // className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                      className='fs-7 text-primary fw-bold fw-regular mt-1 p-1 text-right'
                                                      role='button'
                                                    >
                                                      Do you want to create another product for OT
                                                      hours?
                                                    </a>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        )}

                                        {NetSuiteOTProduct && (
                                          <>
                                            {proj_type == '1' && (
                                              <>
                                                <div className='col-12 m-0'></div>
                                                <div className='col-xs-12 col-md-3 '>
                                                  <label className='form-label'>
                                                    NetSuite Product Name - OT *{' '}
                                                  </label>
                                                  <input
                                                    type='text'
                                                    className='form-control fs-6'
                                                    disabled={DisableNetSuiteOTQBProduct}
                                                    {...register2('netSuite_ot_product_name', {
                                                      onChange: (e) => {
                                                        setNetSuiteOTProductName(e.target.value)
                                                      },
                                                      required: NetSuiteOTProduct ? true : false,
                                                    })}
                                                  />
                                                  {NetSuiteOTProduct && (
                                                    <>
                                                      {errors2.netSuite_ot_product_name &&
                                                        errors2.netSuite_ot_product_name.type ===
                                                          'required' && (
                                                          <p className='error text-danger mb-0'>
                                                            OT Product Name is Required
                                                          </p>
                                                        )}
                                                    </>
                                                  )}
                                                </div>
                                                {NetSuiteOTProductOpen &&
                                                  getValues2('netSuite_ot_product_id') && (
                                                    <div className='col-xs-12 col-md-3 '>
                                                      <label className='form-label'>
                                                        NetSuite Product ID - OT{' '}
                                                      </label>
                                                      <input
                                                        type='text'
                                                        className='form-control fs-6'
                                                        disabled={true}
                                                        {...register2('netSuite_ot_product_id')}
                                                      />
                                                    </div>
                                                  )}
                                                {!getValues2('netSuite_ot_product_id') && (
                                                  <div className='col-xs-12 col-md-auto '>
                                                    <button
                                                      className='btn btn-secondary form-group mt-8'
                                                      type='button'
                                                      onClick={handleNetSuiteOTProductClick}
                                                      aria-disabled='true'
                                                    >
                                                      Get NetSuite Product ID - OT
                                                    </button>
                                                  </div>
                                                )}
                                                {localStorage.getItem('refresh_Token') ==
                                                  'false' && (
                                                  <>
                                                    {(numArray?.includes('superadmin') ||
                                                      numArray?.includes('admin') ||
                                                      numArray?.includes('accounts')) && (
                                                      <>
                                                        {getValues2('netSuite_ot_product_id') && (
                                                          <div className='col-xs-12 col-md-auto '>
                                                            <button
                                                              className='btn btn-light-primary btn-sm cursor-pointer mt-8'
                                                              type='button'
                                                              onClick={() =>
                                                                EditOTQBProductPop('NetSuite')
                                                              }
                                                              aria-disabled='true'
                                                            >
                                                              Edit NetSuite Product ID - OT
                                                            </button>
                                                          </div>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                                <div className='col-xs-12 col-md-auto '>
                                                  <Tooltip text={'Cancel OT Product'}>
                                                    <button
                                                      className='btn btn-icon btn-active-color-primary btn-icon-primary mt-8  '
                                                      type='button'
                                                      onClick={
                                                        otProductHoursNetSuitePopupCancelClick
                                                      }
                                                      // disabled={NetSuiteOTProduct ? true : false}
                                                    >
                                                      <i className='bi bi-x-circle fs-3 '></i>
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className='d-flex justify-content-end  py-1 '>
                                    <div>
                                      <div className='d-flex gap-3 '>
                                        {displayqb && (
                                          <div>
                                            <button
                                              type='submit'
                                              className='btn btn-primary'
                                              // disabled={message == 'Records Found' ? true : false}
                                              disabled={UserProjectBUDisabledButton ? true : false}
                                            >
                                              Update
                                            </button>
                                          </div>
                                        )}
                                        <div>
                                          {' '}
                                          <button
                                            type='button'
                                            onClick={() => navigate(-1)}
                                            className='btn btn-danger'
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                                {/* </>
                              )} */}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Tab>
                  {numArray?.includes('admin') && disableNetSuite && displayqb && (
                    <Tab
                      eventKey='netsuite_invoice'
                      title='Invoice Details'
                      disabled={StatusDisabledField}
                    >
                      <>
                        <div className='card card-custom card-flush mb-6'>
                          <div className='card-body py-5'>
                            <div className='fs-6 text-gray-500 text-transform-unset'></div>
                            <div className='row gy-4 '>
                              <div className='col-xs-12 col-md-3 col-lg-3'>
                                <label className='form-label fw-semibold'>
                                  Date Range * <span className='fs-9'>(DD-MMM-YYYY)</span>
                                  <Tooltip text={'Select date range for generating invoice'}>
                                    <span>
                                      <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                    </span>
                                  </Tooltip>
                                </label>
                                <DatePicker
                                  // showYearDropdown
                                  placeholderText='Select Date Range'
                                  selectsRange={true}
                                  startDate={startDateNetSuiteInvoice}
                                  endDate={endDateNetSuiteInvoice}
                                  dateFormat='dd-MMM-yyyy'
                                  calendarStartDay={1}
                                  // onChange={(update: any) => {
                                  //   // setNetSuiteDateRange(update)

                                  // }}
                                  onChange={(update: any) => {
                                    singleNetSuiteInvoiceDate(update)
                                  }}
                                  isClearable={true}
                                  maxDate={maxNetSuiteInvoiceDate}
                                  monthsShown={2}
                                  className={clsx('form-control')}
                                />
                                {startDateNetSuiteInvoice == null || endDateNetSuiteInvoice == null
                                  ? NS_InvoiceValidation && (
                                      <span className='error text-danger mb-0'>
                                        Date Range is required
                                      </span>
                                    )
                                  : ''}
                              </div>
                              {/* {!NS_InvoiceValidation && CreateNSInvoice && CreateNSInvoice.length == 0 && ( */}
                              {(NS_DateRun || (CreateNSInvoice && CreateNSInvoice.length == 0)) && (
                                <div className='col-auto pt-md-0 pt-lg-8'>
                                  <button
                                    className='btn btn-sm btn-primary'
                                    // onClick={() => handleNetSuiteRun(page, perPage)}
                                    onClick={() => handleNetSuiteRun()}
                                  >
                                    Run
                                  </button>
                                </div>
                              )}
                              {CreateNSInvoice && CreateNSInvoice.length > 0 && !NS_DateRun && (
                                <div className='col-xs-12 col-md-2'>
                                  <label className='form-label fw-semibold'>
                                    {' '}
                                    Invoice Date * <span className='fs-9'>(DD-MMM-YYYY)</span>
                                  </label>
                                  <DatePickerIcon
                                    className='form-control fs-6'
                                    selected={NSInvoiceDate}
                                    // dateFormat='MM/dd/yyyy'
                                    dateFormat='dd-MMM-yyyy'
                                    handleDate={(date: any) => {
                                      handleNetSuiteInvoiceDate(date)
                                    }}
                                    min={endDateNetSuiteInvoice ? endDateNetSuiteInvoice : ''}
                                    placeholderText='Select invoice date'
                                  />
                                  {NSInvoiceDate == null && NSInvoiceDateValidation && (
                                    <span className='error text-danger mb-0'>
                                      Invoice Date is required
                                    </span>
                                  )}
                                </div>
                              )}
                              <div className='col-auto pt-md-0 pt-lg-8 '>
                                <button
                                  className='btn btn-sm btn-primary'
                                  // onClick={() => handleClick(page, perPage)}
                                  onClick={() => handleNetSuiteInvoice('')}
                                  disabled={
                                    !NS_InvoiceValidation &&
                                    CreateNSInvoice &&
                                    CreateNSInvoice.length > 0 &&
                                    !NS_DateRun
                                      ? false
                                      : true
                                  }
                                >
                                  Create Invoice
                                </button>
                              </div>
                              {/* <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      // onClick={resetData}
                    >
                      Reset
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      // onClick={exporthandleClick}
                    >
                      Export
                    </button>
                  </div> */}
                            </div>
                          </div>
                        </div>
                        <div className='fs-6 text-gray-500 text-transform-unset'>
                          Note: Invoice can be generated only for timesheets submitted through our
                          timesheets portal.
                        </div>
                        <div className='pt-3'>
                          <div className='card card-custom card-flush '>
                            <div className='card-body p-3 py-3'>
                              <div className='text-gray-900 fs-5 fw-semibold text-transform-unset'>
                                Count{' '}
                                <Tooltip
                                  text={'Total no. of available timesheets to create invoice'}
                                >
                                  <span>
                                    <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                  </span>
                                </Tooltip>
                                : {InvoiceNSTimesheets[0]?.count && InvoiceNSTimesheets[0]?.count}
                              </div>
                              <KTCardBody className='py-1 '>
                                <div className='pt-3'>
                                  <div className='card card-custom card-flush '>
                                    <div className='card-body p-3 py-3'>
                                      <div className='table-responsive reports-table'>
                                        <table
                                          id='kt_table_users'
                                          className='table table-row-bordered table-row-gray-300 gy-4'
                                        >
                                          <>
                                            <thead>
                                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                                <th className='  py-4'>Date </th>
                                                <th className='  py-4'>Regular Hours </th>
                                                <th className='  py-4'>OT Hours </th>
                                                <th className='  py-4'>Bill Rate </th>
                                                <th className='  py-4'>OT Bill Rate </th>
                                                <th className='  py-4'>Amount</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {CreateNSInvoice?.length > 0 ? (
                                                <>
                                                  {CreateNSInvoice?.map((each: any, index: any) => {
                                                    let hr_min =
                                                      each.regular_hours + each.regular_minutes / 60
                                                    let hr_min1 =
                                                      each.ot_hours + each.ot_minutes / 60
                                                    return (
                                                      <tr
                                                        className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                                        key={index}
                                                      >
                                                        <td className=''>
                                                          {each.timesheet_date
                                                            ? moment(each.timesheet_date).format(
                                                                'DD-MMM-YYYY'
                                                              )
                                                            : '-'}
                                                        </td>
                                                        <td className=''>
                                                          {hr_min ? hr_min.toFixed(2) : '-'}
                                                        </td>
                                                        <td className=''>
                                                          {hr_min1 ? hr_min1.toFixed(2) : '-'}
                                                        </td>
                                                        <td className=''>
                                                          {each.bill_rate
                                                            ? '$' +
                                                              Number(each.bill_rate).toLocaleString(
                                                                undefined,
                                                                {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              )
                                                            : '-'}
                                                        </td>
                                                        <td className=''>
                                                          {each.ot_bill_rate
                                                            ? '$' +
                                                              Number(
                                                                each.ot_bill_rate
                                                              ).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              })
                                                            : '-'}
                                                        </td>
                                                        <td className=''>
                                                          {each.amount
                                                            ? '$' +
                                                              Number(each.amount).toLocaleString(
                                                                undefined,
                                                                {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              )
                                                            : '-'}
                                                        </td>
                                                      </tr>
                                                    )
                                                  })}
                                                </>
                                              ) : (
                                                ''
                                              )}
                                            </tbody>
                                          </>
                                        </table>
                                        {(CreateNSInvoice?.length == null ||
                                          CreateNSInvoice?.length == 0) && (
                                          <div className='text-center  fw-bold text-gray-800'>
                                            No Records found{' '}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <UsersListPagination />
                              </KTCardBody>
                            </div>
                          </div>
                        </div>
                      </>
                    </Tab>
                  )}
                  {numArray?.includes('admin') && disableNetSuite && displayqb && (
                    <Tab
                      eventKey='netsuite_history'
                      title='Invoice History'
                      disabled={StatusDisabledField}
                    >
                      <>
                        <div className='card card-custom card-flush '>
                          <div className='card-body p-2 py-3'>
                            <div className='row gy-3 align-items-center py-2'>
                              <div className='col-xs-12 col-md-3 col-lg-3'>
                                <label className='form-label fw-semibold'>
                                  Date Range * <span className='fs-9'>(DD-MMM-YYYY)</span>
                                </label>
                                <MultiDatePicker
                                  value={NSDateValue1}
                                  format='MMM YYYY'
                                  // multiple
                                  numberOfMonths={2}
                                  range={true}
                                  sort
                                  highlightToday={false}
                                  rangeHover
                                  ref={calendarRef}
                                  // minDate={NSDateValue1 ? NSDateValue1[0] : []}
                                  minDate={minDateValue1}
                                  maxDate={maxNSHistoryDate}
                                  // showOtherDays
                                  // showMonthYearPicker
                                  onlyMonthPicker
                                  hideMonth
                                  months={months}
                                  onChange={handleNSChange1}
                                  dateSeparator=' - '
                                  inputClass='form-control'
                                  // showWeekend
                                  // showMonthArrow
                                  // months={[
                                  //   {year: 2022, month: 1},
                                  //   {year: 2023, month: 2},
                                  // ]}
                                />
                                {NSDateValue1 == null || NSDateValue1?.length < 2
                                  ? NSHistoryValidation && (
                                      <span className='error text-danger mb-0'>
                                        Select from month and to month
                                      </span>
                                    )
                                  : ''}
                              </div>
                              <div className='col-auto pt-md-0 pt-lg-8'>
                                <button
                                  className='btn btn-sm btn-primary'
                                  onClick={() => handleNetSuiteHistoryRun(page, perPage)}
                                  // onClick={() => handleNetSuiteHistoryRun()}
                                >
                                  Run
                                </button>
                              </div>
                              <div className='col-auto pt-md-0 pt-lg-8'>
                                <button
                                  // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                                  className='btn btn-light-primary   btn-sm'
                                  onClick={exporthandleClick}
                                >
                                  Export
                                  {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <KTCardBody className='py-1 '>
                          <div className='pt-3'>
                            <div className='card card-custom card-flush '>
                              <div className='card-body p-3 py-3'>
                                <div className='table-responsive reports-table'>
                                  <table
                                    id='kt_table_users'
                                    className='table table-row-bordered table-row-gray-300 gy-4'
                                  >
                                    <>
                                      <thead>
                                        <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                          <th className='  py-4'>Week </th>
                                          <th className='  py-4'>Raised on </th>
                                          <th className='  py-4'>Regular Hours </th>
                                          <th className='  py-4'>OT Hours </th>
                                          <th className='  py-4'>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {NetSuiteHistoryInvoice?.length > 0 ? (
                                          <>
                                            {NetSuiteHistoryInvoice?.map(
                                              (each: any, index: any) => {
                                                return (
                                                  <tr
                                                    className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                                    key={index}
                                                  >
                                                    <td className=''>
                                                      {each.start_date
                                                        ? moment(each.start_date).format('MMMM DD')
                                                        : '-'}{' '}
                                                      -{' '}
                                                      {each.end_date
                                                        ? moment(each.end_date).format('MMMM DD')
                                                        : '-'}
                                                    </td>
                                                    <td className=''>
                                                      {each.invoice_date
                                                        ? moment(each.invoice_date).format(
                                                            'DD-MMM-YYYY'
                                                          )
                                                        : '-'}
                                                    </td>
                                                    <td className=''>
                                                      {each.regular_hours
                                                        ? each.regular_hours
                                                        : '-'}
                                                    </td>
                                                    <td className=''>
                                                      {each.ot_hours ? each.ot_hours : '-'}
                                                    </td>

                                                    <td>
                                                      <div className='d-flex nowrap'>
                                                        <Tooltip text={'View'}>
                                                          <a
                                                            onClick={() =>
                                                              handleView(
                                                                each.qb_invoice_id,
                                                                each.business_unit_id,
                                                                each.start_date,
                                                                each.end_date
                                                              )
                                                            }
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                          >
                                                            <i
                                                              className='fa fa-eye'
                                                              aria-hidden='true'
                                                            ></i>
                                                          </a>
                                                        </Tooltip>
                                                        <Tooltip text={'Download PDF'}>
                                                          <a
                                                            onClick={() =>
                                                              handleNetSuitePDFDownload(
                                                                each.netsuite_invoice_id
                                                              )
                                                            }
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                          >
                                                            <i className='bi bi-file-earmark-arrow-down fs-2'></i>
                                                          </a>
                                                        </Tooltip>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              }
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </tbody>
                                    </>
                                  </table>
                                  {(NetSuiteHistoryInvoice?.length == null ||
                                    NetSuiteHistoryInvoice?.length == 0) && (
                                    <div className='text-center  fw-bold text-gray-800'>
                                      No Records found{' '}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <UsersListPagination />
                        </KTCardBody>

                        <>
                          <div className='d-flex flex-stack py-2'>
                            {NetSuiteHistoryInvoice?.length > 0 ? (
                              <div>
                                <Container className='p-0'>
                                  <Row className='align-items-center'>
                                    <Col xs='auto' className='pe-0'>
                                      {' '}
                                      <label className='form-label fw-semibold'>
                                        Rows Per Page
                                      </label>
                                    </Col>

                                    <Col>
                                      <select
                                        name='perPage'
                                        className='form-select'
                                        onChange={handleNetSuiteRowChange}
                                        value={perPage}
                                      >
                                        <option value='25'>25</option>
                                        <option value='50'>50</option>
                                        <option value='75'>75</option>
                                        <option value={AllCreateInvoiceCount}>All</option>
                                      </select>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            ) : null}

                            <div>
                              {NetSuiteHistoryInvoice?.length > 0 ? (
                                <ReactPaginate
                                  previousLabel={'<'}
                                  nextLabel={'>'}
                                  pageCount={pages}
                                  onPageChange={handleNetSuitePageClick}
                                  containerClassName={'pagination justify-content-right'}
                                  activeClassName={'active'}
                                  breakLabel={'...'}
                                  pageClassName={'page-item'}
                                  pageLinkClassName={'page-link'}
                                  previousClassName={'page-item'}
                                  previousLinkClassName={'page-link'}
                                  nextClassName={'page-item'}
                                  nextLinkClassName={'page-link'}
                                  breakClassName={'page-item'}
                                  breakLinkClassName={'page-link'}
                                />
                              ) : null}
                            </div>
                          </div>
                        </>
                      </>
                    </Tab>
                  )}
                </Tabs>
              </>
            </Tab>
          )}

        {/* {(numArray?.includes('admin') ||
          numArray?.includes('accounts') ||
          localStorage.getItem('invoice_access') == 'true') &&
          disableQuickBooks &&
          displayqb &&
          IndividualBU && ( */}

        {/* {(numArray?.includes('admin') ||
          numArray?.includes('accounts') ||
          localStorage.getItem('invoice_access') == 'true') &&
          disableQuickBooks &&
          displayqb &&
          IndividualBU && ( */}

        {/* {(numArray?.includes('admin') || localStorage.getItem('invoice_access') == 'true') &&
          disableQuickBooks &&
          displayqb &&
          IndividualBU && ( */}
        {displayqb && (
          <Tab eventKey='work' title='Work Location'>
            <>
              <div className='pt-3'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-3 py-3'>
                    <div className='table-responsive reports-table'>
                      <table
                        id='kt_table_users'
                        className='table table-row-bordered table-row-gray-300 gy-4'
                      >
                        <>
                          <thead>
                            <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                              <th onClick={() => 'work_country'} className='  py-4'>
                                Work Country{' '}
                              </th>
                              <th onClick={() => 'work_state'} className='  py-4'>
                                Work State{' '}
                              </th>
                              <th onClick={() => 'work_city'} className='  py-4'>
                                Work City{' '}
                              </th>
                              <th onClick={() => 'created_by_name'} className='  py-4'>
                                Updated by{' '}
                              </th>
                              <th onClick={() => 'createdate'} className='  py-4'>
                                Updated on (MM/DD/YYYY){' '}
                              </th>
                            </tr>
                          </thead>
                          {ProjectWorkLocationHistory?.length > 0 ? (
                            <>
                              <tbody className='' {...getTableBodyProps()}>
                                {ProjectWorkLocationHistory?.length > 0 ? (
                                  <>
                                    {ProjectWorkLocationHistory?.map((user: any, index: any) => {
                                      return (
                                        <tr
                                          key={index}
                                          className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                        >
                                          <td className='text-capitalize'>
                                            {user.work_country ? user.work_country : '-'}
                                          </td>
                                          <td className='text-capitalize'>
                                            {user.work_state ? user.work_state : '-'}
                                          </td>
                                          <td className='text-capitalize'>
                                            {user.work_city ? user.work_city : '-'}
                                          </td>
                                          <td className='text-capitalize'>
                                            {user.created_by_name ? user.created_by_name : '-'}
                                          </td>
                                          <td className=''>
                                            {user.createdate
                                              ? moment(user.createdate).format('MM/DD/YYYY')
                                              : '-'}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </>
                                ) : (
                                  ''
                                )}
                              </tbody>
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      </table>

                      {(ProjectWorkLocationHistory?.length == null ||
                        ProjectWorkLocationHistory?.length == 0) && (
                        <p className='text-center'> No Records Found</p>
                      )}
                    </div>
                    <UsersListPagination />
                  </div>
                </div>
              </div>
            </>
          </Tab>
        )}
        {displayqb && (
          <Tab eventKey='payment' title='Payment Info'>
            <>
              <div className='pt-3'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-3 py-3'>
                    <div className='text-gray-900 fs-5 fw-semibold text-transform-unset'>
                      Payment Terms{' '}
                      <Tooltip text={'This information from Placement Portal'}>
                        <span>
                          <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                        </span>
                      </Tooltip>
                      : {projectid?.payment_terms}
                    </div>
                    {/* <KTCardBody className='py-1 '> */}

                    <div className='pt-3'>
                      <div className='card card-custom card-flush '>
                        <div className='card-body p-3 py-3'>
                          <div className='table-responsive reports-table'>
                            <table
                              id='kt_table_users'
                              className='table table-row-bordered table-row-gray-300 gy-4'
                            >
                              <>
                                <thead>
                                  <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                    <th onClick={() => 'work_country'} className='  py-4'>
                                      Pay Rate{' '}
                                    </th>
                                    <th onClick={() => 'work_state'} className='  py-4'>
                                      Bill Rate{' '}
                                    </th>
                                    <th onClick={() => 'work_city'} className='  py-4'>
                                      OT Pay Rate{' '}
                                    </th>
                                    <th onClick={() => 'created_by_name'} className='  py-4'>
                                      OT Bill Rate{' '}
                                    </th>
                                    <th onClick={() => 'createdate'} className='  py-4'>
                                      Effective Date{' '}
                                    </th>
                                    <th onClick={() => 'createdate'} className='  py-4'>
                                      Update Date{' '}
                                    </th>
                                  </tr>
                                </thead>
                                {ProjectPaymentHistory?.length > 0 ? (
                                  <>
                                    <tbody className='' {...getTableBodyProps()}>
                                      {ProjectPaymentHistory?.length > 0 ? (
                                        <>
                                          {ProjectPaymentHistory?.map((user: any, index: any) => {
                                            return (
                                              <tr
                                                key={index}
                                                className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                              >
                                                <td className=''>
                                                  {user.agreed_pay_rate
                                                    ? user.agreed_pay_rate
                                                    : '-'}
                                                </td>
                                                <td className=''>
                                                  {user.bill_rate ? user.bill_rate : '-'}
                                                </td>
                                                <td className=''>
                                                  {user.ot_agreed_pay_rate
                                                    ? user.ot_agreed_pay_rate
                                                    : '-'}
                                                </td>
                                                <td className=''>
                                                  {user.ot_bill_rate ? user.ot_bill_rate : '-'}
                                                </td>
                                                <td className=''>
                                                  {user.effective_date
                                                    ? moment(user.effective_date).format(
                                                        'MM/DD/YYYY'
                                                      )
                                                    : '-'}
                                                </td>
                                                <td className=''>
                                                  {user.modified_on
                                                    ? moment(user.modified_on).format(
                                                        'MM/DD/YYYY hh:mm:ss'
                                                      )
                                                    : '-'}
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </tbody>
                                  </>
                                ) : (
                                  ''
                                )}
                              </>
                            </table>

                            {(ProjectPaymentHistory?.length == null ||
                              ProjectPaymentHistory?.length == 0) && (
                              <p className='text-center'> No Records Found</p>
                            )}
                          </div>
                          <UsersListPagination />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Tab>
        )}
      </Tabs>
    </>
  )
}
export default AddProject
