/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, FC} from 'react'
import {ToastContainer, toast} from 'react-toastify'

import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {useNavigate} from 'react-router-dom'
import {default as Apiservices} from '../../../../common/index'
import {Projects} from '../../../../app/modules/profile/components/Projects'
import Cookies from 'universal-cookie'
import clsx from 'clsx'
import {Tooltip} from '../../../../app/modules/apps/timesheetentry/ComponentTooltip'

// type Props = {
//   user?: string
// }
// const HeaderUserMenu: FC<Props> = (props: any) => {
const HeaderUserMenu = (props: any) => {
  const navigate = useNavigate()
  const [employeesProjectList, setEmployeesProjectList] = useState<any[]>([])
  const cookies = new Cookies()
  function removeAll() {
    let lRemove: any = [
      'token',
      'user_email',
      'org_list',
      'role_Name',
      'org_prefix',
      'org_id',
      'org_name',
      'tool_name',
      'ecdb_user_id',
      'userid',
      'Authorization',
      'NetSuiteToken',
      'enable_netsuite',
      'subsidary_name',
      'logged_in_id',
      'Announcement',
      'refresh_Token',
      'enable_quickbooks',
      'org_icon',
      'time_sheet_admin_screen_user_id',
      'MobileForceUpdateAccessbyEmail',
      'allowed_business_unit_id',
      'bu_list',
      'enable_unbilled',
      'invoice_access',
      'enable_email',
    ]
    for (let each of lRemove) {
      localStorage.removeItem(each)
    }
  }

  const logout = async () => {
    // localStorage.getItem('clear');

    const data: any = await Apiservices.getUserLogout()
    const data1: any = await Apiservices.getUserLogout1()
    if (data.data.statusCode == 200 || data1.status == 200) {
      // localStorage.clear()
      removeAll()
      props.widgetLogout()
      cookies.remove('JWT-TOkEN')
      cookies.remove('JWT_TOKEN')
      cookies.remove('org_id')
      cookies.remove('torg_id')
      cookies.remove('profileView')
      localStorage.setItem('business_unit_id', '')
      cookies.remove('JWT-TOkEN', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('JWT_TOKEN', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('org_id', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('torg_id', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('profileView', {path: '/', domain: '.msrcosmos.com'})

      return (
        toast.success('Logout Successful'),
        {
          position: toast.POSITION.BOTTOM_LEFT,
        },
        setTimeout(function () {
          navigate('/login')
        }, 1000)
      )
    }
    // if (data1.status == 200) {
    //   toast.success('Logout successfully')
    // }
    // localStorage.clear()
    // cookies.remove('JWT-TOkEN')
    // cookies.remove('JWT_TOKEN')

    // cookies.remove('JWT-TOkEN', {path: '/', domain: '.msrcosmos.com'})
    // cookies.remove('JWT_TOKEN', {path: '/', domain: '.msrcosmos.com'})

    // cookies.remove('org_id')

    // return (
    //   toast.success('Logout Successfully'),
    //   {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   },
    //   setTimeout(function () {
    //     navigate('/login')
    //   }, 1000)
    // )
  }

  const handleProfile = (blob: any) => {
    cookies.set('profileView', 'yes', {path: '/', domain: '.msrcosmos.com'})

    if (cookies.get('profileView')) {
      setTimeout(function () {
        window.open(blob, '_blank')
        // cookies.set('profileView', 'yes', {path: '/', domain: '.msrcosmos.com'})
      }, 1000)
    }
  }

  let blob1 =
    'https://timesheetsstouat.blob.core.windows.net/timesheetsuat/255/281/132-timesheet/2023-05-29-2023-06-04/3b5a7dd0-0c2a-11ee-9abb-4368e1d4fdf5dummy.pdf?sv=2021-10-04&ss=btqf&srt=sco&spr=https&st=2023-06-16T10%3A17%3A59Z&se=2023-06-16T11%3A17%3A59Z&sp=rwdlacupi&sig=wjWvWfew5S351nUPOWYnIImWuHC9nx2OHDBdl%2Bcc7LQ%3D'

  let blob3 =
    'https://timesheetsstouat.blob.core.windows.net/timesheetsuat/255/281/132-timesheet/2023-05-29-2023-06-04/ab1003d0-0c29-11ee-a79c-43169c3aee29Dresscode Policy.pdf?sv=2021-10-04&ss=btqf&srt=sco&spr=https&st=2023-06-16T09%3A09%3A24Z&se=2023-06-16T10%3A09%3A24Z&sp=rwdlacupi&sig=vuYKh%2F0POfLtU%2BsTHr42ihiU8OY3casOucGLeccV58w%3D'

  useEffect(() => {
    getEmployeeProjectListFunction()
  }, [])
  const getEmployeeProjectListFunction = async () => {
    setEmployeesProjectList([])

    const userid = localStorage.getItem('userid')
    // const getProjectList: any = await Apiservices.getEmployeeProjectList({
    //   user_id: userid,
    // })

    // const resData = getProjectList.data.data
    // setEmployeesProjectList(resData)
  }
  // {
  //   employeesProjectList &&
  //     employeesProjectList.map((emp: any) => {
  //       localStorage.setItem('employee_name', emp.employee_name)
  //     })
  // }
  const userEmail: any = localStorage.getItem('user_email')
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <div className='menu-content  px-5'>
          {/* <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
          </div> */}
          <div className='d-flex align-items-center gap-2'>
            <div>
              <i
                className={clsx(
                  props.user ? 'bi bi-person-circle fs-40' : 'bi bi-person-circle fs-30'
                )}
              ></i>
            </div>
            <Tooltip text={userEmail}>
              <div className='overflow-hidden'>
                <>
                  {' '}
                  {props.user && (
                    <div className='menu-text text-dark  fs-6 text-capitalize'> {props.user}</div>
                  )}
                  <div
                    className={clsx(
                      props.user
                        ? 'menu-text text-gray-600 fs-7 fw-normal text-lowercase'
                        : 'menu-text text-dark  fs-6 text-capitalize'
                    )}
                  >
                    {localStorage.getItem('user_email')}
                  </div>
                </>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* {employeesProjectList && ( */}
      <>
        {/* <div className='menu-item px-5'>
            <a href='#' className='menu-link px-5'>
              <span className='menu-text'>
                {employeesProjectList &&
                employeesProjectList[0] &&
                employeesProjectList[0].employee_name
                  ? employeesProjectList[0].employee_name
                  : ''}
              </span>
            </a>
          </div> */}
        {window.location.pathname != '/timetracker/OrganizationList' && (
          <>
            <div className='menu-item px-5'>
              {/* <Link to={'/profile'} className='menu-link px-5'> */}
              {/* <Link
              to={'https://launchpad-uat.msrcosmos.com/#/home/profile/about'}
              className='menu-link px-5'
            >
              My Profile
            </Link> */}

              {/* <a
              href={'https://launchpad-uat.msrcosmos.com/#/home/profile/about'}
              target='_blank'
              className='menu-link px-5'
            >
              My Profile
            </a> */}

              {window.location.hostname === 'timesheets.msrcosmos.com' ? (
                <a
                  onClick={() =>
                    handleProfile('https://launchpad.msrcosmos.com/#/home/profile/about')
                  }
                  className='menu-link px-5'
                >
                  My Profile
                </a>
              ) : (
                <a
                  onClick={() =>
                    handleProfile('https://launchpad-uat.msrcosmos.com/#/home/profile/about')
                  }
                  className='menu-link px-5'
                >
                  My Profile
                </a>
              )}
            </div>
          </>
        )}
        <div
          className='menu-item px-5'
          data-kt-menu-trigger='hover'
          data-kt-menu-placement='left-start'
          data-kt-menu-flip='bottom'
        >
          {/* <a href='#' className='menu-link px-5'>
              {employeesProjectList && (
                <>
                  <span className='menu-title'>My Projects</span>
                  <span className='menu-arrow'></span>
                </>
              )}
            </a> */}

          {/* <div className='menu-sub menu-sub-dropdown w-175px py-4'>
              {employeesProjectList &&
                employeesProjectList.map((projects: any) => (
                  <div className='menu-item px-3'>
                    <a href='#' className='menu-link px-5'>
                      {projects && projects.project_name ? projects.project_name : ''}
                    </a>
                  </div>
                ))}

              <div className='separator my-2'></div>
            </div> */}
        </div>
      </>
      {/* )} */}
      {/* {localStorage.getItem('role_Name') === 'employee' ? (
        <div className='menu-item px-5'>
          <a href={blob1} target='_blank' className='menu-link px-5'>
            Help
          </a>
        </div>
      ) : (
        <div className='menu-item px-5'>
          <a href={blob1} target='_blank' className='menu-link px-5'>
            Help(Others)
          </a>
        </div>
      )} */}
      {/* <div className='menu-item px-5'>
        <a
          href={process.env.PUBLIC_URL + '/Dresscode.pdf'}
          target='_blank'
          className='menu-link px-5'
        >
          Help
        </a>
      </div> */}
      {/* {localStorage.getItem('role_Name') === 'employee' ? ( */}
      {numArray?.includes('employee') ? (
        <div className='menu-item px-5'>
          <a
            href={process.env.PUBLIC_URL + '/TIMESHEET_employee_Version.pdf'}
            target='_blank'
            className='menu-link px-5'
          >
            Help
          </a>
        </div>
      ) : // ) : localStorage.getItem('role_Name') === 'timesheetadmin' ? (
      numArray?.includes('timesheetadmin') ? (
        <div className='menu-item px-5'>
          <a
            href={process.env.PUBLIC_URL + '/TIMESHEET_timesheetadmin.docx.pdf'}
            target='_blank'
            className='menu-link px-5'
          >
            Help
          </a>
        </div>
      ) : (
        <div className='menu-item px-5'>
          <a
            href={process.env.PUBLIC_URL + '/TIMESHEET_Approver.pdf'}
            target='_blank'
            className='menu-link px-5'
          >
            Help
          </a>
        </div>
      )}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Logout
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
