import React, {useState, useMemo, useEffect, useRef, useContext} from 'react'
import {useForm} from 'react-hook-form'
import {useIntl} from 'react-intl'
import Cookies from 'universal-cookie'
import {useLocation} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SofaIcon from '../../../localMedia/sofa.svg'
import PlaneIcon from '../../../localMedia/plane.svg'
import UmbrellaIcon from '../../../localMedia/umbrella.svg'
import Cal from '../../../localMedia/cal.svg'
import ButtonCal from '../../../localMedia/buttonCal.svg'
import Accordion from 'react-bootstrap/Accordion'
import {useAccordionButton} from 'react-bootstrap/AccordionButton'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import {default as Apiservices} from '../../../common/index'
import Drawer from '../../../app/modules/apps/timesheetentry/ComponentDrawer'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import History from '../../../localMedia/history.svg'
import {useNavigate} from 'react-router-dom'
import ThingsContext from '../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../modules/apps/masters/commonicons/ValidateContext'
import {PageTitle} from '../../../_metronic/layout/core'
import {usersColumns} from '../../modules/apps/user-management/users-list/table/columns/_columns'
import {Tooltip} from '../../modules/apps/timesheetentry/ComponentTooltip'
import BackDrop from '../../modules/apps/timesheetentry/ComponentBackdrop'
import SubmitButtonPop from '../../modules/apps/masters/modal/SubmitButtonPop'
import ViewDocumentPop from '../../modules/apps/masters/modal/ViewDocumentPop'
import LeaveAndHolidayPopup from '../../modules/apps/masters/modal/LeaveAndHolidayPop'
import LeavePopup from '../../modules/apps/masters/modal/LeavePop'
import {DrawerMessenger} from '../../../_metronic/partials'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import CopyButtonPop from '../../modules/apps/masters/modal/CopyButtonPop'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import VacationPop from '../../modules/apps/masters/modal/VacationPop'
import {KTSVG} from '../../../_metronic/helpers'
import WorkLocationPop from '../../modules/apps/masters/modal/WorkLocationPop'
import WorkLocationSubmitAllPop from '../../modules/apps/masters/modal/WorkLocationSubmitAllPop'
import {Toolbar1} from '../../../_metronic/layout/components/toolbar/Toolbar1'
import AccordionContext from 'react-bootstrap/AccordionContext'
import parse from 'html-react-parser'
import SkillsRequiredPopup from '../login/SkillsRequiredPopup'

type FormData = {
  monday_actual: any
  tuesday_actual: any
  wednesday_actual: any
  thursday_actual: any
  friday_actual: any
  saturday_actual: any
  sunday_actual: any
  total_actual: any
  total_leave: any
  monday_ot: any
  tuesday_ot: any
  wednesday_ot: any
  thursday_ot: any
  friday_ot: any
  saturday_ot: any
  sunday_ot: any
  monday_leave: any
  tuesday_leave: any
  wednesday_leave: any
  thursday_leave: any
  friday_leave: any
  saturday_leave: any
  sunday_leave: any
  project_name: any
  client_manager_name: any
  client_manager_email: any
  comments: any
}
type FormInputs = {
  regular_hours: any
  ot_hours: any
}

function ContextAwareToggle(person: {children: string; eventKey: any}) {
  const {activeEventKey} = useContext(AccordionContext)
  const decoratedOnClick = useAccordionButton(person.eventKey)
  const isCurrentEventKey: any = activeEventKey === person.eventKey
  return (
    <button type='button' className='btn btn-icon bg-body mt-7 p-0' onClick={decoratedOnClick}>
      {isCurrentEventKey ? (
        <i className='bi fs-3 bi-chevron-up text-primary'></i>
      ) : (
        <i className='bi fs-3 bi-chevron-down text-primary'></i>
      )}
    </button>
  )
}

function AddTimesheet(props: any) {
  const [show, setShow] = useState(false)
  const [viewshow, setViewShow] = useState(false)
  const [view_open, setViewOpen] = useState(false)
  const [view_blob, setViewBlob] = useState<any | []>([])
  const [submitshow, setSubmitShow] = useState(false)
  const [WorkLocationshow, setWorkLocationShow] = useState(false)
  const [WorkLocationAllshow, setWorkLocationAllShow] = useState(false)
  const [submitAllshow, setSubmitAllShow] = useState(false)
  const [forLeaveAndHoldiayPopup, setForLeaveAndHoldiayPopup] = useState(false)
  const [forLeavePopup, setForLeavePopup] = useState(false)
  const [otdisable, setOtDisable] = useState(true)
  const [submit_open, setSubmitOpen] = useState(false)
  const [submitall_open, setSubmitAllOpen] = useState(false)
  const [ClientManagerNameValidation, setClientManagerNameValidation] = useState<any | []>([])
  const [WorkLocationValidation, setWorkLocationValidation] = useState<any | []>([])
  const [otfieldsdisabledValidation, setotfieldsdisabledValidation] = useState<any | []>([])
  const [history_per_week, setHistoryPerWeek] = useState<any | []>([])
  const [history_per_day, setHistoryPerDay] = useState<any | []>([])
  const [history_status_per_day, setHistoryStatusPerDay] = useState<any | []>([])
  const [currentWeek, setCurrentWeek] = useState<any | []>([])
  const [history_open_per_week, setHistoryOpenPerWeek] = useState(false)
  const [CopyProjectMatching, setCopyProjectMatching] = useState(false)
  const [CopyProjectMatchingprevweek, setCopyProjectMatchingPrevWeek] = useState<any | []>([])
  const [history_open_per_day, setHistoryOpenPerDay] = useState(false)
  const [indexState, setindexState] = useState<any>('')
  const [workPopMessage, setWorkPopMessage] = useState('')
  const [workAllPopMessage, setWorkAllPopMessage] = useState('')
  const [workAllPopModifyMessage, setWorkAllPopModifyMessage] = useState('')
  const [workPopModifyMessage, setWorkPopModifyMessage] = useState('')
  const [docResultDataRes, setDocResultDataRes] = useState<any>([])
  const [prevAr, setPrevAr] = useState<any>([])
  const [sumofRegularHoursProject, setSumofRegularHoursProject] = useState<any[]>([])
  const [sumofOtHoursProject, setSumofOtHoursProject] = useState<any[]>([])
  const [pageTitle, setPageTitle] = useState<any>('')
  const [ermState, setErmState] = useState<any>('')
  const [isLogin, setIsLogin] = useState('')
  const [showSkillsRequiredPopup, setShowSkillsRequiredPopup] = useState(false)
  const [sumofLeaveHoursProject, setSumofLeaveHoursProject] = useState<any[]>([])
  const location = useLocation()
  const [weekDates, setWeekDates] = useState<any>([])
  const [weekStartDate, setWeekStartDate] = useState('')
  const [monthStartDate, setMonthStartDate] = useState('')
  const [month_view_dates, setMonthViewDates] = useState<any[]>([])
  const [total_month_view_hours, setTotalMonthViewHours] = useState('')
  const [total_month_view_status, setTotalMonthViewStatus] = useState<any | {}>({})
  const [month_view, setMonth_View] = useState(false)
  const [month_week_view, setMonthWeekView] = useState(false)
  const [weekEndDate, setWeekEndDate] = useState('')
  const [monthEndDate, setMonthEndDate] = useState('')
  const [startEnd, setStartEnd] = useState('')
  const [startMonthNumber, setStartMonthNumber] = useState('')
  const [RegularHoursEntry, setRegularHoursEntry] = useState<any>(8)
  const [OTHoursEntry, setOTHoursEntry] = useState<any>(16)
  const [LeaveHoursEntry, setLeaveHoursEntry] = useState<any>(8)
  const [disableWorkLocation, setDisableWorkLocation] = useState(false)
  const [disableDesc, setDisableDesc] = useState(false)
  const [startYearNumber, setStartYearNumber] = useState('')
  const [list, setList] = useState<any[]>([])
  const [mon, setMon] = useState<any>()
  const [tue, setTue] = useState<any>()
  const [wed, setWed] = useState<any>()
  const [thu, setThu] = useState<any>()
  const [fri, setFri] = useState<any>()
  const [sat, setSat] = useState<any>()
  const [sun, setSun] = useState<any>()
  const [Random, setRandom] = useState('')

  const [copySuccess, setCopySuccess] = useState('')
  const abc = `<div style='display: block'>
  <div><h2>
  <b>Submit Time Off Requests From ADP</b>
  </h2></div>
  <div style='display: block; 'color:#747474'>
  <p style='color:#747474'>
  Navigate to: <b style="font-weight:bolder">Myself > Time Off > My Time Off</b>
  </p>
  <li style='color:#747474'>
  Click <b style="font-weight:bolder">Request Time Off</b> and enter the <b style="font-weight:bolder">Start Date</b> and <b>End Date.</b>
  </li>
  <p style='color:#747474'>If preferred, you can instead click <b style="font-weight:bolder">Calendar View</b> first and click days to select them before clicking <b style="font-weight:bolder">Request Time Off.</b></p>
  
  <li style='color:#747474'>Select the applicable <b style="font-weight:bolder">Time Off Policy</b> and <b style="font-weight:bolder">Reason Code.</b></li>
  <li style='color:#747474'>If applicable, click <b style="font-weight:bolder">Make Recurring</b> to set up a repeating pattern.</li>
  <li style='color:#747474'>To change the number of hours per day for the request, click and type in the
  <b style="font-weight:bolder">Hours Per Day</b> field and then click out of the field.</li>
  <li style='color:#747474'>Verify the <b style="font-weight:bolder">Start Time</b> or select another time.</li>
  <li style='color:#747474'>If more days should be added with a different Hours Per Day amount, click <b style="font-weight:bolder">Add more Time Off</b> and enter those dates and all applicable data as well.</li>
  <li style='color:#747474'>Click <b style="font-weight:bolder">Review.</b></li>
  <li style='color:#747474'>In the <b style="font-weight:bolder">Approve by Date</b> field, you can click to select a date. There is also a field for <b style="font-weight:bolder">Comments..</b></li>
  <li style='color:#747474'>Click <b style="font-weight:bolder">Submit.</b></li>
  </div>
  </div>`

  const xmlString = abc

  const [employeesProjectList, setEmployeesProjectList] = useState<any[]>([])
  const [headerHours, setHeaderHours] = useState<any | {}>({})
  const [weekHourDataSubmit, setWeekHourDataSubmit] = useState<any | {}>({
    submit: 0,
    pending: 0,
    rejected: 0,
    approve: 0,
    headerTotalHours: 0,
  })
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  const [sumofAllProj, setSumofAllProj] = useState<any[]>([])
  const [sumofAllProjLeave, setSumofAllProjLeave] = useState<any[]>([])
  const [imagesArrayRes, setImagesArrayRes] = useState<any>([])
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [timeTracker, setTimeTracker] = useState(moment())
  // const user_id =
  //   localStorage.getItem('role_Name') === 'timesheetadmin'
  //     ? localStorage.getItem('time_sheet_admin_screen_user_id')
  //     : localStorage.getItem('userid')
  const state2: any = location.state
  const user_id =
    state2?.names == 'create'
      ? localStorage.getItem('time_sheet_admin_screen_user_id')
      : localStorage.getItem('userid')
  const [weekDayDataEntry, setweekDayDataEntry] = useState<any[]>([])
  const [StateFielddisabled, setStateFielddisabled] = useState<any | []>([])
  const [SelectedCountry, setSelectedCountry] = useState<any[]>([])
  const [WorkStatesBasedOnCountry, setWorkStatesBasedOnCountry] = useState<any | []>([])
  const [submitButtonEnableDisable, setSubmitButtonEnableDisable] = useState<any[]>([])
  const [submitButtonEnableDisableValidation, setSubmitButtonEnableDisableValidation] = useState<
    any[]
  >([])
  const [CopyButtonEnableDisableValidation, setCopyButtonEnableDisableValidation] = useState<any[]>(
    []
  )
  const [holidayData, setHolidayData] = useState<any | {}>({})
  const [projectStartDateDisable, setProjectStartDateDisable] = useState<any[]>([])
  const [projectEndDateDisable, setProjectEndDateDisable] = useState<any[]>([])
  const [disableLeftArrow, setDisableLeftArrow] = useState<any>(false)
  const [ApplyPopupOpen, setApplyPopupOpen] = useState<any>(false)
  const [disableRightArrow, setDisableRightArrow] = useState<any>(false)
  const [disableMonthLeftArrow, setDisableMonthLeftArrow] = useState<any>(false)
  const [disableMonthRightArrow, setDisableMonthRightArrow] = useState<any>(false)
  const [disableCalendarDays, setdisableCalendarDays] = useState<any>(false)
  const [weekDayStatus, setWeekDayStatus] = useState<any[]>([])
  const [weekDayName, setWeekDayName] = useState<any>('')
  const [statusForSubmitALL, setStatusForSubmitALL] = useState<any[]>([])
  const [statusForUnSubmitALL, setStatusForUnSubmitALL] = useState<any[]>([])
  const [monthYear, setmonthYear] = useState('')
  const [month_name, setMonthName] = useState('')
  const [year_name, setYearName] = useState('')
  const [monthNumber, setMonthNumber] = useState<any>()
  const [seletedDate, setseletedDate] = useState(moment(new Date()))
  const [country, setCountry] = useState<any | []>([])
  const [countries, setCountries] = useState<any | []>([])
  const [state, setState] = useState<any | []>([])
  const [states, setStates] = useState<any | []>([])
  const [useStateCountryId, setUseStateCountryId] = useState<any | []>([])
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<FormInputs>()

  const SuccessToast = (message: any) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 500,
    })
  }
  const WarnToast = (message: any) => {
    toast.warn(message, {
      position: 'top-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    })
  }

  const ErrorToast = (message: any) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    })
  }

  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }
  useEffect(() => {
    renderThings()
  }, [])

  const tokenHeader1: any = useContext(ValidContext)
  const renderThings1 = async () => {
    tokenHeader1.getValidate()
  }

  // useEffect(() => {
  //   renderThings1()
  // }, [])
  const intl = useIntl()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  useEffect(() => {
    let state: any = location.state
    let state1: any = location.state
    setPageTitle(state1)
    let state2: any = location.state
    setErmState(state2)
    setIsLogin(state2?.consultant_role)
    if (location.state) {
      var week = state.week
      viewEmployee(week)
    } else {
      getWeekFun()
      let start = moment(new Date())
    }
    let business: any = localStorage.getItem('role_Name')
    let numArray: any = business?.split(',')
    if (numArray?.includes('employee')) {
      const isSkillsRequired = localStorage.getItem('skillsrequired') ? false : true
      setShowSkillsRequiredPopup(isSkillsRequired)
    }
  }, [])

  const viewEmployee = async (week: any) => {
    let state: any = location.state
    state = state.week
    let week1 = moment(state.slice(0, 10), 'YYYY-MM-DD').format('MMM DD')
    let week2 = moment(state.slice(11, 21), 'YYYY-MM-DD').format('MMM DD, YYYY')
    let weeks = `${week1} - ${week2}`
    setStartEnd(weeks)
    setTimeTracker(moment(state.slice(0, 10)))
    var startOfWeek = moment(state.slice(0, 10)).clone().startOf('isoWeek')
    var endOfWeek = moment(state.slice(11, 21)).clone().endOf('isoWeek')
    var days = []
    var day: any = startOfWeek
    while (day.isSameOrBefore(endOfWeek)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []
    let arr1 = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      let week2 = moment(each, 'ddd MMM DD YYYY').format('DD/MM/YYYY')
      arr.push(week1)
      arr1.push(week2)
      setWeekDates(arr)
    }
    setCurrentWeek(arr1)
    let DateArray = arr
    var startdate = moment(days[0], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var enddate = moment(days[6], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    setWeekStartDate(startdate)
    setWeekEndDate(enddate)
    let week1update = moment(days[0], 'ddd MMM DD YYYY').format('MMM DD')
    let prestartweek = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
    let week2update = moment(days[6], 'ddd MMM DD YYYY').format('MMM DD, YYYY')
    let preendweek = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
    let weekupdate = `${week1update} - ${week2update}`
    let prevWeek = `${prestartweek}T00:00:00Z - ${preendweek}T00:00:00Z`
    setStartEnd(weekupdate)
    let weekOnMonth = moment(days[0], 'ddd MMM DD YYYY').format('M')
    let weekOnYear = moment(days[0], 'ddd MMM DD YYYY').format('YYYY')
    setStartMonthNumber(weekOnMonth)
    setStartYearNumber(weekOnYear)
    // setseletedDate(moment(startOfWeek))
    setseletedDate(moment(days[0]))
    setMon(
      String(new Date(days[0]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[0]).getMonth() + 1).padStart(2, '0')
    )
    setTue(
      String(new Date(days[1]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[1]).getMonth() + 1).padStart(2, '0')
    )
    setWed(
      String(new Date(days[2]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[2]).getMonth() + 1).padStart(2, '0')
    )
    setThu(
      String(new Date(days[3]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[3]).getMonth() + 1).padStart(2, '0')
    )
    setFri(
      String(new Date(days[4]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[4]).getMonth() + 1).padStart(2, '0')
    )
    setSat(
      String(new Date(days[5]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[5]).getMonth() + 1).padStart(2, '0')
    )
    setSun(
      String(new Date(days[6]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[6]).getMonth() + 1).padStart(2, '0')
    )
    getEmployeeProjectsList(user_id, startdate, enddate, DateArray)
  }
  type ButtonProps = React.HTMLProps<HTMLButtonElement>
  const [startDate, setStartDate] = useState(new Date())
  const ExampleCustomInput = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({value, onClick}, ref: any) => (
      <button className='btn btn-icon btn-light btn-sm  ms-2 ' onClick={onClick} ref={ref}>
        <img src={ButtonCal} className='buttonCal' />
      </button>
    )
  )
  //Calander Button XXXXXXX//

  function getDays(e: any) {
    var days = []
    if (e == 0) {
      setTimeTracker(moment())
    } else {
      timeTracker.add(e, 'weeks')
    }
    var startOfWeek = timeTracker.clone().startOf('isoWeek')
    var endOfWeek = timeTracker.clone().endOf('isoWeek')
    var days = []
    var day: any = startOfWeek
    while (day.isSameOrBefore(endOfWeek)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []
    let arr1 = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      let week2 = moment(each, 'ddd MMM DD YYYY').format('DD/MM/YYYY')
      arr.push(week1)
      arr1.push(week2)
      setWeekDates(arr)
    }
    setCurrentWeek(arr1)
    let DatesArray = arr
    let week1 = moment(days[0], 'ddd MMM DD YYYY').format('MMM DD')
    let prestartweek = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
    let week2 = moment(days[6], 'ddd MMM DD YYYY').format('MMM DD, YYYY')
    let preendweek = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
    var startdate = moment(days[0], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var enddate = moment(days[6], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    setWeekStartDate(startdate)
    setWeekEndDate(enddate)
    let week = `${week1} - ${week2}`
    let prevWeek = `${prestartweek}T00:00:00Z - ${preendweek}T00:00:00Z`
    setStartEnd(week)
    let weekOnMonth = moment(days[0], 'ddd MMM DD YYYY').format('M')
    let weekOnYear = moment(days[0], 'ddd MMM DD YYYY').format('YYYY')
    setStartMonthNumber(weekOnMonth)
    setStartYearNumber(weekOnYear)
    // setseletedDate(moment(startOfWeek))
    setseletedDate(moment(days[0]))
    setMon(
      String(new Date(days[0]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[0]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[0]).getFullYear())
    )
    setTue(
      String(new Date(days[1]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[1]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[1]).getFullYear())
    )
    setWed(
      String(new Date(days[2]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[2]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[2]).getFullYear())
    )
    setThu(
      String(new Date(days[3]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[3]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[3]).getFullYear())
    )
    setFri(
      String(new Date(days[4]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[4]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[4]).getFullYear())
    )
    setSat(
      String(new Date(days[5]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[5]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[5]).getFullYear())
    )
    setSun(
      String(new Date(days[6]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[6]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[6]).getFullYear())
    )
    getEmployeeProjectsList(user_id, startdate, enddate, DatesArray)
    return days.join('<br />')
  }

  const getWeekFun = async () => {
    setSumofAllProj([])
    setweekDayDataEntry([])
    ZeroAllTotalHours()
    setImagesArrayRes([])
    setEmployeesProjectList([])
    getDays(0)
  }

  const nextWeek = async () => {
    setWorkAllPopModifyMessage('')
    setWorkPopModifyMessage('')
    setSubmitButtonEnableDisableValidation([])
    setCopyButtonEnableDisableValidation([])
    setSumofAllProj([])
    setweekDayDataEntry([])
    setDocResultDataRes([])
    setEmployeesProjectList([])
    ZeroAllTotalHours()
    setImagesArrayRes([])
    getDays(1)
    setMonthViewDates([])
    setMonth_View(false)
    setMonthWeekView(false)
    setOtDisable(true)
  }

  const prevWeek = async () => {
    setWorkAllPopModifyMessage('')
    setWorkPopModifyMessage('')
    setSubmitButtonEnableDisableValidation([])
    setCopyButtonEnableDisableValidation([])
    setweekDayDataEntry([])
    setSumofAllProj([])
    setDocResultDataRes([])
    setEmployeesProjectList([])
    ZeroAllTotalHours()
    setImagesArrayRes([])
    getDays(-1)
    setMonthViewDates([])
    setMonth_View(false)
    setMonthWeekView(false)
    setOtDisable(true)
  }

  const singleDateSelection = async (date: any) => {
    setEmployeesProjectList([])
    setCopyButtonEnableDisableValidation([])
    setDocResultDataRes([])
    setMonthWeekView(false)
    setMonthViewDates([])
    var today = moment(date)
    var startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD')
    var endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD')
    let week1 = moment(startOfWeek, 'YYYY-MM-DD').format('MMM DD')
    let week2 = moment(endOfWeek, 'YYYY-MM-DD').format('MMM DD, YYYY')
    let weeks = `${week1} - ${week2}`
    setStartEnd(weeks)
    let weekOnMonth = moment(startOfWeek, 'YYYY-MM-DD').format('M')
    let weekOnYear = moment(startOfWeek, 'YYYY-MM-DD').format('YYYY')
    setStartMonthNumber(weekOnMonth)
    setStartYearNumber(weekOnYear)
    setseletedDate(moment(startOfWeek))
    setTimeTracker(moment(startOfWeek))
    var start = moment(startOfWeek).clone().startOf('isoWeek')
    var end = moment(endOfWeek).clone().endOf('isoWeek')
    var days = []
    var day: any = start
    while (day.isSameOrBefore(end)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []
    let arr1 = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      let week2 = moment(each, 'ddd MMM DD YYYY').format('DD/MM/YYYY')
      arr.push(week1)
      arr1.push(week2)
      setWeekDates(arr)
    }
    setCurrentWeek(arr1)
    let DateArray = arr
    var startdate = moment(days[0], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var enddate = moment(days[6], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    setMon(
      String(new Date(days[0]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[0]).getMonth() + 1).padStart(2, '0')
    )
    setTue(
      String(new Date(days[1]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[1]).getMonth() + 1).padStart(2, '0')
    )
    setWed(
      String(new Date(days[2]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[2]).getMonth() + 1).padStart(2, '0')
    )
    setThu(
      String(new Date(days[3]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[3]).getMonth() + 1).padStart(2, '0')
    )
    setFri(
      String(new Date(days[4]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[4]).getMonth() + 1).padStart(2, '0')
    )
    setSat(
      String(new Date(days[5]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[5]).getMonth() + 1).padStart(2, '0')
    )
    setSun(
      String(new Date(days[6]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[6]).getMonth() + 1).padStart(2, '0')
    )
    getEmployeeProjectsList(user_id, startdate, enddate, DateArray)
    return days.join('<br />')
  }

  const ZeroAllTotalHours = () => {
    setWeekHourDataSubmit({
      ...weekHourDataSubmit,
      pending: 0,
      submit: 0,
      rejected: 0,
      approve: 0,
      headerTotalHours: 0,
    })
  }

  const ApplyVacation = async (vacation_type: any, startDate: any, endDate: any) => {
    var days = []
    var day: any = moment(startDate)
    while (day.isSameOrBefore(endDate)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr.push(week1)
    }
    let DatesArray = arr
    const article = {
      user_id: Number(user_id),
      absent_type: Number(vacation_type),
      dates: DatesArray,
    }
    setApplyPopupOpen(false)
    const response: any = await Apiservices.applyLeavesHolidays(article)
    if (response.statuscode == 200) {
      getEmployeeProjectsList(user_id, weekStartDate, weekEndDate, weekDates)
    }
  }
  const ApplyLeavePopUpOpen = () => {
    setApplyPopupOpen(true)
  }
  const ApplyLeavePopUpClose = () => {
    setApplyPopupOpen(false)
  }
  const monthView = async () => {
    setShowBackDrop(true)
    setmonthYear('')
    setMonthName('')
    setYearName('')
    setMonthWeekView(!month_week_view)
    if (month_week_view) {
      setMonthViewDates([])
      let week1 = moment(monthStartDate, 'YYYY-MM-DD').format('MMM DD')
      let week2 = moment(monthEndDate, 'YYYY-MM-DD').format('MMM DD, YYYY')
      let weeks = `${week1} - ${week2}`
      setStartEnd(weeks)
      let weekOnMonth = moment(monthStartDate, 'YYYY-MM-DD').format('M')
      let weekOnYear = moment(monthStartDate, 'YYYY-MM-DD').format('YYYY')
      setStartMonthNumber(weekOnMonth)
      setStartYearNumber(weekOnYear)
      setseletedDate(moment(monthStartDate))
      setTimeTracker(moment(monthStartDate))
      var start = moment(monthStartDate).clone().startOf('isoWeek')
      var end = moment(monthEndDate).clone().endOf('isoWeek')
      var days = []
      var day: any = start
      while (day.isSameOrBefore(end)) {
        days.push(day.toDate())
        day = day.add(1, 'days')
      }
      let arr = []
      let arr1 = []
      for (let each of days) {
        let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
        let week2 = moment(each, 'ddd MMM DD YYYY').format('DD/MM/YYYY')
        arr.push(week1)
        arr1.push(week2)
        setWeekDates(arr)
      }
      setCurrentWeek(arr1)
      let DateArray = arr
      var startdate = moment(days[0], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      var enddate = moment(days[6], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      setMon(
        String(new Date(days[0]).getDate()).padStart(2, '0') +
          '/' +
          String(new Date(days[0]).getMonth() + 1).padStart(2, '0')
      )
      setTue(
        String(new Date(days[1]).getDate()).padStart(2, '0') +
          '/' +
          String(new Date(days[1]).getMonth() + 1).padStart(2, '0')
      )
      setWed(
        String(new Date(days[2]).getDate()).padStart(2, '0') +
          '/' +
          String(new Date(days[2]).getMonth() + 1).padStart(2, '0')
      )
      setThu(
        String(new Date(days[3]).getDate()).padStart(2, '0') +
          '/' +
          String(new Date(days[3]).getMonth() + 1).padStart(2, '0')
      )
      setFri(
        String(new Date(days[4]).getDate()).padStart(2, '0') +
          '/' +
          String(new Date(days[4]).getMonth() + 1).padStart(2, '0')
      )
      setSat(
        String(new Date(days[5]).getDate()).padStart(2, '0') +
          '/' +
          String(new Date(days[5]).getMonth() + 1).padStart(2, '0')
      )
      setSun(
        String(new Date(days[6]).getDate()).padStart(2, '0') +
          '/' +
          String(new Date(days[6]).getMonth() + 1).padStart(2, '0')
      )
      getEmployeeProjectsList(user_id, startdate, enddate, DateArray)
      return days.join('<br />')
    } else {
      // let month = moment(new Date()).format('MMMM')
      let month = moment(startMonthNumber, 'M').format('MMMM')
      // let year = moment(new Date()).format('YYYY')
      let year = moment(startYearNumber, 'YYYY').format('YYYY')
      const monthNo = moment().month() + 1
      const present = `${month} ${year}`
      setmonthYear(present)
      setMonthName(month)
      setYearName(year)
      monthViewNextAndPrev(startMonthNumber, startYearNumber)
      setEmployeesProjectList([])
    }
    setShowBackDrop(false)
  }

  const monthViewNextAndPrev = async (month: any, year: any) => {
    setShowBackDrop(true)
    let SelectedMonth = year + '-' + month + '-' + 1
    let SelectedMonth2 = year + '-' + month + '-' + 30
    if (
      new Date(SelectedMonth).getTime() <
      new Date(moment().subtract(disableCalendarDays, 'days').format('YYYY-MM-DD')).getTime()
    ) {
      setDisableMonthLeftArrow(true)
    } else {
      setDisableMonthLeftArrow(false)
    }
    if (
      new Date(SelectedMonth2).getTime() >
      new Date(moment().add(disableCalendarDays, 'days').format('YYYY-MM-DD')).getTime()
    ) {
      setDisableMonthRightArrow(true)
    } else {
      setDisableMonthRightArrow(false)
    }

    const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      user_id: user_id,
      year: year,
      month: month,
    })
    setEmployeesProjectList([])
    if (data.status == 200) {
      let start = data.data.data[0].week_start_date
      let end = data.data.data[0].week_end_date
      setMonthStartDate(start)
      setMonthEndDate(end)
      let month_data = [data.data.data]
      let month_length = Object(month_data[0])
      const keys = Object.values(month_data)
      setMonthViewDates(month_data)
      setTotalMonthViewHours(data.data.hours_data.final_total_hm)
      setTotalMonthViewStatus(data.data.status_wise_hours_data)

      setMonth_View(true)
    }
    setShowBackDrop(false)
  }

  const closeSKillPopup = () => {
    setShowSkillsRequiredPopup(false)
  }

  const copyLastWeek = async (
    projectIndex: any,
    popup: any,
    employeesProjectList1: any,
    newDocResultDataRes1: any
  ) => {
    // let userIdData5 =
    //   localStorage.getItem('role_Name') === 'timesheetadmin'
    //     ? localStorage.getItem('time_sheet_admin_screen_user_id')
    //     : localStorage.getItem('userid')
    let state2: any = location.state
    let userIdData5 =
      state2?.names == 'create'
        ? localStorage.getItem('time_sheet_admin_screen_user_id')
        : localStorage.getItem('userid')
    const getProjectList11: any = await Apiservices.getCopyLastWeekHoursData({
      user_id: userIdData5,
      week_start_date: weekStartDate,
      week_end_date: weekEndDate,
    })
    let get_org_id = localStorage.getItem('org_id')
    const resAllowedPastEntryDays: any = await Apiservices.getOrganizationSetting({
      org_id: get_org_id,
    })

    let CalDisableDays = resAllowedPastEntryDays?.data?.data[0]?.allowed_past_entry_days
      ? resAllowedPastEntryDays?.data?.data[0]?.allowed_past_entry_days
      : 180
    var TimesheetWorkLocation = resAllowedPastEntryDays?.data?.data[0]?.timesheet_work_location
    setDisableWorkLocation(TimesheetWorkLocation)
    let regular =
      resAllowedPastEntryDays &&
      resAllowedPastEntryDays.data &&
      resAllowedPastEntryDays.data.data &&
      resAllowedPastEntryDays.data.data[0] &&
      resAllowedPastEntryDays.data.data[0].max_regular_hours
    setRegularHoursEntry(regular)
    if (regular) {
      setOTHoursEntry(24 - regular)
    }
    setdisableCalendarDays(CalDisableDays)

    if (getProjectList11?.data?.message == 'No Records Found') {
      setEmployeesProjectList([])
      setShowBackDrop(false)
    } else {
      // if (popup != 'okay') {
      //   setCopyProjectMatchingPrevWeek(getProjectList11?.data?.data)
      //   setCopyProjectMatching(true)
      // }

      const newWeek = employeesProjectList1
      let oldWeek = getProjectList11?.data?.data
      /* Replacing old week details*/
      // for (var j = 0; j < projectIndex?.length; j++) {
      let projIndex = oldWeek.findIndex((temp: any) => temp.id == projectIndex)
      let NewprojIndex = newWeek.findIndex((temp: any) => temp.id == projectIndex)

      if (projIndex >= 0) {
        newWeek[NewprojIndex].timesheet_data = oldWeek[projIndex].timesheet_data

        newWeek[NewprojIndex].timesheet_data.map((singleDay: any, index: any) => {
          singleDay.status = ''
          singleDay.id = ''
          singleDay.absent_type = null
          singleDay.absent_hours = 0
          singleDay.absent_minutes = 0
          return singleDay
        })

        if (numArray?.includes('timesheetadmin')) {
          newWeek[NewprojIndex].ts_user_notes = oldWeek[projIndex].ts_user_notes
        }
      }
      // }
      /* xxxxxx Replacing old week details xxxxxxxx*/

      // if (popup == 'okay') {
      const projectData = newWeek
      setEmployeesProjectList(projectData)
      setHeaderHours(getProjectList11.data.hours_data)
      let forSingleProjectValidation: any = []
      let forSingleProjectWorkLocationValidation: any = []
      let otLeaveHolidayStatus: any = []
      for (var i = 0; i < projectData?.length; i++) {
        var forSingleProject: any = {
          monday_ott: false,
          tuesday_ott: false,
          wednesday_ott: false,
          thursday_ott: false,
          friday_ott: false,
          saturday_ott: false,
          sunday_ott: false,
          monday_leavet: false,
          tuesday_leavet: false,
          wednesday_leavet: false,
          thursday_leavet: false,
          friday_leavet: false,
          saturday_leavet: false,
          sunday_leavet: false,
          monday_holidayt: false,
          tuesday_holidayt: false,
          wednesday_holidayt: false,
          thursday_holidayt: false,
          friday_holidayt: false,
          saturday_holidayt: false,
          sunday_holidayt: false,
          otdisable: true,
        }
        otLeaveHolidayStatus.push(forSingleProject)
        var forSingleProjectVal: any = {
          cmValidation: false,
          ccValidation: false,
          ucValidation: false,
          ceValidation: false,
        }
        forSingleProjectValidation.push(forSingleProjectVal)
        var forSingleProjectValidate: any = {
          countryValidation: false,
          stateValidation: false,
          cityValidation: false,
        }
        forSingleProjectWorkLocationValidation.push(forSingleProjectValidate)
      }

      setClientManagerNameValidation(forSingleProjectValidation)
      setWorkLocationValidation(forSingleProjectWorkLocationValidation)
      let workCountry = []
      let workState1 = []
      if (projectData?.length > 0) {
        var ProjDetailsArray = []
        for (let p = 0; p < projectData?.length; p++) {
          let data = [
            {
              name: TimesheetWorkLocation
                ? projectData && projectData[p] && projectData[p].ts_work_country
                  ? projectData[p].ts_work_country
                  : projectData[p].work_country
                  ? projectData[p].work_country
                  : ''
                : null,
            },
          ]
          let data1 = [
            {
              name: TimesheetWorkLocation
                ? projectData && projectData[p] && projectData[p].ts_work_state
                  ? projectData[p].ts_work_state
                  : projectData[p].work_state
                  ? projectData[p].work_state
                  : ''
                : null,
            },
          ]
          if (projectData[p].timesheet_data !== null) {
            var projDetails: any = {
              user_id: Number(user_id),
              project_id: projectData[p].id,
              year: parseInt(moment(weekStartDate).format('YYYY')),
              month: parseInt(moment(weekStartDate).format('MM')),
              week_start_date: weekStartDate,
              week_end_date: weekEndDate,
              user_notes: numArray?.includes('timesheetadmin')
                ? projectData[p].ts_user_notes
                : null,
              approver_id: null,
              approver_notes: null,
              client_manager_email: projectData[p].client_manager_email
                ? projectData[p].client_manager_email
                : projectData[p].ts_client_manager_email,
              client_manager_name: projectData[p].client_manager_name
                ? projectData[p].client_manager_name
                : projectData[p].ts_client_manager_name,
              // ts_approver_notes: projectData[p]?.ts_approver_notes,
              ts_status: projectData[p].ts_status,
              work_country: data[0].name,
              work_state: data1[0].name,
              work_city: TimesheetWorkLocation
                ? projectData[p].ts_work_city
                  ? projectData[p].ts_work_city
                  : projectData[p].work_city
                : null,
              ot_product: projectData[p].ot_product,
            }
            var ProjObj: any = []
            var Days = [
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
              'Sunday',
            ]
            Days.forEach((e: any, index: any) => {
              ProjObj.push({
                timesheet_date: weekDates[index],
                regular_hours: 0,
                regular_minutes: 0,
                ot_hours: 0,
                ot_minutes: 0,
                absent_type: null,
                absent_hours: 0,
                absent_minutes: 0,
                status: '',
                qb_timesheet_id: null,
                qb_status: null,
              })
            })
            Days.forEach((Day: any, i: any) => {
              projectData[p]?.timesheet_data.forEach((data: any, j: any) => {
                if (Day == data.day_name) {
                  let regularMinutes: any =
                    projectData[p]?.timesheet_data[j]?.regular_minutes <= 9
                      ? '0' + projectData[p]?.timesheet_data[j]?.regular_minutes
                      : projectData[p]?.timesheet_data[j]?.regular_minutes
                  let absentMinutes: any =
                    projectData[p]?.timesheet_data[j]?.absent_minutes <= 9
                      ? '0' + projectData[p]?.timesheet_data[j]?.absent_minutes
                      : projectData[p]?.timesheet_data[j]?.absent_minutes
                  let otMinutes: any =
                    projectData[p]?.timesheet_data[j]?.ot_minutes <= 9
                      ? '0' + projectData[p]?.timesheet_data[j]?.ot_minutes
                      : projectData[p]?.timesheet_data[j]?.ot_minutes
                  ProjObj[i] = {
                    id: projectData[p]?.timesheet_data[j]?.id,
                    // timesheet_date: projectData[p]?.timesheet_data[j]?.timesheet_date,
                    timesheet_date: weekDates[j],

                    regular_hours:
                      projectData[p]?.timesheet_data[j]?.regular_hours + ':' + regularMinutes,
                    regular_minutes: projectData[p]?.timesheet_data[j]?.regular_minutes,
                    ot_hours: projectData[p]?.timesheet_data[j]?.ot_hours + ':' + otMinutes,
                    ot_minutes: projectData[p]?.timesheet_data[j]?.ot_minutes,
                    absent_type: projectData[p]?.timesheet_data[j]?.absent_type,
                    absent_hours:
                      projectData[p]?.timesheet_data[j]?.absent_hours + ':' + absentMinutes,
                    absent_minutes: projectData[p]?.timesheet_data[j]?.absent_minutes,
                    status: projectData[p]?.timesheet_data[j]?.status,
                    qb_timesheet_id: projectData[p]?.timesheet_data[j]?.qb_timesheet_id,
                    qb_status: projectData[p]?.timesheet_data[j]?.qb_status,
                    qb_invoice_id: projectData[p]?.timesheet_data[j]?.qb_invoice_id,
                    // approver_notes: projectData[p]?.timesheet_data[j]?.approver_notes,
                  }
                }
              })
            })
            projDetails.timesheet_data = ProjObj
            ProjDetailsArray.push(projDetails)
            setweekDayDataEntry([...ProjDetailsArray])
          } else {
            var projDetails: any = {
              user_id: Number(user_id),
              project_id: projectData[p].id,
              year: parseInt(moment(weekStartDate).format('YYYY')),
              month: parseInt(moment(weekStartDate).format('MM')),
              week_start_date: weekStartDate,
              week_end_date: weekEndDate,
              user_notes: '',
              approver_id: null,
              approver_notes: null,
              client_manager_email: projectData[p].client_manager_email,
              client_manager_name: projectData[p].client_manager_name,
              work_country: data[0].name,
              work_state: data1[0].name,
              work_city: TimesheetWorkLocation
                ? projectData[p].ts_work_city
                  ? projectData[p].ts_work_city
                  : projectData[p].work_city
                : null,
              ot_product: projectData[p].ot_product,
            }
            var ProjObj: any = []
            for (let i = 0; i < 7; i++) {
              ProjObj.push({
                timesheet_date: weekDates[i],
                regular_hours: 0,
                regular_minutes: 0,
                ot_hours: 0,
                ot_minutes: 0,
                absent_type: null,
                absent_hours: 0,
                absent_minutes: 0,
                status: '',
                qb_timesheet_id: null,
                qb_status: null,
              })
            }
            projDetails.timesheet_data = ProjObj
            ProjDetailsArray.push(projDetails)
          }
          workCountry.push(data)
          setCountries(workCountry)
          workState1.push(data1)
          setStates(workState1)
        }
        /*Adding dates to the days */
        for (let x = 0; x < ProjDetailsArray?.length; x++) {
          for (let y = 0; y < ProjDetailsArray[x].timesheet_data.length; y++) {
            ProjDetailsArray[x].timesheet_data[y].timesheet_date = weekDates[y]
          }
        }
        /*xxxxxxxxxxxxxAdding dates to the days xxxxxxxxxx*/

        for (let p = 0; p < projectData?.length; p++) {
          for (let j = 0; j < ProjDetailsArray[p]?.timesheet_data?.length; j++) {
            if (ProjDetailsArray[p]?.timesheet_data[j]?.absent_type == 1) {
              var Holiday = [
                'monday_holidayt',
                'tuesday_holidayt',
                'wednesday_holidayt',
                'thursday_holidayt',
                'friday_holidayt',
                'saturday_holidayt',
                'sunday_holidayt',
              ]
              otLeaveHolidayStatus[p][Holiday[j]] = false
              setWeekDayStatus([...otLeaveHolidayStatus])
            }
            if (ProjDetailsArray[p]?.timesheet_data[j]?.absent_type == 2) {
              var Leave = [
                'monday_leavet',
                'tuesday_leavet',
                'wednesday_leavet',
                'thursday_leavet',
                'friday_leavet',
                'saturday_leavet',
                'sunday_leavet',
              ]
              otLeaveHolidayStatus[p][Leave[j]] = false
              setWeekDayStatus([...otLeaveHolidayStatus])
            }
            if (
              parseInt(ProjDetailsArray[p]?.timesheet_data[j]?.ot_hours) ||
              parseInt(ProjDetailsArray[p]?.timesheet_data[j]?.ot_minutes)
            ) {
              var OT = [
                'monday_ott',
                'tuesday_ott',
                'wednesday_ott',
                'thursday_ott',
                'friday_ott',
                'saturday_ott',
                'sunday_ott',
              ]
              otLeaveHolidayStatus[p][OT[j]] = true
              setWeekDayStatus([...otLeaveHolidayStatus])
            }
          }
        }

        otfieldsdisabledValidation.length = 0
        for (let p = 0; p < projectData?.length; p++) {
          let weekOtFieldsDisable = []
          for (let j = 0; j < ProjDetailsArray[p]?.timesheet_data?.length; j++) {
            if (ProjDetailsArray[p]?.timesheet_data[j]?.regular_hours) {
              let splitRegularHours1 = {...ProjDetailsArray[p]}
              let splitted = splitRegularHours1?.timesheet_data[j]?.regular_hours.split(':')
              let totaltime = splitted[0] * 60 + parseInt(splitted[1])
              if (totaltime > 0) {
                weekOtFieldsDisable.push(false)
              } else {
                weekOtFieldsDisable.push(true)
              }
            } else {
              weekOtFieldsDisable.push(true)
            }
          }
          otfieldsdisabledValidation.push(weekOtFieldsDisable)
        }
        setotfieldsdisabledValidation(otfieldsdisabledValidation)

        /**** removal code due to single week fn not available ******* */
        // for (let j = 0; j < ProjDetailsArray.length; j++) {
        //   ProjDetailsArray[j].timesheet_data.map((singleDay: any, index: any) => {
        //     singleDay.status = ''
        //     singleDay.id = ''
        //     return singleDay
        //   })
        // }
        // for (let i = 0; i < ProjDetailsArray.length; i++) {
        //   ProjDetailsArray.map((singleDay: any, index: any) => {
        //     singleDay.ts_status = null
        //     return singleDay
        //   })
        // }
        // for (let i = 0; i < ProjDetailsArray.length; i++) {
        //   ProjDetailsArray[i].timesheet_data.map((singleDay: any, index: any) => {
        //     singleDay.absent_type = null
        //     singleDay.absent_hours = 0
        //     singleDay.absent_minutes = 0
        //     return singleDay
        //   })
        // }

        /**** removal code ******* */

        statusForSubmitALL.length = 0
        statusForUnSubmitALL.length = 0
        for (let i = 0; i < ProjDetailsArray.length; i++) {
          let Status =
            ProjDetailsArray[i]?.timesheet_data.find((item: any) => item.status == '') ||
            ProjDetailsArray[i]?.timesheet_data.find((item: any) => item.status == 'REJECTED') ||
            ProjDetailsArray[i]?.timesheet_data.find((item: any) => item.status == 'SAVED')
              ? true
              : false
          statusForSubmitALL.push(Status)
          let UnsubmitStatus = ProjDetailsArray[i]?.timesheet_data.find(
            (item: any) => item.status == 'SUBMITTED'
          )
            ? true
            : false
          statusForUnSubmitALL.push(UnsubmitStatus)
        }
        submitButtonEnableDisableValidation.length = 0
        let ForAllProjects: any = []
        ProjDetailsArray.map((singleProject: any, index: any) => {
          let ForSingleProject: any = []
          if (singleProject?.timesheet_data.find((item: any) => item.status == 'SAVED')) {
            ForSingleProject = []
          } else {
            singleProject.timesheet_data.map((singleDay: any, index: any) => {
              if (singleDay.status == '' || singleDay.status == 'REJECTED') {
                ForSingleProject.push(false)
              }
            })
          }

          ForAllProjects.push(ForSingleProject)
        })
        ForAllProjects.map((eachProject: any, index: any) => {
          if (eachProject.length > 0) {
            submitButtonEnableDisableValidation.push(true)
          } else {
            submitButtonEnableDisableValidation.push(false)
          }
        })
        // for (let j = 0; j < ProjDetailsArray.length; j++) {
        //   ProjDetailsArray[j].timesheet_data.map((singleDay: any, index: any) => {
        //     singleDay.status = ''
        //     singleDay.id = ''
        //     return singleDay
        //   })
        // }
        // for (let i = 0; i < ProjDetailsArray.length; i++) {
        //   ProjDetailsArray.map((singleDay: any, index: any) => {
        //     singleDay.ts_status = null
        //     return singleDay
        //   })
        // }
        // setweekDayDataEntry([])

        setSubmitButtonEnableDisableValidation([...submitButtonEnableDisableValidation])
        setSubmitButtonEnableDisable([...ForAllProjects])
        setStatusForSubmitALL(statusForSubmitALL)
        setStatusForUnSubmitALL(statusForUnSubmitALL)
        setweekDayDataEntry(ProjDetailsArray)
        setWeekDayStatus([...otLeaveHolidayStatus])
        sumOfTotalHours(ProjDetailsArray)
        enableDisableSubmitButton(ProjDetailsArray)
      }
      if (projectData?.length > 0) {
        // let userIdData5 =
        //   localStorage.getItem('role_Name') === 'timesheetadmin'
        //     ? localStorage.getItem('time_sheet_admin_screen_user_id')
        //     : localStorage.getItem('userid')
        let state2: any = location.state
        let userIdData5 =
          state2?.names == 'create'
            ? localStorage.getItem('time_sheet_admin_screen_user_id')
            : localStorage.getItem('userid')

        const getcountries: any = await Apiservices.getallcountries()
        for (let p = 0; p < projectData?.length; p++) {
          var work_Country_id =
            projectData && projectData[p] && projectData[p].ts_work_country
              ? projectData[p].ts_work_country
              : projectData[p].work_country && projectData[p].work_country
          if (work_Country_id) {
            StateFielddisabled[p] = false
            let stateName1 = getcountries?.data?.data.filter(
              (temp: any) => temp?.name == work_Country_id
            )
            if (stateName1) {
              SelectedCountry[p] = stateName1[0]?.id
              setSelectedCountry([...SelectedCountry])
              setUseStateCountryId(stateName1[0]?.id)
              const response: any = await Apiservices.getstatebyname({
                country_id: stateName1[0]?.id,
              })
              if (response?.data?.data) {
                WorkStatesBasedOnCountry.push(response.data.data)
                setWorkStatesBasedOnCountry([...WorkStatesBasedOnCountry])
                setState(WorkStatesBasedOnCountry)
                // setState(response.data.data ? response.data.data : '')
              } else {
                WorkStatesBasedOnCountry.push([])
                setWorkStatesBasedOnCountry([...WorkStatesBasedOnCountry])
                setState(WorkStatesBasedOnCountry)
                // setState([])
              }
            }
          } else {
            StateFielddisabled[p] = true
          }
          setStateFielddisabled([...StateFielddisabled])
        }
        if (numArray?.includes('timesheetadmin')) {
          const getImages = await Apiservices.docNameIdProjectList1({
            // week_start_date: weekStartDate,
            week_start_date: moment(weekStartDate)
              .subtract(1, 'week')
              .startOf('isoWeek')
              .format('YYYY-MM-DD'),
            // week_end_date: weekEndDate,
            week_end_date: moment(weekEndDate)
              .subtract(1, 'week')
              .endOf('isoWeek')
              .format('YYYY-MM-DD'),
            user_id: userIdData5,
          })
          // setDocResultDataRes(getImages?.data?.data)
          let projId = getImages?.data?.data?.filter((temp: any) => {
            return temp.project_id == projectIndex
          })
          let old_data = projId?.map((doc: any) => {
            return {...doc, previous_doc: true}
          })

          if (
            newDocResultDataRes1 &&
            newDocResultDataRes1.length > 0 &&
            old_data &&
            old_data.length > 0
          ) {
            var result1 = [...old_data, ...newDocResultDataRes1]
            var test1 = result1?.filter((obj, index) => {
              return index === result1?.findIndex((o) => obj.id === o.id)
            })
          } else if (newDocResultDataRes1?.length == null && old_data?.length == null) {
            // var test1 = [...old_data]
            var test1 = []
          } else if (
            old_data &&
            old_data?.length > 0 &&
            (newDocResultDataRes1?.length == null ||
              newDocResultDataRes1?.length == 0 ||
              newDocResultDataRes1 == undefined)
          ) {
            var test1 = [...old_data]
          } else {
            var test1 = [...newDocResultDataRes1]
          }
          // setDocResultDataRes(old_data)
          // setPrevAr(old_data)
          setDocResultDataRes(test1)
          if (old_data) {
            setPrevAr([...old_data])
          } else {
            setPrevAr([])
          }

          // let new_Data = [...old_Data, ...newDocResultDataRes1]
          // setDocResultDataRes(new_Data)
          // setPrevAr(new_Data)
        }
      }
      // let get_org_id = localStorage.getItem('org_id')
      // const resAllowedPastEntryDays: any = await Apiservices.getOrganizationSetting({
      //   org_id: get_org_id,
      // })
      // let CalDisableDays = resAllowedPastEntryDays?.data?.data[0]?.allowed_past_entry_days
      //   ? resAllowedPastEntryDays?.data?.data[0]?.allowed_past_entry_days
      //   : 180
      // let regular =
      //   resAllowedPastEntryDays &&
      //   resAllowedPastEntryDays.data &&
      //   resAllowedPastEntryDays.data.data &&
      //   resAllowedPastEntryDays.data.data[0] &&
      //   resAllowedPastEntryDays.data.data[0].max_regular_hours
      // setRegularHoursEntry(regular)
      // if (regular) {
      //   setOTHoursEntry(24 - regular)
      // }
      // setdisableCalendarDays(CalDisableDays)
      projectStartDateDisable.length = 0
      let AllProjectStartDateDisable: any = []
      if (projectData?.length > 0) {
        projectData.map((eachProject: any, index: any) => {
          let DayFieldEntryDisable = eachProject.joining_date
            ? eachProject.joining_date
            : eachProject.start_date
          let SingleProjectStartDateDisable: any = []
          weekDates.map((eachDay: any, dayIndex: any) => {
            if (
              new Date(DayFieldEntryDisable).getTime() >
              new Date(moment().subtract(CalDisableDays, 'days').format('YYYY-MM-DD')).getTime()
            ) {
              if (new Date(DayFieldEntryDisable).getTime() > new Date(eachDay).getTime()) {
                SingleProjectStartDateDisable.push(true)
              } else {
                SingleProjectStartDateDisable.push(false)
              }
            } else {
              if (
                new Date(moment().subtract(CalDisableDays, 'days').format('YYYY-MM-DD')).getTime() >
                new Date(eachDay).getTime()
              ) {
                SingleProjectStartDateDisable.push(true)
              } else {
                SingleProjectStartDateDisable.push(false)
              }
            }
          })
          AllProjectStartDateDisable.push(SingleProjectStartDateDisable)
        })
        setProjectStartDateDisable(AllProjectStartDateDisable)
      }
      let CalDisableEndDays = 30
      projectEndDateDisable.length = 0
      let AllProjectsEndDateDisable: any = []
      if (projectData?.length > 0) {
        projectData.map((eachProject: any, index: any) => {
          let SingleProjectEndDateDisable: any = []
          weekDates.map((eachDay: any, dayIndex: any) => {
            // let endAndCancelDate =
            // eachProject.project_status === 'COMPLETED'
            //   ? eachProject.end_date
            //   : eachProject.cancel_date

            let endAndCancelDate =
              eachProject.project_status &&
              (eachProject.project_status === 'COMPLETED' ||
                eachProject.project_status === 'CANCELLED')
                ? eachProject.project_status === 'COMPLETED' ||
                  eachProject.project_status === 'CANCELLED'
                  ? eachProject.end_date
                  : eachProject.cancel_date
                : ''
            if (endAndCancelDate) {
              if (
                new Date(endAndCancelDate).getTime() <
                new Date(moment().add(CalDisableEndDays, 'days').format('YYYY-MM-DD')).getTime()
              ) {
                if (new Date(endAndCancelDate).getTime() < new Date(eachDay).getTime()) {
                  SingleProjectEndDateDisable.push(true)
                } else {
                  SingleProjectEndDateDisable.push(false)
                }
              } else {
                if (
                  new Date(moment().add(CalDisableEndDays, 'days').format('YYYY-MM-DD')).getTime() <
                  new Date(eachDay).getTime()
                ) {
                  SingleProjectEndDateDisable.push(true)
                } else {
                  SingleProjectEndDateDisable.push(false)
                }
              }
            } else if (
              new Date(moment().add(CalDisableEndDays, 'days').format('YYYY-MM-DD')).getTime() <
              new Date(eachDay).getTime()
            ) {
              SingleProjectEndDateDisable.push(true)
            } else {
              SingleProjectEndDateDisable.push(false)
            }
          })
          AllProjectsEndDateDisable.push(SingleProjectEndDateDisable)
        })
        setProjectEndDateDisable(AllProjectsEndDateDisable)
      }

      if (
        new Date(weekDates[0]).getTime() <=
        new Date(moment().subtract(CalDisableDays, 'days').format('YYYY-MM-DD')).getTime()
      ) {
        setDisableLeftArrow(true)
      } else {
        setDisableLeftArrow(false)
      }
      if (
        new Date(weekDates[6]).getTime() >=
        new Date(moment().add(CalDisableDays, 'days').format('YYYY-MM-DD')).getTime()
      ) {
        setDisableRightArrow(true)
      } else {
        setDisableRightArrow(false)
      }
      setShowBackDrop(false)
      // }
      // else {
      //   setCopyProjectMatching(true)
      //   // toast.warn('Projects are not matching')
      // }
      // }
    }
  }

  const getCopyProjectNotMatchingPopup = (projectIndex: any) => {
    setCopyProjectMatching(false)
    copyLastWeek(projectIndex, 'okay', employeesProjectList, docResultDataRes)
  }

  const getDisableStatusofBenchProject = (currentProjet: any, day: any) => {
    if (employeesProjectList?.length > 0) {
      const Completedplacement = employeesProjectList?.filter(
        (proj: any) => proj?.is_placement_project === 1 && proj?.project_status === 'COMPLETED'
      )
      //filter to check whether the placement projects exists or not
      const placement = employeesProjectList?.filter(
        (proj: any) => proj?.is_placement_project === 1
      )
      let formattedDate:any=null
      if(placement?.length>0){
      const highestDateObject = placement?.reduce((max: any, current: any) => {
        // Convert date strings to Date objects for comparison
        const maxDate = new Date(max?.end_date)
        const currentDate = new Date(current?.end_date)
        return currentDate > maxDate ? current : max
      }, 0)
      let date = new Date(highestDateObject?.end_date)
      let day1 = ('0' + date?.getDate())?.slice(-2) // Pad single digit days with a leading zero
      let month = ('0' + (date?.getMonth() + 1))?.slice(-2) // Pad single digit months with a leading zero
      let year = date?.getFullYear()

      // Final formatted date
      formattedDate = `${day1}/${month}/${year}`
    }
      if (day && currentProjet === 2) {
        if (employeesProjectList?.length === 1) {
          return false
        } else {
          let resp
          if (currentProjet === 2 && placement?.length > 0) {
            //find whether placement project completed or not
            let completedProjects = employeesProjectList?.filter(
              (proj: any) => proj?.project_status === 'COMPLETED'
            )
            //condition to check all placement projects completed or not
            //if all are completed then bench project timesheet enble to add timesheet entry
            if (completedProjects?.length > 0 && completedProjects?.length === placement?.length) {
              if (day && formattedDate >= day) {
                resp = true
              } else {
                resp = false
              }
            } else {
              resp = true
            }
          } else {
            resp = false
          }
          return resp
        }
      } else {
        let resp
        if (currentProjet === 2 && Completedplacement?.length == placement?.length&&formattedDate) {
          currentWeek?.map((week: any) => {
            if (formattedDate >= week) {
              resp = true
            } else {
              resp = false
            }
          })
        } else {
          resp = false
        }
        return resp
      }
    }
  }

  const getCopyProjectNotMatchingClosePopup = () => {
    setCopyProjectMatching(false)
  }

  const getEmployeeProjectsList = async (
    user_id: any,
    startdate: any,
    enddate: any,
    dates: any
  ) => {
    setWeekStartDate(startdate)
    setWeekEndDate(enddate)
    setShowBackDrop(true)
    const getProjectList: any = await Apiservices.getSingleWeekTimesheet({
      user_id: user_id,
      week_start_date: startdate,
      week_end_date: enddate,
    })
    const getProjectList11: any = await Apiservices.getCopyLastWeekHoursData({
      user_id: user_id,
      week_start_date: startdate,
      week_end_date: enddate,
    })
    let get_org_id = localStorage.getItem('org_id')
    const resAllowedPastEntryDays: any = await Apiservices.getOrganizationSetting({
      org_id: get_org_id,
    })
    let CalDisableDays = resAllowedPastEntryDays?.data?.data[0]?.allowed_past_entry_days
      ? resAllowedPastEntryDays?.data?.data[0]?.allowed_past_entry_days
      : 180
    var TimesheetWorkLocation = resAllowedPastEntryDays?.data?.data[0]?.timesheet_work_location
    setDisableWorkLocation(TimesheetWorkLocation)
    let regular =
      resAllowedPastEntryDays &&
      resAllowedPastEntryDays.data &&
      resAllowedPastEntryDays.data.data &&
      resAllowedPastEntryDays.data.data[0] &&
      resAllowedPastEntryDays.data.data[0].max_regular_hours
    setRegularHoursEntry(regular)
    if (regular) {
      setOTHoursEntry(24 - regular)
    }
    setdisableCalendarDays(CalDisableDays)

    if (getProjectList?.data?.message == 'No Records Found') {
      setEmployeesProjectList([])
      setShowBackDrop(false)
    } else {
      const projectData = getProjectList?.data?.data
      setEmployeesProjectList(projectData)
      setHeaderHours(getProjectList.data.hours_data)
      let forSingleProjectValidation: any = []
      let forSingleProjectWorkLocationValidation: any = []
      let otLeaveHolidayStatus: any = []
      for (var i = 0; i < projectData?.length; i++) {
        var forSingleProject: any = {
          monday_ott: false,
          tuesday_ott: false,
          wednesday_ott: false,
          thursday_ott: false,
          friday_ott: false,
          saturday_ott: false,
          sunday_ott: false,
          monday_leavet: false,
          tuesday_leavet: false,
          wednesday_leavet: false,
          thursday_leavet: false,
          friday_leavet: false,
          saturday_leavet: false,
          sunday_leavet: false,
          monday_holidayt: false,
          tuesday_holidayt: false,
          wednesday_holidayt: false,
          thursday_holidayt: false,
          friday_holidayt: false,
          saturday_holidayt: false,
          sunday_holidayt: false,
          otdisable: true,
        }
        otLeaveHolidayStatus.push(forSingleProject)
        var forSingleProjectVal: any = {
          cmValidation: false,
          ccValidation: false,
          ucValidation: false,
          ceValidation: false,
        }
        forSingleProjectValidation.push(forSingleProjectVal)
        var forSingleProjectValidate: any = {
          countryValidation: false,
          stateValidation: false,
          cityValidation: false,
        }
        forSingleProjectWorkLocationValidation.push(forSingleProjectValidate)
      }
      setClientManagerNameValidation(forSingleProjectValidation)
      setWorkLocationValidation(forSingleProjectWorkLocationValidation)
      let workCountry = []
      let workState1 = []

      if (projectData?.length > 0) {
        var ProjDetailsArray = []
        for (let p = 0; p < projectData?.length; p++) {
          let data = [
            {
              name: TimesheetWorkLocation
                ? projectData && projectData[p] && projectData[p].ts_work_country
                  ? projectData[p].ts_work_country
                  : projectData[p].work_country
                  ? projectData[p].work_country
                  : ''
                : null,
            },
          ]
          let data1 = [
            {
              name: TimesheetWorkLocation
                ? projectData && projectData[p] && projectData[p].ts_work_state
                  ? projectData[p].ts_work_state
                  : projectData[p].work_state
                  ? projectData[p].work_state
                  : ''
                : null,
            },
          ]
          if (projectData[p].timesheet_data !== null) {
            var projDetails: any = {
              user_id: Number(user_id),
              project_id: projectData[p].id,
              year: parseInt(moment(startdate).format('YYYY')),
              month: parseInt(moment(startdate).format('MM')),
              week_start_date: startdate,
              week_end_date: enddate,
              user_notes: projectData[p].ts_user_notes,
              approver_id: null,
              approver_notes: null,
              client_manager_email: projectData[p].client_manager_email
                ? projectData[p].client_manager_email
                : projectData[p].ts_client_manager_email,
              client_manager_name: projectData[p].client_manager_name
                ? projectData[p].client_manager_name
                : projectData[p].ts_client_manager_name,
              ts_approver_notes: projectData[p]?.ts_approver_notes,
              ts_status: projectData[p].ts_status,
              work_country: data[0].name,
              work_state: data1[0].name,
              work_city: TimesheetWorkLocation
                ? projectData[p].ts_work_city
                  ? projectData[p].ts_work_city
                  : projectData[p].work_city
                : null,
              ot_product: projectData[p].ot_product,
            }
            var ProjObj: any = []
            var Days = [
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
              'Sunday',
            ]
            Days.forEach((e: any, index: any) => {
              ProjObj.push({
                timesheet_date: dates[index],
                regular_hours: 0,
                regular_minutes: 0,
                ot_hours: 0,
                ot_minutes: 0,
                absent_type: null,
                absent_hours: 0,
                absent_minutes: 0,
                status: '',
                qb_timesheet_id: null,
                qb_status: null,
              })
            })
            Days.forEach((Day: any, i: any) => {
              projectData[p]?.timesheet_data.forEach((data: any, j: any) => {
                if (Day == data.day_name) {
                  let regularMinutes: any =
                    projectData[p]?.timesheet_data[j]?.regular_minutes <= 9
                      ? '0' + projectData[p]?.timesheet_data[j]?.regular_minutes
                      : projectData[p]?.timesheet_data[j]?.regular_minutes
                  let absentMinutes: any =
                    projectData[p]?.timesheet_data[j]?.absent_minutes <= 9
                      ? '0' + projectData[p]?.timesheet_data[j]?.absent_minutes
                      : projectData[p]?.timesheet_data[j]?.absent_minutes
                  let otMinutes: any =
                    projectData[p]?.timesheet_data[j]?.ot_minutes <= 9
                      ? '0' + projectData[p]?.timesheet_data[j]?.ot_minutes
                      : projectData[p]?.timesheet_data[j]?.ot_minutes
                  ProjObj[i] = {
                    id: projectData[p]?.timesheet_data[j]?.id,
                    timesheet_date: projectData[p]?.timesheet_data[j]?.timesheet_date,
                    regular_hours:
                      projectData[p]?.timesheet_data[j]?.regular_hours + ':' + regularMinutes,
                    regular_minutes: projectData[p]?.timesheet_data[j]?.regular_minutes,
                    ot_hours: projectData[p]?.timesheet_data[j]?.ot_hours + ':' + otMinutes,
                    ot_minutes: projectData[p]?.timesheet_data[j]?.ot_minutes,
                    absent_type: projectData[p]?.timesheet_data[j]?.absent_type,
                    absent_hours:
                      projectData[p]?.timesheet_data[j]?.absent_hours + ':' + absentMinutes,
                    absent_minutes: projectData[p]?.timesheet_data[j]?.absent_minutes,
                    status: projectData[p]?.timesheet_data[j]?.status,
                    qb_timesheet_id: projectData[p]?.timesheet_data[j]?.qb_timesheet_id,
                    qb_status: projectData[p]?.timesheet_data[j]?.qb_status,
                    approver_notes: projectData[p]?.timesheet_data[j]?.approver_notes,
                    qb_invoice_id: projectData[p]?.timesheet_data[j]?.qb_invoice_id,
                  }
                }
              })
            })
            projDetails.timesheet_data = ProjObj
            ProjDetailsArray.push(projDetails)
            setweekDayDataEntry([...ProjDetailsArray])
          } else {
            var projDetails: any = {
              user_id: Number(user_id),
              project_id: projectData[p].id,
              year: parseInt(moment(startdate).format('YYYY')),
              month: parseInt(moment(startdate).format('MM')),
              week_start_date: startdate,
              week_end_date: enddate,
              user_notes: '',
              approver_id: null,
              approver_notes: null,
              client_manager_email: projectData[p].client_manager_email,
              client_manager_name: projectData[p].client_manager_name,
              work_country: data[0].name,
              work_state: data1[0].name,
              work_city: TimesheetWorkLocation
                ? projectData[p].ts_work_city
                  ? projectData[p].ts_work_city
                  : projectData[p].work_city
                : null,
              ot_product: projectData[p].ot_product,
            }
            var ProjObj: any = []
            for (let i = 0; i < 7; i++) {
              ProjObj.push({
                timesheet_date: dates[i],
                regular_hours: 0,
                regular_minutes: 0,
                ot_hours: 0,
                ot_minutes: 0,
                absent_type: null,
                absent_hours: 0,
                absent_minutes: 0,
                status: '',
                qb_timesheet_id: null,
                qb_status: null,
              })
            }
            projDetails.timesheet_data = ProjObj
            ProjDetailsArray.push(projDetails)
          }
          workCountry.push(data)
          setCountries(workCountry)
          workState1.push(data1)
          setStates(workState1)
        }
        for (let p = 0; p < projectData?.length; p++) {
          for (let j = 0; j < ProjDetailsArray[p]?.timesheet_data?.length; j++) {
            if (ProjDetailsArray[p]?.timesheet_data[j]?.absent_type == 1) {
              var Holiday = [
                'monday_holidayt',
                'tuesday_holidayt',
                'wednesday_holidayt',
                'thursday_holidayt',
                'friday_holidayt',
                'saturday_holidayt',
                'sunday_holidayt',
              ]
              otLeaveHolidayStatus[p][Holiday[j]] = true
              setWeekDayStatus([...otLeaveHolidayStatus])
            }
            if (ProjDetailsArray[p]?.timesheet_data[j]?.absent_type == 2) {
              var Leave = [
                'monday_leavet',
                'tuesday_leavet',
                'wednesday_leavet',
                'thursday_leavet',
                'friday_leavet',
                'saturday_leavet',
                'sunday_leavet',
              ]
              otLeaveHolidayStatus[p][Leave[j]] = true
              setWeekDayStatus([...otLeaveHolidayStatus])
            }
            if (
              parseInt(ProjDetailsArray[p]?.timesheet_data[j]?.ot_hours) ||
              parseInt(ProjDetailsArray[p]?.timesheet_data[j]?.ot_minutes)
            ) {
              var OT = [
                'monday_ott',
                'tuesday_ott',
                'wednesday_ott',
                'thursday_ott',
                'friday_ott',
                'saturday_ott',
                'sunday_ott',
              ]
              otLeaveHolidayStatus[p][OT[j]] = true
              setWeekDayStatus([...otLeaveHolidayStatus])
            }
          }
        }

        otfieldsdisabledValidation.length = 0
        for (let p = 0; p < projectData?.length; p++) {
          let weekOtFieldsDisable = []
          for (let j = 0; j < ProjDetailsArray[p]?.timesheet_data?.length; j++) {
            if (ProjDetailsArray[p]?.timesheet_data[j]?.regular_hours) {
              let splitRegularHours1 = {...ProjDetailsArray[p]}
              let splitted = splitRegularHours1?.timesheet_data[j]?.regular_hours.split(':')
              let totaltime = splitted[0] * 60 + parseInt(splitted[1])
              if (totaltime > 0) {
                weekOtFieldsDisable.push(false)
              } else {
                weekOtFieldsDisable.push(true)
              }
            } else {
              weekOtFieldsDisable.push(true)
            }
          }
          otfieldsdisabledValidation.push(weekOtFieldsDisable)
        }
        setotfieldsdisabledValidation(otfieldsdisabledValidation)
        statusForSubmitALL.length = 0
        statusForUnSubmitALL.length = 0
        for (let i = 0; i < ProjDetailsArray.length; i++) {
          let Status =
            ProjDetailsArray[i]?.timesheet_data.find((item: any) => item.status == '') ||
            ProjDetailsArray[i]?.timesheet_data.find((item: any) => item.status == 'REJECTED') ||
            ProjDetailsArray[i]?.timesheet_data.find((item: any) => item.status == 'SAVED')
              ? true
              : false
          statusForSubmitALL.push(Status)
          let UnsubmitStatus = ProjDetailsArray[i]?.timesheet_data.find(
            (item: any) => item.status == 'SUBMITTED'
          )
            ? true
            : false
          statusForUnSubmitALL.push(UnsubmitStatus)
        }
        submitButtonEnableDisableValidation.length = 0
        CopyButtonEnableDisableValidation.length = 0
        let ForAllProjects: any = []
        ProjDetailsArray.map((singleProject: any, index: any) => {
          let ForSingleProject: any = []
          if (singleProject?.timesheet_data.find((item: any) => item.status == 'SAVED')) {
            ForSingleProject = []
          } else {
            singleProject.timesheet_data.map((singleDay: any, index: any) => {
              if (singleDay.status == '' || singleDay.status == 'REJECTED') {
                ForSingleProject.push(false)
              }
            })
          }

          ForAllProjects.push(ForSingleProject)
        })
        let ForAllProjectsCopyStatus: any = []
        ProjDetailsArray.map((singleProject: any, index: any) => {
          if (
            singleProject?.timesheet_data.find(
              (item: any) =>
                item.status == 'SAVED' ||
                item.status == 'SUBMITTED' ||
                item.status == 'APPROVED' ||
                item.status == 'REJECTED'
            )
          ) {
            ForAllProjectsCopyStatus.push(false)
          } else {
            let PrevWeekData = getProjectList11?.data?.data?.filter(
              (test: any) => test.id == singleProject.project_id
            )
            if (PrevWeekData?.length == 0) {
              ForAllProjectsCopyStatus.push(false)
            } else if (PrevWeekData && PrevWeekData[0]?.timesheet_data) {
              ForAllProjectsCopyStatus.push(true)
            } else {
              ForAllProjectsCopyStatus.push(false)
            }
          }
        })

        setCopyButtonEnableDisableValidation(ForAllProjectsCopyStatus)
        /* xxxxxxxxxxxxx Copy Button enable or disable for copyAllButton xxxxxxx*/
        // let ForAllProjectsCopyStatus: any = []
        // ProjDetailsArray.map((singleProject: any, index: any) => {
        //   if (
        //     singleProject?.timesheet_data.find(
        //       (item: any) =>
        //         item.status == 'SAVED' ||
        //         item.status == 'SUBMITTED' ||
        //         item.status == 'APPROVED' ||
        //         item.status == 'REJECTED'
        //     )
        //   ) {
        //     ForAllProjectsCopyStatus.push(false)
        //   }
        // })

        // let PrevWeekData = getProjectList11?.data?.data?.filter((test: any) => test.timesheet_data)

        // if (
        //   ForAllProjectsCopyStatus.length > 0 ||
        //   ForAllProjectsCopyStatus.length == ProjDetailsArray.length
        // ) {
        //   CopyButtonEnableDisableValidation.push(true)
        // } else if (PrevWeekData?.length > 0) {
        //   CopyButtonEnableDisableValidation.push(false)
        // }
        /* xxxxxxxxxxxxxxxxxx--------xxxxxxxxx*/
        ForAllProjects.map((eachProject: any, index: any) => {
          if (eachProject.length > 0) {
            submitButtonEnableDisableValidation.push(true)
          } else {
            submitButtonEnableDisableValidation.push(false)
          }
        })
        setSubmitButtonEnableDisableValidation([...submitButtonEnableDisableValidation])
        // setCopyButtonEnableDisableValidation([...CopyButtonEnableDisableValidation])
        setSubmitButtonEnableDisable([...ForAllProjects])
        setStatusForSubmitALL(statusForSubmitALL)
        setStatusForUnSubmitALL(statusForUnSubmitALL)
        setweekDayDataEntry(ProjDetailsArray)
        setWeekDayStatus([...otLeaveHolidayStatus])
        sumOfTotalHours(ProjDetailsArray)
      }
      if (projectData?.length > 0) {
        // let userIdData5 =
        //   localStorage.getItem('role_Name') === 'timesheetadmin'
        //     ? localStorage.getItem('time_sheet_admin_screen_user_id')
        //     : localStorage.getItem('userid')
        let state2: any = location.state
        let userIdData5 =
          state2?.names == 'create'
            ? localStorage.getItem('time_sheet_admin_screen_user_id')
            : localStorage.getItem('userid')
        // let userIdData5: any = localStorage.getItem('userid')
        const getcountries: any = await Apiservices.getallcountries()
        for (let p = 0; p < projectData?.length; p++) {
          var work_Country_id =
            projectData && projectData[p] && projectData[p].ts_work_country
              ? projectData[p].ts_work_country
              : projectData[p].work_country && projectData[p].work_country
          if (work_Country_id) {
            StateFielddisabled[p] = false
            let stateName1 = getcountries?.data?.data.filter(
              (temp: any) => temp?.name == work_Country_id
            )
            if (stateName1) {
              SelectedCountry[p] = stateName1[0]?.id
              setSelectedCountry([...SelectedCountry])
              setUseStateCountryId(stateName1[0]?.id)
              const response: any = await Apiservices.getstatebyname({
                country_id: stateName1[0]?.id,
              })
              if (response?.data?.data) {
                WorkStatesBasedOnCountry.push(response.data.data)
                setWorkStatesBasedOnCountry([...WorkStatesBasedOnCountry])
                setState(WorkStatesBasedOnCountry)
                // setState(response.data.data ? response.data.data : '')
              } else {
                WorkStatesBasedOnCountry.push([])
                setWorkStatesBasedOnCountry([...WorkStatesBasedOnCountry])
                setState(WorkStatesBasedOnCountry)
                // setState([])
              }
            }
          } else {
            StateFielddisabled[p] = true
          }
          setStateFielddisabled([...StateFielddisabled])
        }
        const getImages = await Apiservices.docNameIdProjectList1({
          week_start_date: startdate,
          week_end_date: enddate,
          user_id: userIdData5,
        })
        setDocResultDataRes(getImages.data.data)
      }
      // let get_org_id = localStorage.getItem('org_id')
      // const resAllowedPastEntryDays: any = await Apiservices.getOrganizationSetting({
      //   org_id: get_org_id,
      // })
      // let CalDisableDays = resAllowedPastEntryDays?.data?.data[0]?.allowed_past_entry_days
      //   ? resAllowedPastEntryDays?.data?.data[0]?.allowed_past_entry_days
      //   : 180
      // let TimesheetWorkLocation = resAllowedPastEntryDays?.data?.data[0]?.timesheet_work_location
      // setDisableWorkLocation(TimesheetWorkLocation)
      // let regular =
      //   resAllowedPastEntryDays &&
      //   resAllowedPastEntryDays.data &&
      //   resAllowedPastEntryDays.data.data &&
      //   resAllowedPastEntryDays.data.data[0] &&
      //   resAllowedPastEntryDays.data.data[0].max_regular_hours
      // setRegularHoursEntry(regular)
      // if (regular) {
      //   setOTHoursEntry(24 - regular)
      // }
      // setdisableCalendarDays(CalDisableDays)
      projectStartDateDisable.length = 0
      let AllProjectStartDateDisable: any = []
      if (projectData?.length > 0) {
        projectData.map((eachProject: any, index: any) => {
          let DayFieldEntryDisable1 = eachProject.joining_date
            ? eachProject.joining_date
            : eachProject.start_date
          var DayFieldEntryDisable: any
          if (new Date(DayFieldEntryDisable1) <= new Date(eachProject.emp_joining_date)) {
            DayFieldEntryDisable = eachProject.emp_joining_date
          } else if (new Date(DayFieldEntryDisable1) >= new Date(eachProject.emp_joining_date)) {
            DayFieldEntryDisable = DayFieldEntryDisable1
          }

          let SingleProjectStartDateDisable: any = []
          dates.map((eachDay: any, dayIndex: any) => {
            if (
              new Date(DayFieldEntryDisable).getTime() >
              new Date(moment().subtract(CalDisableDays, 'days').format('YYYY-MM-DD')).getTime()
            ) {
              if (new Date(DayFieldEntryDisable).getTime() > new Date(eachDay).getTime()) {
                SingleProjectStartDateDisable.push(true)
              } else {
                SingleProjectStartDateDisable.push(false)
              }
            } else {
              if (
                new Date(moment().subtract(CalDisableDays, 'days').format('YYYY-MM-DD')).getTime() >
                new Date(eachDay).getTime()
              ) {
                SingleProjectStartDateDisable.push(true)
              } else {
                SingleProjectStartDateDisable.push(false)
              }
            }
          })
          AllProjectStartDateDisable.push(SingleProjectStartDateDisable)
        })
        setProjectStartDateDisable(AllProjectStartDateDisable)
      }
      let CalDisableEndDays = 30
      projectEndDateDisable.length = 0
      let AllProjectsEndDateDisable: any = []
      if (projectData?.length > 0) {
        projectData.map((eachProject: any, index: any) => {
          let SingleProjectEndDateDisable: any = []
          dates.map((eachDay: any, dayIndex: any) => {
            // let endAndCancelDate =
            // eachProject.project_status === 'COMPLETED'
            //   ? eachProject.end_date
            //   : eachProject.cancel_date

            let endAndCancelDate = eachProject.leaving_date
              ? eachProject.leaving_date
              : eachProject.project_status &&
                (eachProject.project_status === 'COMPLETED' ||
                  eachProject.project_status === 'CANCELLED')
              ? eachProject.project_status === 'COMPLETED' ||
                eachProject.project_status === 'CANCELLED'
                ? eachProject.end_date
                : eachProject.cancel_date
              : ''
            if (endAndCancelDate) {
              if (
                new Date(endAndCancelDate).getTime() <
                new Date(moment().add(CalDisableEndDays, 'days').format('YYYY-MM-DD')).getTime()
              ) {
                if (new Date(endAndCancelDate).getTime() < new Date(eachDay).getTime()) {
                  SingleProjectEndDateDisable.push(true)
                } else {
                  SingleProjectEndDateDisable.push(false)
                }
              } else {
                if (
                  new Date(moment().add(CalDisableEndDays, 'days').format('YYYY-MM-DD')).getTime() <
                  new Date(eachDay).getTime()
                ) {
                  SingleProjectEndDateDisable.push(true)
                } else {
                  SingleProjectEndDateDisable.push(false)
                }
              }
            } else if (
              new Date(moment().add(CalDisableEndDays, 'days').format('YYYY-MM-DD')).getTime() <
              new Date(eachDay).getTime()
            ) {
              SingleProjectEndDateDisable.push(true)
            } else {
              SingleProjectEndDateDisable.push(false)
            }
          })
          AllProjectsEndDateDisable.push(SingleProjectEndDateDisable)
        })
        setProjectEndDateDisable(AllProjectsEndDateDisable)
      }

      if (
        new Date(dates[0]).getTime() <=
        new Date(moment().subtract(CalDisableDays, 'days').format('YYYY-MM-DD')).getTime()
      ) {
        setDisableLeftArrow(true)
      } else {
        setDisableLeftArrow(false)
      }
      if (
        new Date(dates[6]).getTime() >=
        new Date(moment().add(CalDisableDays, 'days').format('YYYY-MM-DD')).getTime()
      ) {
        setDisableRightArrow(true)
      } else {
        setDisableRightArrow(false)
      }
      setShowBackDrop(false)
    }
  }

  const showOtLeaveHolidayHandler = (index: any, day: any, dayIndex: any) => {
    setWeekDayName(day)
    weekDayStatus[index][day] = !weekDayStatus[index][day]
    setWeekDayStatus([...weekDayStatus])
    weekDayDataEntry[index].timesheet_data[dayIndex].ot_hours = 0
    setweekDayDataEntry([...weekDayDataEntry])
  }

  const showOtLeaveHolidayHandlerForOtbutton = (index: any, day: any, dayIndex: any) => {
    setWeekDayName(day)
    weekDayStatus[index][day] = false
    setWeekDayStatus([...weekDayStatus])
    weekDayDataEntry[index].timesheet_data[dayIndex].ot_hours = 0
    setweekDayDataEntry([...weekDayDataEntry])
  }

  const onClickHolidayDisableLeave = (index: any, day: any, dayIndex: any) => {
    const Holiday = {ProjectIndex: index, dayName: day, dayIndexNo: dayIndex}
    setHolidayData(Holiday)
    if (weekDayStatus[index][day] == false) {
      setForLeaveAndHoldiayPopup(true)
    } else {
      leaveHandler('ApplyLeave', Holiday)
    }
  }

  const leaveHandler = (message: any, data: any) => {
    setForLeaveAndHoldiayPopup(false)
    if (message == 'ApplyLeave') {
      let index = data.ProjectIndex
      let day = data.dayName
      let dayIndex = data.dayIndexNo
      weekDayStatus[index][day] = !weekDayStatus[index][day]
      setWeekDayStatus([...weekDayStatus])
      if (weekDayStatus[index][day]) {
        weekDayDataEntry[index].timesheet_data[dayIndex].absent_type = 2
        setweekDayDataEntry([...weekDayDataEntry])
        SuccessToast('Marked As Holiday')
      } else {
        weekDayDataEntry[index].timesheet_data[dayIndex].absent_type = null
        setweekDayDataEntry([...weekDayDataEntry])
        ErrorToast('Holiday Not Applied')
      }
      enableDisableSubmitButton(weekDayDataEntry)
    }
  }

  function copyToClipboard(e: any) {
    // setRandom(random)
    const textField = document.createElement('textarea')
    textField.innerText = e
    // weekDayStatus[index].otdisable = true
    // setWeekDayStatus([...weekDayStatus])
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    setCopySuccess('Copied!')
    setTimeout(function () {
      setCopySuccess('')
      // weekDayStatus[index].otdisable = false
    }, 1000)
  }

  const DailyEntry = (e: any, projectIndex: number, weekDayIndex: number) => {
    let weekDayDataEntries = weekDayDataEntry
    if (e.target.getAttribute('name') == 'user_notes') {
      weekDayDataEntries[projectIndex][e.target.getAttribute('name')] = e.target.value
      ClientManagerNameValidation[projectIndex].ccValidation = false
    }
    if (e.target.getAttribute('name') == 'absent_hours') {
      if (e.target.value > LeaveHoursEntry) {
        ErrorToast('Leave hours not more than 8:00 hours')
      }
      weekDayDataEntries[projectIndex].timesheet_data[weekDayIndex].absent_type = 1
    }
    if (e.target.getAttribute('name') == 'ot_hours') {
      if (e.target.value > OTHoursEntry) {
        ErrorToast('OT  hours not more than 16:00 hours')
      }
    }
    const re = /^[0-9.:\b]+$/
    const LengthOfEnteredValue = e.target.value
    let count = 0
    for (let i = 0; i < LengthOfEnteredValue.length; i++) {
      if (LengthOfEnteredValue[i] === ':') {
        count++
      }
      if (LengthOfEnteredValue[i] === '.') {
        count++
      }
    }

    if (
      (e.target.value === '' || re.test(e.target.value)) &&
      count <= 1 &&
      e.target.value.length <= 5
    ) {
      weekDayDataEntries[projectIndex].timesheet_data[weekDayIndex][e.target.getAttribute('name')] =
        e.target.value
    }
    if (e.target.getAttribute('name') === 'regular_hours') {
      if (e.target.value > RegularHoursEntry) {
        // timechangeForRegularHours(e, projectIndex, weekDayIndex, 8)
        // {`Regular hours not more than ${RegularHoursEntry}:00 hours`}
        ErrorToast(`Regular hours not more than ${RegularHoursEntry}:00 hours`)
      }
      if (e.target.value !== '') {
        otfieldsdisabledValidation[projectIndex][weekDayIndex] = false
      } else {
        otfieldsdisabledValidation[projectIndex][weekDayIndex] = true
        let ottDay: any = [
          'monday_ott',
          'tuesday_ott',
          'wednesday_ott',
          'thursday_ott',
          'friday_ott',
          'saturday_ott',
          'sunday_ott',
        ]

        showOtLeaveHolidayHandlerForOtbutton(projectIndex, ottDay[weekDayIndex], weekDayIndex)
      }
      setotfieldsdisabledValidation([...otfieldsdisabledValidation])
    }
    let RegularHoursTwoFour =
      weekDayDataEntries[projectIndex].timesheet_data[weekDayIndex].regular_hours
    let OtHoursTwoFour = weekDayDataEntries[projectIndex].timesheet_data[weekDayIndex].ot_hours
    let AbsentHoursTwoFour =
      weekDayDataEntries[projectIndex].timesheet_data[weekDayIndex].absent_hours

    var TotalMinutesOne = 0
    var TotalMinutesTwo = 0
    if (
      e.target.getAttribute('name') === 'regular_hours' ||
      e.target.getAttribute('name') === 'ot_hours' ||
      e.target.getAttribute('name') === 'absent_hours'
    ) {
      if (e.target.getAttribute('name') === 'regular_hours') {
        if (OtHoursTwoFour) {
          if (OtHoursTwoFour.toString().indexOf(':') > -1) {
            var [OtTwoFour, OtMinutesTwoFour] = OtHoursTwoFour.split(':')
            var TotalMinutesOne = OtTwoFour * 60 + parseInt(OtMinutesTwoFour)
          } else {
            TotalMinutesOne = OtHoursTwoFour
          }
        } else {
          TotalMinutesOne = 0
        }
        if (AbsentHoursTwoFour) {
          if (AbsentHoursTwoFour.toString().indexOf(':') > -1) {
            var [AbsentTwoFour, AbsentMinuteTwoFour] = AbsentHoursTwoFour.split(':')
            var TotalMinutesTwo = AbsentTwoFour * 60 + parseInt(AbsentMinuteTwoFour)
          } else {
            TotalMinutesTwo = AbsentHoursTwoFour
          }
        } else {
          TotalMinutesTwo = 0
        }
      }
      if (e.target.getAttribute('name') === 'ot_hours') {
        if (RegularHoursTwoFour) {
          if (RegularHoursTwoFour.toString().indexOf(':') > -1) {
            var [ReguarHoursTwoFour, RegularMinutesTwoFour] = RegularHoursTwoFour.split(':')
            var TotalMinutesOne = ReguarHoursTwoFour * 60 + parseInt(RegularMinutesTwoFour)
          } else {
            TotalMinutesOne = RegularHoursTwoFour
          }
        } else {
          TotalMinutesOne = 0
        }
        if (AbsentHoursTwoFour) {
          if (AbsentHoursTwoFour.toString().indexOf(':') > -1) {
            var [AbsentTwoFour, AbsentMinuteTwoFour] = AbsentHoursTwoFour.split(':')
            var TotalMinutesTwo = AbsentTwoFour * 60 + parseInt(AbsentMinuteTwoFour)
          } else {
            TotalMinutesTwo = AbsentHoursTwoFour
          }
        } else {
          TotalMinutesTwo = 0
        }
      }
      if (e.target.getAttribute('name') === 'absent_hours') {
        if (RegularHoursTwoFour) {
          if (RegularHoursTwoFour.toString().indexOf(':') > -1) {
            var [ReguarHoursTwoFour, RegularMinutesTwoFour] = RegularHoursTwoFour.split(':')
            var TotalMinutesOne = ReguarHoursTwoFour * 60 + parseInt(RegularMinutesTwoFour)
          } else {
            TotalMinutesOne = RegularHoursTwoFour
          }
        } else {
          TotalMinutesOne = 0
        }
        if (OtHoursTwoFour) {
          if (OtHoursTwoFour.toString().indexOf(':') > -1) {
            var [OtTwoFour, OtMinutesTwoFour] = OtHoursTwoFour.split(':')
            var TotalMinutesTwo = OtTwoFour * 60 + parseInt(OtMinutesTwoFour)
          } else {
            TotalMinutesTwo = OtHoursTwoFour
          }
        } else {
          TotalMinutesTwo = 0
        }
        if (AbsentHoursTwoFour) {
          if (AbsentHoursTwoFour.toString().indexOf(':') > -1) {
            let [lessThanOneHours, lessThanOneMinutes] = AbsentHoursTwoFour.split(':')
            var lessThanHour: number = lessThanOneHours * 60
            var lessThanHourMinutes: number =
              lessThanOneMinutes >= 60 ? 59 : parseInt(lessThanOneMinutes)
            var checkLeassThanOneHour = lessThanHour + lessThanHourMinutes
          } else if (AbsentHoursTwoFour.toString().indexOf('.') > -1) {
            let [lessThanOneHours, lessThanOneMinutes] = AbsentHoursTwoFour.split('.')
            var lessThanHour: number = lessThanOneHours * 60
            var lessThanHourMinutes: number =
              (lessThanOneMinutes * 60) / Math.pow(10, lessThanOneMinutes.length)
            lessThanHourMinutes = Math.round(lessThanHourMinutes)
            var checkLeassThanOneHour = lessThanHour + lessThanHourMinutes
          } else {
            let tfregularStart = AbsentHoursTwoFour
            var lessThanHour: number = tfregularStart * 60
            var checkLeassThanOneHour = lessThanHour
          }
          if (checkLeassThanOneHour <= 59) {
            ErrorToast('Less than One hour not allowed for leave')
            weekDayDataEntries[projectIndex].timesheet_data[weekDayIndex][
              e.target.getAttribute('name')
            ] = 0
          }
        }
      }
      let calcAbsentHours = e.target.value
      if (calcAbsentHours.indexOf(':') > -1) {
        var tftotalMints = MinutesConvertionWithColon(calcAbsentHours)
      } else if (calcAbsentHours.indexOf('.') > -1) {
        var tftotalMints = MinutesConvertionWithDot(calcAbsentHours)
      } else {
        let tfregularStart = calcAbsentHours
        var tfregularStartingValue: number = tfregularStart * 60
        var tftotalMints = tfregularStartingValue
      }

      tftotalMints += TotalMinutesOne + TotalMinutesTwo
      if (tftotalMints > 1440) {
        ErrorToast('Per day per project max of 24 hours allowed')
        weekDayDataEntries[projectIndex].timesheet_data[weekDayIndex][
          e.target.getAttribute('name')
        ] = 0
      }
    }
    setweekDayDataEntry([...weekDayDataEntries])
  }
  const MinutesConvertionWithColon = (Hours: any) => {
    let [tfregularStart, tfregularDec] = Hours.split(':')
    var tfregularStartingValue: number = tfregularStart * 60
    var tfreularMins: number = tfregularDec >= 60 ? 59 : parseInt(tfregularDec)
    var tftotalMints = tfregularStartingValue + tfreularMins
    return tftotalMints
  }

  const MinutesConvertionWithDot = (Hours: any) => {
    let [tfregularStart, tfregularDec] = Hours.split('.')
    var tfregularStartingValue: number = tfregularStart * 60
    var tfreularMins: number = (tfregularDec * 60) / Math.pow(10, tfregularDec.length)
    tfreularMins = Math.round(tfreularMins)
    var tftotalMints = tfregularStartingValue + tfreularMins
    return tftotalMints
  }

  /****************adding client name client email form text fileds********************** */
  const ClientDetails = (e: any, projectIndex: number) => {
    const textFieldName = e.target.getAttribute('name')
    weekDayDataEntry[projectIndex][e.target.getAttribute('name')] = e.target.value

    if (e.target.getAttribute('name') == 'client_manager_name') {
      ClientManagerNameValidation[projectIndex].cmValidation = false
    }
    if (e.target.getAttribute('name') == 'work_city') {
      WorkLocationValidation[projectIndex].cityValidation = false
    }
    setweekDayDataEntry([...weekDayDataEntry])
  }
  /**********************XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX**************** */
  const [documentId, setDocumentId] = useState<any[]>([])
  let DocResultData: any = []

  const FileUpload = async (e: any, index: number) => {
    var fileData = e.target.files
    let FileSize = []
    let FileFormats = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
    ]
    let EmptyFileFormats = []
    if (fileData.length > 0) {
      for (let i = 0; i < fileData.length; i++) {
        if (fileData[i].size / (1024 * 1024) <= 10) {
          FileSize.push(fileData[i].size / (1024 * 1024))
        } else {
          FileSize.length = 0
          break
        }
        if (FileFormats.includes(fileData[i].type)) {
          EmptyFileFormats.push(fileData[i].type)
        } else {
          EmptyFileFormats.length = 0
          break
        }
      }
    }
    if (fileData.length <= 5) {
      if (FileSize.length > 0) {
        if (EmptyFileFormats.length > 0) {
          var formData = new FormData()
          var filesLength = fileData && fileData.length ? fileData.length : 0
          for (var i = 0; i < filesLength; i++) {
            formData.append('file', fileData[i])
          }
          const PrevFileLength =
            imagesArrayRes && imagesArrayRes[index] ? imagesArrayRes[index].length : 0
          if (PrevFileLength > 0) {
            for (var j = 0; j < PrevFileLength; j++) {
              if (imagesArrayRes[index].length > 0) {
                formData.append('document_name', imagesArrayRes[index][j])
              }
            }
          }

          var org_id: any = localStorage.getItem('org_id')
          var project_id = employeesProjectList[index].id
          // var user_id: any = localStorage.getItem('userid')
          // let user_id: any =
          //   localStorage.getItem('role_Name') === 'timesheetadmin'
          //     ? localStorage.getItem('time_sheet_admin_screen_user_id')
          let state2: any = location.state
          let user_id: any =
            state2?.names == 'create'
              ? localStorage.getItem('time_sheet_admin_screen_user_id')
              : localStorage.getItem('userid')

          formData.append('week_start_date', weekStartDate)
          formData.append('week_end_date', weekEndDate)
          formData.append('createdby', '1')
          formData.append('user_id', user_id)
          formData.append('document_name', DocResultData)

          formData.append('org_id', org_id)
          formData.append('project_id', project_id)
          setShowBackDrop(true)
          const res = await Apiservices.insertDocument(formData)
          setShowBackDrop(false)
          res.data.documents_result.map((each: any) => {
            let docsResult = each.original_name
            DocResultData.push(docsResult)
          })

          if (res.message == 'Added Successfully') {
            SuccessToast('Uploaded Successfully')
          } else {
            ErrorToast('Error Occured')
          }
          // let userIdData5: any = localStorage.getItem('userid')
          // let userIdData5 =
          //   localStorage.getItem('role_Name') === 'timesheetadmin'
          //     ? localStorage.getItem('time_sheet_admin_screen_user_id')
          //     : localStorage.getItem('userid')
          let userIdData5 =
            state2?.names == 'create'
              ? localStorage.getItem('time_sheet_admin_screen_user_id')
              : localStorage.getItem('userid')

          const response1 = await Apiservices.docNameIdProjectList1({
            week_start_date: weekStartDate,
            week_end_date: weekEndDate,
            user_id: userIdData5,
          })
          if (numArray?.includes('timesheetadmin')) {
            if (
              response1 &&
              response1.data &&
              response1.data.data &&
              response1.data.data.length > 0 &&
              prevAr &&
              prevAr.length > 0
            ) {
              var test2 = [...prevAr, ...response1.data.data]
            } else if (response1?.data?.data?.length == null && prevAr?.length == null) {
              var test2 = [...prevAr]
            } else if (prevAr && prevAr?.length > 0 && response1?.data?.data?.length == null) {
              var test2 = [...prevAr]
            } else {
              var test2 = [...response1?.data?.data]
            }
            setDocResultDataRes(test2)
          } else {
            setDocResultDataRes(response1?.data?.data)
          }

          let DocumentsData = imagesArrayRes

          for (let each in imagesArrayRes) {
            if (project_id === imagesArrayRes[each].project_id) {
              DocumentsData[each].documents =
                response1.data.data !== null ? response1.data.data : []
            }
          }
          setImagesArrayRes(DocumentsData)
          setDocumentId([...documentId])
        } else {
          ErrorToast('Document type not allowed')
        }
      } else {
        ErrorToast('Maximum upload file size 10 MB')
      }
    } else {
      ErrorToast('Please upload less than 5 Files')
    }
  }

  const FormSave = async (index: any) => {
    setShowBackDrop(true)
    // let ProjectNo = index
    // const ProjectList = weekDayDataEntry[index]
    const updatedData = splitHoursAndMinutes(weekDayDataEntry[index])
    const data = updatedData
    let updateTimesheetDataArray = []
    for (let i = 0; i < data.timesheet_data.length; i++) {
      if (
        Number(data.timesheet_data[i].regular_hours) > 0 ||
        Number(data.timesheet_data[i].regular_minutes) > 0 ||
        Number(data.timesheet_data[i].ot_hours) > 0 ||
        Number(data.timesheet_data[i].ot_minutes) > 0 ||
        Number(data.timesheet_data[i].absent_hours) > 0 ||
        Number(data.timesheet_data[i].absent_minutes) > 0 ||
        Number(data.timesheet_data[i].absent_type) == 2 ||
        Number(data.timesheet_data[i].id)
      ) {
        updateTimesheetDataArray.push(data.timesheet_data[i])
      }
    }
    data.timesheet_data = updateTimesheetDataArray
    var saveAllProjects: any = await Apiservices.timeSheetSaveInsert(data)
    if (numArray?.includes('timesheetadmin')) {
      if (docResultDataRes) {
        let docarr = []
        for (let each of docResultDataRes) {
          if (each.previous_doc) {
            docarr.push(each.id)
          }
        }
        const updateDocsData = {
          user_id: data.user_id,
          project_id: data.project_id,
          week_start_date: weekStartDate,
          week_end_date: weekEndDate,
          documents: docarr,
        }
        if (docarr && docarr.length > 0) {
          var copyDocs: any = await Apiservices.copyTimesheetDocs(updateDocsData)
          if (copyDocs.message == 'Added Successfully') {
            setPrevAr([])
          }
        }
      }
    }

    if (updatedData) {
      handlerForResetDataAndShowToasters(saveAllProjects.message, saveAllProjects.isError)
    }
    setShowBackDrop(false)
  }

  const handleSubmitted = (e: any, index: any) => {
    setSubmitOpen(true)
    setSubmitShow(false)
    FormSingleProjectSubmit(index, 'handleSubmit', list, '')
  }

  const modalPop = () => {
    setSubmitOpen(false)
    setSubmitShow(false)
    setShowBackDrop(false)
  }
  const WorkLocationContinuePop = (e: any, alert_any: any, index: any) => {
    setWorkPopMessage(alert_any)
    setWorkLocationShow(false)
    setWorkPopModifyMessage('')
    setSubmitOpen(true)
    setSubmitShow(true)
  }
  const WorkLocationOkPop = (e: any, alert: any) => {
    setWorkPopMessage('')
    setWorkPopModifyMessage(alert)
    let today = moment(new Date()).format('YYYY-MM-DD')
    let pastDay: any = moment(employeesProjectList[indexState].work_location_updatedate).format(
      'YYYY-MM-DD'
    )
    let startt = moment(today)
    let enddd = moment(pastDay)
    let duration1 = moment.duration(startt.diff(enddd))
    let dayss = duration1.asDays()
    var workCountrySetting: any = []
    var workStateSetting: any = []
    if (dayss >= 90) {
      weekDayDataEntry[indexState].work_country = ''
      weekDayDataEntry[indexState].work_state = ''
      weekDayDataEntry[indexState].work_city = ''
      // employeesProjectList[indexState].ts_work_city = ''
      // employeesProjectList[indexState].work_city = ''
      // setEmployeesProjectList([...employeesProjectList])
      setweekDayDataEntry([...weekDayDataEntry])
      let data = [
        {
          name: weekDayDataEntry[indexState].work_country
            ? weekDayDataEntry[indexState].work_country
            : null,
        },
      ]
      let data1 = [
        {
          name: weekDayDataEntry[indexState].work_state
            ? weekDayDataEntry[indexState].work_state
            : null,
        },
      ]
      workCountrySetting[indexState] = data
      workStateSetting[indexState] = data1
    }
    setWorkLocationShow(false)
    setweekDayDataEntry([...weekDayDataEntry])
    setCountries(workCountrySetting)
    setStates(workStateSetting)
  }

  const FormSingleProjectSubmit = async (
    index: any,
    fromVal: any,
    list: any,
    work_locaion_pop: any
  ) => {
    setindexState(index)
    setList(list)
    if (fromVal !== 'handleSubmit') {
      let WorkLocationAllPopLength = 0
      let today = moment(new Date()).format('YYYY-MM-DD')
      let pastDay: any = moment(list?.work_location_updatedate).format('YYYY-MM-DD')
      let startt = moment(today)
      let enddd = moment(pastDay)
      let duration1 = moment.duration(startt.diff(enddd))
      let dayss = duration1.asDays()
      let filteredDocsBasedonProjId =
        docResultDataRes &&
        docResultDataRes.filter(
          (each: any) => each.project_id === weekDayDataEntry[index]?.project_id
        )
      const updatedData = splitHoursAndMinutes(weekDayDataEntry[index])

      const data = updatedData
      let regulararray = []

      for (let j = 0; j < data.timesheet_data.length; j++) {
        if (data.timesheet_data[j].status != 'APPROVED') {
          let data123: any = data?.timesheet_data[j]
          regulararray.push(data123)
        }
      }
      TotalSumofHoursEntry(regulararray)

      let updateTimesheetDataArray = []
      for (let i = 0; i < data.timesheet_data.length; i++) {
        if (
          Number(data.timesheet_data[i].regular_hours) > 0 ||
          Number(data.timesheet_data[i].regular_minutes) > 0 ||
          Number(data.timesheet_data[i].ot_hours) > 0 ||
          Number(data.timesheet_data[i].ot_minutes) > 0 ||
          Number(data.timesheet_data[i].absent_hours) > 0 ||
          Number(data.timesheet_data[i].absent_minutes) > 0 ||
          Number(data.timesheet_data[i].absent_type) == 2 ||
          Number(data.timesheet_data[i].id)
        ) {
          updateTimesheetDataArray.push(data.timesheet_data[i])
        }
      }
      if (weekDayDataEntry[index].user_notes?.length > 0) {
        ClientManagerNameValidation[index].ccValidation = false
      } else {
        ClientManagerNameValidation[index].ccValidation = true
      }
      if (weekDayDataEntry[index].client_manager_name?.length > 0) {
        ClientManagerNameValidation[index].cmValidation = false
      } else {
        ClientManagerNameValidation[index].cmValidation = true
      }
      if (!disableWorkLocation || weekDayDataEntry[index].work_country?.length > 0) {
        WorkLocationValidation[index].countryValidation = false
      } else {
        WorkLocationValidation[index].countryValidation = true
      }
      if (!disableWorkLocation || weekDayDataEntry[index].work_state?.length > 0) {
        WorkLocationValidation[index].stateValidation = false
      } else {
        WorkLocationValidation[index].stateValidation = true
      }
      if (!disableWorkLocation || weekDayDataEntry[index].work_city?.length > 0) {
        WorkLocationValidation[index].cityValidation = false
      } else {
        WorkLocationValidation[index].cityValidation = true
      }
      if (workPopModifyMessage != 'modify') {
        //before click on modify
        if (dayss >= 90) {
          WorkLocationAllPopLength += 1
        } else {
          setWorkPopMessage('')
          setWorkPopModifyMessage('')
        }
      }
      let LeaveHoursSplit = sumofAllProjLeave[index]
      let LeaveHoursSplit1 = LeaveHoursSplit && LeaveHoursSplit.split(':')
      let arr2 = LeaveHoursSplit1 && LeaveHoursSplit1[0]
      let arr3 = LeaveHoursSplit1 && LeaveHoursSplit1[1]
      let timesheet1 = sumofAllProj[index] == '0:00' && (arr2 >= 0 || arr3 >= 0)
      // if (filteredDocsBasedonProjId?.length > 0) {
      // if (filteredDocsBasedonProjId?.length > 0 || timesheet1 || !list.is_placement_project) {
      if (
        filteredDocsBasedonProjId?.length > 0 ||
        timesheet1 ||
        list.is_placement_project == 2 ||
        list.is_placement_project == 3
      ) {
        ClientManagerNameValidation[index].ucValidation = false
      } else {
        ClientManagerNameValidation[index].ucValidation = true
      }
      if (
        updateTimesheetDataArray.length > 0 &&
        weekDayDataEntry[index].user_notes?.length > 0 &&
        weekDayDataEntry[index].client_manager_name?.length > 0 &&
        (!disableWorkLocation || weekDayDataEntry[index].work_country?.length > 0) &&
        (!disableWorkLocation || weekDayDataEntry[index].work_state?.length > 0) &&
        (!disableWorkLocation || weekDayDataEntry[index].work_city?.length > 0) &&
        // weekDayDataEntry[index].client_manager_email?.length > 0 &&
        // (filteredDocsBasedonProjId?.length > 0 || timesheet1 || !list.is_placement_project)
        (filteredDocsBasedonProjId?.length > 0 ||
          timesheet1 ||
          list.is_placement_project == 2 ||
          list.is_placement_project == 3)
      ) {
        ClientManagerNameValidation[index].cmValidation = false
        ClientManagerNameValidation[index].ccValidation = false
        ClientManagerNameValidation[index].ucValidation = false
        WorkLocationValidation[index].countryValidation = false
        WorkLocationValidation[index].stateValidation = false
        WorkLocationValidation[index].cityValidation = false

        // setSubmitOpen(true)
        // setSubmitShow(true)

        if (WorkLocationAllPopLength > 0) {
          setWorkLocationShow(true)
        } else {
          setSubmitOpen(true)
          setSubmitShow(true)
        }

        // let wcountry =
        //   weekDayDataEntry[index].work_country == list.ts_work_country ||
        //   weekDayDataEntry[index].work_country == list.work_country

        // let ws =
        //   weekDayDataEntry[index].work_state == list.ts_work_state ||
        //   weekDayDataEntry[index].work_state == list.work_state

        // let wc =
        //   weekDayDataEntry[index].work_city == list.ts_work_city ||
        //   weekDayDataEntry[index].work_city == list.work_city
        // if (!wcountry || !ws || !wc) {
        //   setWorkPopMessage('')
        //   setSubmitOpen(true)
        //   setSubmitShow(true)
        // } else {
        //   if (wcountry && ws && wc && dayss >= 90) {
        //     setWorkLocationShow(true)
        //   } else {
        //     setWorkPopMessage('')
        //     setSubmitOpen(true)
        //     setSubmitShow(true)
        //   }
        // }
      } else {
        // ClientManagerNameValidation[index] = true
        // ClientManagerNameValidation[index].cmValidation = true
        // ClientManagerNameValidation[index].ccValidation = true
        // ClientManagerNameValidation[index].ucValidation = true
        // WorkLocationValidation[index].countryValidation = false
        // WorkLocationValidation[index].stateValidation = false
        // WorkLocationValidation[index].cityValidation = false
        // toast.warn('Please fill all the required fields to submit the timesheet')
        setShowBackDrop(false)
      }
    } else if (fromVal === 'handleSubmit') {
      let filteredDocsBasedonProjId =
        docResultDataRes &&
        docResultDataRes.filter(
          (each: any) => each.project_id === weekDayDataEntry[index]?.project_id
        )
      let LeaveHoursSplit = sumofAllProjLeave[index]
      let LeaveHoursSplit1 = LeaveHoursSplit && LeaveHoursSplit.split(':')
      let arr2 = LeaveHoursSplit1 && LeaveHoursSplit1[0]
      let arr3 = LeaveHoursSplit1 && LeaveHoursSplit1[1]

      let timesheet1 = sumofAllProj[index] == '0:00' && (arr2 >= 0 || arr3 >= 0)
      let today = moment(new Date()).format('YYYY-MM-DD')
      let pastDay: any = moment(employeesProjectList[index].work_location_updatedate).format(
        'YYYY-MM-DD'
      )
      let startt = moment(today)
      let enddd = moment(pastDay)
      let duration1 = moment.duration(startt.diff(enddd))
      let dayss = duration1.asDays()

      setShowBackDrop(true)
      let wcountry =
        weekDayDataEntry[index].work_country == employeesProjectList[index].ts_work_country ||
        weekDayDataEntry[index].work_country == employeesProjectList[index].work_country

      let ws =
        weekDayDataEntry[index].work_state == employeesProjectList[index].ts_work_state ||
        weekDayDataEntry[index].work_state == employeesProjectList[index].work_state

      let wc =
        weekDayDataEntry[index].work_city == employeesProjectList[index].ts_work_city ||
        weekDayDataEntry[index].work_city == employeesProjectList[index].work_city

      if (workPopModifyMessage == 'modify') {
        //after click on modify
        if (dayss >= 90) {
          if (!wcountry || !ws || !wc) {
            //changed
            weekDayDataEntry[index].user_same_work_location = false
            setWorkPopMessage('')
          } else {
            weekDayDataEntry[index].user_same_work_location = true
          }
        }
      } else {
        weekDayDataEntry[index].user_same_work_location = false
      }

      if (
        weekDayDataEntry[index]?.user_notes?.length > 0 &&
        weekDayDataEntry[index]?.client_manager_name?.length > 0 &&
        // weekDayDataEntry[index]?.client_manager_email?.length > 0 &&
        // (filteredDocsBasedonProjId?.length > 0 || timesheet1 || !list.is_placement_project)
        (filteredDocsBasedonProjId?.length > 0 ||
          timesheet1 ||
          list.is_placement_project == 2 ||
          list.is_placement_project == 3)
      ) {
        const updatedData = splitHoursAndMinutes(weekDayDataEntry[index])
        if (workPopMessage == 'continue') {
          weekDayDataEntry[index]['user_same_work_location'] = true
        } else if (workPopModifyMessage == 'modify') {
          weekDayDataEntry[index]['user_same_work_location'] =
            weekDayDataEntry[index].user_same_work_location
        } else {
          weekDayDataEntry[index]['user_same_work_location'] = false
        }

        const data = updatedData

        let updateTimesheetDataArray = []
        for (let i = 0; i < data.timesheet_data.length; i++) {
          if (
            Number(data.timesheet_data[i].regular_hours) > 0 ||
            Number(data.timesheet_data[i].regular_minutes) > 0 ||
            Number(data.timesheet_data[i].ot_hours) > 0 ||
            Number(data.timesheet_data[i].ot_minutes) > 0 ||
            Number(data.timesheet_data[i].absent_hours) > 0 ||
            Number(data.timesheet_data[i].absent_minutes) > 0 ||
            Number(data.timesheet_data[i].absent_type) == 2 ||
            Number(data.timesheet_data[i].id)
          ) {
            updateTimesheetDataArray.push(data.timesheet_data[i])
          }
        }

        if (updateTimesheetDataArray.length > 0) {
          let sat_check = updateTimesheetDataArray.find(
            (f1: any) => f1.timesheet_date == weekDates[5]
          )

          let sun_check = updateTimesheetDataArray.find(
            (f1: any) => f1.timesheet_date == weekDates[6]
          )

          if (!sat_check) {
            updateTimesheetDataArray.push({
              timesheet_date: weekDates[5],
              regular_hours: 0,
              regular_minutes: 0,
              ot_hours: 0,
              ot_minutes: 0,
              absent_type: null,
              absent_hours: 0,
              absent_minutes: 0,
              status: '',
              qb_timesheet_id: null,
              qb_status: null,
            })
          }
          if (!sun_check) {
            updateTimesheetDataArray.push({
              timesheet_date: weekDates[6],
              regular_hours: 0,
              regular_minutes: 0,
              ot_hours: 0,
              ot_minutes: 0,
              absent_type: null,
              absent_hours: 0,
              absent_minutes: 0,
              status: '',
              qb_timesheet_id: null,
              qb_status: null,
            })
          }
          data.timesheet_data = updateTimesheetDataArray

          const timeSheetSingleProjectSubmit: any = await Apiservices.timeSheetSingleProjectSubmit(
            data
          )

          if (numArray?.includes('timesheetadmin')) {
            if (docResultDataRes) {
              let docarr = []
              for (let each of docResultDataRes) {
                if (each.previous_doc) {
                  docarr.push(each.id)
                }
              }

              const updateDocsData = {
                user_id: data.user_id,
                project_id: data.project_id,
                week_start_date: weekStartDate,
                week_end_date: weekEndDate,
                documents: docarr,
              }
              if (docarr && docarr.length > 0) {
                var copyDocs: any = await Apiservices.copyTimesheetDocs(updateDocsData)
                if (copyDocs.message == 'Added Successfully') {
                  setPrevAr([])
                }
              }
            }
          }
          handlerForResetDataAndShowToasters(
            timeSheetSingleProjectSubmit.message,
            timeSheetSingleProjectSubmit.isError
          )
        } else {
          // toast.warn('Please fill all the required fields to submit the timesheet')
          setShowBackDrop(false)
        }
      } else {
        // toast.warn('Please fill all the required fields to submit the timesheet')

        setShowBackDrop(false)
      }
    }
    for (let w = 0; w < weekDayDataEntry[index].timesheet_data.length; w++) {
      if (
        weekDayDataEntry[index].timesheet_data[w].regular_hours ||
        weekDayDataEntry[index].timesheet_data[w].regular_minutes
      ) {
        let rMinutes =
          weekDayDataEntry[index].timesheet_data[w].regular_minutes <= 9
            ? '0' + weekDayDataEntry[index].timesheet_data[w].regular_minutes
            : weekDayDataEntry[index].timesheet_data[w].regular_minutes
        weekDayDataEntry[index].timesheet_data[w].regular_hours =
          weekDayDataEntry[index].timesheet_data[w].regular_hours + ':' + rMinutes
      }
      if (
        weekDayDataEntry[index].timesheet_data[w].ot_hours ||
        weekDayDataEntry[index].timesheet_data[w].ot_minutes
      ) {
        let otMinutes =
          weekDayDataEntry[index].timesheet_data[w].ot_minutes <= 9
            ? '0' + weekDayDataEntry[index].timesheet_data[w].ot_minutes
            : weekDayDataEntry[index].timesheet_data[w].ot_minutes
        weekDayDataEntry[index].timesheet_data[w].ot_hours =
          weekDayDataEntry[index].timesheet_data[w].ot_hours + ':' + otMinutes
      }
      if (
        weekDayDataEntry[index].timesheet_data[w].absent_hours ||
        weekDayDataEntry[index].timesheet_data[w].absent_minutes
      ) {
        let absentMinutes =
          weekDayDataEntry[index].timesheet_data[w].absent_minutes <= 9
            ? '0' + weekDayDataEntry[index].timesheet_data[w].absent_minutes
            : weekDayDataEntry[index].timesheet_data[w].absent_minutes
        weekDayDataEntry[index].timesheet_data[w].absent_hours =
          weekDayDataEntry[index].timesheet_data[w].absent_hours + ':' + absentMinutes
      }
    }
    // setweekDayDataEntry([...weekDayDataEntry])
  }

  const handleSubmittedAll = (e: any, index: any) => {
    setSubmitAllOpen(true)
    setSubmitAllShow(false)
    FormSubmitAllProjects(index, 'handleSubmit')
  }

  const modalPopforSubmitAll = () => {
    setSubmitAllOpen(false)
    setSubmitAllShow(false)
    setShowBackDrop(false)
  }
  const TotalSumofHoursEntry = (regulararray: any) => {
    let totalRegularHoursArr: any = []
    let totalOtHoursArr: any = []
    let totalLeaveHoursArr: any = []
    const sumofhour = regulararray?.reduce(function (acc: any, data: any) {
      return acc + Number(data?.regular_hours)
    }, 0)
    const sumofmin = regulararray?.reduce(function (acc: any, data: any) {
      return acc + Number(data?.regular_minutes)
    }, 0)
    const sumofOthour = regulararray?.reduce(function (acc: any, data: any) {
      return acc + Number(data?.ot_hours)
    }, 0)
    const sumofOtmin = regulararray?.reduce(function (acc: any, data: any) {
      return acc + Number(data?.ot_minutes)
    }, 0)
    const sumofLeavehour = regulararray?.reduce(function (acc: any, data: any) {
      return acc + Number(data?.absent_hours)
    }, 0)
    const sumofLeavemin = regulararray?.reduce(function (acc: any, data: any) {
      return acc + Number(data?.absent_minutes)
    }, 0)
    const total_hour = sumofhour * 60
    const total_minutes = total_hour + sumofmin
    const total_ot_hour = sumofOthour * 60
    const total_ot_minutes = total_ot_hour + sumofOtmin
    const total_leave_hour = sumofLeavehour * 60
    const total_leave_minutes = total_leave_hour + sumofLeavemin
    const rh =
      Math.floor(total_minutes / 60) <= 9
        ? '0' + Math.floor(total_minutes / 60)
        : Math.floor(total_minutes / 60)
    const rm = total_minutes % 60 <= 9 ? '0' + (total_minutes % 60) : total_minutes % 60
    const total_sum_of_regular_hours = `${rh}:${rm}`
    const oth =
      Math.floor(total_ot_minutes / 60) <= 9
        ? '0' + Math.floor(total_ot_minutes / 60)
        : Math.floor(total_ot_minutes / 60)
    const otm = total_ot_minutes % 60 <= 9 ? '0' + (total_ot_minutes % 60) : total_ot_minutes % 60
    const total_sum_of_ot_hours = `${oth}:${otm}`

    const lh =
      Math.floor(total_leave_minutes / 60) <= 9
        ? '0' + Math.floor(total_leave_minutes / 60)
        : Math.floor(total_leave_minutes / 60)
    const lm =
      total_leave_minutes % 60 <= 9 ? '0' + (total_leave_minutes % 60) : total_leave_minutes % 60
    const total_sum_of_leave_hours = `${lh}:${lm}`

    totalRegularHoursArr.push(total_sum_of_regular_hours)
    totalOtHoursArr.push(total_sum_of_ot_hours)
    totalLeaveHoursArr.push(total_sum_of_leave_hours)

    setSumofRegularHoursProject(totalRegularHoursArr)
    setSumofOtHoursProject(totalOtHoursArr)
    setSumofLeaveHoursProject(totalLeaveHoursArr)
  }

  const WorkLocationAllContinuePop = (e: any, alert_any: any, index: any) => {
    setWorkAllPopMessage(alert_any)
    setWorkAllPopModifyMessage('')
    setWorkLocationAllShow(false)
    setSubmitAllOpen(true)
    setSubmitAllShow(true)
  }
  const WorkLocationAllOkPop = (e: any, alert: any) => {
    setWorkAllPopMessage('')
    setWorkAllPopModifyMessage(alert)
    var workCountrySetting: any = []
    var workStateSetting: any = []
    weekDayDataEntry.map((items: any, index: any) => {
      let today = moment(new Date()).format('YYYY-MM-DD')
      let pastDay: any = moment(employeesProjectList[index].work_location_updatedate).format(
        'YYYY-MM-DD'
      )
      let startt = moment(today)
      let enddd = moment(pastDay)
      let duration1 = moment.duration(startt.diff(enddd))
      let dayss = duration1.asDays()
      if (dayss >= 90) {
        weekDayDataEntry[index].work_country = ''
        weekDayDataEntry[index].work_state = ''
        weekDayDataEntry[index].work_city = ''
        // employeesProjectList[index].ts_work_city = ''
        // employeesProjectList[index].work_city = ''
        // setEmployeesProjectList([...employeesProjectList])
        setweekDayDataEntry([...weekDayDataEntry])
        let data = [
          {
            name: weekDayDataEntry[index].work_country
              ? weekDayDataEntry[index].work_country
              : null,
          },
        ]
        let data1 = [
          {
            name: weekDayDataEntry[index].work_state ? weekDayDataEntry[index].work_state : null,
          },
        ]
        workCountrySetting[index] = data
        workStateSetting[index] = data1
      } else {
        let data = [
          {
            name: weekDayDataEntry[index].work_country
              ? weekDayDataEntry[index].work_country
              : null,
          },
        ]
        let data1 = [
          {
            name: weekDayDataEntry[index].work_state ? weekDayDataEntry[index].work_state : null,
          },
        ]
        workCountrySetting[index] = data
        workStateSetting[index] = data1
      }
      setWorkLocationAllShow(false)
    })
    setweekDayDataEntry([...weekDayDataEntry])
    setCountries(workCountrySetting)
    setStates(workStateSetting)
  }
  const FormSubmitAllProjects = async (index: any, fromVal: any) => {
    setShowBackDrop(true)
    setindexState(index)
    if (fromVal !== 'handleSubmit') {
      const updatedData = splitHoursAndMinutes(weekDayDataEntry)
      let validProjLength = 0
      let issuesProjectLength = 0
      let WorkLocationAllPopLength = 0
      weekDayDataEntry.map((items: any, index: any) => {
        let today = moment(new Date()).format('YYYY-MM-DD')
        let pastDay: any = moment(employeesProjectList[index].work_location_updatedate).format(
          'YYYY-MM-DD'
        )
        let startt = moment(today)
        let enddd = moment(pastDay)
        let duration1 = moment.duration(startt.diff(enddd))
        let dayss = duration1.asDays()
        let Document =
          docResultDataRes &&
          docResultDataRes.filter((doc: any) => doc.project_id === items.project_id)
        if (items.user_notes == null || items.user_notes == '' || items.user_notes == undefined) {
          ClientManagerNameValidation[index].ccValidation = true
        } else {
          ClientManagerNameValidation[index].ccValidation = false
        }
        if (
          items.client_manager_name == null ||
          items.client_manager_name == '' ||
          items.client_manager_name == undefined
        ) {
          ClientManagerNameValidation[index].cmValidation = true
        } else {
          ClientManagerNameValidation[index].cmValidation = false
        }
        if (
          disableWorkLocation &&
          (items.work_country == null ||
            items.work_country == '' ||
            items.work_country == undefined)
        ) {
          WorkLocationValidation[index].countryValidation = true
        } else {
          WorkLocationValidation[index].countryValidation = false
        }
        if (
          disableWorkLocation &&
          (items.work_state == null || items.work_state == '' || items.work_state == undefined)
        ) {
          WorkLocationValidation[index].stateValidation = true
        } else {
          WorkLocationValidation[index].stateValidation = false
        }
        if (
          disableWorkLocation &&
          (items.work_city == null || items.work_city == '' || items.work_city == undefined)
        ) {
          WorkLocationValidation[index].cityValidation = true
        } else {
          WorkLocationValidation[index].cityValidation = false
        }
        let Timesheet = items?.timesheet_data?.filter(
          (test: any) =>
            test.regular_hours == 0 && (test.absent_hours > 0 || test.absent_minutes > 0)
        )
        let Timesheet1 = items?.timesheet_data?.filter((test: any) => test.regular_hours > 0)

        if (Document?.length === 0 || Document == undefined || Document == null || Document == '') {
          // if (employeesProjectList[index].is_placement_project) {
          if (employeesProjectList[index].is_placement_project == 1) {
            if (Timesheet?.length == 0 || Timesheet1.length > 0) {
              ClientManagerNameValidation[index].ucValidation = true
            }
          } else {
            ClientManagerNameValidation[index].ucValidation = false
          }
        } else {
          ClientManagerNameValidation[index].ucValidation = false
        }

        if (
          items.user_notes == null ||
          items.user_notes == '' ||
          items.user_notes == undefined ||
          items.client_manager_name == null ||
          items.client_manager_name == '' ||
          items.client_manager_name == undefined ||
          (disableWorkLocation &&
            (items.work_country == null ||
              items.work_country == '' ||
              items.work_country == undefined ||
              items.work_state == null ||
              items.work_state == '' ||
              items.work_state == undefined ||
              items.work_city == null ||
              items.work_city == '' ||
              items.work_city == undefined)) ||
          // items.client_manager_email == null ||
          // items.client_manager_email == '' ||
          // items.client_manager_email == undefined ||
          Document?.length === 0 ||
          Document == undefined ||
          Document == null ||
          Document == ''
        ) {
          // issuesProjectLength += 1
          if (
            // (employeesProjectList[index].is_placement_project &&
            (employeesProjectList[index].is_placement_project == 1 &&
              (Timesheet?.length == 0 || Timesheet1.length > 0)) ||
            items.user_notes == null ||
            items.user_notes == '' ||
            items.user_notes == undefined ||
            items.client_manager_name == null ||
            items.client_manager_name == '' ||
            items.client_manager_name == undefined ||
            (disableWorkLocation &&
              (items.work_country == null ||
                items.work_country == '' ||
                items.work_country == undefined ||
                items.work_state == null ||
                items.work_state == '' ||
                items.work_state == undefined ||
                items.work_city == null ||
                items.work_city == '' ||
                items.work_city == undefined))
          ) {
            issuesProjectLength += 1
          } else {
            validProjLength += 1
          }
        } else {
          validProjLength += 1
        }

        if (workAllPopModifyMessage != 'modify') {
          //before click on modify
          if (dayss >= 90) {
            WorkLocationAllPopLength += 1
          } else {
            setWorkAllPopMessage('')
          }
        }
      })
      if (issuesProjectLength > 0) {
        // toast.warn(`Please fill all the required fields to submit the timesheet`)
      }

      if (weekDayDataEntry.length === validProjLength) {
        const data = updatedData
        let regulararray = []

        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].timesheet_data?.length; j++) {
            if (data[i].timesheet_data[j].status != 'APPROVED') {
              let data123: any = data[i].timesheet_data[j]
              regulararray.push(data123)
            }
          }
        }
        TotalSumofHoursEntry(regulararray)

        let validWeekLength = 0

        for (let j = 0; j < data.length; j++) {
          let updateTimesheetDataArray = []
          let duplicateWeekEntry = []
          for (let i = 0; i < data[j].timesheet_data.length; i++) {
            if (
              Number(data[j].timesheet_data[i].regular_hours) > 0 ||
              Number(data[j].timesheet_data[i].regular_minutes) > 0 ||
              Number(data[j].timesheet_data[i].ot_hours) > 0 ||
              Number(data[j].timesheet_data[i].ot_minutes) > 0 ||
              Number(data[j].timesheet_data[i].absent_hours) > 0 ||
              Number(data[j].timesheet_data[i].absent_minutes) > 0 ||
              Number(data[j].timesheet_data[i].absent_type) == 2
            ) {
              updateTimesheetDataArray.push(data[j].timesheet_data[i])
            }
            duplicateWeekEntry.push(data[j].timesheet_data[i])
          }

          if (updateTimesheetDataArray.length > 0) {
            data[j].timesheet_data = updateTimesheetDataArray
            validWeekLength += 1
          } else {
            toast.warn(`Timesheets data are missing `)
          }
          weekDayDataEntry[j].timesheet_data = duplicateWeekEntry
        }

        if (data.length === validWeekLength) {
          if (WorkLocationAllPopLength > 0) {
            setWorkLocationAllShow(true)
          } else {
            setSubmitAllOpen(true)
            setSubmitAllShow(true)
          }
        }
      }

      setweekDayDataEntry([...weekDayDataEntry])
    } else if (fromVal === 'handleSubmit') {
      const updatedData = splitHoursAndMinutes(weekDayDataEntry)
      let validProjLength = 0
      let issuesProjectLength = 0

      weekDayDataEntry.map((items: any, index: any) => {
        let Document =
          docResultDataRes &&
          docResultDataRes.filter((doc: any) => doc.project_id === items.project_id)

        let Timesheet = items?.timesheet_data?.filter(
          (test: any) =>
            test.regular_hours == 0 && (test.absent_hours > 0 || test.absent_minutes > 0)
        )
        let Timesheet1 = items?.timesheet_data?.filter((test: any) => test.regular_hours > 0)

        if (
          items.user_notes == null ||
          items.user_notes == '' ||
          items.user_notes == undefined ||
          items.client_manager_name == null ||
          items.client_manager_name == '' ||
          items.client_manager_name == undefined ||
          (disableWorkLocation &&
            (items.work_country == null ||
              items.work_country == '' ||
              items.work_country == undefined ||
              items.work_state == null ||
              items.work_state == '' ||
              items.work_state == undefined ||
              items.work_city == null ||
              items.work_city == '' ||
              items.work_city == undefined)) ||
          // items.client_manager_email == null ||
          // items.client_manager_email == '' ||
          // items.client_manager_email == undefined ||
          Document?.length === 0 ||
          Document == undefined ||
          Document == null ||
          Document == ''
        ) {
          // issuesProjectLength += 1
          if (
            // (employeesProjectList[index].is_placement_project &&
            (employeesProjectList[index].is_placement_project == 1 &&
              (Timesheet?.length == 0 || Timesheet1.length > 0)) ||
            items.user_notes == null ||
            items.user_notes == '' ||
            items.user_notes == undefined ||
            items.client_manager_name == null ||
            items.client_manager_name == '' ||
            items.client_manager_name == undefined ||
            (disableWorkLocation &&
              (items.work_country == null ||
                items.work_country == '' ||
                items.work_country == undefined ||
                items.work_state == null ||
                items.work_state == '' ||
                items.work_state == undefined ||
                items.work_city == null ||
                items.work_city == '' ||
                items.work_city == undefined))
          ) {
            issuesProjectLength += 1
          } else {
            validProjLength += 1
          }
        } else {
          validProjLength += 1
        }
      })
      if (issuesProjectLength > 0) {
        // toast.warn(`Please fill all the required fields to submit the timesheet`)
      }

      if (weekDayDataEntry.length === validProjLength) {
        const data = updatedData
        let validWeekLength = 0
        weekDayDataEntry.map((items: any, index: any) => {
          let today = moment(new Date()).format('YYYY-MM-DD')
          let pastDay: any = moment(employeesProjectList[index].work_location_updatedate).format(
            'YYYY-MM-DD'
          )
          let startt = moment(today)
          let enddd = moment(pastDay)
          let duration1 = moment.duration(startt.diff(enddd))
          let dayss = duration1.asDays()

          let wcountry =
            items.work_country == employeesProjectList[index]?.ts_work_country ||
            items.work_country == employeesProjectList[index]?.work_country

          let ws =
            items.work_state == employeesProjectList[index]?.ts_work_state ||
            items.work_state == employeesProjectList[index]?.work_state

          let wc =
            items.work_city == employeesProjectList[index]?.ts_work_city ||
            items.work_city == employeesProjectList[index]?.work_city

          if (workAllPopModifyMessage == 'modify') {
            //after click on modify
            if (dayss >= 90) {
              if (!wcountry || !ws || !wc) {
                //changed
                weekDayDataEntry[index].user_same_work_location = false
                setWorkAllPopMessage('')
              } else {
                weekDayDataEntry[index].user_same_work_location = true
              }
            }
          } else {
            weekDayDataEntry[index].user_same_work_location = false
          }

          if (workAllPopMessage == 'continue') {
            weekDayDataEntry[index]['user_same_work_location'] = true
          } else if (workAllPopModifyMessage == 'modify') {
            weekDayDataEntry[index]['user_same_work_location'] =
              weekDayDataEntry[index].user_same_work_location
          } else {
            weekDayDataEntry[index]['user_same_work_location'] = false
          }
        })

        for (let j = 0; j < data.length; j++) {
          let updateTimesheetDataArray = []
          for (let i = 0; i < data[j].timesheet_data.length; i++) {
            if (
              Number(data[j].timesheet_data[i].regular_hours) > 0 ||
              Number(data[j].timesheet_data[i].regular_minutes) > 0 ||
              Number(data[j].timesheet_data[i].ot_hours) > 0 ||
              Number(data[j].timesheet_data[i].ot_minutes) > 0 ||
              Number(data[j].timesheet_data[i].absent_hours) > 0 ||
              Number(data[j].timesheet_data[i].absent_minutes) > 0 ||
              Number(data[j].timesheet_data[i].absent_type) == 2
            ) {
              updateTimesheetDataArray.push(data[j].timesheet_data[i])
            }
          }

          for (let j = 0; j < data.length; j++) {
            let sat_check = updateTimesheetDataArray.find(
              (f1: any) => f1.timesheet_date == weekDates[5]
            )

            if (!sat_check) {
              updateTimesheetDataArray.push({
                timesheet_date: weekDates[5],
                regular_hours: 0,
                regular_minutes: 0,
                ot_hours: 0,
                ot_minutes: 0,
                absent_type: null,
                absent_hours: 0,
                absent_minutes: 0,
                status: '',
                qb_timesheet_id: null,
                qb_status: null,
              })
            }
            let sun_check = updateTimesheetDataArray.find(
              (f1: any) => f1.timesheet_date == weekDates[6]
            )
            if (!sun_check) {
              updateTimesheetDataArray.push({
                timesheet_date: weekDates[6],
                regular_hours: 0,
                regular_minutes: 0,
                ot_hours: 0,
                ot_minutes: 0,
                absent_type: null,
                absent_hours: 0,
                absent_minutes: 0,
                status: '',
                qb_timesheet_id: null,
                qb_status: null,
              })
            }
          }

          if (updateTimesheetDataArray.length > 0) {
            data[j].timesheet_data = updateTimesheetDataArray
            data[j].ot_product = data[j].ot_product
            validWeekLength += 1
          } else {
            toast.warn(`Timesheets data are missing `)
          }
        }

        if (data.length === validWeekLength) {
          const updatedSendingData = {
            year: moment(weekStartDate).format('YYYY'),
            month: moment(weekStartDate).format('MM'),
            week_start_date: weekStartDate,
            week_end_date: weekEndDate,
            timesheets: data,
          }
          const timeSheetAllProjectSubmit: any = await Apiservices.timeSheetSubmitAllProject(
            updatedSendingData
          )
          if (numArray?.includes('timesheetadmin')) {
            if (docResultDataRes) {
              for (let j = 0; j < data.length; j++) {
                let docarr = []
                for (let each of docResultDataRes) {
                  if (data[j].project_id == each.project_id)
                    if (each.previous_doc) {
                      docarr.push(each.id)
                    }
                }

                const updateDocsData = {
                  user_id: data[j].user_id,
                  project_id: data[j].project_id,
                  week_start_date: weekStartDate,
                  week_end_date: weekEndDate,
                  documents: docarr,
                }
                if (docarr && docarr.length > 0) {
                  var copyDocs: any = await Apiservices.copyTimesheetDocs(updateDocsData)
                  if (copyDocs.message == 'Added Successfully') {
                    setPrevAr([])
                  }
                }
              }
            }
          }
          handlerForResetDataAndShowToasters(
            timeSheetAllProjectSubmit.message,
            timeSheetAllProjectSubmit.isError
          )
        }
      }
    }
    for (let i = 0; i < weekDayDataEntry.length; i++) {
      for (let w = 0; w < weekDayDataEntry[i].timesheet_data.length; w++) {
        if (
          weekDayDataEntry[i].timesheet_data[w].regular_hours ||
          weekDayDataEntry[i].timesheet_data[w].regular_minutes
        ) {
          let rMinutes =
            weekDayDataEntry[i].timesheet_data[w].regular_minutes <= 9
              ? '0' + weekDayDataEntry[i].timesheet_data[w].regular_minutes
              : weekDayDataEntry[i].timesheet_data[w].regular_minutes
          weekDayDataEntry[i].timesheet_data[w].regular_hours =
            weekDayDataEntry[i].timesheet_data[w].regular_hours + ':' + rMinutes
        }
        if (
          weekDayDataEntry[i].timesheet_data[w].ot_hours ||
          weekDayDataEntry[i].timesheet_data[w].ot_minutes
        ) {
          weekDayDataEntry[i].timesheet_data[w].ot_hours =
            weekDayDataEntry[i].timesheet_data[w].ot_hours +
            ':' +
            weekDayDataEntry[i].timesheet_data[w].ot_minutes
        }
        if (
          weekDayDataEntry[i].timesheet_data[w].absent_hours ||
          weekDayDataEntry[i].timesheet_data[w].absent_minutes
        ) {
          weekDayDataEntry[i].timesheet_data[w].absent_hours =
            weekDayDataEntry[i].timesheet_data[w].absent_hours +
            ':' +
            weekDayDataEntry[i].timesheet_data[w].absent_minutes
        }
      }
    }
    setweekDayDataEntry([...weekDayDataEntry])
    setShowBackDrop(false)
  }
  const FormSaveAll = async (index: any) => {
    setShowBackDrop(true)
    const updatedData = splitHoursAndMinutes(weekDayDataEntry)
    const data = updatedData
    let validWeekLength = 0
    for (let j = 0; j < data.length; j++) {
      let updateTimesheetDataArray = []
      for (let i = 0; i < data[j].timesheet_data.length; i++) {
        if (
          Number(data[j].timesheet_data[i].regular_hours) > 0 ||
          Number(data[j].timesheet_data[i].regular_minutes) > 0 ||
          Number(data[j].timesheet_data[i].ot_hours) > 0 ||
          Number(data[j].timesheet_data[i].ot_minutes) > 0 ||
          Number(data[j].timesheet_data[i].absent_hours) > 0 ||
          Number(data[j].timesheet_data[i].absent_minutes) > 0 ||
          Number(data[j].timesheet_data[i].absent_type) == 2 ||
          Number(data[j].timesheet_data[i].id)
        ) {
          updateTimesheetDataArray.push(data[j].timesheet_data[i])
        }
      }
      if (updateTimesheetDataArray.length > 0) {
        data[j].timesheet_data = updateTimesheetDataArray
        data[j].ot_product = data[j].ot_product
        validWeekLength += 1
      } else {
        toast.warn(`Timesheet entries are missing `)
      }
    }
    if (data.length === validWeekLength) {
      const updatedSendingData = {
        year: moment(weekStartDate).format('YYYY'),
        month: moment(weekStartDate).format('MM'),
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        timesheets: data,
      }
      var saveAllProjects: any = await Apiservices.timeSheetSaveAllInsert(updatedSendingData)
      if (numArray?.includes('timesheetadmin')) {
        if (docResultDataRes) {
          for (let j = 0; j < data.length; j++) {
            let docarr = []
            for (let each of docResultDataRes) {
              if (data[j].project_id == each.project_id)
                if (each.previous_doc) {
                  docarr.push(each.id)
                }
            }
            const updateDocsData = {
              user_id: data[j].user_id,
              project_id: data[j].project_id,
              week_start_date: weekStartDate,
              week_end_date: weekEndDate,
              documents: docarr,
            }
            if (docarr && docarr.length > 0) {
              var copyDocs: any = await Apiservices.copyTimesheetDocs(updateDocsData)

              if (copyDocs.message == 'Added Successfully') {
                setPrevAr([])
              }
            }
          }
        }
      }
      handlerForResetDataAndShowToasters(saveAllProjects.message, saveAllProjects.isError)
    }
    setShowBackDrop(false)
  }
  const FormSingleProjectUnSubmit = async (index: any) => {
    setShowBackDrop(true)
    const data = weekDayDataEntry[index]
    const unSubmitData = {
      user_id: data.user_id,
      project_id: data.project_id,
      year: data.year,
      month: parseInt(data.month),
      week_start_date: data.week_start_date,
      week_end_date: data.week_end_date,
      ot_product: data.ot_product,
    }
    const singleProjectUnSubmit: any = await Apiservices.timeSheetUnSubmitSingleProject(
      unSubmitData
    )
    handlerForResetDataAndShowToasters(singleProjectUnSubmit.message, singleProjectUnSubmit.isError)
  }

  const FormUnSubmitAll = async (index: any) => {
    setShowBackDrop(true)
    const data = weekDayDataEntry
    var timesheetData = []
    for (let i = 0; i < data.length; i++) {
      var unSubmitData = {
        user_id: data[i].user_id,
        project_id: data[i].project_id,
        year: data[i].year,
        month: parseInt(data[i].month),
        week_start_date: data[i].week_start_date,
        week_end_date: data[i].week_end_date,
        ot_product: data[i].ot_product,
      }
      timesheetData.push(unSubmitData)
    }
    const unSubmitAllData = {
      year: moment(weekStartDate).format('YYYY'),
      month: moment(weekStartDate).format('MM'),
      week_start_date: weekStartDate,
      week_end_date: weekEndDate,
      timesheets: timesheetData,
    }
    const unSubmitAllProjects: any = await Apiservices.timeSheetUnSubmitAllProject(unSubmitAllData)
    handlerForResetDataAndShowToasters(unSubmitAllProjects.message, unSubmitAllProjects.isError)
  }
  const splitHoursAndMinutes = (data: any) => {
    if (data.length > 0) {
      for (let j = 0; j < data.length; j++) {
        for (let i = 0; i < data[j].timesheet_data.length; i++) {
          if (data[j].timesheet_data[i].regular_hours) {
            if (data[j].timesheet_data[i].regular_hours.toString().indexOf(':') > -1) {
              let splitRegularHours = data[j].timesheet_data[i].regular_hours.split(':')
              data[j].timesheet_data[i].regular_hours = parseInt(splitRegularHours[0])
              data[j].timesheet_data[i].regular_minutes = parseInt(splitRegularHours[1])
            }
          }
          if (data[j].timesheet_data[i].absent_hours) {
            if (data[j].timesheet_data[i].absent_hours.toString().indexOf(':') > -1) {
              let splitAbsentHours = data[j].timesheet_data[i].absent_hours.split(':')
              data[j].timesheet_data[i].absent_hours = parseInt(splitAbsentHours[0])
              data[j].timesheet_data[i].absent_minutes = parseInt(splitAbsentHours[1])
            }
          }
          if (data[j].timesheet_data[i].ot_hours) {
            if (data[j].timesheet_data[i].ot_hours.toString().indexOf(':') > -1) {
              let splitOTHours = data[j].timesheet_data[i].ot_hours.split(':')
              data[j].timesheet_data[i].ot_hours = parseInt(splitOTHours[0])
              data[j].timesheet_data[i].ot_minutes = parseInt(splitOTHours[1])
            }
          }
        }
      }
      return data
    } else {
      for (let i = 0; i < data.timesheet_data.length; i++) {
        if (data.timesheet_data[i].regular_hours) {
          if (data.timesheet_data[i].regular_hours.toString().indexOf(':') > -1) {
            let splitRegularHours = data.timesheet_data[i].regular_hours.split(':')
            data.timesheet_data[i].regular_hours = parseInt(splitRegularHours[0])
            data.timesheet_data[i].regular_minutes = parseInt(splitRegularHours[1])
          }
        }
        if (data.timesheet_data[i].absent_hours) {
          if (data.timesheet_data[i].absent_hours.toString().indexOf(':') > -1) {
            let splitAbsentHours = data.timesheet_data[i].absent_hours.split(':')
            data.timesheet_data[i].absent_hours = parseInt(splitAbsentHours[0])
            data.timesheet_data[i].absent_minutes = parseInt(splitAbsentHours[1])
          }
        }
        if (data.timesheet_data[i].ot_hours) {
          if (data.timesheet_data[i].ot_hours.toString().indexOf(':') > -1) {
            let splitOTHours = data.timesheet_data[i].ot_hours.split(':')
            data.timesheet_data[i].ot_hours = parseInt(splitOTHours[0])
            data.timesheet_data[i].ot_minutes = parseInt(splitOTHours[1])
          }
        }
      }

      return data
    }
  }
  const splitHoursAndMinutesTwo = (data: any) => {
    if (data.length > 0) {
      for (let j = 0; j < data.length; j++) {
        for (let i = 0; i < data[j].timesheet_data.length; i++) {
          if (data[j].timesheet_data[i].regular_hours) {
            if (data[j].timesheet_data[i].regular_hours.toString().indexOf(':') > -1) {
              let splitRegularHours = data[j].timesheet_data[i].regular_hours.split(':')
              data[j].timesheet_data[i].regular_hours = parseInt(splitRegularHours[0])
              data[j].timesheet_data[i].regular_minutes = parseInt(splitRegularHours[1])
            }
          } else {
            data[j].timesheet_data[i].regular_hours = 0
          }
          if (data[j].timesheet_data[i].absent_hours) {
            if (data[j].timesheet_data[i].absent_hours.toString().indexOf(':') > -1) {
              let splitAbsentHours = data[j].timesheet_data[i].absent_hours.split(':')
              data[j].timesheet_data[i].absent_hours = parseInt(splitAbsentHours[0])
              data[j].timesheet_data[i].absent_minutes = parseInt(splitAbsentHours[1])
            }
          } else {
            data[j].timesheet_data[i].absent_hours = 0
          }
          if (data[j].timesheet_data[i].ot_hours) {
            if (data[j].timesheet_data[i].ot_hours.toString().indexOf(':') > -1) {
              let splitOTHours = data[j].timesheet_data[i].ot_hours.split(':')
              data[j].timesheet_data[i].ot_hours = parseInt(splitOTHours[0])
              data[j].timesheet_data[i].ot_minutes = parseInt(splitOTHours[1])
            }
          } else {
            data[j].timesheet_data[i].ot_hours = 0
          }
        }
      }
      return data
    } else {
      for (let i = 0; i < data.timesheet_data.length; i++) {
        if (data.timesheet_data[i].regular_hours) {
          if (data.timesheet_data[i].regular_hours.toString().indexOf(':') > -1) {
            let splitRegularHours = data.timesheet_data[i].regular_hours.split(':')
            data.timesheet_data[i].regular_hours = parseInt(splitRegularHours[0])
            data.timesheet_data[i].regular_minutes = parseInt(splitRegularHours[1])
          }
        }
        if (data.timesheet_data[i].absent_hours) {
          if (data.timesheet_data[i].absent_hours.toString().indexOf(':') > -1) {
            let splitAbsentHours = data.timesheet_data[i].absent_hours.split(':')
            data.timesheet_data[i].absent_hours = parseInt(splitAbsentHours[0])
            data.timesheet_data[i].absent_minutes = parseInt(splitAbsentHours[1])
          }
        }
        if (data.timesheet_data[i].ot_hours) {
          if (data.timesheet_data[i].ot_hours.toString().indexOf(':') > -1) {
            let splitOTHours = data.timesheet_data[i].ot_hours.split(':')
            data.timesheet_data[i].ot_hours = parseInt(splitOTHours[0])
            data.timesheet_data[i].ot_minutes = parseInt(splitOTHours[1])
          }
        }
      }

      return data
    }
  }
  const handlerForResetDataAndShowToasters = (toasterMessage: any, isError: any) => {
    if (isError) {
      setEmployeesProjectList([])
      getDays(`${weekStartDate}-${weekEndDate}`)
      WarnToast(toasterMessage)
    } else {
      setEmployeesProjectList([])
      getDays(`${weekStartDate}-${weekEndDate}`)
      SuccessToast(toasterMessage)
    }
    setShowBackDrop(false)
  }

  const removeDoc1 = async (fileName: any, projectIndex: any, projectID: any) => {
    var formData = new FormData()
    const doc_id = documentId[projectIndex]
    formData.append('id', doc_id)
    let Doc = docResultDataRes[projectIndex]
    let arr = docResultDataRes
    if (Doc.previous_doc) {
      let x = arr.splice(projectIndex, 1)
      setDocResultDataRes([...arr])
      setPrevAr([...arr])
    } else {
      const response: any = await Apiservices.deleteDocId({id: fileName})

      const response1 = await Apiservices.docNameIdProjectList({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        project_id: projectID,
      })
      // setDocResultDataRes(response1.data.data)
      if (numArray?.includes('timesheetadmin')) {
        if (response1.data.data && response1.data.data.length > 0 && prevAr && prevAr.length > 0) {
          var test3 = [...prevAr, ...response1.data.data]
        } else if (response1.data.data?.length == null && prevAr?.length == null) {
          // var test3 = [...prevAr]
          var test3 = [response1?.data?.data]
        } else if (prevAr && prevAr?.length > 0 && response1.data.data?.length == null) {
          var test3 = [...prevAr]
        } else {
          var test3 = response1?.data?.data ? [...response1?.data?.data] : []
        }

        setDocResultDataRes(test3)
      } else {
        setDocResultDataRes(response1.data.data)
      }
      let DocumentsData = imagesArrayRes

      for (let each in imagesArrayRes) {
        if (projectID === imagesArrayRes[each].project_id) {
          DocumentsData[each].documents = response1.data.data !== null ? response1.data.data : []
        }
      }

      setImagesArrayRes(DocumentsData)
      setDocumentId([...documentId])
      // let userIdData5: any = localStorage.getItem('userid')
      // let userIdData5 =
      //   localStorage.getItem('role_Name') === 'timesheetadmin'
      //     ? localStorage.getItem('time_sheet_admin_screen_user_id')
      //     : localStorage.getItem('userid')
      let state2: any = location.state
      let userIdData5 =
        state2?.names == 'create'
          ? localStorage.getItem('time_sheet_admin_screen_user_id')
          : localStorage.getItem('userid')

      const getImages = await Apiservices.docNameIdProjectList1({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        user_id: userIdData5,
      })
      // setDocResultDataRes(getImages.data.data)
      if (numArray?.includes('timesheetadmin')) {
        if (
          getImages?.data?.data &&
          getImages?.data?.data?.length > 0 &&
          prevAr &&
          prevAr.length > 0
        ) {
          var test4 = [...prevAr, ...getImages?.data?.data]
        } else if (getImages?.data?.data?.length == null && prevAr?.length == null) {
          // var test4 = [...prevAr]
          var test4 = [getImages?.data?.data]
        } else if (prevAr && prevAr?.length > 0 && getImages?.data?.data?.length == null) {
          var test4 = [...prevAr]
        } else {
          var test4 = getImages?.data?.data ? [...getImages?.data?.data] : []
        }
        setDocResultDataRes(test4)
      } else {
        setDocResultDataRes(getImages.data.data)
      }
    }
  }

  const downloadFile = (blob: any, index: any) => {
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = blob
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  const viewFile = (blob: any, index: any) => {
    // window.open(blob, '_blank', 'noopener,noreferrer')
    // window.location.href = blob
    setViewOpen(true)
    setViewBlob(blob)
    setViewShow(true)
    setHistoryOpenPerWeek(false)
  }
  const handleCancel = () => {
    setViewShow(false)
  }

  const getHistoryPerDay = async (proj_id: any, timesheet_date: any) => {
    setShow(true)
    setHistoryOpenPerDay(true)

    const test = {
      project_id: proj_id,
      week_start_date: weekStartDate,
      week_end_date: weekEndDate,
      timesheet_date: moment(timesheet_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }
    const res = await Apiservices.timesheetHistoryPerDay(test)
    if (res.data && res.data.data && res.data.data) {
      setHistoryPerDay(res.data.data)
    } else {
      setHistoryPerDay([])
    }
  }

  const getHistoryPerWeek = async (project_id: any, list: any) => {
    setShow(true)
    setHistoryOpenPerWeek(true)
    const week = `${weekStartDate}T00:00:00Z-${weekEndDate}T00:00:00Z`

    const test = {
      project_id: project_id,
      week_start_date: weekStartDate,
      week_end_date: weekEndDate,
    }
    const res = await Apiservices.timesheetHistory(test)

    if (res.data && res.data.data && res.data.data) {
      setHistoryPerWeek(res.data.data.timesheet_status_history)
    } else {
      setHistoryPerWeek([])
    }
  }

  const handleSubmit1 = () => {}
  const [textinput, setTextinput] = useState('')

  const test = (event: any) => {
    setTextinput(event.target.value)
  }
  const [leaveOnBlurData, setLeaveOnBlurData] = useState<any | {}>({})
  const leaveOnBlur = (e: any, index: any, weekDayIndex: any, validationHours: any) => {
    const leaveData = {
      e: e,
      projectIndex: index,
      dayIndex: weekDayIndex,
      validationHours: validationHours,
    }
    setLeaveOnBlurData(leaveData)
    let eValue = e.target.value.toString()
    if (eValue.indexOf(':') > 0) {
      let EnteredHours = e.target.value.split(':')
      let totalLeaveMinutes: number = EnteredHours[0] * 60 + parseInt(EnteredHours[1])
      callValidate(totalLeaveMinutes, e, index, weekDayIndex, validationHours)
    } else if (eValue.indexOf('.') > 0) {
      let EnteredHours = e.target.value.split('.')
      let totalLeaveMinutes: number = EnteredHours[0] * 60 + parseInt(EnteredHours[1])
      callValidate(totalLeaveMinutes, e, index, weekDayIndex, validationHours)
    } else {
      let totalLeaveMinutes: number = e.target.value
      callValidate(totalLeaveMinutes, e, index, weekDayIndex, validationHours)
    }
  }
  const callValidate = (
    totalLeaveMinutes: number,
    e: any,
    index: any,
    weekDayIndex: any,
    validationHours: any
  ) => {
    if (totalLeaveMinutes > 0) {
      setForLeavePopup(true)
    } else {
      timechange(e, index, weekDayIndex, validationHours)
    }
  }
  const leavePopHandler = (message: any, data: any) => {
    setForLeavePopup(false)

    if (message == 'ApplyLeave') {
      timechange(data.e, data.projectIndex, data.dayIndex, data.validationHours)
    } else {
      data.e.target.value = 0
      timechange(data.e, data.projectIndex, data.dayIndex, data.validationHours)
    }
  }
  const ClientEmailValidation = (e: any, index: any) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (e.target.value && !re.test(String(e.target.value).toLowerCase())) {
      ClientManagerNameValidation[index].ceValidation = true
      setClientManagerNameValidation([...ClientManagerNameValidation])
      toast.warn('Email Format is invalid')
    } else {
      ClientManagerNameValidation[index].ceValidation = false
      setClientManagerNameValidation([...ClientManagerNameValidation])
    }
  }

  const timechangeForRegularHours = (
    e: any,
    projectIndex: any,
    dayIndex: any,
    ValidationHours: any = RegularHoursEntry
  ) => {
    let timeString = e.target.value

    if (timeString.indexOf(':') > -1) {
      var totalMints = MinutesConvertionWithColon(timeString)
    } else if (timeString.indexOf('.') > -1) {
      var totalMints = MinutesConvertionWithDot(timeString)
    } else {
      let regularStart = timeString
      var regularStartingValue: number = regularStart * 60
      var totalMints = regularStartingValue
    }

    if (totalMints <= ValidationHours * 60) {
      timechange(e, projectIndex, dayIndex, ValidationHours)
      // timechange(e, index, 0, 16)
    } else {
      e.target.value = ValidationHours
      e.target.name = 'regular_hours'
      timechange(e, projectIndex, dayIndex, ValidationHours)
      var OT = [
        'monday_ott',
        'tuesday_ott',
        'wednesday_ott',
        'thursday_ott',
        'friday_ott',
        'saturday_ott',
        'sunday_ott',
      ]
      weekDayStatus[projectIndex][OT[dayIndex]] = true
      setWeekDayStatus([...weekDayStatus])
      let remainingMinutes = totalMints - ValidationHours * 60
      let hoursRemaining = Math.floor(remainingMinutes / 60) + ':' + (remainingMinutes % 60)
      e.target.value = hoursRemaining
      e.target.name = 'ot_hours'
      timechange(e, projectIndex, dayIndex, OTHoursEntry)
    }
    e.target.name = 'regular_hours'
  }

  const timechange = (e: any, projectIndex: any, dayIndex: any, ValidationHours: any = 8) => {
    let timeString = e.target.value
    var timeFormat = '0:00'

    if (timeString.length !== 0) {
      if (timeString.split(':').length > 2 || timeString.split('.').length > 2) {
        timeFormat = '00:00'
      } else {
        if (timeString.indexOf(':') > -1 || timeString.indexOf('.') > -1) {
          if (
            parseInt(timeString) >= ValidationHours ||
            timeString.split('').filter((ele: any) => {
              return ele === '.'
            }).length > 1
          ) {
            timeFormat = parseInt(ValidationHours) + ':00'
          } else if (timeString.indexOf('.') > -1 && timeString.indexOf(':') > -1) {
            timeFormat = '00:00'
          } else if (timeString.indexOf('.') > -1) {
            if (timeString.indexOf('.') !== 0) {
              if (parseInt(timeString) <= ValidationHours) {
                let [int, dec] = timeString.split('.')
                var mins: any = (dec * 60) / Math.pow(10, dec.length)
                mins = Math.round(mins)
                if (mins <= 9) {
                  mins = '0' + parseInt(mins)
                }
                timeFormat =
                  parseInt(int) +
                  ':' +
                  (parseInt(timeString) == ValidationHours ? '00' : mins ? mins : '00')
              }
            } else {
              let [int, dec] = timeString.split('.')
              int = '0'
              var mins: any = (dec * 60) / Math.pow(10, dec.length)
              mins = Math.round(mins)
              if (mins <= 9) {
                mins = '0' + parseInt(mins)
              }
              timeFormat =
                parseInt(int) +
                ':' +
                (parseInt(timeString) == ValidationHours ? '00' : mins ? mins : '00')
            }
          } else if (timeString.indexOf(':') > -1) {
            let [int, dec] = timeString.split(':')
            var mins: any = parseInt(dec.length) == 2 ? dec : '00'

            mins = parseInt(dec) >= 60 ? 59 : dec
            if (mins <= 9) {
              mins = '0' + parseInt(mins)
            }
            int = int.length !== 0 ? int : '0'
            timeFormat =
              parseInt(int) +
              ':' +
              (parseInt(timeString) == ValidationHours ? '00' : mins ? mins : '00')
          } else {
            timeFormat = parseInt(timeString) + ':00'
          }
        } else {
          if (timeString.indexOf('.') == -1 && timeString.indexOf(':') == -1) {
            if (parseInt(timeString) <= ValidationHours) {
              timeFormat = parseInt(timeString) + ':00'
            } else {
              timeFormat = parseInt(ValidationHours) + ':00'
            }
          }
        }
      }
    } else if (timeString.length == 0) {
      timeFormat = '0:00'
    } else {
      timeFormat = '0.00'
    }

    weekDayDataEntry[projectIndex].timesheet_data[dayIndex][e.target.getAttribute('name')] =
      timeFormat
    // if (weekDayDataEntry[projectIndex].timesheet_data[dayIndex]) {
    // if (weekDayDataEntry[projectIndex] && timeFormat == '0:00') {
    //   showOtLeaveHolidayHandler(projectIndex, weekDayName, dayIndex)
    //   setOtDisable(true)
    //   weekDayStatus[projectIndex].otdisable = true
    //   weekDayStatus[projectIndex].monday_ott = false
    // } else {
    //   weekDayStatus[projectIndex].otdisable = false
    //   setOtDisable(false)
    // }
    // }
    if (
      e.target.getAttribute('name') == 'absent_hours' ||
      e.target.getAttribute('name') == 'absent-minutes'
    ) {
      let HolidayDisable: any = [
        'monday_holidayt',
        'tuesday_holidayt',
        'wednesday_holidayt',
        'thursday_holidayt',
        'friday_holidayt',
        'saturday_holidayt',
        'sunday_holidayt',
      ]
      let HolidayDisabledDay = HolidayDisable[dayIndex]
      let absentHours: any =
        weekDayDataEntry[projectIndex].timesheet_data[dayIndex].absent_hours.split(':')
      var totalMinutes = absentHours[0] * 60 + parseInt(absentHours[1])
      if (totalMinutes > 0) {
        weekDayDataEntry[projectIndex].timesheet_data[dayIndex].absent_type = 1
        weekDayStatus[projectIndex][HolidayDisabledDay] = true
        setWeekDayStatus([...weekDayStatus])
        SuccessToast('Leave Applied')
      } else {
        weekDayDataEntry[projectIndex].timesheet_data[dayIndex].absent_type = null
        weekDayStatus[projectIndex][HolidayDisabledDay] = false
        setWeekDayStatus([...weekDayStatus])
        WarnToast('Leave Not Applied')
      }
    }
    if (e.target.getAttribute('name') === 'regular_hours') {
      let ottDay: any = [
        'monday_ott',
        'tuesday_ott',
        'wednesday_ott',
        'thursday_ott',
        'friday_ott',
        'saturday_ott',
        'sunday_ott',
      ]
      let regularHours: any =
        weekDayDataEntry[projectIndex].timesheet_data[dayIndex].regular_hours.split(':')
      var totalMinutes = regularHours[0] * 60 + parseInt(regularHours[1])
      if (totalMinutes > 0) {
        otfieldsdisabledValidation[projectIndex][dayIndex] = false
      } else {
        otfieldsdisabledValidation[projectIndex][dayIndex] = true
        showOtLeaveHolidayHandlerForOtbutton(projectIndex, ottDay[dayIndex], dayIndex)
      }
      setotfieldsdisabledValidation([...otfieldsdisabledValidation])
    }

    enableDisableSubmitButton(weekDayDataEntry)
    sumOfTotalHours(weekDayDataEntry)
    setweekDayDataEntry([...weekDayDataEntry])
  }
  const enableDisableSubmitButton = (weekDayDataEntry: any) => {
    let ForAllProjects: any = []

    weekDayDataEntry.map((singleProject: any, Projectindex: any) => {
      let ForSingleProject: any = []
      singleProject.timesheet_data.map((singleDay: any, index: any) => {
        if (singleDay.status == '' || singleDay.status == 'REJECTED') {
          if (singleDay.absent_hours.toString().indexOf(':') > -1) {
            var AbsentMinutes = singleDay.absent_hours.toString()
          } else {
            AbsentMinutes = '0:0'
          }
          if (singleDay.regular_hours.toString().indexOf(':') > -1) {
            var RegularMinutes = singleDay.regular_hours.toString()
          } else {
            RegularMinutes = '0:0'
          }
          if (singleDay.ot_hours.toString().indexOf(':') > -1) {
            var OtMinutes = singleDay.ot_hours.toString()
          } else {
            OtMinutes = '0:0'
          }
          let ValidationAbsentHours: any = AbsentMinutes.split(':')
          let ValidationAbsentMinutes =
            ValidationAbsentHours[0] * 60 + parseInt(ValidationAbsentHours[1])
          let ValidationRegularHours: any = RegularMinutes.split(':')
          let ValidationRegularMinutes =
            ValidationRegularHours[0] * 60 + parseInt(ValidationRegularHours[1])
          let ValidationOtHours: any = OtMinutes.split(':')
          let ValidationOtMinutes = ValidationOtHours[0] * 60 + parseInt(ValidationOtHours[1])
          if (
            ValidationAbsentMinutes > 0 ||
            ValidationRegularMinutes > 0 ||
            ValidationOtMinutes > 0 ||
            singleDay.absent_type == 2
          ) {
            ForSingleProject.splice(0, 1)
          } else {
            ForSingleProject.push(false)
          }
        }
      })

      ForAllProjects.push(ForSingleProject)
    })
    setSubmitButtonEnableDisableValidation([])
    let submitButtonVlidation: any = []
    ForAllProjects.map((project: any, index: any) => {
      if (weekDayDataEntry[index].timesheet_data.find((item: any) => item.status == 'SAVED')) {
        submitButtonVlidation.push(false)
      } else {
        if (project.length == submitButtonEnableDisable[index].length) {
          submitButtonVlidation.push(true)
        } else {
          submitButtonVlidation.push(false)
        }
      }
    })
    setSubmitButtonEnableDisableValidation([...submitButtonVlidation])
  }

  const sumOfTotalHours = (weekDayDataEntry: any) => {
    sumofAllProj.length = 0
    sumofAllProjLeave.length = 0
    weekDayDataEntry.map((singleProject: any, i: any) => {
      var regularHours = 0
      var regularMinutes = 0
      var LeaveHours = 0
      var LeaveMinutes = 0

      for (let i = 0; i < singleProject.timesheet_data.length; i++) {
        if (
          singleProject.timesheet_data[i].regular_hours ||
          singleProject.timesheet_data[i].absent_hours
        ) {
          var rHours = singleProject.timesheet_data[i].regular_hours
            ? singleProject.timesheet_data[i].regular_hours.split(':')[0] * 60
            : 0
          var otHours = singleProject.timesheet_data[i].ot_hours
            ? singleProject.timesheet_data[i].ot_hours.split(':')[0] * 60
            : 0
          regularHours += rHours + otHours
          var rMinutes = singleProject.timesheet_data[i].regular_hours
            ? parseInt(singleProject.timesheet_data[i].regular_hours.split(':')[1])
            : 0
          var otMinutes = singleProject.timesheet_data[i].ot_hours
            ? parseInt(singleProject.timesheet_data[i].ot_hours.split(':')[1])
            : 0

          regularHours += rMinutes + otMinutes

          if (singleProject.timesheet_data[i].absent_hours) {
            LeaveHours += singleProject.timesheet_data[i].absent_hours
              ? singleProject.timesheet_data[i].absent_hours.split(':')[0] * 60
              : 0
            LeaveMinutes += singleProject.timesheet_data[i].absent_hours
              ? parseInt(singleProject.timesheet_data[i].absent_hours.split(':')[1])
              : 0
          }
        }
      }

      let regularHoursMints = Math.floor(regularHours / 60)
      regularMinutes = regularHours % 60
      let LeaveHoursMints = Math.floor((LeaveHours + LeaveMinutes) / 60)
      LeaveMinutes = (LeaveHours + LeaveMinutes) % 60

      const hoursAndMinutes = `${regularHoursMints}:${
        regularMinutes <= 9 ? '0' + regularMinutes : regularMinutes
      }`
      const LeaveHoursAndMinutes = `${LeaveHoursMints}:${
        LeaveMinutes <= 9 ? '0' + LeaveMinutes : LeaveMinutes
      }`

      sumofAllProj.push(hoursAndMinutes)
      sumofAllProjLeave.push(LeaveHoursAndMinutes)
      setSumofAllProj([...sumofAllProj])
      setSumofAllProjLeave([...sumofAllProjLeave])
    })
    var HeaderHours = 0
    var HeaderMinutes = 0

    sumofAllProj.map((each: any, i: any) => {
      if (each) {
        HeaderHours += each.split(':')[0] * 60
        HeaderMinutes += parseInt(each.split(':')[1])
      }
    })

    let HeaderHoursMints = Math.floor((HeaderHours + HeaderMinutes) / 60)
    HeaderMinutes = HeaderMinutes % 60
    const TotalHeaderHoursAndMintes = `${
      HeaderHoursMints <= 9 ? '0' + HeaderHoursMints : HeaderHoursMints
    }:${HeaderMinutes <= 9 ? '0' + HeaderMinutes : HeaderMinutes}`
    setWeekHourDataSubmit({
      ...weekHourDataSubmit,
      headerTotalHours: TotalHeaderHoursAndMintes,
    })
  }

  const nextMonth = async () => {
    setmonthYear('')
    setMonthName('')
    setYearName('')
    var lastDayOfNextMonth = moment(seletedDate.add(1, 'M')).endOf('month')

    setseletedDate(lastDayOfNextMonth)
    const monthNo = moment(seletedDate).month()
    const nextMonth = seletedDate.format('MMMM')
    const nextMonth1 = seletedDate.format('M')
    const nextYear = seletedDate.format('YYYY')
    const next: any = `${nextMonth} ${nextYear}`
    setmonthYear(next)
    setMonthName(nextMonth)
    setYearName(nextYear)
    setMonthNumber(monthNo)
    monthViewNextAndPrev(monthNo + 1, nextYear)
  }
  const prevMonth = async () => {
    setmonthYear('')

    setMonthName('')
    setYearName('')
    var lastDayOfPreviousMonth = moment(seletedDate.add(-1, 'M')).endOf('month')
    setseletedDate(lastDayOfPreviousMonth)
    const monthNo = moment(seletedDate).month()
    const nextMonth = seletedDate.format('MMMM')
    const nextMonth1 = seletedDate.format('M')
    const nextYear = seletedDate.format('YYYY')
    const next: any = `${nextMonth} ${nextYear}`
    setmonthYear(next)
    setMonthName(nextMonth)
    setYearName(nextYear)
    setMonthNumber(monthNo)
    monthViewNextAndPrev(monthNo + 1, nextYear)
  }
  const singleMonthSelection = async (date: any) => {
    var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
    setseletedDate(lastDayOfPreviousMonth)
    const nextMonth = moment(date).format('MMMM')
    const monthNo = moment(date).month()
    const nextYear = moment(date).format('YYYY')
    const next: any = `${nextMonth} ${nextYear}`
    setmonthYear(next)
    setMonthName(nextMonth)
    setYearName(nextYear)
    monthViewNextAndPrev(monthNo + 1, nextYear)
  }
  const userName = (full_name: any) => {
    if (full_name === null) return ''
    else {
      let str = ''
      full_name?.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })
      return str
    }
  }
  const handleCountries = async (country: any) => {
    if (country && country.length >= 2) {
      const response: any = await Apiservices.getcountriesbyname({country_name: country})
      if (response.data.data) {
        setCountry(response.data.data ? response.data.data : '')
      } else {
        setCountry([])
      }
    }
  }

  const selectedCountries = async (selected_country_name: any, projectIndex: any) => {
    let work2: any = []
    if (selected_country_name.length > 0) {
      StateFielddisabled[projectIndex] = false
    } else {
      StateFielddisabled[projectIndex] = true
      let candidate = [
        {
          name: states && states[0] && states[0]?.name ? '' : '',
        },
      ]
      states[projectIndex] = candidate
      weekDayDataEntry[projectIndex]['work_state'] = ''
    }
    WorkLocationValidation[projectIndex].countryValidation = false
    setStateFielddisabled([...StateFielddisabled])
    setStates([...states])
    work2[projectIndex] = selected_country_name
    setCountries(work2)
    weekDayDataEntry[projectIndex]['work_country'] = selected_country_name[0]?.name
    setweekDayDataEntry([...weekDayDataEntry])
    setUseStateCountryId(selected_country_name[0]?.id)
    SelectedCountry[projectIndex] = selected_country_name[0]?.id
    setSelectedCountry([...SelectedCountry])
    const response: any = await Apiservices.getstatebyname({
      country_id: selected_country_name[0]?.id,
    })

    if (response.data.data) {
      WorkStatesBasedOnCountry[projectIndex] = response.data.data
      setWorkStatesBasedOnCountry([...WorkStatesBasedOnCountry])
      setState([...WorkStatesBasedOnCountry])
    }
  }

  const handleStates = async (state: any, projectIndex: any, blur_event: any) => {
    if (blur_event === 'Blur') {
      let workStateSetting: any = []
      let data1 = [
        {
          name: weekDayDataEntry[projectIndex].work_state
            ? weekDayDataEntry[projectIndex].work_state
            : null,
        },
      ]
      workStateSetting[projectIndex] = data1
      setStates(workStateSetting)
    } else {
      if (SelectedCountry[projectIndex]) {
        // const response: any = await Apiservices.getstatebyname({country_id: useStateCountryId})
        const response: any = await Apiservices.getstatebyname({
          country_id: SelectedCountry[projectIndex],
        })

        if (response?.data?.data) {
          WorkStatesBasedOnCountry[projectIndex] = response.data.data
          setWorkStatesBasedOnCountry([...WorkStatesBasedOnCountry])
          setState([...WorkStatesBasedOnCountry])
          // setState(response.data.data ? response.data.data : '')
        }
      }
    }
  }
  const selectedStates = async (selected_state_name: any, projectIndex: any) => {
    let workState: any = []
    workState[projectIndex] = selected_state_name
    setStates(workState)
    weekDayDataEntry[projectIndex]['work_state'] = selected_state_name[0]?.name
    setweekDayDataEntry([...weekDayDataEntry])
    WorkLocationValidation[projectIndex].stateValidation = false
  }

  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  return (
    <>
      {pageTitle?.names == 'create' ? (
        // <PageTitle breadcrumbs={[]}>
        //   {intl.formatMessage({id: 'MENU.ADDTIMESHEET'})}
        //   {`${pageTitle?.fullName}`}
        // </PageTitle>
        <PageTitle breadcrumbs={[]}>{`${userName(pageTitle?.fullName)}`}</PageTitle>
      ) : location.state ? (
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.UPDATETIMESHEET'})}</PageTitle>
      ) : (
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADDTIMESHEET'})}</PageTitle>
      )}
      <BackDrop showBackDrop={showBackDrop} />
      <Toolbar1 isLogin={isLogin} users={pageTitle?.user_list} />
      <div>
        <div className='row pb-6 justify-content-between gap-3'>
          <div className='col-auto d-none d-sm-none d-md-block'>
            <div className='card card-custom card-flush'>
              <div className='card-body p-2'>
                <div className='row  flex-nowrap'>
                  <div className='col-auto'>
                    <div className='bg-primary rounded calContainer'>
                      <img src={Cal} className='calImage ' />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='d-flex gap-10 flex-nowrap '>
                      <div className=' text-nowrap'>
                        <span className='fs-6 fw-regular text-gray-600 text-nowrap'>
                          {' '}
                          {month_week_view ? 'Month' : 'Week'}:
                        </span>
                        <span className='fs-6 fw-semibold text-gray-900 text-nowrap'>
                          {' '}
                          {month_week_view ? monthYear : startEnd}
                        </span>
                      </div>
                      <div className='ps-5 border-start'>
                        <span className='fs-6 fw-regular text-gray-600 text-nowrap'>
                          Total Hours :{' '}
                        </span>
                        {month_week_view ? (
                          <span className='fs-6 fw-semibold text-gray-900 text-nowrap'>
                            {total_month_view_hours}
                          </span>
                        ) : (
                          <span className='fs-6 fw-semibold text-gray-900 text-nowrap'>
                            {weekHourDataSubmit.headerTotalHours}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='border-top my-2'></div>
                    <div className='d-flex gap-3 flex-nowrap me-0'>
                      <div className=''>
                        <div className='text-nowrap'>
                          <span className='fs-7 fw-regular text-gray-600'>Saved : </span>
                          {month_week_view ? (
                            <span className='fs-7 badge badge-light-primary fw-semibold'>
                              {total_month_view_status?.SAVED?.total_hm
                                ? total_month_view_status?.SAVED?.total_hm
                                : '00:00'}
                            </span>
                          ) : (
                            <span className='fs-7 badge badge-light-primary fw-semibold'>
                              {headerHours?.total_saved_hours_minutes
                                ? headerHours?.total_saved_hours_minutes
                                : '00:00'}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='ps-2 border-start'>
                        <div className='text-nowrap'>
                          <span className='fs-7 fw-regular text-gray-600'>Waiting : </span>
                          {month_week_view ? (
                            <span className='fs-7 badge badge-light-warning fw-semibold'>
                              {total_month_view_status?.SUBMITTED?.total_hm
                                ? total_month_view_status?.SUBMITTED?.total_hm
                                : '00:00'}
                            </span>
                          ) : (
                            <span className='fs-7 badge badge-light-warning fw-semibold'>
                              {headerHours?.total_submitted_hours_minutes
                                ? headerHours?.total_submitted_hours_minutes
                                : '00:00'}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='ps-2 border-start'>
                        <div className='text-nowrap'>
                          <span className='fs-7 fw-regular text-gray-600'>Rejected : </span>
                          {month_week_view ? (
                            <span className='fs-7 badge badge-light-danger fw-semibold'>
                              {total_month_view_status?.REJECTED?.total_hm
                                ? total_month_view_status?.REJECTED?.total_hm
                                : '00:00'}
                            </span>
                          ) : (
                            <span className='fs-7 badge badge-light-danger fw-semibold'>
                              {headerHours?.total_rejected_hours_minutes
                                ? headerHours?.total_rejected_hours_minutes
                                : '00:00'}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='ps-2 border-start'>
                        <div className='text-nowrap'>
                          <span className='fs-7 fw-regular text-gray-600'>Approved : </span>
                          {month_week_view ? (
                            <span className='fs-7 badge badge-light-success fw-semibold'>
                              {total_month_view_status?.APPROVED?.total_hm
                                ? total_month_view_status?.APPROVED?.total_hm
                                : '00:00'}
                            </span>
                          ) : (
                            <span className='fs-7 badge badge-light-success fw-semibold'>
                              {headerHours?.total_approved_hours_minutes
                                ? headerHours?.total_approved_hours_minutes
                                : '00:00'}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-auto'>
            <div className='card card-custom card-flush h-100'>
              <div className='card-body p-2 py-5 px-4'>
                <div className='row align-items-center h-100 flex-nowrap'>
                  {/* <div className='col-auto d-none d-sm-none d-md-block px-1'>
                    <Tooltip text='Apply Leave/Vacation'>
                      <button
                        type='button'
                        className='btn btn-sm btn-light-primary p-3'
                        onClick={ApplyLeavePopUpOpen}
                      >
                        <span className='svg-icon svg-icon-muted svg-icon-3 me-0'>
                          <svg
                            width='12'
                            height='11'
                            viewBox='0 0 12 11'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M0.390533 9.35255H11.6332V10.5H0.390533V9.35255ZM11.9704 3.9825C11.8462 3.52352 11.355 3.24814 10.8817 3.37435L7.73966 4.18904L3.65681 0.5L2.5148 0.792599L4.9645 4.9062L2.02367 5.66925L0.857989 4.78571L0 5.00947L1.53255 7.58548L11.3373 5.04389C11.8166 4.91193 12.0947 4.44148 11.9704 3.9825Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                      </button>
                    </Tooltip>
                  </div> */}

                  {/* {CopyButtonEnableDisableValidation.includes(false) ? (
                    <div className='col-auto d-none d-sm-none d-md-block px-1'>
                      <Tooltip text='Copy Last Week Hours'>
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary p-2'
                          onClick={() => copyLastWeek('', '', employeesProjectList)}
                          disabled={
                            projectStartDateDisable.some((r) => r.includes(true)) ||
                            projectEndDateDisable.some((r) => r.includes(true))
                          }
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen054.svg'
                            className='svg-icon-muted svg-icon-1 me-0'
                          />
                        </button>
                      </Tooltip>
                    </div>
                  ) : (
                    ''
                  )} */}
                  {/* <div className='col-auto d-none d-sm-none d-md-block px-1'>
                    <Tooltip text={month_week_view ? 'Week View' : 'Month View'}>
                      <button
                        type='button'
                        className='btn btn-sm btn-light-primary p-2'
                        onClick={() => monthView()}
                      >
                        {month_week_view ? (
                          <KTSVG
                            path='/media/icons/duotune/abstract/abs015.svg'
                            className='svg-icon-muted svg-icon-1 me-0'
                          />
                        ) : (
                          <KTSVG
                            path='/media/icons/duotune/abstract/abs030.svg'
                            className='svg-icon-muted svg-icon-1 me-0'
                          />
                        )}
                      </button>
                    </Tooltip>
                  </div> */}
                  <div className='col-auto d-none d-sm-none d-md-block'>
                    <button
                      type='button'
                      className='btn btn-sm   btn-light  btn-active-color-primary'
                      onClick={() => monthView()}
                    >
                      {month_week_view ? 'Week View' : 'Month View'}
                    </button>
                  </div>
                  {month_week_view ? (
                    <div className='col-auto px-1'>
                      <div className='d-flex'>
                        <div className='col'>
                          <div className='d-flex align-items-center'>
                            <button
                              className='btn btn-icon btn-primary btn-sm   '
                              // disabled={disableMonthLeftArrow}
                              onClick={() => {
                                prevMonth()
                              }}
                            >
                              <i className='bi bi-chevron-left'></i>
                            </button>

                            <span className='fs-7 fw-semibold text-gray-700 px-3 dateChangeMinWidth text-center'>
                              {monthYear}
                              {/* {monthDataList} */}
                            </span>
                            <button
                              className='btn btn-icon btn-primary btn-sm   '
                              // disabled={disableMonthRightArrow}
                              onClick={() => {
                                nextMonth()
                              }}
                            >
                              <i className='bi bi-chevron-right'></i>
                            </button>
                          </div>
                        </div>
                        <div className='col'>
                          <DatePicker
                            selected={startDate}
                            onChange={(date: any) => singleMonthSelection(date)}
                            customInput={<ExampleCustomInput />}
                            className='tableTdBlueBg'
                            // calendarStartDay={1}
                            showMonthYearPicker
                            // maxDate={addDays(new Date(), disableCalendarDays)}
                            // minDate={subDays(new Date(), disableCalendarDays)}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='col-auto px-1'>
                      <div className='d-flex'>
                        <div className='col'>
                          <div className='d-flex align-items-center'>
                            <button
                              className='btn btn-icon btn-primary btn-sm   '
                              // disabled={disableLeftArrow}
                              onClick={() => {
                                prevWeek()
                              }}
                            >
                              <i className='bi bi-chevron-left'></i>
                            </button>

                            <span className='fs-7 fw-semibold text-gray-700 px-3 dateChangeMinWidth text-center'>
                              {startEnd}
                            </span>
                            <button
                              className='btn btn-icon btn-primary btn-sm   '
                              // disabled={disableRightArrow}
                              onClick={() => {
                                nextWeek()
                              }}
                            >
                              <i className='bi bi-chevron-right'></i>
                            </button>
                          </div>
                        </div>
                        <div className='col'>
                          <div className='hoverWeek'>
                            <DatePicker
                              selected={startDate}
                              onChange={(date: any) => singleDateSelection(date)}
                              customInput={<ExampleCustomInput />}
                              className='tableTdBlueBg'
                              calendarStartDay={1}
                              // maxDate={addDays(new Date(), disableCalendarDays)}
                              // minDate={subDays(new Date(), disableCalendarDays)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {month_view
          ? month_view_dates &&
            month_view_dates.length > 0 &&
            month_view_dates.map((list: any, index: any) => (
              <React.Fragment key={index}>
                {/* <span> {list[0].project_name}</span> */}
                <div className='py-5'>
                  <div className='card shadow-sm '>
                    <div className='card shadow-sm '>
                      <div className='monthName'>
                        <div className='text-center bg-light-primary text-primary fw-semibold fs-2 calMonth'>
                          {/* January 2023 */}
                          <span>
                            {moment(list[15].timesheet_date).format('MMMM') +
                              ' - ' +
                              moment(list[15].timesheet_date).format('YYYY')}
                            <br />
                            {/* <span className='text-gray-700 fs-5'>{list[0].project_name}</span> */}
                            {/* <span className='text-gray-700 fs-5'>March</span> */}
                            {/* <span> March</span> */}
                          </span>
                          {/* <span> {list[0].timesheet_date.slice(5,7).toLocaleString('en-us', { month: 'long' })}</span> */}
                        </div>
                      </div>
                    </div>
                    <div className='calMonthView'>
                      <table className='table table-row-bordered'>
                        <thead>
                          <tr>
                            <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>MON</th>
                            <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>TUE</th>
                            <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>WED</th>
                            <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>THU</th>
                            <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>FRI</th>
                            <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>SAT</th>
                            <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>SUN</th>
                          </tr>
                        </thead>
                        <tbody className='text-center'>
                          {list &&
                            list.length > 0 &&
                            list.map((temp: any, index: any) => (
                              <React.Fragment key={index}>
                                {index % 7 == 0 ? (
                                  <tr key={index}>
                                    {list.length > 0 &&
                                      list
                                        .slice(index, index + 7)
                                        .map((temp: any, indexTwo: any) => (
                                          <td key={indexTwo}>
                                            <div className='col-auto'>
                                              <Popup
                                                trigger={
                                                  <a
                                                    // href='#'
                                                    onClick={() =>
                                                      singleDateSelection(temp.timesheet_date)
                                                    }
                                                  >
                                                    <div className='fs-6 py-1 fw-normal text-gray-700  '>
                                                      {/* {temp.timesheet_date} */}
                                                      {moment(temp.timesheet_date).format('DD')}
                                                    </div>

                                                    <div className='fs-7  monthHours fw-semibold'>
                                                      {temp.regular_hours_minutes} +
                                                      {temp.ot_hours_minutes}
                                                    </div>
                                                    {temp &&
                                                    temp?.absent_type &&
                                                    temp?.absent_type == 1 ? (
                                                      <div className='fs-9  fw-regular text-gray-700  text-center'>
                                                        {temp.absent_hours_minutes}{' '}
                                                        <span>Leave</span>
                                                      </div>
                                                    ) : temp &&
                                                      temp?.absent_type &&
                                                      temp?.absent_type == 2 ? (
                                                      <div className='fs-9  fw-regular text-gray-700  text-center'>
                                                        Holiday
                                                      </div>
                                                    ) : (
                                                      <div className='fs-9  fw-regular text-gray-700  text-center'>
                                                        {temp.absent_hours_minutes}
                                                      </div>
                                                    )}

                                                    <div
                                                      className={clsx(
                                                        'fs-7 fw-semibold  bg-light py-2 ',
                                                        temp.ts_status == 'APPROVED'
                                                          ? 'text-success'
                                                          : temp.ts_status == 'REJECTED'
                                                          ? 'text-danger'
                                                          : temp.ts_status == 'SUBMITTED'
                                                          ? 'text-warning'
                                                          : temp.ts_status == 'SAVED'
                                                          ? 'text-primary'
                                                          : 'text-gray-600'
                                                      )}
                                                    >
                                                      {temp.ts_status == 'APPROVED'
                                                        ? 'Approved'
                                                        : temp.ts_status == 'REJECTED'
                                                        ? 'Rejected'
                                                        : temp.ts_status == 'SUBMITTED'
                                                        ? 'Waiting'
                                                        : temp.ts_status == 'SAVED'
                                                        ? 'Draft'
                                                        : 'No Entry'}
                                                    </div>
                                                  </a>
                                                }
                                                on={['hover', 'focus']}
                                                // position='bottom center'
                                                // arrow={false}
                                                // on='hover'
                                                // position={['bottom right', 'top center', 'bottom left']}

                                                keepTooltipInside={true}
                                              >
                                                <div>
                                                  {temp.project_wise_data.map(
                                                    (project_name: any, index: any) => (
                                                      <div className='p-3'>
                                                        <div className='text-gray-900 fs-7 fw-semibold'>
                                                          Project Name:{project_name.project_name}
                                                        </div>
                                                        <div className='text-gray-900 fs-7 fw-semibold'>
                                                          Total Hours :{' '}
                                                          <span className='fs-7  monthHours fw-semibold'>
                                                            {project_name.regular_hours <= 9
                                                              ? '0' + project_name.regular_hours
                                                              : project_name.regular_hours}
                                                            :
                                                            {project_name.regular_minutes <= 9
                                                              ? '0' + project_name.regular_minutes
                                                              : project_name.regular_minutes}{' '}
                                                            +
                                                            {project_name.ot_hours <= 9
                                                              ? '0' + project_name.ot_hours
                                                              : project_name.ot_hours}
                                                            :
                                                            {project_name.ot_minutes <= 9
                                                              ? '0' + project_name.ot_minutes
                                                              : project_name.ot_minutes}
                                                          </span>
                                                        </div>
                                                        <div className='text-gray-900 fs-7 fw-semibold'>
                                                          Leave Hours :{' '}
                                                          <span className='fs-7  monthHours fw-semibold'>
                                                            {project_name.absent_hours <= 9
                                                              ? '0' + project_name.absent_hours
                                                              : project_name.absent_hours}
                                                            :
                                                            {project_name.absent_minutes <= 9
                                                              ? '0' + project_name.absent_minutes
                                                              : project_name.absent_minutes}{' '}
                                                          </span>
                                                        </div>
                                                        <div className='text-gray-900 fs-7 fw-semibold'>
                                                          Status:{' '}
                                                          <span
                                                            className={clsx(
                                                              'fs-7 monthHours fw-semibold',
                                                              project_name.status == 'APPROVED'
                                                                ? 'text-success'
                                                                : project_name.status == 'REJECTED'
                                                                ? 'text-danger'
                                                                : project_name.status == 'SUBMITTED'
                                                                ? 'text-warning'
                                                                : project_name.status == 'SAVED'
                                                                ? 'text-primary'
                                                                : 'text-gray-600'
                                                            )}
                                                          >
                                                            {project_name.status == 'APPROVED'
                                                              ? 'Approved'
                                                              : project_name.status == 'REJECTED'
                                                              ? 'Rejected'
                                                              : project_name.status == 'SUBMITTED'
                                                              ? 'Waiting'
                                                              : project_name.status == 'SAVED'
                                                              ? 'Draft'
                                                              : 'No Entry'}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                  {(temp.project_wise_data == '' ||
                                                    temp.project_wise_data == null) && (
                                                    <div className='text-gray-900 fs-7 fw-semibold p-5'>
                                                      No Data Found
                                                    </div>
                                                  )}
                                                </div>
                                              </Popup>{' '}
                                            </div>
                                          </td>
                                        ))}
                                  </tr>
                                ) : (
                                  ''
                                )}
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))
          : ''}

        <>
          {employeesProjectList && employeesProjectList.length > 0 && (
            <div className=''>
              <div
                className={`timesheetScrollContainer ${
                  employeesProjectList?.length === 1
                    ? 'timesheetScrollContainerForSingleProject'
                    : ''
                }`}
              >
                {employeesProjectList.map((list: any, index) => (
                  <div
                    className={clsx(employeesProjectList?.length !== index + 1 ? 'pb-8' : '')}
                    key={index}
                  >
                    <div className='card card-custom card-flush h-100'>
                      <div className='card-body p-0'>
                        <Accordion
                          defaultActiveKey={employeesProjectList?.length === 1 ? '0' : null}
                          flush
                        >
                          <div className='w-100 p-5'>
                            <div className='row  g-3  disabledColorForApprover'>
                              <div className='col-xs-12 col-md'>
                                <div className='row g-3'>
                                  <div className='col-xs-12 col-md-3 '>
                                    <label className='form-label '>Project Name</label>
                                    <input
                                      type='text'
                                      className='form-control fs-6'
                                      // defaultValue={list.project_name}
                                      defaultValue={
                                        list?.direct_customer_engagement
                                          ? list?.end_customer_name
                                          : list?.project_vendors &&
                                            list?.project_vendors[0] &&
                                            list?.project_vendors[0].client_name &&
                                            list?.project_vendors[0].client_name
                                      }
                                      disabled
                                      // {...methods.register('project_name')}
                                      name='project_name'
                                    />
                                  </div>
                                  <div className='col-xs-12 col-md-3 '>
                                    <label className='form-label '>
                                      Start Date <span className='fs-9'>(MM/DD/YYYY)</span>
                                    </label>
                                    <input
                                      disabled={true}
                                      type='text'
                                      className='form-control fs-6'
                                      // defaultValue={moment(list?.start_date).format('MM/DD/YYYY')}
                                      defaultValue={
                                        list?.joining_date
                                          ? moment(list?.joining_date).format('MM/DD/YYYY')
                                          : list?.start_date
                                          ? moment(list?.start_date).format('MM/DD/YYYY')
                                          : '-'
                                      }
                                      name='project_start_date'
                                      // onChange={(e) => {
                                      //   ClientDetails(e, index)
                                      // }}
                                    />
                                  </div>
                                  <div className='col-xs-12 col-md-3'>
                                    <label className='form-label '>Client Manager Name *</label>
                                    <input
                                      type='text'
                                      // className='form-control fs-6'
                                      className={` ${
                                        ClientManagerNameValidation[index].cmValidation
                                          ? `form-control fs-6  is-invalid`
                                          : `form-control fs-6 text-capitalize `
                                      }`}
                                      disabled={
                                        weekDayDataEntry[index]?.ts_status === 'APPROVED'
                                          ? true
                                          : false
                                      }
                                      defaultValue={
                                        list?.ts_client_manager_name
                                          ? list.ts_client_manager_name
                                          : list?.client_manager_name
                                          ? list?.client_manager_name
                                          : ''
                                      }
                                      name='client_manager_name'
                                      onChange={(e) => {
                                        ClientDetails(e, index)
                                      }}
                                    />
                                    {ClientManagerNameValidation[index].cmValidation && (
                                      <p className='error text-danger mb-0 mt-2'>
                                        Client Manager is required
                                      </p>
                                    )}
                                  </div>
                                  <div className='col-xs-12 col-md-3 '>
                                    <label className='form-label '>Client Manager Email</label>
                                    <input
                                      disabled={
                                        weekDayDataEntry[index]?.ts_status === 'APPROVED'
                                          ? true
                                          : false
                                      }
                                      type='email'
                                      // className='form-control fs-6'
                                      className={` ${
                                        ClientManagerNameValidation[index].ceValidation
                                          ? `form-control fs-6  is-invalid`
                                          : `form-control fs-6  `
                                      }`}
                                      defaultValue={
                                        list?.ts_client_manager_email
                                          ? list.ts_client_manager_email
                                          : list?.client_manager_email
                                          ? list?.client_manager_email
                                          : ''
                                      }
                                      onBlur={(e) => ClientEmailValidation(e, index)}
                                      name='client_manager_email'
                                      onChange={(e) => {
                                        ClientDetails(e, index)
                                      }}
                                    />
                                  </div>
                                  <div className='row g-3'>
                                    {disableWorkLocation && (
                                      <>
                                        <div className='col-xs-12 col-md-3 '>
                                          <label className='form-label '>Work Country * </label>
                                          <Typeahead
                                            id='basic-typeahead-single'
                                            onChange={(e) => selectedCountries(e, index)}
                                            onInputChange={(e) => handleCountries(e)}
                                            options={country}
                                            placeholder='Search Country '
                                            selected={countries[index]}
                                            labelKey={(country: any) => user(country.name)}
                                            // inputProps={{required: true}}
                                            inputProps={{
                                              className: `${
                                                WorkLocationValidation[index].countryValidation
                                                  ? 'form-control  is-invalid'
                                                  : 'form-control '
                                              }`,
                                            }}
                                          >
                                            {({onClear, selected}) => (
                                              <div className='rbt-aux'>
                                                {!!selected.length && (
                                                  // <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                                                  <ClearButton onClick={onClear} />
                                                )}
                                              </div>
                                            )}
                                          </Typeahead>
                                          {WorkLocationValidation[index].countryValidation && (
                                            <p className='error text-danger mb-0 mt-2'>
                                              Work Country is required
                                            </p>
                                          )}
                                        </div>
                                        <div className='col-xs-12 col-md-3'>
                                          <label className='form-label'>Work State * </label>
                                          <Typeahead
                                            id='basic-typeahead-single'
                                            onChange={(e) => selectedStates(e, index)}
                                            onInputChange={(e) => handleStates(e, index, 'Not')}
                                            onBlur={(e) => handleStates(e, index, 'Blur')}
                                            options={state[index]}
                                            disabled={StateFielddisabled[index]}
                                            placeholder='Search State'
                                            selected={states[index]}
                                            labelKey={(state: any) => user(state.name)}
                                            // inputProps={{required: true}}
                                            inputProps={{
                                              className: `${
                                                WorkLocationValidation[index].stateValidation
                                                  ? 'form-control  is-invalid'
                                                  : 'form-control '
                                              }`,
                                            }}
                                          >
                                            {({onClear, selected}) => (
                                              <div className='rbt-aux'>
                                                {!!selected.length && (
                                                  // <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                                                  <ClearButton onClick={onClear} />
                                                )}
                                              </div>
                                            )}
                                          </Typeahead>
                                          {WorkLocationValidation[index].stateValidation && (
                                            <p className='error text-danger mb-0 mt-2'>
                                              Work State is required
                                            </p>
                                          )}
                                        </div>
                                        <div className='col-xs-12 col-md-3 '>
                                          <label className='form-label'>Work City *</label>
                                          <input
                                            type='text'
                                            placeholder='Enter City Name'
                                            // className='form-control fs-6 text-capitalize'
                                            className={` ${
                                              WorkLocationValidation[index].cityValidation
                                                ? `form-control fs-6  is-invalid`
                                                : `form-control fs-6 text-capitalize `
                                            }`}
                                            value={
                                              weekDayDataEntry[index]?.work_city == null
                                                ? ''
                                                : weekDayDataEntry[index]?.work_city
                                                ? weekDayDataEntry[index]?.work_city
                                                : ''
                                            }
                                            onChange={(e) => {
                                              ClientDetails(e, index)
                                            }}
                                            minLength={3}
                                            name='work_city'
                                          ></input>
                                          {WorkLocationValidation[index].cityValidation && (
                                            <p className='error text-danger mb-0 mt-2'>
                                              Work City is required
                                            </p>
                                          )}
                                        </div>
                                      </>
                                    )}
                                    {CopyButtonEnableDisableValidation[index] ? (
                                      <div className='col-xs-12 col-md-3 '>
                                        <button
                                          type='button'
                                          className='btn btn-sm btn-light-primary p-2 mt-9'
                                          onClick={() =>
                                            copyLastWeek(
                                              list.id,
                                              '',
                                              employeesProjectList,
                                              docResultDataRes
                                            )
                                          }
                                          disabled={
                                            projectStartDateDisable[index]?.includes(true) ||
                                            projectEndDateDisable[index]?.includes(true)
                                          }
                                        >
                                          {/* <KTSVG
                                            path='/media/icons/duotune/general/gen054.svg'
                                            className='svg-icon-muted svg-icon-1 me-0'
                                          /> */}
                                          Copy Last Week Hours
                                        </button>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className='col-xs-12 col-auto text-end '>
                                {weekDayDataEntry[index].ts_status === 'APPROVED' ? (
                                  <span
                                    className={
                                      'badge badge-light-success mt-11 p-2 px-3 fw-semibold fs-6'
                                    }
                                  >
                                    Approved
                                  </span>
                                ) : (
                                  ''
                                )}
                                {weekDayDataEntry[index].ts_status === 'REJECTED' ? (
                                  <span
                                    className={
                                      'badge badge-light-danger mt-11 p-2 px-3 fw-semibold fs-6'
                                    }
                                  >
                                    Rejected
                                  </span>
                                ) : (
                                  ''
                                )}
                                {weekDayDataEntry[index].ts_status === 'SUBMITTED' ? (
                                  <span
                                    className={
                                      'badge badge-light-warning mt-11 p-2 px-3 fw-semibold fs-6'
                                    }
                                  >
                                    Waiting for approval
                                  </span>
                                ) : (
                                  ''
                                )}
                                {weekDayDataEntry[index].ts_status == null ? (
                                  <span
                                    className={
                                      'badge badge-light-primary mt-11 p-2 px-3 fw-semibold fs-6'
                                    }
                                  >
                                    Yet to submit
                                  </span>
                                ) : (
                                  ''
                                )}
                                {weekDayDataEntry[index].ts_status == 'SAVED' ? (
                                  <span
                                    className={
                                      'badge badge-light-primary mt-11 p-2 px-3 fw-semibold fs-6'
                                    }
                                  >
                                    Draft
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                              <>
                                {/* <div className='col-xs-12 col-auto text-end '>
                              {weekDayDataEntry[index].timesheet_data?.find(
                                (item: any) => item.status == ''
                              ) ? (
                                <span
                                  className={
                                    'badge badge-light-primary mt-11 p-2 px-3 fw-semibold fs-6'
                                  }
                                >
                                  Yet to submit
                                </span>
                              ) : weekDayDataEntry[index].timesheet_data?.find(
                                (item: any) => item.status == 'REJECTED'
                              ) ? (
                                <span
                                  className={
                                    'badge badge-light-danger mt-11 p-2 px-3 fw-semibold fs-6'
                                  }
                                >
                                  Rejected
                                </span>
                              ) : weekDayDataEntry[index].timesheet_data?.find(
                                (item: any) => item.status == 'SAVED'
                              ) ? (
                                <span
                                  className={
                                    'badge badge-light-primary mt-11 p-2 px-3 fw-semibold fs-6'
                                  }
                                >
                                  Yet to submit
                                </span>
                              ) : weekDayDataEntry[index].timesheet_data?.find(
                                (item: any) => item.status == 'REJECTED'
                              ) ? (
                                <span
                                  className={
                                    'badge badge-light-danger mt-11 p-2 px-3 fw-semibold fs-6'
                                  }
                                >
                                  Rejected
                                </span>
                              ) : weekDayDataEntry[index].timesheet_data?.find(
                                (item: any) => item.status == 'SUBMITTED'
                              ) ? (
                                weekDayDataEntry[index].timesheet_data?.find(
                                  (item: any) => item.status == 'APPROVED'
                                ) ? (
                                  <span
                                    className={
                                      'badge badge-light-primary mt-11 p-2 px-3 fw-semibold fs-6'
                                    }
                                  >
                                    Partially Approved
                                  </span>
                                ) : (
                                  <span
                                    className={
                                      'badge badge-light-primary mt-11 p-2 px-3 fw-semibold fs-6'
                                    }
                                  >
                                    Waiting for approval
                                  </span>
                                )
                              ) : (
                                <span
                                  className={
                                    'badge badge-light-success mt-11 p-2 px-3 fw-semibold fs-6'
                                  }
                                >
                                  Approved
                                </span>
                              )}
                            </div>
                           */}
                              </>
                              <div className='col-xs-12 col-auto'>
                                <ContextAwareToggle eventKey={`${index}`}>
                                  Click me!
                                </ContextAwareToggle>
                              </div>
                            </div>
                          </div>
                          <Accordion.Collapse eventKey={`${index}`}>
                            <div className='disabledColorForApprover'>
                              <div className='border-top '>
                                <div className='px-5'>
                                  <div className='table-responsive timesheetEntry '>
                                    <table className='w-100'>
                                      <tbody>
                                        <tr>
                                          <td
                                            className={clsx(
                                              'px-2 py-3',
                                              weekDayDataEntry[index]?.timesheet_data[0]?.status ==
                                                'REJECTED'
                                                ? 'tableTdRedBg'
                                                : weekDayStatus[index]?.monday_holidayt
                                                ? 'tableTdYellowBg'
                                                : weekDayStatus[index]?.monday_leavet
                                                ? 'tableTdPinkBg'
                                                : 'tableTdBlueBg'
                                            )}
                                          >
                                            <div className='d-flex align-items-center flex-nowrap'>
                                              <label className='form-label mb-0 fw-semibold fs-8'>
                                                {/* MON:{mon ? mon.slice(0, 5) : ''} */}
                                                MON:{' '}
                                                {mon
                                                  ? moment(mon.slice(3, 5)).format('MMM')
                                                  : ''}{' '}
                                                {mon ? mon.slice(0, 2) : ''}
                                              </label>

                                              {weekDayDataEntry[index]?.timesheet_data[0]
                                                ?.status !== '' && (
                                                <Tooltip text='Day History'>
                                                  <button
                                                    className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                    onClick={() => getHistoryPerDay(list.id, mon)}
                                                  >
                                                    <img src={History} alt='history' />
                                                  </button>
                                                </Tooltip>
                                              )}
                                              {weekDayStatus[index]?.monday_holidayt ? (
                                                <img
                                                  src={PlaneIcon}
                                                  className='timeEntryIcons yellowBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              {weekDayStatus[index]?.monday_leavet ? (
                                                <img
                                                  src={UmbrellaIcon}
                                                  className='timeEntryIcons pinkBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              <div className='invisibleBlock'></div>
                                            </div>

                                            <div className='d-flex  gap-1'>
                                              <div>
                                                <div className='input-group flex-nowrap'>
                                                  {/* <input type="text" value = {weekDayDataEntry[index]?.timesheet_data[0]?.regular_hours} onChange={(e: any) => {
                                                    DailyEntry(e, index, 0)
                                                  }}/> */}
                                                  <input
                                                    type='text'
                                                    className={clsx(
                                                      'form-control  px-1 my-2 fs-6',
                                                      weekDayStatus[index]?.monday_ott
                                                        ? 'formControlMinWidth45 border-end-0'
                                                        : 'formControlMinWidth90'
                                                    )}
                                                    value={
                                                      weekDayDataEntry[index]?.timesheet_data[0]
                                                        ?.regular_hours == '0.00'
                                                        ? ''
                                                        : weekDayDataEntry[index]?.timesheet_data[0]
                                                            ?.regular_hours
                                                    }
                                                    onBlur={(e) =>
                                                      timechangeForRegularHours(
                                                        e,
                                                        index,
                                                        0,
                                                        RegularHoursEntry
                                                      )
                                                    }
                                                    name={'regular_hours'}
                                                    tabIndex={10 * index + 1}
                                                    onFocus={(event) => event.target.select()}
                                                    onChange={(e: any) => {
                                                      DailyEntry(e, index, 0)
                                                    }}
                                                    placeholder='0:00'
                                                    aria-label='Hours'
                                                    disabled={
                                                      ermState?.consultant_role == 'ERM'
                                                        ? true
                                                        : getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            mon
                                                          )
                                                        ? // list?.is_placement_project === 2
                                                          true
                                                        : weekDayDataEntry[index]?.timesheet_data[0]
                                                            ?.qb_invoice_id != null
                                                        ? true
                                                        : projectStartDateDisable &&
                                                          projectStartDateDisable[index] &&
                                                          projectStartDateDisable[index][0]
                                                        ? true
                                                        : projectEndDateDisable &&
                                                          projectEndDateDisable[index] &&
                                                          projectEndDateDisable[index][0]
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[0]
                                                            ?.status == 'SUBMITTED'
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[0]
                                                            ?.status == 'APPROVED'
                                                        ? true
                                                        : false
                                                    }
                                                  />

                                                  {weekDayStatus[index]?.monday_ott && (
                                                    <>
                                                      {}
                                                      <span
                                                        className={clsx(
                                                          'input-group-text  bg-body   px-0 my-2 fs-6 ',
                                                          weekDayDataEntry[index]?.timesheet_data[0]
                                                            ?.status == 'SUBMITTED'
                                                            ? 'disabledBg'
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[0]?.status ==
                                                              'APPROVED'
                                                            ? 'disabledBg'
                                                            : ''
                                                        )}
                                                      >
                                                        +
                                                      </span>
                                                      <input
                                                        type='text'
                                                        className={clsx(
                                                          'form-control border-start-0 px-1 my-2 fs-6',
                                                          weekDayStatus[index]?.monday_ott
                                                            ? 'formControlMinWidth45'
                                                            : 'formControlMinWidth90'
                                                        )}
                                                        value={
                                                          weekDayDataEntry[index]?.timesheet_data[0]
                                                            ?.ot_hours == 0.0
                                                            ? ''
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[0]?.ot_hours
                                                        }
                                                        onBlur={(e) =>
                                                          timechange(e, index, 0, OTHoursEntry)
                                                        }
                                                        onFocus={(event) => event.target.select()}
                                                        onChange={(e: any) => {
                                                          DailyEntry(e, index, 0)
                                                        }}
                                                        disabled={
                                                          weekDayDataEntry[index]?.timesheet_data[0]
                                                            ?.qb_invoice_id != null
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[0]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[0]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : false
                                                        }
                                                        name='ot_hours'
                                                        placeholder='0:00'
                                                        aria-label='OT'
                                                      />
                                                    </>
                                                  )}
                                                </div>
                                                <div className='d-flex '>
                                                  <div className='col'>
                                                    <input
                                                      type='text'
                                                      className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                      placeholder='Leave'
                                                      name='absent_hours'
                                                      disabled={
                                                        ermState?.consultant_role == 'ERM'
                                                          ? true
                                                          : getDisableStatusofBenchProject(
                                                              list?.is_placement_project,
                                                              mon
                                                            )
                                                          ? // list?.is_placement_project === 2
                                                            true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[0]?.qb_invoice_id !=
                                                            null
                                                          ? true
                                                          : projectStartDateDisable &&
                                                            projectStartDateDisable[index] &&
                                                            projectStartDateDisable[index][0]
                                                          ? true
                                                          : projectEndDateDisable &&
                                                            projectEndDateDisable[index] &&
                                                            projectEndDateDisable[index][0]
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[0]?.status ==
                                                            'SUBMITTED'
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[0]?.status ==
                                                            'APPROVED'
                                                          ? true
                                                          : weekDayStatus[index]?.monday_leavet
                                                          ? true
                                                          : false
                                                      }
                                                      value={
                                                        weekDayDataEntry[index]?.timesheet_data[0]
                                                          ?.absent_hours == '0.00'
                                                          ? ''
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[0]?.absent_hours
                                                      }
                                                      onBlur={
                                                        (e) =>
                                                          leaveOnBlur(e, index, 0, LeaveHoursEntry)
                                                        //  timechange(e, index, 0, 24)
                                                      }
                                                      // onFocus={(e: any) => {
                                                      //
                                                      // }}
                                                      // ref={searchInput}
                                                      onChange={(e: any) => {
                                                        DailyEntry(e, index, 0)
                                                      }}
                                                    />
                                                    {weekDayDataEntry[index]?.timesheet_data[0]
                                                      ?.status == 'APPROVED' ? (
                                                      <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                        Approved
                                                      </div>
                                                    ) : weekDayDataEntry[index]?.timesheet_data[0]
                                                        ?.status == 'REJECTED' ? (
                                                      <Tooltip
                                                        text={
                                                          weekDayDataEntry[index]?.timesheet_data[0]
                                                            ?.approver_notes
                                                        }
                                                      >
                                                        <div
                                                          className='fs-8 text-danger fw-regular mt-1 text-center'
                                                          role='button'
                                                        >
                                                          <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                          Rejected
                                                        </div>
                                                      </Tooltip>
                                                    ) : (
                                                      <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                        Hidden
                                                      </div>
                                                    )}
                                                  </div>

                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text='Mark as holiday'>
                                                      <button
                                                        className='btn icon-btn p-0  d-flex mt-1'
                                                        disabled={
                                                          ermState?.consultant_role == 'ERM'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[0]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : projectStartDateDisable &&
                                                              projectStartDateDisable[index] &&
                                                              projectStartDateDisable[index][0]
                                                            ? true
                                                            : projectEndDateDisable &&
                                                              projectEndDateDisable[index] &&
                                                              projectEndDateDisable[index][0]
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[0]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[0]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : weekDayStatus[index]?.monday_holidayt
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() =>
                                                          onClickHolidayDisableLeave(
                                                            index,
                                                            'monday_leavet',
                                                            0
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={UmbrellaIcon}
                                                          className='timeEntryIcons pinkBgLight'
                                                        />
                                                      </button>
                                                    </Tooltip>
                                                  </div>
                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text=''>
                                                      <div className='pt5'>
                                                        <Popup
                                                          trigger={
                                                            <a
                                                              onClick={() =>
                                                                copyToClipboard(parse(xmlString))
                                                              }
                                                            >
                                                              <i className='bi bi-info-lg text-gray  fs-6 mt-1'></i>
                                                            </a>
                                                          }
                                                          position='right center'
                                                          on={['hover', 'focus']}
                                                          // arrow={position !== 'center center'}
                                                        >
                                                          <div>
                                                            <div className='p-3'>
                                                              <div className='text-gray-900 fs-7 fw-semibold'>
                                                                {parse(xmlString)}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Popup>
                                                      </div>
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <button
                                                  type='button'
                                                  className='btn btn-light btn-active-color-primary mt-5 p-1 fs-9'
                                                  onClick={() =>
                                                    showOtLeaveHolidayHandler(
                                                      index,
                                                      'monday_ott',
                                                      0
                                                    )
                                                  }
                                                  disabled={
                                                    ermState?.consultant_role == 'ERM'
                                                      ? true
                                                      : getDisableStatusofBenchProject(
                                                          list?.is_placement_project,
                                                          mon
                                                        )
                                                      ? // list?.is_placement_project === 2
                                                        true
                                                      : weekDayDataEntry[index]?.timesheet_data[0]
                                                          ?.qb_invoice_id != null
                                                      ? true
                                                      : projectStartDateDisable &&
                                                        projectStartDateDisable[index] &&
                                                        projectStartDateDisable[index][0]
                                                      ? true
                                                      : projectEndDateDisable &&
                                                        projectEndDateDisable[index] &&
                                                        projectEndDateDisable[index][0]
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[0] &&
                                                        weekDayDataEntry[index].timesheet_data[0]
                                                          .status == 'SUBMITTED'
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[0] &&
                                                        weekDayDataEntry[index].timesheet_data[0]
                                                          .status == 'APPROVED'
                                                      ? true
                                                      : otfieldsdisabledValidation[index][0]
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  OT
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            className={clsx(
                                              'px-2 py-3',
                                              weekDayDataEntry[index]?.timesheet_data[1]?.status ==
                                                'REJECTED'
                                                ? 'tableTdRedBg'
                                                : weekDayStatus[index]?.tuesday_holidayt
                                                ? 'tableTdYellowBg'
                                                : weekDayStatus[index]?.tuesday_leavet
                                                ? 'tableTdPinkBg'
                                                : 'tableTdBlueBg'
                                            )}
                                          >
                                            <div className='d-flex align-items-center flex-nowrap'>
                                              <label className='form-label mb-0 fw-semibold fs-8'>
                                                {/* {tue ? tue.slice(0, 5) : ''} */}
                                                TUE:{' '}
                                                {tue
                                                  ? moment(tue.slice(3, 5)).format('MMM')
                                                  : ''}{' '}
                                                {tue ? tue.slice(0, 2) : ''}
                                              </label>
                                              {weekDayDataEntry[index]?.timesheet_data[1]
                                                ?.status !== '' && (
                                                <Tooltip text='Day History'>
                                                  <button
                                                    className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                    onClick={() => getHistoryPerDay(list.id, tue)}
                                                  >
                                                    <img src={History} alt='history' />
                                                  </button>
                                                </Tooltip>
                                              )}
                                              {weekDayStatus[index]?.tuesday_holidayt ? (
                                                <img
                                                  src={PlaneIcon}
                                                  className='timeEntryIcons yellowBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              {weekDayStatus[index]?.tuesday_leavet ? (
                                                <img
                                                  src={UmbrellaIcon}
                                                  className='timeEntryIcons pinkBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              <div className='invisibleBlock'></div>
                                            </div>

                                            <div className='d-flex  gap-1'>
                                              <div>
                                                <div className='input-group flex-nowrap'>
                                                  <input
                                                    type='text'
                                                    className={clsx(
                                                      'form-control  px-1 my-2 fs-6',
                                                      weekDayStatus[index]?.tuesday_ott
                                                        ? 'formControlMinWidth45 border-end-0'
                                                        : 'formControlMinWidth90'
                                                    )}
                                                    value={
                                                      weekDayDataEntry[index]?.timesheet_data[1]
                                                        ?.regular_hours == '0.00'
                                                        ? ''
                                                        : weekDayDataEntry[index]?.timesheet_data[1]
                                                            ?.regular_hours
                                                    }
                                                    onBlur={(e) =>
                                                      timechangeForRegularHours(
                                                        e,
                                                        index,
                                                        1,
                                                        RegularHoursEntry
                                                      )
                                                    }
                                                    name={'regular_hours'}
                                                    tabIndex={10 * index + 2}
                                                    disabled={
                                                      ermState?.consultant_role == 'ERM'
                                                        ? true
                                                        : getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            tue
                                                          )
                                                        ? // list?.is_placement_project === 2
                                                          true
                                                        : weekDayDataEntry[index]?.timesheet_data[1]
                                                            ?.qb_invoice_id != null
                                                        ? true
                                                        : projectStartDateDisable &&
                                                          projectStartDateDisable[index] &&
                                                          projectStartDateDisable[index][1]
                                                        ? true
                                                        : projectEndDateDisable &&
                                                          projectEndDateDisable[index] &&
                                                          projectEndDateDisable[index][1]
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[1]
                                                            ?.status == 'SUBMITTED'
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[1]
                                                            ?.status == 'APPROVED'
                                                        ? true
                                                        : false
                                                    }
                                                    onFocus={(event) => event.target.select()}
                                                    onChange={(e: any) => {
                                                      DailyEntry(e, index, 1)
                                                    }}
                                                    placeholder='0:00'
                                                    aria-label='Hours'
                                                  />

                                                  {weekDayStatus[index]?.tuesday_ott && (
                                                    <>
                                                      {}
                                                      <span
                                                        className={clsx(
                                                          'input-group-text  bg-body   px-0 my-2 fs-6 ',
                                                          weekDayDataEntry[index]?.timesheet_data[1]
                                                            ?.status == 'SUBMITTED'
                                                            ? 'disabledBg'
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[1]?.status ==
                                                              'APPROVED'
                                                            ? 'disabledBg'
                                                            : ''
                                                        )}
                                                      >
                                                        +
                                                      </span>
                                                      <input
                                                        type='text'
                                                        className={clsx(
                                                          'form-control border-start-0 px-1 my-2 fs-6',
                                                          weekDayStatus[index]?.tuesday_ott
                                                            ? 'formControlMinWidth45'
                                                            : 'formControlMinWidth90'
                                                        )}
                                                        value={
                                                          weekDayDataEntry[index]?.timesheet_data[1]
                                                            ?.ot_hours == '0.00'
                                                            ? ''
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[1]?.ot_hours
                                                        }
                                                        onBlur={(e) =>
                                                          timechange(e, index, 1, OTHoursEntry)
                                                        }
                                                        onFocus={(event) => event.target.select()}
                                                        onChange={(e: any) => {
                                                          DailyEntry(e, index, 1)
                                                        }}
                                                        disabled={
                                                          getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            tue
                                                          )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[1]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[1]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[1]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : false
                                                        }
                                                        name='ot_hours'
                                                        placeholder='0:00'
                                                        aria-label='OT'
                                                      />
                                                    </>
                                                  )}
                                                </div>

                                                <div className='d-flex '>
                                                  <div className='col'>
                                                    <input
                                                      type='text'
                                                      className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                      placeholder='Leave'
                                                      name='absent_hours'
                                                      disabled={
                                                        ermState?.consultant_role == 'ERM'
                                                          ? true
                                                          : getDisableStatusofBenchProject(
                                                              list?.is_placement_project,
                                                              tue
                                                            )
                                                          ? // list?.is_placement_project === 2
                                                            true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[1]?.qb_invoice_id !=
                                                            null
                                                          ? true
                                                          : projectStartDateDisable &&
                                                            projectStartDateDisable[index] &&
                                                            projectStartDateDisable[index][1]
                                                          ? true
                                                          : projectEndDateDisable &&
                                                            projectEndDateDisable[index] &&
                                                            projectEndDateDisable[index][1]
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[1]?.status ==
                                                            'SUBMITTED'
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[1]?.status ==
                                                            'APPROVED'
                                                          ? true
                                                          : weekDayStatus[index]?.tuesday_leavet
                                                          ? true
                                                          : false
                                                      }
                                                      value={
                                                        weekDayDataEntry[index]?.timesheet_data[1]
                                                          ?.absent_hours == '0.00'
                                                          ? ''
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[1]?.absent_hours
                                                      }
                                                      // onBlur={(e) => timechange(e, index, 1, 24)}
                                                      onBlur={(e) =>
                                                        leaveOnBlur(e, index, 1, LeaveHoursEntry)
                                                      }
                                                      onFocus={(event) => event.target.select()}
                                                      onChange={(e: any) => {
                                                        DailyEntry(e, index, 1)
                                                      }}
                                                    />
                                                    {weekDayDataEntry[index]?.timesheet_data[1]
                                                      ?.status == 'APPROVED' ? (
                                                      <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                        Approved
                                                      </div>
                                                    ) : weekDayDataEntry[index]?.timesheet_data[1]
                                                        ?.status == 'REJECTED' ? (
                                                      <Tooltip
                                                        text={
                                                          weekDayDataEntry[index]?.timesheet_data[1]
                                                            ?.approver_notes
                                                        }
                                                      >
                                                        <div
                                                          className='fs-8 text-danger fw-regular mt-1 text-center'
                                                          role='button'
                                                        >
                                                          <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                          Rejected
                                                        </div>
                                                      </Tooltip>
                                                    ) : (
                                                      <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                        Hidden
                                                      </div>
                                                    )}
                                                  </div>

                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text='Mark as holiday'>
                                                      <button
                                                        className='btn icon-btn p-0  d-flex mt-1'
                                                        disabled={
                                                          ermState?.consultant_role == 'ERM'
                                                            ? true
                                                            : getDisableStatusofBenchProject(
                                                                list?.is_placement_project,
                                                                tue
                                                              )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[1]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : projectStartDateDisable &&
                                                              projectStartDateDisable[index] &&
                                                              projectStartDateDisable[index][1]
                                                            ? true
                                                            : projectEndDateDisable &&
                                                              projectEndDateDisable[index] &&
                                                              projectEndDateDisable[index][1]
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[1]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[1]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : weekDayStatus[index]?.tuesday_holidayt
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() =>
                                                          onClickHolidayDisableLeave(
                                                            index,
                                                            'tuesday_leavet',
                                                            1
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={UmbrellaIcon}
                                                          className='timeEntryIcons pinkBgLight'
                                                        />
                                                      </button>
                                                    </Tooltip>
                                                  </div>
                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text=''>
                                                      <div className='pt5'>
                                                        <Popup
                                                          trigger={
                                                            <a
                                                              onClick={() =>
                                                                copyToClipboard(parse(xmlString))
                                                              }
                                                            >
                                                              <i className='bi bi-info-lg text-gray  fs-6 mt-1'></i>
                                                            </a>
                                                          }
                                                          position='right center'
                                                          on={['hover', 'focus']}
                                                          // arrow={position !== 'center center'}
                                                        >
                                                          <div>
                                                            <div className='p-3'>
                                                              <div className='text-gray-900 fs-7 fw-semibold'>
                                                                {parse(xmlString)}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Popup>
                                                      </div>
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <button
                                                  type='button'
                                                  className='btn btn-light btn-active-color-primary mt-5 p-1 fs-9'
                                                  onClick={() =>
                                                    showOtLeaveHolidayHandler(
                                                      index,
                                                      'tuesday_ott',
                                                      1
                                                    )
                                                  }
                                                  disabled={
                                                    ermState?.consultant_role == 'ERM'
                                                      ? true
                                                      : getDisableStatusofBenchProject(
                                                          list?.is_placement_project,
                                                          tue
                                                        )
                                                      ? // list?.is_placement_project === 2
                                                        true
                                                      : weekDayDataEntry[index]?.timesheet_data[1]
                                                          ?.qb_invoice_id != null
                                                      ? true
                                                      : projectStartDateDisable &&
                                                        projectStartDateDisable[index] &&
                                                        projectStartDateDisable[index][1]
                                                      ? true
                                                      : projectEndDateDisable &&
                                                        projectEndDateDisable[index] &&
                                                        projectEndDateDisable[index][1]
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[1] &&
                                                        weekDayDataEntry[index].timesheet_data[1]
                                                          .status == 'SUBMITTED'
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[1] &&
                                                        weekDayDataEntry[index].timesheet_data[1]
                                                          .status == 'APPROVED'
                                                      ? true
                                                      : otfieldsdisabledValidation[index][1]
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  OT
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            className={clsx(
                                              'px-2 py-3',
                                              weekDayDataEntry[index]?.timesheet_data[2]?.status ==
                                                'REJECTED'
                                                ? 'tableTdRedBg'
                                                : weekDayStatus[index]?.wednesday_holidayt
                                                ? 'tableTdYellowBg'
                                                : weekDayStatus[index]?.wednesday_leavet
                                                ? 'tableTdPinkBg'
                                                : 'tableTdBlueBg'
                                            )}
                                          >
                                            <div className='d-flex align-items-center flex-nowrap'>
                                              <label className='form-label mb-0 fw-semibold fs-8'>
                                                {/* WED:{wed ? wed.slice(0, 5) : ''} */}
                                                WED:{' '}
                                                {wed
                                                  ? moment(wed.slice(3, 5)).format('MMM')
                                                  : ''}{' '}
                                                {wed ? wed.slice(0, 2) : ''}
                                              </label>
                                              {weekDayDataEntry[index]?.timesheet_data[2]
                                                ?.status !== '' && (
                                                <Tooltip text='Day History'>
                                                  <button
                                                    className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                    onClick={() =>
                                                      getHistoryPerDay(
                                                        list.id,
                                                        wed
                                                        // weekDates[0]?.slice(0, 10),
                                                        // index
                                                      )
                                                    }
                                                  >
                                                    <img src={History} alt='history' />
                                                  </button>
                                                </Tooltip>
                                              )}
                                              {weekDayStatus[index]?.wednesday_holidayt ? (
                                                <img
                                                  src={PlaneIcon}
                                                  className='timeEntryIcons yellowBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              {weekDayStatus[index]?.wednesday_leavet ? (
                                                <img
                                                  src={UmbrellaIcon}
                                                  className='timeEntryIcons pinkBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              <div className='invisibleBlock'></div>
                                            </div>

                                            <div className='d-flex  gap-1'>
                                              <div>
                                                <div className='input-group flex-nowrap'>
                                                  <input
                                                    type='text'
                                                    className={clsx(
                                                      'form-control  px-1 my-2 fs-6',
                                                      weekDayStatus[index]?.wednesday_ott
                                                        ? 'formControlMinWidth45 border-end-0'
                                                        : 'formControlMinWidth90'
                                                    )}
                                                    value={
                                                      weekDayDataEntry[index]?.timesheet_data[2]
                                                        ?.regular_hours == '0.00'
                                                        ? ''
                                                        : weekDayDataEntry[index]?.timesheet_data[2]
                                                            ?.regular_hours
                                                    }
                                                    onBlur={(e) =>
                                                      timechangeForRegularHours(
                                                        e,
                                                        index,
                                                        2,
                                                        RegularHoursEntry
                                                      )
                                                    }
                                                    name={'regular_hours'}
                                                    tabIndex={10 * index + 3}
                                                    disabled={
                                                      ermState?.consultant_role == 'ERM'
                                                        ? true
                                                        : getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            wed
                                                          )
                                                        ? // list?.is_placement_project === 2
                                                          true
                                                        : weekDayDataEntry[index]?.timesheet_data[2]
                                                            ?.qb_invoice_id != null
                                                        ? true
                                                        : projectStartDateDisable &&
                                                          projectStartDateDisable[index] &&
                                                          projectStartDateDisable[index][2]
                                                        ? true
                                                        : projectEndDateDisable &&
                                                          projectEndDateDisable[index] &&
                                                          projectEndDateDisable[index][2]
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[2]
                                                            ?.status == 'SUBMITTED'
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[2]
                                                            ?.status == 'APPROVED'
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e: any) => {
                                                      DailyEntry(e, index, 2)
                                                    }}
                                                    placeholder='0:00'
                                                    aria-label='Hours'
                                                  />

                                                  {weekDayStatus[index]?.wednesday_ott && (
                                                    <>
                                                      {}
                                                      <span
                                                        className={clsx(
                                                          'input-group-text  bg-body   px-0 my-2 fs-6 ',
                                                          weekDayDataEntry[index]?.timesheet_data[2]
                                                            ?.status == 'SUBMITTED'
                                                            ? 'disabledBg'
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[2]?.status ==
                                                              'APPROVED'
                                                            ? 'disabledBg'
                                                            : ''
                                                        )}
                                                      >
                                                        +
                                                      </span>
                                                      <input
                                                        type='text'
                                                        className={clsx(
                                                          'form-control border-start-0 px-1 my-2 fs-6',
                                                          weekDayStatus[index]?.wednesday_ott
                                                            ? 'formControlMinWidth45'
                                                            : 'formControlMinWidth90'
                                                        )}
                                                        value={
                                                          weekDayDataEntry[index]?.timesheet_data[2]
                                                            ?.ot_hours == '0.00'
                                                            ? ''
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[2]?.ot_hours
                                                        }
                                                        onBlur={(e) =>
                                                          timechange(e, index, 2, OTHoursEntry)
                                                        }
                                                        onFocus={(event) => event.target.select()}
                                                        onChange={(e: any) => {
                                                          DailyEntry(e, index, 2)
                                                        }}
                                                        disabled={
                                                          getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            wed
                                                          )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[2]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[2]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[2]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : false
                                                        }
                                                        name='ot_hours'
                                                        placeholder='0:00'
                                                        aria-label='OT'
                                                      />
                                                    </>
                                                  )}
                                                </div>

                                                <div className='d-flex '>
                                                  <div className='col'>
                                                    <input
                                                      type='text'
                                                      className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                      placeholder='Leave'
                                                      name='absent_hours'
                                                      disabled={
                                                        ermState?.consultant_role == 'ERM'
                                                          ? true
                                                          : getDisableStatusofBenchProject(
                                                              list?.is_placement_project,
                                                              wed
                                                            )
                                                          ? // list?.is_placement_project === 2
                                                            true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[2]?.qb_invoice_id !=
                                                            null
                                                          ? true
                                                          : projectStartDateDisable &&
                                                            projectStartDateDisable[index] &&
                                                            projectStartDateDisable[index][2]
                                                          ? true
                                                          : projectEndDateDisable &&
                                                            projectEndDateDisable[index] &&
                                                            projectEndDateDisable[index][2]
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[2]?.status ==
                                                            'SUBMITTED'
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[2]?.status ==
                                                            'APPROVED'
                                                          ? true
                                                          : weekDayStatus[index]?.wednesday_leavet
                                                          ? true
                                                          : false
                                                      }
                                                      value={
                                                        weekDayDataEntry[index]?.timesheet_data[2]
                                                          ?.absent_hours == '0.00'
                                                          ? ''
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[2]?.absent_hours
                                                      }
                                                      // onBlur={(e) => timechange(e, index, 2, 24)}
                                                      onBlur={(e) =>
                                                        leaveOnBlur(e, index, 2, LeaveHoursEntry)
                                                      }
                                                      onFocus={(event) => event.target.select()}
                                                      onChange={(e: any) => {
                                                        DailyEntry(e, index, 2)
                                                      }}
                                                    />
                                                    {weekDayDataEntry[index]?.timesheet_data[2]
                                                      ?.status == 'APPROVED' ? (
                                                      <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                        Approved
                                                      </div>
                                                    ) : weekDayDataEntry[index]?.timesheet_data[2]
                                                        ?.status == 'REJECTED' ? (
                                                      <Tooltip
                                                        text={
                                                          weekDayDataEntry[index]?.timesheet_data[2]
                                                            ?.approver_notes
                                                        }
                                                      >
                                                        <div
                                                          className='fs-8 text-danger fw-regular mt-1 text-center'
                                                          role='button'
                                                        >
                                                          <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                          Rejected
                                                        </div>
                                                      </Tooltip>
                                                    ) : (
                                                      <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                        Hidden
                                                      </div>
                                                    )}
                                                  </div>

                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text='Mark as holiday'>
                                                      <button
                                                        className='btn icon-btn p-0  d-flex mt-1'
                                                        disabled={
                                                          ermState?.consultant_role == 'ERM'
                                                            ? true
                                                            : getDisableStatusofBenchProject(
                                                                list?.is_placement_project,
                                                                wed
                                                              )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[2]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : projectStartDateDisable &&
                                                              projectStartDateDisable[index] &&
                                                              projectStartDateDisable[index][2]
                                                            ? true
                                                            : projectEndDateDisable &&
                                                              projectEndDateDisable[index] &&
                                                              projectEndDateDisable[index][2]
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[2]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[2]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : weekDayStatus[index]
                                                                ?.wednesday_holidayt
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() =>
                                                          onClickHolidayDisableLeave(
                                                            index,
                                                            'wednesday_leavet',
                                                            2
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={UmbrellaIcon}
                                                          className='timeEntryIcons pinkBgLight'
                                                        />
                                                      </button>
                                                    </Tooltip>
                                                  </div>
                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text=''>
                                                      <div className='pt5'>
                                                        <Popup
                                                          trigger={
                                                            <a
                                                              onClick={() =>
                                                                copyToClipboard(parse(xmlString))
                                                              }
                                                            >
                                                              <i className='bi bi-info-lg text-gray  fs-6 mt-1'></i>
                                                            </a>
                                                          }
                                                          position='right center'
                                                          on={['hover', 'focus']}
                                                          // arrow={position !== 'center center'}
                                                        >
                                                          <div>
                                                            <div className='p-3'>
                                                              <div className='text-gray-900 fs-7 fw-semibold'>
                                                                {parse(xmlString)}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Popup>
                                                      </div>
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <button
                                                  type='button'
                                                  className='btn btn-light btn-active-color-primary mt-5 p-1 fs-9'
                                                  onClick={() =>
                                                    showOtLeaveHolidayHandler(
                                                      index,
                                                      'wednesday_ott',
                                                      2
                                                    )
                                                  }
                                                  disabled={
                                                    ermState?.consultant_role == 'ERM'
                                                      ? true
                                                      : getDisableStatusofBenchProject(
                                                          list?.is_placement_project,
                                                          wed
                                                        )
                                                      ? // list?.is_placement_project === 2
                                                        true
                                                      : weekDayDataEntry[index]?.timesheet_data[2]
                                                          ?.qb_invoice_id != null
                                                      ? true
                                                      : projectStartDateDisable &&
                                                        projectStartDateDisable[index] &&
                                                        projectStartDateDisable[index][2]
                                                      ? true
                                                      : projectEndDateDisable &&
                                                        projectEndDateDisable[index] &&
                                                        projectEndDateDisable[index][2]
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[2] &&
                                                        weekDayDataEntry[index].timesheet_data[2]
                                                          .status == 'SUBMITTED'
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[2] &&
                                                        weekDayDataEntry[index].timesheet_data[2]
                                                          .status == 'APPROVED'
                                                      ? true
                                                      : otfieldsdisabledValidation[index][2]
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  OT
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            className={clsx(
                                              'px-2 py-3',
                                              weekDayDataEntry[index]?.timesheet_data[3]?.status ==
                                                'REJECTED'
                                                ? 'tableTdRedBg'
                                                : weekDayStatus[index]?.thursday_holidayt
                                                ? 'tableTdYellowBg'
                                                : weekDayStatus[index]?.thursday_leavet
                                                ? 'tableTdPinkBg'
                                                : 'tableTdBlueBg'
                                            )}
                                          >
                                            <div className='d-flex align-items-center flex-nowrap'>
                                              <label className='form-label mb-0 fw-semibold fs-8'>
                                                {/* {thu ? thu.slice(0, 5) : ''} */}
                                                THU:{' '}
                                                {thu
                                                  ? moment(thu.slice(3, 5)).format('MMM')
                                                  : ''}{' '}
                                                {thu ? thu.slice(0, 2) : ''}
                                              </label>
                                              {weekDayDataEntry[index]?.timesheet_data[3]
                                                ?.status !== '' && (
                                                <Tooltip text='Day History'>
                                                  <button
                                                    className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                    onClick={() =>
                                                      getHistoryPerDay(
                                                        list.id,
                                                        thu
                                                        // weekDates[0]?.slice(0, 10),
                                                        // index
                                                      )
                                                    }
                                                  >
                                                    <img src={History} alt='history' />
                                                  </button>
                                                </Tooltip>
                                              )}
                                              {weekDayStatus[index]?.thursday_holidayt ? (
                                                <img
                                                  src={PlaneIcon}
                                                  className='timeEntryIcons yellowBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              {weekDayStatus[index]?.thursday_leavet ? (
                                                <img
                                                  src={UmbrellaIcon}
                                                  className='timeEntryIcons pinkBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              <div className='invisibleBlock'></div>
                                            </div>

                                            <div className='d-flex  gap-1'>
                                              <div>
                                                <div className='input-group flex-nowrap'>
                                                  <input
                                                    type='text'
                                                    className={clsx(
                                                      'form-control  px-1 my-2 fs-6',
                                                      weekDayStatus[index]?.thursday_ott
                                                        ? 'formControlMinWidth45 border-end-0'
                                                        : 'formControlMinWidth90'
                                                    )}
                                                    value={
                                                      weekDayDataEntry[index]?.timesheet_data[3]
                                                        ?.regular_hours == '0.00'
                                                        ? ''
                                                        : weekDayDataEntry[index]?.timesheet_data[3]
                                                            ?.regular_hours
                                                    }
                                                    onBlur={(e) =>
                                                      timechangeForRegularHours(
                                                        e,
                                                        index,
                                                        3,
                                                        RegularHoursEntry
                                                      )
                                                    }
                                                    name={'regular_hours'}
                                                    tabIndex={10 * index + 4}
                                                    disabled={
                                                      ermState?.consultant_role == 'ERM'
                                                        ? true
                                                        : getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            thu
                                                          )
                                                        ? // list?.is_placement_project === 2
                                                          true
                                                        : weekDayDataEntry[index]?.timesheet_data[3]
                                                            ?.qb_invoice_id != null
                                                        ? true
                                                        : projectStartDateDisable &&
                                                          projectStartDateDisable[index] &&
                                                          projectStartDateDisable[index][3]
                                                        ? true
                                                        : projectEndDateDisable &&
                                                          projectEndDateDisable[index] &&
                                                          projectEndDateDisable[index][3]
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[3]
                                                            ?.status == 'SUBMITTED'
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[3]
                                                            ?.status == 'APPROVED'
                                                        ? true
                                                        : false
                                                    }
                                                    onFocus={(event) => event.target.select()}
                                                    onChange={(e: any) => {
                                                      DailyEntry(e, index, 3)
                                                    }}
                                                    placeholder='0:00'
                                                    aria-label='Hours'
                                                  />

                                                  {weekDayStatus[index]?.thursday_ott && (
                                                    <>
                                                      {}
                                                      <span
                                                        className={clsx(
                                                          'input-group-text  bg-body   px-0 my-2 fs-6 ',
                                                          weekDayDataEntry[index]?.timesheet_data[3]
                                                            ?.status == 'SUBMITTED'
                                                            ? 'disabledBg'
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[3]?.status ==
                                                              'APPROVED'
                                                            ? 'disabledBg'
                                                            : ''
                                                        )}
                                                      >
                                                        +
                                                      </span>
                                                      <input
                                                        type='text'
                                                        className={clsx(
                                                          'form-control border-start-0 px-1 my-2 fs-6',
                                                          weekDayStatus[index]?.thursday_ott
                                                            ? 'formControlMinWidth45'
                                                            : 'formControlMinWidth90'
                                                        )}
                                                        value={
                                                          weekDayDataEntry[index]?.timesheet_data[3]
                                                            ?.ot_hours == '0.00'
                                                            ? ''
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[3]?.ot_hours
                                                        }
                                                        onBlur={(e) =>
                                                          timechange(e, index, 3, OTHoursEntry)
                                                        }
                                                        onFocus={(event) => event.target.select()}
                                                        onChange={(e: any) => {
                                                          DailyEntry(e, index, 3)
                                                        }}
                                                        disabled={
                                                          getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            thu
                                                          )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[3]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[3]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[3]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : false
                                                        }
                                                        name='ot_hours'
                                                        placeholder='0:00'
                                                        aria-label='OT'
                                                      />
                                                    </>
                                                  )}
                                                </div>

                                                <div className='d-flex '>
                                                  <div className='col'>
                                                    <input
                                                      type='text'
                                                      className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                      placeholder='Leave'
                                                      name='absent_hours'
                                                      disabled={
                                                        ermState?.consultant_role == 'ERM'
                                                          ? true
                                                          : getDisableStatusofBenchProject(
                                                              list?.is_placement_project,
                                                              thu
                                                            )
                                                          ? // list?.is_placement_project === 2
                                                            true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[3]?.qb_invoice_id !=
                                                            null
                                                          ? true
                                                          : projectStartDateDisable &&
                                                            projectStartDateDisable[index] &&
                                                            projectStartDateDisable[index][3]
                                                          ? true
                                                          : projectEndDateDisable &&
                                                            projectEndDateDisable[index] &&
                                                            projectEndDateDisable[index][3]
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[3]?.status ==
                                                            'SUBMITTED'
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[3]?.status ==
                                                            'APPROVED'
                                                          ? true
                                                          : weekDayStatus[index]?.thursday_leavet
                                                          ? true
                                                          : false
                                                      }
                                                      value={
                                                        weekDayDataEntry[index]?.timesheet_data[3]
                                                          ?.absent_hours == '0.00'
                                                          ? ''
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[3]?.absent_hours
                                                      }
                                                      // onBlur={(e) => timechange(e, index, 3, 24)}
                                                      onBlur={(e) =>
                                                        leaveOnBlur(e, index, 3, LeaveHoursEntry)
                                                      }
                                                      onFocus={(event) => event.target.select()}
                                                      onChange={(e: any) => {
                                                        DailyEntry(e, index, 3)
                                                      }}
                                                    />
                                                    {weekDayDataEntry[index]?.timesheet_data[3]
                                                      ?.status == 'APPROVED' ? (
                                                      <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                        Approved
                                                      </div>
                                                    ) : weekDayDataEntry[index]?.timesheet_data[3]
                                                        ?.status == 'REJECTED' ? (
                                                      <Tooltip
                                                        text={
                                                          weekDayDataEntry[index]?.timesheet_data[3]
                                                            ?.approver_notes
                                                        }
                                                      >
                                                        <div
                                                          className='fs-8 text-danger fw-regular mt-1 text-center'
                                                          role='button'
                                                        >
                                                          <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                          Rejected
                                                        </div>
                                                      </Tooltip>
                                                    ) : (
                                                      <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                        Hidden
                                                      </div>
                                                    )}
                                                  </div>

                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text='Mark as holiday'>
                                                      <button
                                                        className='btn icon-btn p-0  d-flex mt-1'
                                                        disabled={
                                                          ermState?.consultant_role == 'ERM'
                                                            ? true
                                                            : getDisableStatusofBenchProject(
                                                                list?.is_placement_project,
                                                                thu
                                                              )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[3]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : projectStartDateDisable &&
                                                              projectStartDateDisable[index] &&
                                                              projectStartDateDisable[index][3]
                                                            ? true
                                                            : projectEndDateDisable &&
                                                              projectEndDateDisable[index] &&
                                                              projectEndDateDisable[index][3]
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[3]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[3]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : weekDayStatus[index]
                                                                ?.thursday_holidayt
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() =>
                                                          onClickHolidayDisableLeave(
                                                            index,
                                                            'thursday_leavet',
                                                            3
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={UmbrellaIcon}
                                                          className='timeEntryIcons pinkBgLight'
                                                        />
                                                      </button>
                                                    </Tooltip>
                                                  </div>
                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text=''>
                                                      <div className='pt5'>
                                                        <Popup
                                                          trigger={
                                                            <a
                                                              onClick={() =>
                                                                copyToClipboard(parse(xmlString))
                                                              }
                                                            >
                                                              <i className='bi bi-info-lg text-gray  fs-6 mt-1'></i>
                                                            </a>
                                                          }
                                                          position='right center'
                                                          on={['hover', 'focus']}
                                                          // arrow={position !== 'center center'}
                                                        >
                                                          <div>
                                                            <div className='p-3'>
                                                              <div className='text-gray-900 fs-7 fw-semibold'>
                                                                {parse(xmlString)}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Popup>
                                                      </div>
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <button
                                                  type='button'
                                                  className='btn btn-light btn-active-color-primary mt-5 p-1 fs-9'
                                                  onClick={() =>
                                                    showOtLeaveHolidayHandler(
                                                      index,
                                                      'thursday_ott',
                                                      3
                                                    )
                                                  }
                                                  disabled={
                                                    ermState?.consultant_role == 'ERM'
                                                      ? true
                                                      : getDisableStatusofBenchProject(
                                                          list?.is_placement_project,
                                                          thu
                                                        )
                                                      ? // list?.is_placement_project === 2
                                                        true
                                                      : weekDayDataEntry[index]?.timesheet_data[3]
                                                          ?.qb_invoice_id != null
                                                      ? true
                                                      : projectStartDateDisable &&
                                                        projectStartDateDisable[index] &&
                                                        projectStartDateDisable[index][3]
                                                      ? true
                                                      : projectEndDateDisable &&
                                                        projectEndDateDisable[index] &&
                                                        projectEndDateDisable[index][3]
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[3] &&
                                                        weekDayDataEntry[index].timesheet_data[3]
                                                          .status == 'SUBMITTED'
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[3] &&
                                                        weekDayDataEntry[index].timesheet_data[3]
                                                          .status == 'APPROVED'
                                                      ? true
                                                      : otfieldsdisabledValidation[index][3]
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  OT
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            className={clsx(
                                              'px-2 py-3',
                                              weekDayDataEntry[index]?.timesheet_data[4]?.status ==
                                                'REJECTED'
                                                ? 'tableTdRedBg'
                                                : weekDayStatus[index]?.friday_holidayt
                                                ? 'tableTdYellowBg'
                                                : weekDayStatus[index]?.friday_leavet
                                                ? 'tableTdPinkBg'
                                                : 'tableTdBlueBg'
                                            )}
                                          >
                                            <div className='d-flex align-items-center flex-nowrap'>
                                              <label className='form-label mb-0 fw-semibold fs-8'>
                                                {/* {fri ? fri.slice(0, 5) : ''} */}
                                                FRI:{' '}
                                                {fri
                                                  ? moment(fri.slice(3, 5)).format('MMM')
                                                  : ''}{' '}
                                                {fri ? fri.slice(0, 2) : ''}
                                              </label>
                                              {weekDayDataEntry[index]?.timesheet_data[4]
                                                ?.status !== '' && (
                                                <Tooltip text='Day History'>
                                                  <button
                                                    className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                    onClick={() =>
                                                      getHistoryPerDay(
                                                        list.id,
                                                        fri
                                                        // weekDates[0]?.slice(0, 10),
                                                        // index
                                                      )
                                                    }
                                                  >
                                                    <img src={History} alt='history' />
                                                  </button>
                                                </Tooltip>
                                              )}
                                              {weekDayStatus[index]?.friday_holidayt ? (
                                                <img
                                                  src={PlaneIcon}
                                                  className='timeEntryIcons yellowBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              {weekDayStatus[index]?.friday_leavet ? (
                                                <img
                                                  src={UmbrellaIcon}
                                                  className='timeEntryIcons pinkBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              <div className='invisibleBlock'></div>
                                            </div>

                                            <div className='d-flex  gap-1'>
                                              <div>
                                                <div className='input-group flex-nowrap'>
                                                  <input
                                                    type='text'
                                                    className={clsx(
                                                      'form-control  px-1 my-2 fs-6',
                                                      weekDayStatus[index]?.friday_ott
                                                        ? 'formControlMinWidth45 border-end-0'
                                                        : 'formControlMinWidth90'
                                                    )}
                                                    value={
                                                      weekDayDataEntry[index]?.timesheet_data[4]
                                                        ?.regular_hours == '0.00'
                                                        ? ''
                                                        : weekDayDataEntry[index]?.timesheet_data[4]
                                                            ?.regular_hours
                                                    }
                                                    onBlur={(e) =>
                                                      timechangeForRegularHours(
                                                        e,
                                                        index,
                                                        4,
                                                        RegularHoursEntry
                                                      )
                                                    }
                                                    name={'regular_hours'}
                                                    tabIndex={10 * index + 5}
                                                    disabled={
                                                      ermState?.consultant_role == 'ERM'
                                                        ? true
                                                        : getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            fri
                                                          )
                                                        ? // list?.is_placement_project === 2
                                                          true
                                                        : weekDayDataEntry[index]?.timesheet_data[4]
                                                            ?.qb_invoice_id != null
                                                        ? true
                                                        : projectStartDateDisable &&
                                                          projectStartDateDisable[index] &&
                                                          projectStartDateDisable[index][4]
                                                        ? true
                                                        : projectEndDateDisable &&
                                                          projectEndDateDisable[index] &&
                                                          projectEndDateDisable[index][4]
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[4]
                                                            ?.status == 'SUBMITTED'
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[4]
                                                            ?.status == 'APPROVED'
                                                        ? true
                                                        : false
                                                    }
                                                    onFocus={(event) => event.target.select()}
                                                    onChange={(e: any) => {
                                                      DailyEntry(e, index, 4)
                                                    }}
                                                    placeholder='0:00'
                                                    aria-label='Hours'
                                                  />

                                                  {weekDayStatus[index]?.friday_ott && (
                                                    <>
                                                      {}
                                                      <span
                                                        className={clsx(
                                                          'input-group-text  bg-body   px-0 my-2 fs-6 ',
                                                          weekDayDataEntry[index]?.timesheet_data[4]
                                                            ?.status == 'SUBMITTED'
                                                            ? 'disabledBg'
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[4]?.status ==
                                                              'APPROVED'
                                                            ? 'disabledBg'
                                                            : ''
                                                        )}
                                                      >
                                                        +
                                                      </span>
                                                      <input
                                                        type='text'
                                                        className={clsx(
                                                          'form-control border-start-0 px-1 my-2 fs-6',
                                                          weekDayStatus[index]?.friday_ott
                                                            ? 'formControlMinWidth45'
                                                            : 'formControlMinWidth90'
                                                        )}
                                                        value={
                                                          weekDayDataEntry[index]?.timesheet_data[4]
                                                            ?.ot_hours == '0.00'
                                                            ? ''
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[4]?.ot_hours
                                                        }
                                                        onBlur={(e) =>
                                                          timechange(e, index, 4, OTHoursEntry)
                                                        }
                                                        onFocus={(event) => event.target.select()}
                                                        onChange={(e: any) => {
                                                          DailyEntry(e, index, 4)
                                                        }}
                                                        disabled={
                                                          getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            fri
                                                          )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[4]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[4]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[4]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : false
                                                        }
                                                        name='ot_hours'
                                                        placeholder='0:00'
                                                        aria-label='OT'
                                                      />
                                                    </>
                                                  )}
                                                </div>

                                                <div className='d-flex '>
                                                  <div className='col'>
                                                    <input
                                                      type='text'
                                                      className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                      placeholder='Leave'
                                                      name='absent_hours'
                                                      disabled={
                                                        ermState?.consultant_role == 'ERM'
                                                          ? true
                                                          : getDisableStatusofBenchProject(
                                                              list?.is_placement_project,
                                                              fri
                                                            )
                                                          ? // list?.is_placement_project === 2
                                                            true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[4]?.qb_invoice_id !=
                                                            null
                                                          ? true
                                                          : projectStartDateDisable &&
                                                            projectStartDateDisable[index] &&
                                                            projectStartDateDisable[index][4]
                                                          ? true
                                                          : projectEndDateDisable &&
                                                            projectEndDateDisable[index] &&
                                                            projectEndDateDisable[index][4]
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[4]?.status ==
                                                            'SUBMITTED'
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[4]?.status ==
                                                            'APPROVED'
                                                          ? true
                                                          : weekDayStatus[index]?.friday_leavet
                                                          ? true
                                                          : false
                                                      }
                                                      value={
                                                        weekDayDataEntry[index]?.timesheet_data[4]
                                                          ?.absent_hours == '0.00'
                                                          ? ''
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[4]?.absent_hours
                                                      }
                                                      // onBlur={(e) => timechange(e, index, 4, 24)}
                                                      onBlur={(e) =>
                                                        leaveOnBlur(e, index, 4, LeaveHoursEntry)
                                                      }
                                                      onFocus={(event) => event.target.select()}
                                                      onChange={(e: any) => {
                                                        DailyEntry(e, index, 4)
                                                      }}
                                                    />
                                                    {weekDayDataEntry[index]?.timesheet_data[4]
                                                      ?.status == 'APPROVED' ? (
                                                      <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                        Approved
                                                      </div>
                                                    ) : weekDayDataEntry[index]?.timesheet_data[4]
                                                        ?.status == 'REJECTED' ? (
                                                      <Tooltip
                                                        text={
                                                          weekDayDataEntry[index]?.timesheet_data[4]
                                                            ?.approver_notes
                                                        }
                                                      >
                                                        <div
                                                          className='fs-8 text-danger fw-regular mt-1 text-center'
                                                          role='button'
                                                        >
                                                          <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                          Rejected
                                                        </div>
                                                      </Tooltip>
                                                    ) : (
                                                      <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                        Hidden
                                                      </div>
                                                    )}
                                                  </div>

                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text='Mark as holiday'>
                                                      <button
                                                        className='btn icon-btn p-0  d-flex mt-1'
                                                        disabled={
                                                          ermState?.consultant_role == 'ERM'
                                                            ? true
                                                            : getDisableStatusofBenchProject(
                                                                list?.is_placement_project,
                                                                fri
                                                              )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[4]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : projectStartDateDisable &&
                                                              projectStartDateDisable[index] &&
                                                              projectStartDateDisable[index][4]
                                                            ? true
                                                            : projectEndDateDisable &&
                                                              projectEndDateDisable[index] &&
                                                              projectEndDateDisable[index][4]
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[4]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[4]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : weekDayStatus[index]?.friday_holidayt
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() =>
                                                          onClickHolidayDisableLeave(
                                                            index,
                                                            'friday_leavet',
                                                            4
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={UmbrellaIcon}
                                                          className='timeEntryIcons pinkBgLight'
                                                        />
                                                      </button>
                                                    </Tooltip>
                                                  </div>
                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text=''>
                                                      <div className='pt5'>
                                                        <Popup
                                                          trigger={
                                                            <a
                                                              onClick={() =>
                                                                copyToClipboard(parse(xmlString))
                                                              }
                                                            >
                                                              <i className='bi bi-info-lg text-gray  fs-6 mt-1'></i>
                                                            </a>
                                                          }
                                                          position='right center'
                                                          on={['hover', 'focus']}
                                                          // arrow={position !== 'center center'}
                                                        >
                                                          <div>
                                                            <div className='p-3'>
                                                              <div className='text-gray-900 fs-7 fw-semibold'>
                                                                {parse(xmlString)}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Popup>
                                                      </div>
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <button
                                                  type='button'
                                                  className='btn btn-light btn-active-color-primary mt-5 p-1 fs-9'
                                                  onClick={() =>
                                                    showOtLeaveHolidayHandler(
                                                      index,
                                                      'friday_ott',
                                                      4
                                                    )
                                                  }
                                                  disabled={
                                                    ermState?.consultant_role == 'ERM'
                                                      ? true
                                                      : getDisableStatusofBenchProject(
                                                          list?.is_placement_project,
                                                          fri
                                                        )
                                                      ? // list?.is_placement_project === 2
                                                        true
                                                      : weekDayDataEntry[index]?.timesheet_data[4]
                                                          ?.qb_invoice_id != null
                                                      ? true
                                                      : projectStartDateDisable &&
                                                        projectStartDateDisable[index] &&
                                                        projectStartDateDisable[index][4]
                                                      ? true
                                                      : projectEndDateDisable &&
                                                        projectEndDateDisable[index] &&
                                                        projectEndDateDisable[index][4]
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[4] &&
                                                        weekDayDataEntry[index].timesheet_data[4]
                                                          .status == 'SUBMITTED'
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[4] &&
                                                        weekDayDataEntry[index].timesheet_data[4]
                                                          .status == 'APPROVED'
                                                      ? true
                                                      : otfieldsdisabledValidation[index][4]
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  OT
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            className={clsx(
                                              'px-2 py-3',
                                              weekDayDataEntry[index]?.timesheet_data[5]?.status ==
                                                'REJECTED'
                                                ? 'tableTdRedBg'
                                                : weekDayStatus[index]?.saturday_holidayt
                                                ? 'tableTdYellowBg'
                                                : weekDayStatus[index]?.saturday_leavet
                                                ? 'tableTdPinkBg'
                                                : 'tableTdBlueBg'
                                            )}
                                          >
                                            <div className='d-flex align-items-center flex-nowrap'>
                                              <label className='form-label mb-0 fw-semibold fs-8'>
                                                {/* {sat ? sat.slice(0, 5) : ''} */}
                                                SAT:{' '}
                                                {sat
                                                  ? moment(sat.slice(3, 5)).format('MMM')
                                                  : ''}{' '}
                                                {sat ? sat.slice(0, 2) : ''}
                                              </label>
                                              <Tooltip text='Weekend'>
                                                <img
                                                  src={SofaIcon}
                                                  className='timeEntryIcons blueBg ms-2'
                                                />
                                              </Tooltip>
                                              {weekDayDataEntry[index]?.timesheet_data[5]
                                                ?.status !== '' && (
                                                <Tooltip text='Day History'>
                                                  <button
                                                    className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                    onClick={() =>
                                                      getHistoryPerDay(
                                                        list.id,
                                                        sat
                                                        // weekDates[0]?.slice(0, 10),
                                                        // index
                                                      )
                                                    }
                                                  >
                                                    <img src={History} alt='history' />
                                                  </button>
                                                </Tooltip>
                                              )}
                                              {weekDayStatus[index]?.saturday_holidayt ? (
                                                <img
                                                  src={PlaneIcon}
                                                  className='timeEntryIcons yellowBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              {weekDayStatus[index]?.saturday_leavet ? (
                                                <img
                                                  src={UmbrellaIcon}
                                                  className='timeEntryIcons pinkBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              <div className='invisibleBlock'></div>
                                            </div>

                                            <div className='d-flex  gap-1'>
                                              <div>
                                                <div className='input-group flex-nowrap'>
                                                  <input
                                                    type='text'
                                                    className={clsx(
                                                      'form-control  px-1 my-2 fs-6',
                                                      weekDayStatus[index]?.saturday_ott
                                                        ? 'formControlMinWidth45 border-end-0'
                                                        : 'formControlMinWidth90'
                                                    )}
                                                    value={
                                                      weekDayDataEntry[index]?.timesheet_data[5]
                                                        ?.regular_hours == '0.00'
                                                        ? ''
                                                        : weekDayDataEntry[index]?.timesheet_data[5]
                                                            ?.regular_hours
                                                    }
                                                    onBlur={(e) =>
                                                      timechangeForRegularHours(
                                                        e,
                                                        index,
                                                        5,
                                                        RegularHoursEntry
                                                      )
                                                    }
                                                    name={'regular_hours'}
                                                    tabIndex={10 * index + 6}
                                                    disabled={
                                                      ermState?.consultant_role == 'ERM'
                                                        ? true
                                                        : getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            sat
                                                          )
                                                        ? // list?.is_placement_project === 2
                                                          true
                                                        : weekDayDataEntry[index]?.timesheet_data[5]
                                                            ?.qb_invoice_id != null
                                                        ? true
                                                        : projectStartDateDisable &&
                                                          projectStartDateDisable[index] &&
                                                          projectStartDateDisable[index][5]
                                                        ? true
                                                        : projectEndDateDisable &&
                                                          projectEndDateDisable[index] &&
                                                          projectEndDateDisable[index][5]
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[5]
                                                            ?.status == 'SUBMITTED'
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[5]
                                                            ?.status == 'APPROVED'
                                                        ? true
                                                        : false
                                                    }
                                                    onFocus={(event) => event.target.select()}
                                                    onChange={(e: any) => {
                                                      DailyEntry(e, index, 5)
                                                    }}
                                                    placeholder='0:00'
                                                    aria-label='Hours'
                                                  />

                                                  {weekDayStatus[index]?.saturday_ott && (
                                                    <>
                                                      {}
                                                      <span
                                                        className={clsx(
                                                          'input-group-text  bg-body   px-0 my-2 fs-6 ',
                                                          weekDayDataEntry[index]?.timesheet_data[5]
                                                            ?.status == 'SUBMITTED'
                                                            ? 'disabledBg'
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[5]?.status ==
                                                              'APPROVED'
                                                            ? 'disabledBg'
                                                            : ''
                                                        )}
                                                      >
                                                        +
                                                      </span>
                                                      <input
                                                        type='text'
                                                        className={clsx(
                                                          'form-control border-start-0 px-1 my-2 fs-6',
                                                          weekDayStatus[index]?.saturday_ott
                                                            ? 'formControlMinWidth45'
                                                            : 'formControlMinWidth90'
                                                        )}
                                                        value={
                                                          weekDayDataEntry[index]?.timesheet_data[5]
                                                            ?.ot_hours == '0.00'
                                                            ? ''
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[5]?.ot_hours
                                                        }
                                                        onBlur={(e) =>
                                                          timechange(e, index, 5, OTHoursEntry)
                                                        }
                                                        onFocus={(event) => event.target.select()}
                                                        onChange={(e: any) => {
                                                          DailyEntry(e, index, 5)
                                                        }}
                                                        disabled={
                                                          getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            sat
                                                          )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[5]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[5]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[5]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : false
                                                        }
                                                        name='ot_hours'
                                                        placeholder='0:00'
                                                        aria-label='OT'
                                                      />
                                                    </>
                                                  )}
                                                </div>

                                                <div className='d-flex '>
                                                  <div className='col'>
                                                    <input
                                                      type='text'
                                                      className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                      placeholder='Leave'
                                                      name='absent_hours'
                                                      disabled={
                                                        ermState?.consultant_role == 'ERM'
                                                          ? true
                                                          : getDisableStatusofBenchProject(
                                                              list?.is_placement_project,
                                                              sat
                                                            )
                                                          ? // list?.is_placement_project === 2
                                                            true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[5]?.qb_invoice_id !=
                                                            null
                                                          ? true
                                                          : projectStartDateDisable &&
                                                            projectStartDateDisable[index] &&
                                                            projectStartDateDisable[index][5]
                                                          ? true
                                                          : projectEndDateDisable &&
                                                            projectEndDateDisable[index] &&
                                                            projectEndDateDisable[index][5]
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[5]?.status ==
                                                            'SUBMITTED'
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[5]?.status ==
                                                            'APPROVED'
                                                          ? true
                                                          : weekDayStatus[index]?.saturday_leavet
                                                          ? true
                                                          : false
                                                      }
                                                      value={
                                                        weekDayDataEntry[index]?.timesheet_data[5]
                                                          ?.absent_hours == '0.00'
                                                          ? ''
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[5]?.absent_hours
                                                      }
                                                      // onBlur={(e) => timechange(e, index, 5, 24)}
                                                      onBlur={(e) =>
                                                        leaveOnBlur(e, index, 5, LeaveHoursEntry)
                                                      }
                                                      onFocus={(event) => event.target.select()}
                                                      onChange={(e: any) => {
                                                        DailyEntry(e, index, 5)
                                                      }}
                                                    />
                                                    {weekDayDataEntry[index]?.timesheet_data[5]
                                                      ?.status == 'APPROVED' ? (
                                                      <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                        Approved
                                                      </div>
                                                    ) : weekDayDataEntry[index]?.timesheet_data[5]
                                                        ?.status == 'REJECTED' ? (
                                                      <Tooltip
                                                        text={
                                                          weekDayDataEntry[index]?.timesheet_data[5]
                                                            ?.approver_notes
                                                        }
                                                      >
                                                        <div
                                                          className='fs-8 text-danger fw-regular mt-1 text-center'
                                                          role='button'
                                                        >
                                                          <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                          Rejected
                                                        </div>
                                                      </Tooltip>
                                                    ) : (
                                                      <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                        Hidden
                                                      </div>
                                                    )}
                                                  </div>

                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text='Mark as holiday'>
                                                      <button
                                                        className='btn icon-btn p-0  d-flex mt-1'
                                                        disabled={
                                                          ermState?.consultant_role == 'ERM'
                                                            ? true
                                                            : getDisableStatusofBenchProject(
                                                                list?.is_placement_project,
                                                                sat
                                                              )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[5]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : projectStartDateDisable &&
                                                              projectStartDateDisable[index] &&
                                                              projectStartDateDisable[index][5]
                                                            ? true
                                                            : projectEndDateDisable &&
                                                              projectEndDateDisable[index] &&
                                                              projectEndDateDisable[index][5]
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[5]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[5]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : weekDayStatus[index]
                                                                ?.saturday_holidayt
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() =>
                                                          onClickHolidayDisableLeave(
                                                            index,
                                                            'saturday_leavet',
                                                            5
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={UmbrellaIcon}
                                                          className='timeEntryIcons pinkBgLight'
                                                        />
                                                      </button>
                                                    </Tooltip>
                                                  </div>
                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text=''>
                                                      <div className='pt5'>
                                                        <Popup
                                                          trigger={
                                                            <a
                                                              onClick={() =>
                                                                copyToClipboard(parse(xmlString))
                                                              }
                                                            >
                                                              <i className='bi bi-info-lg text-gray  fs-6 mt-1'></i>
                                                            </a>
                                                          }
                                                          position='right center'
                                                          on={['hover', 'focus']}
                                                          // arrow={position !== 'center center'}
                                                        >
                                                          <div>
                                                            <div className='p-3'>
                                                              <div className='text-gray-900 fs-7 fw-semibold'>
                                                                {parse(xmlString)}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Popup>
                                                      </div>
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <button
                                                  type='button'
                                                  className='btn btn-light btn-active-color-primary mt-5 p-1 fs-9'
                                                  onClick={() =>
                                                    showOtLeaveHolidayHandler(
                                                      index,
                                                      'saturday_ott',
                                                      5
                                                    )
                                                  }
                                                  disabled={
                                                    ermState?.consultant_role == 'ERM'
                                                      ? true
                                                      : getDisableStatusofBenchProject(
                                                          list?.is_placement_project,
                                                          sat
                                                        )
                                                      ? // list?.is_placement_project === 2
                                                        true
                                                      : weekDayDataEntry[index]?.timesheet_data[5]
                                                          ?.qb_invoice_id != null
                                                      ? true
                                                      : projectStartDateDisable &&
                                                        projectStartDateDisable[index] &&
                                                        projectStartDateDisable[index][5]
                                                      ? true
                                                      : projectEndDateDisable &&
                                                        projectEndDateDisable[index] &&
                                                        projectEndDateDisable[index][5]
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[5] &&
                                                        weekDayDataEntry[index].timesheet_data[5]
                                                          .status == 'SUBMITTED'
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[5] &&
                                                        weekDayDataEntry[index].timesheet_data[5]
                                                          .status == 'APPROVED'
                                                      ? true
                                                      : otfieldsdisabledValidation[index][5]
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  OT
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            className={clsx(
                                              'px-2 py-3',
                                              weekDayDataEntry[index]?.timesheet_data[6]?.status ==
                                                'REJECTED'
                                                ? 'tableTdRedBg'
                                                : weekDayStatus[index]?.sunday_holidayt
                                                ? 'tableTdYellowBg'
                                                : weekDayStatus[index]?.sunday_leavet
                                                ? 'tableTdPinkBg'
                                                : 'tableTdBlueBg'
                                            )}
                                          >
                                            <div className='d-flex align-items-center flex-nowrap'>
                                              <label className='form-label mb-0 fw-semibold fs-8'>
                                                {/* {sun ? sun.slice(0, 5) : ''} */}
                                                SUN:{' '}
                                                {sun
                                                  ? moment(sun.slice(3, 5)).format('MMM')
                                                  : ''}{' '}
                                                {sun ? sun.slice(0, 2) : ''}
                                              </label>
                                              <Tooltip text='Weekend'>
                                                <img
                                                  src={SofaIcon}
                                                  className='timeEntryIcons blueBg ms-2'
                                                />
                                              </Tooltip>
                                              {weekDayDataEntry[index]?.timesheet_data[6]
                                                ?.status !== '' && (
                                                <Tooltip text='Day History'>
                                                  <button
                                                    className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                    onClick={() =>
                                                      getHistoryPerDay(
                                                        list.id,
                                                        sun
                                                        // weekDates[0]?.slice(0, 10),
                                                        // index
                                                      )
                                                    }
                                                  >
                                                    <img src={History} alt='history' />
                                                  </button>
                                                </Tooltip>
                                              )}
                                              {weekDayStatus[index]?.sunday_holidayt ? (
                                                <img
                                                  src={PlaneIcon}
                                                  className='timeEntryIcons yellowBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              {weekDayStatus[index]?.sunday_leavet ? (
                                                <img
                                                  src={UmbrellaIcon}
                                                  className='timeEntryIcons pinkBg ms-2'
                                                />
                                              ) : (
                                                ''
                                              )}
                                              <div className='invisibleBlock'></div>
                                            </div>

                                            <div className='d-flex  gap-1'>
                                              <div>
                                                <div className='input-group flex-nowrap'>
                                                  <input
                                                    type='text'
                                                    className={clsx(
                                                      'form-control  px-1 my-2 fs-6',
                                                      weekDayStatus[index]?.sunday_ott
                                                        ? 'formControlMinWidth45 border-end-0'
                                                        : 'formControlMinWidth90'
                                                    )}
                                                    value={
                                                      weekDayDataEntry[index]?.timesheet_data[6]
                                                        ?.regular_hours == '0.00'
                                                        ? ''
                                                        : weekDayDataEntry[index]?.timesheet_data[6]
                                                            ?.regular_hours
                                                    }
                                                    onBlur={(e) =>
                                                      timechangeForRegularHours(
                                                        e,
                                                        index,
                                                        6,
                                                        RegularHoursEntry
                                                      )
                                                    }
                                                    name={'regular_hours'}
                                                    tabIndex={10 * index + 7}
                                                    disabled={
                                                      ermState?.consultant_role == 'ERM'
                                                        ? true
                                                        : getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            sun
                                                          )
                                                        ? // list?.is_placement_project === 2
                                                          true
                                                        : weekDayDataEntry[index]?.timesheet_data[6]
                                                            ?.qb_invoice_id != null
                                                        ? true
                                                        : projectStartDateDisable &&
                                                          projectStartDateDisable[index] &&
                                                          projectStartDateDisable[index][6]
                                                        ? true
                                                        : projectEndDateDisable &&
                                                          projectEndDateDisable[index] &&
                                                          projectEndDateDisable[index][6]
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[6]
                                                            ?.status == 'SUBMITTED'
                                                        ? true
                                                        : weekDayDataEntry[index]?.timesheet_data[6]
                                                            ?.status == 'APPROVED'
                                                        ? true
                                                        : false
                                                    }
                                                    onFocus={(event) => event.target.select()}
                                                    onChange={(e: any) => {
                                                      DailyEntry(e, index, 6)
                                                    }}
                                                    placeholder='0:00'
                                                    aria-label='Hours'
                                                  />

                                                  {weekDayStatus[index]?.sunday_ott && (
                                                    <>
                                                      {}
                                                      <span
                                                        className={clsx(
                                                          'input-group-text  bg-body   px-0 my-2 fs-6 ',
                                                          weekDayDataEntry[index]?.timesheet_data[6]
                                                            ?.status == 'SUBMITTED'
                                                            ? 'disabledBg'
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[6]?.status ==
                                                              'APPROVED'
                                                            ? 'disabledBg'
                                                            : ''
                                                        )}
                                                      >
                                                        +
                                                      </span>
                                                      <input
                                                        type='text'
                                                        className={clsx(
                                                          'form-control border-start-0 px-1 my-2 fs-6',
                                                          weekDayStatus[index]?.sunday_ott
                                                            ? 'formControlMinWidth45'
                                                            : 'formControlMinWidth90'
                                                        )}
                                                        value={
                                                          weekDayDataEntry[index]?.timesheet_data[6]
                                                            ?.ot_hours == '0.00'
                                                            ? ''
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[6]?.ot_hours
                                                        }
                                                        onBlur={(e) =>
                                                          timechange(e, index, 6, OTHoursEntry)
                                                        }
                                                        onFocus={(event) => event.target.select()}
                                                        onChange={(e: any) => {
                                                          DailyEntry(e, index, 6)
                                                        }}
                                                        disabled={
                                                          getDisableStatusofBenchProject(
                                                            list?.is_placement_project,
                                                            sun
                                                          )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[6]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[6]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[6]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : false
                                                        }
                                                        name='ot_hours'
                                                        placeholder='0:00'
                                                        aria-label='OT'
                                                      />
                                                    </>
                                                  )}
                                                </div>

                                                <div className='d-flex '>
                                                  <div className='col'>
                                                    <input
                                                      type='text'
                                                      className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                      placeholder='Leave'
                                                      name='absent_hours'
                                                      disabled={
                                                        ermState?.consultant_role == 'ERM'
                                                          ? true
                                                          : getDisableStatusofBenchProject(
                                                              list?.is_placement_project,
                                                              sun
                                                            )
                                                          ? // list?.is_placement_project === 2
                                                            true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[6]?.qb_invoice_id !=
                                                            null
                                                          ? true
                                                          : projectStartDateDisable &&
                                                            projectStartDateDisable[index] &&
                                                            projectStartDateDisable[index][6]
                                                          ? true
                                                          : projectEndDateDisable &&
                                                            projectEndDateDisable[index] &&
                                                            projectEndDateDisable[index][6]
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[6]?.status ==
                                                            'SUBMITTED'
                                                          ? true
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[6]?.status ==
                                                            'APPROVED'
                                                          ? true
                                                          : weekDayStatus[index]?.sunday_leavet
                                                          ? true
                                                          : false
                                                      }
                                                      value={
                                                        weekDayDataEntry[index]?.timesheet_data[6]
                                                          ?.absent_hours == '0.00'
                                                          ? ''
                                                          : weekDayDataEntry[index]
                                                              ?.timesheet_data[6]?.absent_hours
                                                      }
                                                      // onBlur={(e) => timechange(e, index, 6, 24)}
                                                      onBlur={(e) =>
                                                        leaveOnBlur(e, index, 6, LeaveHoursEntry)
                                                      }
                                                      onFocus={(event) => event.target.select()}
                                                      onChange={(e: any) => {
                                                        DailyEntry(e, index, 6)
                                                      }}
                                                    />
                                                    {weekDayDataEntry[index]?.timesheet_data[6]
                                                      ?.status == 'APPROVED' ? (
                                                      <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                        Approved
                                                      </div>
                                                    ) : weekDayDataEntry[index]?.timesheet_data[6]
                                                        ?.status == 'REJECTED' ? (
                                                      <Tooltip
                                                        text={
                                                          weekDayDataEntry[index]?.timesheet_data[6]
                                                            ?.approver_notes
                                                        }
                                                      >
                                                        <div
                                                          className='fs-8 text-danger fw-regular mt-1 text-center'
                                                          role='button'
                                                        >
                                                          <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                          Rejected
                                                        </div>
                                                      </Tooltip>
                                                    ) : (
                                                      <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                        Hidden
                                                      </div>
                                                    )}
                                                  </div>

                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text='Mark as holiday'>
                                                      <button
                                                        className='btn icon-btn p-0  d-flex mt-1'
                                                        disabled={
                                                          ermState?.consultant_role == 'ERM'
                                                            ? true
                                                            : getDisableStatusofBenchProject(
                                                                list?.is_placement_project,
                                                                sun
                                                              )
                                                            ? // list?.is_placement_project === 2
                                                              true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[6]
                                                                ?.qb_invoice_id != null
                                                            ? true
                                                            : projectStartDateDisable &&
                                                              projectStartDateDisable[index] &&
                                                              projectStartDateDisable[index][6]
                                                            ? true
                                                            : projectEndDateDisable &&
                                                              projectEndDateDisable[index] &&
                                                              projectEndDateDisable[index][6]
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[6]?.status ==
                                                              'SUBMITTED'
                                                            ? true
                                                            : weekDayDataEntry[index]
                                                                ?.timesheet_data[6]?.status ==
                                                              'APPROVED'
                                                            ? true
                                                            : weekDayStatus[index]?.sunday_holidayt
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() =>
                                                          onClickHolidayDisableLeave(
                                                            index,
                                                            'sunday_leavet',
                                                            6
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={UmbrellaIcon}
                                                          className='timeEntryIcons pinkBgLight'
                                                        />
                                                      </button>
                                                    </Tooltip>
                                                  </div>
                                                  <div className='col-auto ms-1'>
                                                    <Tooltip text=''>
                                                      <div className='pt5'>
                                                        <Popup
                                                          trigger={
                                                            <a
                                                              onClick={() =>
                                                                copyToClipboard(parse(xmlString))
                                                              }
                                                            >
                                                              <i className='bi bi-info-lg text-gray  fs-6 mt-1'></i>
                                                            </a>
                                                          }
                                                          position='right center'
                                                          on={['hover', 'focus']}
                                                          // arrow={position !== 'center center'}
                                                        >
                                                          <div>
                                                            <div className='p-3'>
                                                              <div className='text-gray-900 fs-7 fw-semibold'>
                                                                {parse(xmlString)}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Popup>
                                                      </div>
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <button
                                                  type='button'
                                                  className='btn btn-light btn-active-color-primary mt-5 p-1 fs-9'
                                                  onClick={() =>
                                                    showOtLeaveHolidayHandler(
                                                      index,
                                                      'sunday_ott',
                                                      6
                                                    )
                                                  }
                                                  disabled={
                                                    ermState?.consultant_role == 'ERM'
                                                      ? true
                                                      : getDisableStatusofBenchProject(
                                                          list?.is_placement_project,
                                                          sun
                                                        )
                                                      ? // list?.is_placement_project === 2
                                                        true
                                                      : weekDayDataEntry[index]?.timesheet_data[6]
                                                          ?.qb_invoice_id != null
                                                      ? true
                                                      : projectStartDateDisable &&
                                                        projectStartDateDisable[index] &&
                                                        projectStartDateDisable[index][6]
                                                      ? true
                                                      : projectEndDateDisable &&
                                                        projectEndDateDisable[index] &&
                                                        projectEndDateDisable[index][6]
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[6] &&
                                                        weekDayDataEntry[index].timesheet_data[6]
                                                          .status == 'SUBMITTED'
                                                      ? true
                                                      : weekDayDataEntry[index] &&
                                                        weekDayDataEntry[index].timesheet_data[6] &&
                                                        weekDayDataEntry[index].timesheet_data[6]
                                                          .status == 'APPROVED'
                                                      ? true
                                                      : otfieldsdisabledValidation[index][6]
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  OT
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                          <td className='px-2 py-3 border border-end-0'>
                                            <div className='d-flex align-items-center flex-nowrap'>
                                              <label className='form-label mb-0 fw-semibold fs-8'>
                                                TOTAL
                                              </label>

                                              <button
                                                className='btn btn-icon invisible   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                onClick={() =>
                                                  getHistoryPerDay(
                                                    list.id,
                                                    sun
                                                    // weekDates[0]?.slice(0, 10),
                                                    // index
                                                  )
                                                }
                                              >
                                                <img src={History} alt='history' />
                                              </button>
                                            </div>
                                            <div className='d-flex  gap-1'>
                                              <div>
                                                <input
                                                  type='text'
                                                  className='form-control px-1 my-2 fs-6 formControlMinWidth90'
                                                  name='total_actual'
                                                  disabled
                                                  placeholder='Total'
                                                  value={sumofAllProj[index]}
                                                />

                                                <div className='d-flex align-items-center'>
                                                  <div className='col'>
                                                    <input
                                                      type='text'
                                                      className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                      placeholder='Leave Hours'
                                                      name='total_leave'
                                                      disabled
                                                      value={sumofAllProjLeave[index]}
                                                    />

                                                    <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                      Hidden
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div className='border-top '></div>

                              <div className='py-4 px-5'>
                                <div className='row g-3'>
                                  <div className='col-md-12 col-lg'>
                                    <label className='form-label '>Project Status Update *</label>
                                    <textarea
                                      required
                                      // className='form-control'
                                      className={` ${
                                        ClientManagerNameValidation[index].ccValidation
                                          ? `form-control fs-6  is-invalid`
                                          : `form-control fs-6  `
                                      }`}
                                      disabled={
                                        ermState?.consultant_role == 'ERM'
                                          ? true
                                          : getDisableStatusofBenchProject(
                                              list?.is_placement_project,
                                              null
                                            )
                                          ? // list?.is_placement_project === 2
                                            true
                                          : !(
                                              projectStartDateDisable &&
                                              projectStartDateDisable[index] &&
                                              projectStartDateDisable[index].includes(false)
                                            )
                                          ? true
                                          : !(
                                              projectEndDateDisable &&
                                              projectEndDateDisable[index] &&
                                              projectEndDateDisable[index].includes(false)
                                            )
                                          ? true
                                          : weekDayDataEntry[index]?.timesheet_data.find(
                                              (item: any) => item.status == ''
                                            ) ||
                                            weekDayDataEntry[index]?.timesheet_data.find(
                                              (item: any) => item.status == 'REJECTED'
                                            ) ||
                                            weekDayDataEntry[index]?.timesheet_data.find(
                                              (item: any) => item.status == 'SAVED'
                                            )
                                          ? false
                                          : true
                                      }
                                      value={
                                        weekDayDataEntry[index]?.user_notes == null
                                          ? ''
                                          : weekDayDataEntry[index]?.user_notes
                                          ? weekDayDataEntry[index]?.user_notes
                                          : weekDayDataEntry[index]?.ts_user_notes
                                      }
                                      name='user_notes'
                                      placeholder='Please Enter Project Status Update'
                                      rows={3}
                                      onChange={(e: any) => {
                                        DailyEntry(e, index, 0)
                                      }}
                                    ></textarea>
                                    {ClientManagerNameValidation[index].ccValidation && (
                                      <p className='error text-danger mb-0 mt-2'>
                                        Project status update is required
                                      </p>
                                    )}
                                  </div>
                                  {weekDayDataEntry[index]?.timesheet_data.find(
                                    (item: any, index: number) => item.status === 'APPROVED'
                                  ) && (
                                    <div className='col-md-12 col-lg'>
                                      <label className='form-label '>Approver Comments</label>
                                      <textarea
                                        className='form-control'
                                        placeholder='Approver Comments'
                                        rows={3}
                                        disabled
                                        value={
                                          weekDayDataEntry[index]?.ts_approver_notes == null
                                            ? ''
                                            : weekDayDataEntry[index]?.ts_approver_notes
                                        }
                                      ></textarea>
                                    </div>
                                  )}
                                  {(weekDayDataEntry[index]?.timesheet_data.find(
                                    (item: any, index: number) => item.status === ''
                                  ) ||
                                    weekDayDataEntry[index]?.timesheet_data.find(
                                      (item: any, index: number) => item.status === 'REJECTED'
                                    ) ||
                                    weekDayDataEntry[index]?.timesheet_data.find(
                                      (item: any, index: number) => item.status === 'SAVED'
                                    ) ||
                                    weekDayDataEntry[index]?.timesheet_data.find(
                                      (item: any, index: number) => item.status === 'SUBMITTED'
                                    )) && (
                                    <div className='col-md-12 col-lg '>
                                      <label className='form-label' style={{visibility: 'hidden'}}>
                                        Upload The Document
                                      </label>

                                      <input
                                        type='file'
                                        id={'test' + index}
                                        className='d-none'
                                        name='file'
                                        multiple
                                        disabled={
                                          ermState?.consultant_role == 'ERM'
                                            ? true
                                            : getDisableStatusofBenchProject(
                                                list?.is_placement_project,
                                                null
                                              )
                                            ? // list?.is_placement_project === 2
                                              true
                                            : !(
                                                projectStartDateDisable &&
                                                projectStartDateDisable[index] &&
                                                projectStartDateDisable[index].includes(false)
                                              )
                                            ? true
                                            : !(
                                                projectEndDateDisable &&
                                                projectEndDateDisable[index] &&
                                                projectEndDateDisable[index].includes(false)
                                              )
                                            ? true
                                            : false
                                        }
                                        accept='image/png,image/jpg,image/jpeg,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword'
                                        onChange={(e: any) => {
                                          FileUpload(e, index)
                                          e.target.value = ''
                                        }}
                                      />

                                      <div>
                                        <div className=''>
                                          <label
                                            role='button'
                                            htmlFor={'test' + index}
                                            className={clsx(
                                              ClientManagerNameValidation[index].ucValidation
                                                ? 'border-danger bg-danger'
                                                : 'border-primary bg-primary',
                                              'width100   p-2 text-dark bg-opacity-10 border  border-dashed uploadDocument  d-flex align-items-center h85 justify-content-center'
                                            )}
                                          >
                                            {' '}
                                            <div className='d-flex align-items-center justify-content-center gap-3'>
                                              <div>
                                                <i className='bi bi-upload fs50'></i>
                                                {/* <img src={Arrow078} className='downloadIcon' /> */}
                                              </div>
                                              <div>
                                                <div className='text-gray-700  fw-semibold fs-6'>
                                                  Upload Client approved timesheets{' '}
                                                  {list.is_placement_project == '1' && '*'}
                                                </div>
                                                <div className='text-gray-500  fw-normal fs-8'>
                                                  Max 10 MB , Max 5 Files
                                                  <br />
                                                  png, jpg, jpeg, docx, xlsx, pdf
                                                </div>
                                                {/* <div className="fs-9 text-primary">(MAX 10 MB)</div> */}
                                              </div>
                                            </div>
                                          </label>
                                          {ClientManagerNameValidation[index].ucValidation && (
                                            <p className='error text-danger mb-0 mt-2'>
                                              Documents are required
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* {disableWorkLocation && (
                                <>
                                  <div className='border-top '></div>
                                  <div className='py-4 px-5'>
                                    <div className='row g-3'>
                                      <div className='col-xs-12 col-md '>
                                        <label className='form-label '>
                                          Work Country {list?.is_placement_project && '*'}
                                        </label>
                                        <Typeahead
                                          id='basic-typeahead-single'
                                          onChange={(e) => selectedCountries(e, index)}
                                          onInputChange={(e) => handleCountries(e)}
                                          options={country}
                                          placeholder='Enter Country Name'
                                          selected={countries[index]}
                                          labelKey={(country: any) => user(country.name)}
                                          // inputProps={{required: true}}
                                          inputProps={{
                                            required: list?.is_placement_project,
                                          }}
                                        >
                                          {({onClear, selected}) => (
                                            <div className='rbt-aux'>
                                              {!!selected.length && (
                                                // <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                                                <ClearButton onClick={onClear} />
                                              )}
                                            </div>
                                          )}
                                        </Typeahead>
                                      </div>
                                      <div className='col-xs-12 col-md '>
                                        <label className='form-label'>Work State </label>
                                        <Typeahead
                                          id='basic-typeahead-single'
                                          onChange={(e) => selectedStates(e, index)}
                                          onInputChange={(e) => handleStates(e)}
                                          options={state}
                                          placeholder='Enter State Name'
                                          selected={states[index]}
                                          labelKey={(state: any) => user(state.name)}
                                          inputProps={{required: true}}
                                        >
                                          {({onClear, selected}) => (
                                            <div className='rbt-aux'>
                                              {!!selected.length && (
                                                // <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                                                <ClearButton onClick={onClear} />
                                              )}
                                            </div>
                                          )}
                                        </Typeahead>
                                      </div>

                                      <div className='col-xs-12 col-md '>
                                        <label className='form-label'>Work City </label>
                                        <input
                                          type='text'
                                          placeholder='City'
                                          className='form-control fs-6 text-capitalize'
                                          defaultValue={
                                            list?.ts_work_city
                                              ? list.ts_work_city
                                              : list?.work_city
                                              ? list?.work_city
                                              : ''
                                          }
                                          onChange={(e) => {
                                            ClientDetails(e, index)
                                          }}
                                          minLength={3}
                                          name='work_city'
                                        ></input>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )} */}

                              {docResultDataRes?.length > 0 && (
                                <>
                                  <div className='border-top '></div>
                                  <div className='py-4 px-5'>
                                    <div className='row gap-3 px-3'>
                                      {docResultDataRes.map((each: any, docIndex: any) => {
                                        if (each.project_id === list.id) {
                                          return (
                                            <div
                                              key={docIndex}
                                              className='col-auto p-2 text-dark bg-opacity-10 border border-primary border-dashed uploadDocument  text-gray-700 fw-normal fs-7'
                                            >
                                              <div className='d-flex gap-2 align-items-center'>
                                                {/* <div>{each}</div> */}
                                                <div>{each?.original_name}</div>
                                                {each?.original_name?.length > 0 &&
                                                  (each?.original_name?.substr(
                                                    each?.original_name?.lastIndexOf('.') + 1
                                                  ) == 'jpeg' ||
                                                    each?.original_name?.substr(
                                                      each?.original_name?.lastIndexOf('.') + 1
                                                    ) == 'png' ||
                                                    each?.original_name?.substr(
                                                      each?.original_name?.lastIndexOf('.') + 1
                                                    ) == 'pdf' ||
                                                    each?.original_name?.substr(
                                                      each?.original_name?.lastIndexOf('.') + 1
                                                    ) == 'xlsx' ||
                                                    each?.original_name?.substr(
                                                      each?.original_name?.lastIndexOf('.') + 1
                                                    ) == 'docx' ||
                                                    each?.original_name?.substr(
                                                      each?.original_name?.lastIndexOf('.') + 1
                                                    ) == 'PDF' ||
                                                    each?.original_name?.substr(
                                                      each?.original_name?.lastIndexOf('.') + 1
                                                    ) == 'jpg') && (
                                                    <Tooltip text='View Uploaded File'>
                                                      <div role='button'>
                                                        {/* <a>
                                                          <i
                                                            className='bi bi-eye fw-bold fs-4 text-primary'
                                                            onClick={() => {
                                                              viewFile(each?.blobUrl, docIndex)
                                                            }}
                                                          ></i>
                                                        </a> */}

                                                        <a
                                                          href={each.blobUrl}
                                                          target='_blank'
                                                          className='m-1 mx-2'
                                                        >
                                                          <i className='bi bi-eye fw-bold fs-4 text-primary'></i>
                                                        </a>
                                                      </div>
                                                    </Tooltip>
                                                  )}
                                                <Tooltip text='Download File'>
                                                  <div>
                                                    <a
                                                      className='m-1 mx-2'
                                                      role='button'
                                                      onClick={() => {
                                                        downloadFile(each.blobDownloadUrl, docIndex)
                                                      }}
                                                    >
                                                      <i className='bi bi-download  fw-bold fs-4 text-primary'></i>
                                                    </a>
                                                  </div>
                                                </Tooltip>
                                                {
                                                  // (weekDayDataEntry[index]?.timesheet_data.find(
                                                  //   (item: any, index: number) => item.status === ''
                                                  // ) ||
                                                  //   weekDayDataEntry[index]?.timesheet_data.find(
                                                  //     (item: any, index: number) =>
                                                  //       item.status === 'REJECTED'
                                                  //   ) ||
                                                  //   weekDayDataEntry[index]?.timesheet_data.find(
                                                  //     (item: any, index: number) =>
                                                  //       item.status === 'SAVED'
                                                  //   )
                                                  //   ||
                                                  //   weekDayDataEntry[index]?.timesheet_data.find(
                                                  //     (item: any, index: number) =>
                                                  //       item.status === 'SUBMITTED'
                                                  //   )
                                                  //   )
                                                  weekDayDataEntry[index]?.timesheet_data.find(
                                                    (item: any, index: number) =>
                                                      item.status === 'APPROVED'
                                                  ) ||
                                                  weekDayDataEntry[index]?.timesheet_data.find(
                                                    (item: any, index: number) =>
                                                      item.status === 'SUBMITTED'
                                                  ) ? (
                                                    ''
                                                  ) : (
                                                    <Tooltip text='Delete File'>
                                                      <div>
                                                        {/* <h4>
                                                          {docIndex}-{' '}
                                                          {each.previous_doc ? 'Old' : 'New'}
                                                        </h4> */}
                                                        <i
                                                          className='bi bi-trash3 m-1 mx-2 text-danger fw-bold fs-4'
                                                          role='button'
                                                          onClick={() =>
                                                            removeDoc1(
                                                              each.id,
                                                              docIndex,
                                                              each.project_id
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    </Tooltip>
                                                  )
                                                }
                                              </div>
                                            </div>
                                          )
                                        }
                                      })}
                                    </div>
                                  </div>
                                </>
                              )}

                              <Drawer
                                show={show}
                                history_open_per_week={history_open_per_week}
                                setHistoryOpenPerWeek={setHistoryOpenPerWeek}
                                history_open_per_day={history_open_per_day}
                                setHistoryOpenPerDay={setHistoryOpenPerDay}
                                setShow={setShow}
                                history_per_week={history_per_week}
                                history_per_day={history_per_day}
                                history_status_per_day={history_status_per_day}
                              />

                              {viewshow && (
                                <ViewDocumentPop
                                  viewFile={viewFile}
                                  view_open={view_open}
                                  setViewOpen={setViewOpen}
                                  view_blob={view_blob}
                                  setViewBlob={setViewBlob}
                                  cancel={handleCancel}
                                />
                              )}
                              {submitshow ? (
                                <SubmitButtonPop
                                  FormSubmit={FormSingleProjectSubmit}
                                  submit_open={submit_open}
                                  submitshow={submitshow}
                                  setSubmitOpen={setSubmitOpen}
                                  modalPop={modalPop}
                                  handleSubmitted={handleSubmitted}
                                  setSubmitShow={setSubmitShow}
                                  index={indexState}
                                  sumofRegularHoursProject={sumofRegularHoursProject}
                                  sumofOtHoursProject={sumofOtHoursProject}
                                  sumofLeaveHoursProject={sumofLeaveHoursProject}
                                />
                              ) : (
                                ''
                              )}
                              {WorkLocationshow ? (
                                <WorkLocationPop
                                  WorkLocationshow={WorkLocationshow}
                                  setWorkLocationShow={setWorkLocationShow}
                                  WorkLocationContinuePop={WorkLocationContinuePop}
                                  WorkLocationOkPop={WorkLocationOkPop}
                                />
                              ) : (
                                ''
                              )}
                              {WorkLocationAllshow ? (
                                <WorkLocationSubmitAllPop
                                  WorkLocationAllshow={WorkLocationAllshow}
                                  setWorkLocationAllShow={setWorkLocationAllShow}
                                  WorkLocationAllContinuePop={WorkLocationAllContinuePop}
                                  WorkLocationAllOkPop={WorkLocationAllOkPop}
                                />
                              ) : (
                                ''
                              )}

                              {submitAllshow ? (
                                <SubmitButtonPop
                                  FormSubmit={FormSingleProjectSubmit}
                                  submitall_open={submitall_open}
                                  submitAllshow={submitAllshow}
                                  setSubmitAllOpen={setSubmitAllOpen}
                                  modalPopforSubmitAll={modalPopforSubmitAll}
                                  handleSubmittedAll={handleSubmittedAll}
                                  setSubmitAllShow={setSubmitAllShow}
                                  index={indexState}
                                  sumofRegularHoursProject={sumofRegularHoursProject}
                                  sumofOtHoursProject={sumofOtHoursProject}
                                  sumofLeaveHoursProject={sumofLeaveHoursProject}
                                />
                              ) : (
                                ''
                              )}
                              {forLeaveAndHoldiayPopup && (
                                <LeaveAndHolidayPopup
                                  holidayData={holidayData}
                                  leaveHandler={leaveHandler}
                                  text={'Do you want to apply holiday ?'}
                                />
                              )}
                              {forLeavePopup && (
                                <LeavePopup
                                  leavePopHandler={leavePopHandler}
                                  text={'Do you want to apply Leave ?'}
                                  leaveOnBlurData={leaveOnBlurData}
                                />
                              )}

                              <div className='border-top'></div>
                              <div className='text-center  fw-semi-bold text-gray-700 fs-7 pt-5 py-3 '>
                                Note :It's mandatory to submit Timesheet with Regular hrs/ Leave Hrs
                                at least for one day
                              </div>
                              <div className='d-flex gap-3 justify-content-end px-5 pb-5 py-3'>
                                <div>
                                  <button
                                    type='button'
                                    className='btn btn-light-primary  btn-sm'
                                    disabled={
                                      getDisableStatusofBenchProject(
                                        list?.is_placement_project,
                                        null
                                      )
                                        ? // list?.is_placement_project === 2
                                          true
                                        : false
                                    }
                                    onClick={() => getHistoryPerWeek(list.id, list)}
                                  >
                                    History
                                  </button>
                                </div>

                                {ermState?.consultant_role == 'ERM' ? (
                                  ''
                                ) : weekDayDataEntry[index].timesheet_data.find(
                                    (item: any, index: number) => item.status != 'APPROVED'
                                  ) ? (
                                  weekDayDataEntry[index].timesheet_data.find(
                                    (item: any, index: number) => item.status === 'SAVED'
                                  ) ? (
                                    weekDayDataEntry[index].timesheet_data.find(
                                      (item: any, index: number) => item.status === 'SUBMITTED'
                                    ) ? (
                                      weekDayDataEntry[index].timesheet_data.find(
                                        (item: any, index: number) => item.status === 'REJECTED'
                                      ) ? (
                                        <>
                                          <div>
                                            <button
                                              type='button'
                                              className='btn btn-light-primary  btn-sm'
                                              disabled={
                                                getDisableStatusofBenchProject(
                                                  list?.is_placement_project,
                                                  null
                                                )
                                                  ? // list?.is_placement_project === 2
                                                    true
                                                  : false
                                              }
                                              onClick={() => {
                                                FormSave(index)
                                              }}
                                            >
                                              Save
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              type='button'
                                              className='btn btn-primary btn-sm'
                                              disabled={
                                                getDisableStatusofBenchProject(
                                                  list?.is_placement_project,
                                                  null
                                                )
                                                  ? // list?.is_placement_project === 2
                                                    true
                                                  : false
                                              }
                                              onClick={() => {
                                                FormSingleProjectUnSubmit(index)
                                              }}
                                            >
                                              Unsubmit
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              type='button'
                                              className='btn btn-primary btn-sm'
                                              onClick={() => {
                                                FormSingleProjectSubmit(index, '', list, '')
                                              }}
                                              disabled={
                                                getDisableStatusofBenchProject(
                                                  list?.is_placement_project,
                                                  null
                                                )
                                                  ? // list?.is_placement_project === 2
                                                    true
                                                  : submitButtonEnableDisableValidation[index]
                                              }
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div>
                                            <button
                                              type='button'
                                              className='btn btn-light-primary  btn-sm'
                                              disabled={
                                                getDisableStatusofBenchProject(
                                                  list?.is_placement_project,
                                                  null
                                                )
                                                  ? // list?.is_placement_project === 2
                                                    true
                                                  : false
                                              }
                                              onClick={() => {
                                                FormSave(index)
                                              }}
                                            >
                                              Save
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              type='button'
                                              className='btn btn-primary btn-sm'
                                              onClick={() => {
                                                FormSingleProjectUnSubmit(index)
                                              }}
                                            >
                                              Unsubmit
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              type='button'
                                              className='btn btn-primary btn-sm'
                                              disabled={
                                                getDisableStatusofBenchProject(
                                                  list?.is_placement_project,
                                                  null
                                                )
                                                  ? // list?.is_placement_project === 2
                                                    true
                                                  : submitButtonEnableDisableValidation[index]
                                              }
                                              onClick={() => {
                                                FormSingleProjectSubmit(index, '', list, '')
                                              }}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </>
                                      )
                                    ) : weekDayDataEntry[index].timesheet_data.find(
                                        (item: any, index: number) => item.status === 'SAVED'
                                      ) ? (
                                      <>
                                        <div>
                                          <button
                                            type='button'
                                            className='btn btn-light-primary  btn-sm'
                                            disabled={
                                              getDisableStatusofBenchProject(
                                                list?.is_placement_project,
                                                null
                                              )
                                                ? // list?.is_placement_project === 2
                                                  true
                                                : false
                                            }
                                            onClick={() => {
                                              FormSave(index)
                                            }}
                                          >
                                            Save
                                          </button>
                                        </div>
                                        <div>
                                          <button
                                            type='button'
                                            className='btn btn-primary btn-sm'
                                            disabled={
                                              getDisableStatusofBenchProject(
                                                list?.is_placement_project,
                                                null
                                              )
                                                ? // list?.is_placement_project === 2
                                                  true
                                                : submitButtonEnableDisableValidation[index]
                                            }
                                            onClick={() => {
                                              FormSingleProjectSubmit(index, '', list, '')
                                            }}
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      'submit'
                                    )
                                  ) : weekDayDataEntry[index].timesheet_data.find(
                                      (item: any, index: number) => item.status === 'SUBMITTED'
                                    ) ? (
                                    weekDayDataEntry[index].timesheet_data.find(
                                      (item: any, index: number) => item.status === 'REJECTED'
                                    ) ? (
                                      <>
                                        <div>
                                          <button
                                            type='button'
                                            className='btn btn-light-primary  btn-sm'
                                            disabled={
                                              getDisableStatusofBenchProject(
                                                list?.is_placement_project,
                                                null
                                              )
                                                ? // list?.is_placement_project === 2
                                                  true
                                                : false
                                            }
                                            onClick={() => {
                                              FormSave(index)
                                            }}
                                          >
                                            Save
                                          </button>
                                        </div>
                                        <div>
                                          <button
                                            type='button'
                                            className='btn btn-primary btn-sm'
                                            onClick={() => {
                                              FormSingleProjectUnSubmit(index)
                                            }}
                                          >
                                            Unsubmit
                                          </button>
                                        </div>
                                        <div>
                                          <button
                                            type='button'
                                            className='btn btn-primary btn-sm'
                                            disabled={
                                              getDisableStatusofBenchProject(
                                                list?.is_placement_project,
                                                null
                                              )
                                                ? // list?.is_placement_project === 2
                                                  true
                                                : submitButtonEnableDisableValidation[index]
                                            }
                                            onClick={() => {
                                              FormSingleProjectSubmit(index, '', list, '')
                                            }}
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </>
                                    ) : weekDayDataEntry[index].timesheet_data.find(
                                        (item: any, index: number) => item.status === 'SUBMITTED'
                                      ) ? (
                                      weekDayDataEntry[index].timesheet_data.find(
                                        (item: any, index: number) => item.status === ''
                                      ) ? (
                                        <>
                                          <div>
                                            <button
                                              type='button'
                                              className='btn btn-light-primary  btn-sm'
                                              disabled={
                                                getDisableStatusofBenchProject(
                                                  list?.is_placement_project,
                                                  null
                                                )
                                                  ? // list?.is_placement_project === 2
                                                    true
                                                  : false
                                              }
                                              onClick={() => {
                                                FormSave(index)
                                              }}
                                            >
                                              Save
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              type='button'
                                              className='btn btn-primary btn-sm'
                                              onClick={() => {
                                                FormSingleProjectUnSubmit(index)
                                              }}
                                            >
                                              Unsubmit
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              type='button'
                                              className='btn btn-primary btn-sm'
                                              onClick={() => {
                                                FormSingleProjectSubmit(index, '', list, '')
                                              }}
                                              disabled={
                                                getDisableStatusofBenchProject(
                                                  list?.is_placement_project,
                                                  null
                                                )
                                                  ? // list?.is_placement_project === 2
                                                    true
                                                  : submitButtonEnableDisableValidation[index]
                                              }
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        <div>
                                          <button
                                            type='button'
                                            className='btn btn-primary btn-sm'
                                            onClick={() => {
                                              FormSingleProjectUnSubmit(index)
                                            }}
                                          >
                                            Unsubmit
                                          </button>
                                        </div>
                                      )
                                    ) : (
                                      <div>
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-sm'
                                          onClick={() => {
                                            FormSingleProjectUnSubmit(index)
                                          }}
                                        >
                                          Unsubmit
                                        </button>
                                      </div>
                                    )
                                  ) : weekDayDataEntry[index].timesheet_data.find(
                                      (item: any, index: number) => item.status === ''
                                    ) ? (
                                    <>
                                      <div>
                                        <button
                                          type='button'
                                          className='btn btn-light-primary  btn-sm'
                                          disabled={
                                            getDisableStatusofBenchProject(
                                              list?.is_placement_project,
                                              null
                                            )
                                              ? // list?.is_placement_project === 2
                                                true
                                              : false
                                          }
                                          onClick={() => {
                                            FormSave(index)
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-sm'
                                          onClick={() => {
                                            FormSingleProjectSubmit(index, '', list, '')
                                          }}
                                          disabled={
                                            getDisableStatusofBenchProject(
                                              list?.is_placement_project,
                                              null
                                            )
                                              ? // list?.is_placement_project === 2
                                                true
                                              : submitButtonEnableDisableValidation[index]
                                          }
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </>
                                  ) : weekDayDataEntry[index].timesheet_data.find(
                                      (item: any, index: number) => item.status === 'REJECTED'
                                    ) ? (
                                    <>
                                      <div>
                                        <button
                                          type='button'
                                          className='btn btn-light-primary  btn-sm'
                                          disabled={
                                            getDisableStatusofBenchProject(
                                              list?.is_placement_project,
                                              null
                                            )
                                              ? // list?.is_placement_project === 2
                                                true
                                              : false
                                          }
                                          onClick={() => {
                                            FormSave(index)
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-sm'
                                          onClick={() => {
                                            FormSingleProjectSubmit(index, '', list, '')
                                          }}
                                          disabled={
                                            getDisableStatusofBenchProject(
                                              list?.is_placement_project,
                                              null
                                            )
                                              ? // list?.is_placement_project === 2
                                                true
                                              : submitButtonEnableDisableValidation[index]
                                          }
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {CopyProjectMatching ? (
                <CopyButtonPop
                  getCopyProjectNotMatchingPopup={getCopyProjectNotMatchingPopup}
                  getCopyProjectNotMatchingClosePopup={getCopyProjectNotMatchingClosePopup}
                  employeesProjectList={employeesProjectList}
                  CopyProjectMatchingprevweek={CopyProjectMatchingprevweek}
                />
              ) : (
                ''
              )}
              {ApplyPopupOpen ? (
                <VacationPop
                  ApplyLeavePopUpClose={ApplyLeavePopUpClose}
                  ApplyVacation={ApplyVacation}
                />
              ) : (
                ''
              )}

              <DrawerMessenger />
              {ermState?.consultant_role == 'ERM' ? (
                ''
              ) : (
                <div className='d-flex gap-3 justify-content-end px-2 pt-2'>
                  {/* <div>
                  <button
                    type='button'
                    className='btn btn-sm   btn-light  btn-active-color-primary'
                    onClick={ApplyLeavePopUpOpen}
                  >
                    Apply Leave/Vacation
                  </button>
                </div> */}
                  {weekDayDataEntry &&
                    weekDayDataEntry.length > 1 &&
                    statusForSubmitALL &&
                    statusForSubmitALL.length > 0 &&
                    statusForSubmitALL.indexOf(true) > -1 && (
                      <div>
                        <button
                          type='button'
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            const index = 0
                            FormSaveAll(index)
                          }}
                          disabled={
                            submitButtonEnableDisableValidation.includes(false) ? false : true
                          }
                        >
                          Save All
                        </button>
                      </div>
                    )}
                  {weekDayDataEntry &&
                    weekDayDataEntry.length > 1 &&
                    statusForUnSubmitALL &&
                    statusForUnSubmitALL.length > 0 &&
                    statusForUnSubmitALL.indexOf(true) > -1 && (
                      <div>
                        <button
                          type='button'
                          // disabled={submitToggle}
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            const index = 0
                            FormUnSubmitAll(index)
                          }}
                        >
                          Unsubmit All
                        </button>
                      </div>
                    )}
                  {weekDayDataEntry &&
                    weekDayDataEntry.length > 1 &&
                    statusForSubmitALL &&
                    statusForSubmitALL.length > 0 &&
                    statusForSubmitALL.indexOf(true) > -1 && (
                      <div>
                        <button
                          disabled={
                            submitButtonEnableDisableValidation.includes(false) ? false : true
                          }
                          type='button'
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            const index = 0
                            FormSubmitAllProjects(index, '')
                          }}
                        >
                          Submit All
                        </button>
                      </div>
                    )}
                </div>
              )}
            </div>
          )}
          {employeesProjectList && employeesProjectList.length == 0 && !month_week_view && (
            <div className='text-center  fw-bold text-gray-800'>No Projects found </div>
          )}
          {showSkillsRequiredPopup ? (
            <SkillsRequiredPopup
              closeSKillPopup={closeSKillPopup}
              closeModal={showSkillsRequiredPopup}
              // setAnnouncementOpenPoup={setAnnouncementOpenPoup}
              // announcement_list={announcement_list}
            />
          ) : (
            ''
          )}
        </>
      </div>
      <ToastContainer />
    </>
  )
}
export default AddTimesheet
