import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {default as Apiservices} from '../../../common/index'
import {useNavigate} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {Link} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import TimesheetImg from '../../../localMedia/img/timesheet.svg'
import MailIconImg from '../../../localMedia/img/mail-icon.svg'
import GooglePlayStoreImg from '../../../localMedia/img/google-play.svg'
import AppStoreImg from '../../../localMedia/img/app-store.svg'
import MSRcosmosGrouplogoImg from '../../../localMedia/img/MSRcosmosGrouplogo.svg'
type FormData = {
  email: string
}

function ForgotPassword(props: any) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email(' Email is invalid'),
  })
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  })

  const navigate = useNavigate()
  const onSubmit = async (data: FormData) => {
    const article = {
      email: data.email,
    }
    const response: any = await Apiservices.forgotPassword(article)
    const response1: any = await Apiservices.checkEmailForForgetPassword({
      email: data.email,
    })
    if (response.data.message == 'No Records Found') {
      toast.warn('Email is invalid')
    }
    if (response.data.message == 'Email Exists' && response1.message == 'ACTIVE') {
      const sendingTokenForMail: any = await Apiservices.sendingTokenForMail({
        email: data.email,
        // applicationToolName: response.data.data[0].ecdb_tool_name,
        applicationToolName: 'Timesheet Tracker',
      })
      if (sendingTokenForMail.statusCode == 200) {
        // toast.success('Reset link has sent to your mail please check')
        // navigate('/login')
        return (
          toast.success('Reset link has sent to your mail please check'),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          },
          setTimeout(function () {
            navigate('/login')
          }, 2000)
        )
      } else if (sendingTokenForMail.statusCode == 500) {
        return (
          // toast.warn(sendingTokenForMail.message),
          toast.warn('User does not exist. Kindly contact the Administrator!'),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          },
          setTimeout(function () {
            navigate('/login')
          }, 1000)
        )
      }
    }
  }

  useEffect(() => {
    console.log('status123')
  }, [])

  return (
    <>
      <svg className='mask-paths'>
        <clipPath id='mask'>
          <path
            xmlns='http://www.w3.org/2000/svg'
            d='M0,0H604C733,0,837.5,104.54,837.5,233.49h0C837.5,551.89,579.39,810,261,810H162A677.33,677.33,0,0,1,.12,790.48l-.11,0H0Z'
            fill='#d9d9d9'
          />
        </clipPath>
      </svg>
      <div className='d-flex flex-column flex-root login-content'>
        <div className='d-flex flex-column flex-lg-row flex-column-fluid fluid-area'>
          <div className='lft-cover d-flex flex-column'>
            <div className='image-shape'></div>
          </div>
          <div className='d-flex flex-column flex-lg-row-fluid form-section'>
            <div className='d-flex flex-center flex-column flex-column-fluid'>
              <div className='form'>
                <div className='login'>
                  <div>
                    <div className='brand-logo '>
                      <img src={TimesheetImg} alt='' />
                    </div>
                    <h2 className='mb-2'>Welcome To Timesheet</h2>
                    <h5 className='fw-semibold text-gray-9'> Forgot Password ?</h5>
                    <p className='login-descrip'>
                      To keep connected with you please login with your personal information by
                      email & password
                    </p>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      noValidate
                      // id='kt_login_signin_form'
                    >
                      <div className='login-form'>
                        <div className='form-floating '>
                          <img src={MailIconImg} alt='' />
                          <input
                            type='email'
                            id='floatingInput'
                            placeholder='name@example.com'
                            {...register('email', {
                              required: true,
                              // pattern: /^\S+@\S+$/i,
                              // maxLength: 15,
                            })}
                            onKeyPress={(e) => {
                              if (new RegExp(/[0-9a-zA-Z. -@]/i).test(e.key)) {
                              } else e.preventDefault()
                            }}
                            className={`form-control ps-12 ${errors.email ? 'is-invalid' : ''}`}
                          />

                          <label htmlFor='floatingInput'>Email address *</label>
                        </div>
                        <div className='invalid-feedback'>{errors.email?.message}</div>
                        <div className='invalid-feedback d-flex fs-6'>
                          {errors.email?.message ? errors.email?.message + ',' : ''}{' '}
                        </div>
                      </div>
                      <div className='row justify-content-between mt-5 mb-4'>
                        <div className='col-auto d-none'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='Remember-Me'
                            />
                            <label className='form-check-label' htmlFor='Remember-Me'>
                              Remember Me
                            </label>
                          </div>
                        </div>
                        <div className='col '>
                          <Link to='/login' className='forgot-pwd' style={{marginLeft: '5px'}}>
                            Back to Login ?
                          </Link>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          <button type='submit' className='btn btn-primary custom-btn'>
                            Continue
                          </button>
                        </div>
                      </div>
                    </form>

                    <div className='row app-download'>
                      <div className='col'>
                        <p>Or you can use on</p>
                        <ul>
                          <li>
                            <a
                              href='https://play.google.com/store/apps/details?id=com.msrcosmos.timesheets&pli=1'
                              target='_blank'
                            >
                              <img src={GooglePlayStoreImg} alt='' />
                            </a>
                          </li>
                          <li>
                            <a
                              href='https://apps.apple.com/us/app/msr-timesheets/id6451482238'
                              target='_blank'
                            >
                              <img src={AppStoreImg} alt='' />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-center flex-wrap poweredby '>
              <p>Powered by</p>
              <img src={MSRcosmosGrouplogoImg} alt='MSRcosmosGroup' />
            </div>
          </div>
        </div>
        <div className='animate'>
          <div className='animate_circles'>
            <span className='circle1'></span>
            <span className='circle2'></span>
            <span className='circle3'></span>
            <span className='circle4'></span>
            <span className='circle5'></span>
            <span className='circle6'></span>
            <span className='circle7'></span>
            <span className='circle8'></span>
            <span className='circle9'></span>
            <span className='circle10'></span>
            <span className='circle11'></span>
            <span className='circle12'></span>
            <span className='circle13'></span>
            <span className='circle14'></span>
            <span className='circle15'></span>
            <span className='circle16'></span>
            <span className='circle17'></span>
            <span className='circle18'></span>
            <span className='circle19'></span>
            <span className='circle20'></span>
          </div>
        </div>
        <div className='clock'>
          <div className='top'></div>
          <div className='right'></div>
          <div className='bottom'></div>
          <div className='left'></div>
          <div className='center'></div>
          <div className='shadow'></div>
          <div className='hour'></div>
          <div className='minute'></div>
          <div className='second'></div>
        </div>
        <div className='calendar'>
          <div className='body'>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className='bg-items'>
          <span className='circle circle1'> </span>
          <span className='circle circle2'> </span>
          <span className='circle circle3'> </span>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
export default ForgotPassword
