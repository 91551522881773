import React, {useState, useMemo, useEffect, useContext} from 'react'
import {default as Apiservices} from '../../../../../src/common/index'
import Modal from 'react-bootstrap/Modal'
import {useForm} from 'react-hook-form'
import ThingsContext from '../../../modules/apps/masters/commonicons/GlobalContext'
import {ToastContainer, toast} from 'react-toastify'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import {ManageListClients} from '../../../modules/apps/user-management/users-list/table/columns/_columns'
import {useTable, ColumnInstance} from 'react-table'
import moment from 'moment'

export default function OrgNonComplianceReportsPopup(props: any) {
  const {
    register,
    setValue,
    reset,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm()
  const [show, setShow] = useState(true)
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [users, setUsers] = useState([])
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => ManageListClients, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const handleClose = () => {
    setShow(false)
    props.handleClose()
  }

  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }
  useEffect(() => {
    renderThings()
  }, [])

  return (
    <>
      <BackDrop showBackDrop={showBackDrop} />
      <Modal
        show={show}
        onHide={() => handleClose()}
        // size='lg'
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        <Modal.Header closeButton className=' py-4'>
        {(props?.reportData&&props?.reportData?.length>0)&&(
          <>
          <h3 className='text-capitalize'>
            {props?.reportData[0]?.month_name+" "+props?.reportData[0]?.year+" - Organization Non Compliance Report"}
          </h3>
          <h4 className='text-capitalize'>
            {props?.reportData[0]?.project_name+"_"+props?.reportData[0]?.placement_code}
          </h4>
          </>)
        }    
        </Modal.Header>  
        <Modal.Body className=''>
          <div className='pt-3'>
            <div className='card card-custom card-flush '>
              <div className='card-body p-3 py-3'>
                <div className='table-responsive reports-table'>
                  <table
                    id='kt_table_users'
                    className='table table-row-bordered table-row-gray-300 gy-4'
                  >
                    <>
                      <thead>
                        <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                          {/* <th className='  py-4'>Business Unit </th>
                          <th className='  py-4'>Consultant ID </th>
                          <th className='  py-4'>Consultant Name </th>
                          <th className='  py-4'>Placement Code </th>
                          <th className='  py-4'>Project Name </th>
                          <th className='  py-4'>Project Type</th>
                          <th className='  py-4'>Project Sttaus</th>
                          <th className='  py-4'>Start Date</th>
                          <th className='  py-4'>End Date</th>
                          <th className='  py-4'>Placement Type</th> */}
                          <th className='  py-4'>Timesheet Missed Date</th>
                          <th className='  py-4'>HRS Submitted</th>
                          <th className='  py-4'>Actual Hrs to be Submitted</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.reportData?.length > 0 ? (
                          <>
                            {props.reportData?.map((each: any, index: any) => {
                              return (
                                <tr
                                  className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                  key={index}
                                >
                                  {/* <td className=''>
                                                                          {each.user_business_unit_name
                                                                            ? each.user_business_unit_name
                                                                            : '-'}
                                                                        </td>
                                                                        <td className=''>
                                                                          {each.employee_id ? each.employee_id : '-'}
                                                                        </td>
                                                                        <td className='text-capitalize'>
                                                                          {each.display_name
                                                                            ? each.display_name
                                                                            : each.full_name
                                                                            ? each.full_name
                                                                            : '-'}
                                                                        </td>
                                                                        <td className=''>
                                                                          {each.placement_code ? each.placement_code : '-'}
                                                                        </td>
                                                                        <td className='text-capitalize'>
                                                                          {each.project_name ? each.project_name : '-'}
                                                                        </td>
                                                                        <td className='text-capitalize'>
                                                                          {each.is_placement_project
                                                                            ? each.is_placement_project == 1
                                                                              ? 'Placement'
                                                                              : each.is_placement_project == 2
                                                                              ? 'Bench'
                                                                              : each.is_placement_project == 3
                                                                              ? 'Internal'
                                                                              : '-'
                                                                            : '-'}
                                                                        </td>
                                                                        <td className=''>
                                                                          {each.project_status_name ? each.project_status_name : '-'}
                                                                        </td>
                                                                        <td className='text-nowrap'>
                                                                          {each.start_date
                                                                            ? moment(each.start_date).format('DD-MMM-YYYY')
                                                                            : '-'}
                                                                        </td>
                                                                        <td className='text-nowrap'>
                                                                          {each.project_status_name
                                                                            ? each.project_status_name?.toLowerCase() == 'cancelled'
                                                                              ? each.cancel_date
                                                                                ? moment(each.cancel_date).format('DD-MMM-YYYY')
                                                                                : '-'
                                                                              : each.project_status_name?.toLowerCase() == 'completed'
                                                                              ? each.end_date
                                                                                ? moment(each.end_date).format('DD-MMM-YYYY')
                                                                                : '-'
                                                                              : '-'
                                                                            : '-'}
                                                                        </td>
                                                                        <td className=''>
                                                                          {each.employee_type ? each.employee_type : '-'}
                                                                        </td>*/}
                                                                        <td className='text-nowrap'>
                                                                          {each.timesheet_missed_date
                                                                            ? moment(each.timesheet_missed_date).format('DD-MMM-YYYY')
                                                                            : '-'}
                                                                        </td>
                                                                        <td className=''>
                                                                          {each.hours_submitted
                                                                            ? each.hours_submitted.toFixed(2)
                                                                            : '0.00'}
                                                                        </td>
                                                                        <td className=''>
                                                                          <span>
                                                                            {each.expected_hours
                                                                              ? each.expected_hours.toFixed(2)
                                                                              : '0.00'}
                                                                          </span>
                                                                        </td>
                                </tr>
                              )
                            })}
                          </>
                        ) : (
                          ''
                        )}
                      </tbody>
                    </>
                  </table>
                  {(props.reportData?.length == null || props.reportData?.length == 0) && (
                    <div className='text-center  fw-bold text-gray-800'>No Records found </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <ToastContainer />
      </Modal>
    </>
  )
}
