import {useEffect, useState, useRef, useContext} from 'react'
import ThingsContext from '../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../modules/apps/masters/commonicons/ValidateContext'

import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import {useForm} from 'react-hook-form'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {default as Apiservices} from '../../../../src/common/index'
import clsx from 'clsx'
import {PageTitle} from '../../../_metronic/layout/core'
import Cookies from 'universal-cookie'
import BackDrop from '../../modules/apps/timesheetentry/ComponentBackdrop'

const App: React.FC = (props: any) => {
  const methods = useForm()
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const [organizations, setOrganizations] = useState([])
  const cookies = new Cookies()
  const [showBackDrop, setShowBackDrop] = useState(false)

  // useEffect(() => {
  //   if (window.localStorage.getItem('token')) {
  //     setAccepted(true)
  //   }
  // }, [window.localStorage.getItem('token')])

  useEffect(() => {
    let users = JSON.parse(localStorage.getItem('org_list') || '[]')
    users.map((each: any, index: any) => {
      return <div key={index}>{each.organizationName}</div>
    })
    setOrganizations(users)
  }, [])
  // const logout = async () => {
  //   const data: any = await Apiservices.getUserLogout()
  //   localStorage.clear()
  //   return (
  //     toast.error('Session expired'),
  //     {
  //       position: toast.POSITION.BOTTOM_LEFT,
  //     },
  //     setTimeout(function () {
  //       navigate('/login')
  //     }, 1000)
  //   )
  // }
  // const getCookie = async () => {
  //   if (cookies.get('JWT-TOkEN') || cookies.get('JWT_TOKEN')) {
  //     const data1: any = await Apiservices.getSSO()
  //     if (data1 && data1.data && data1.data.statusCode == 200) {
  //       // cookies.set('JWT-TOkEN', data1.data.accessToken)
  //       // cookies.set('JWT_TOKEN', data1.data.accessToken)

  //       cookies.set('JWT-TOkEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})

  //       cookies.set('JWT_TOKEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
  //     }
  //   } else {
  //     logout()
  //   }
  // }
  // useEffect(() => {
  //   getCookie()
  // }, [])
  // const getValidate = async () => {
  //   const res: any = await Apiservices.getValidate()

  //   if (!(res.data.statusCode == 200)) {
  //     logout()
  //   } else {
  //   }
  // }
  // useEffect(() => {
  //   getValidate()
  // }, [])

  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }
  useEffect(() => {
    // renderThings()  for testingg
  }, [])

  const tokenHeader1: any = useContext(ValidContext)
  const renderThings1 = async () => {
    tokenHeader1.getValidate()
  }

  useEffect(() => {
    console.log(window.location.hostname, 'bdgdbdygdy')

    // renderThings1()
    getCookieValueChanged()
  }, [])

  const getCookieValueChanged = async () => {
    if (
      (cookies.get('JWT-TOkEN') || cookies.get('JWT_TOKEN')) &&
      cookies.get('org_id') &&
      cookies.get('torg_id') != cookies.get('org_id')
    ) {
      toast.success('Organization has been changed successfully')
      let orgid = cookies.get('org_id')
      setShowBackDrop(true)

      const data1: any = await Apiservices.getSSO()
      if (data1 && data1.data && data1.data.statusCode == 200) {
        // cookies.set('JWT-TOkEN', data1.data.accessToken)
        // cookies.set('JWT_TOKEN', data1.data.accessToken)

        cookies.set('JWT-TOkEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
        cookies.set('JWT_TOKEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
        localStorage.setItem('user_email', data1.data.email.toLowerCase())
        // localStorage.setItem('token', data1.data.accessToken)
        localStorage.setItem('token', 'Bearer' + ' ' + data1.data.accessToken)
        let organizationName1 = data1.data.organizations
        let orglist: any = []

        let toolNameMoreThanOne = organizationName1.filter((each: any) => each.toolRoles.length > 0)
        let timeSheetTrackerCount = 0
        let tosterCount = 0

        for (let each of organizationName1) {
          if (each.toolRoles && each.toolRoles.length > 0) {
            for (let tools of each.toolRoles) {
              if (tools.toolName == 'Timesheet Tracker') {
                timeSheetTrackerCount += 1
                break
              }
            }
          }
        }
        if (timeSheetTrackerCount >= 1 && toolNameMoreThanOne.length > 0) {
          for (let each of organizationName1) {
            for (let tools of each.toolRoles) {
              if (tools.toolName == 'Timesheet Tracker') {
                let OrgListArray: any = each
                orglist.push(OrgListArray)
                localStorage.setItem('org_list', JSON.stringify(orglist))

                if (organizationName1?.length > 1 && timeSheetTrackerCount > 1) {
                  localStorage.setItem('org_icon', 'true')
                  localStorage.setItem('org_id', orgid)
                  cookies.set('org_id', orgid, {path: '/', domain: '.msrcosmos.com'})
                  cookies.set('torg_id', orgid, {path: '/', domain: '.msrcosmos.com'})

                  tosterCount = tosterCount + 1
                  if (tosterCount == 1) {
                    getUserAuth(orgid)

                    // toast.success('Login Successfully')
                  }

                  // setTimeout(function () {
                  //   navigate('/OrganizationList')
                  // }, 1000)
                } else {
                  localStorage.setItem('role_Name', tools.roleName?.toLowerCase())
                  localStorage.setItem('org_prefix', each.orgPrefix)
                  localStorage.setItem('org_id', each.organizationId)
                  cookies.set('org_id', each.organizationId, {path: '/', domain: '.msrcosmos.com'})
                  cookies.set('torg_id', each.organizationId, {path: '/', domain: '.msrcosmos.com'})
                  localStorage.setItem('org_name', each.organizationName)
                  localStorage.setItem('tool_name', tools.toolName)
                  getUserAuth(each.organizationId)
                  break
                }
              }
            }
          }
        } else {
          toast.warn("User doesn't have timesheet tracker. Please contact your admin")
          navigate('/login')
          // setLoading(false)
        }
        // getUserAuth(orgid)
      }
      // setShowBackDrop(false)
      setTimeout(function () {
        setShowBackDrop(false)
      }, 2000)
    }
  }
  function removeAll() {
    let lRemove: any = [
      'token',
      'user_email',
      'org_list',
      'role_Name',
      'org_prefix',
      'org_id',
      'org_name',
      'tool_name',
      'ecdb_user_id',
      'userid',
      'Authorization',
      'NetSuiteToken',
      'enable_netsuite',
      'subsidary_name',
      'logged_in_id',
      'Announcement',
      'refresh_Token',
      'enable_quickbooks',
      'org_icon',
      'time_sheet_admin_screen_user_id',
      'MobileForceUpdateAccessbyEmail',
      'allowed_business_unit_id',
      'bu_list',
      'enable_unbilled',
      'invoice_access',
      'enable_email',
    ]
    for (let each of lRemove) {
      localStorage.removeItem(each)
    }
  }
  const getUser = async (org: any) => {
    let organizationName = org
    localStorage.setItem('org_prefix', org.orgPrefix)
    localStorage.setItem('org_id', org.organizationId)
    cookies.set('org_id', org.organizationId, {path: '/', domain: '.msrcosmos.com'})
    cookies.set('torg_id', org.organizationId, {path: '/', domain: '.msrcosmos.com'})
    localStorage.setItem('org_name', org.organizationName)
    for (let tools of organizationName.toolRoles) {
      if (tools.toolName == 'Timesheet Tracker') {
        // toast.success('Login Successfully')
        localStorage.setItem('role_Name', tools.roleName?.toLowerCase())
        localStorage.setItem('tool_name', tools.toolName)
        getUserAuth(org.organizationId)
        break
      }
    }
    // toast.error("User doesn't exist")
  }

  const getEmployeeInfo = async (orgId: any) => {
    setShowBackDrop(true)
    const response: any = await Apiservices.getEmployeeData(orgId)
    console.log('.......resp on emp', response?.userRequestDto)
    localStorage.setItem('skillsrequired', response?.userRequestDto?.skill)
  }

  const getUserAuth = async (orgId: any) => {
    setShowBackDrop(true)

    // setIsLoading(true)
    let orgEmail = localStorage.getItem('user_email')
    let authtoken: any = localStorage.getItem('token')?.split(' ')
    let data: any = {}
    let roles: any = []
    var buRoles
    data.email = orgEmail
    data.organizationId = orgId
    data.ecdbToken = authtoken[1]
    const response: any = await Apiservices.tokenData(data)
    if (response.isError == false) {
      if (response?.userBUresponse?.length > 0) {
        for (let each of response.userBUresponse) {
          roles.push(each.roleName?.toLowerCase())
        }
        roles.push(response.user_data.role_name?.toLowerCase())
        buRoles = roles
      } else {
        buRoles = response.user_data.role_name?.toLowerCase()
      }
      localStorage.setItem('ecdb_user_id', response.user_data.ecdb_user_id)
      localStorage.setItem('enable_unbilled', response?.user_data?.enable_unbilled)
      localStorage.setItem('invoice_access', response?.user_data?.invoice_access)
      localStorage.setItem('userid', response.user_data.id)
      localStorage.setItem('Authorization', response.data)
      localStorage.setItem('logged_in_id', orgId)
      // localStorage.setItem('role_Name', response.user_data.role_name?.toLowerCase())
      localStorage.setItem('role_Name', buRoles)
      localStorage.setItem('bu_list', JSON.stringify(response.userBUresponse))
      localStorage.setItem('org_name', response.user_data.org_name)
      localStorage.setItem('allowed_business_unit_id', response.allowed_business_unit_id)
      let business: any = localStorage.getItem('role_Name')
      let numArray: any = business?.split(',')
      let get_org_id = localStorage.getItem('org_id')
      const res: any = await Apiservices.getOrganizationSetting({org_id: get_org_id})
      localStorage.setItem('enable_netsuite', res && res?.data?.data[0]?.enable_netsuite)
      localStorage.setItem('subsidary_name', res && res?.data?.data[0]?.subsidary_name)
      localStorage.setItem('enable_email', res && res?.data?.data[0]?.enable_email)
      if (res && res?.data?.data[0]?.enable_netsuite) {
        // if (numArray?.includes('admin')) {
        if (numArray?.includes('admin') || numArray?.includes('accounts')) {
          const data: any = await Apiservices.getAdminNetSuiteConfig({
            // subsidary_name: organization_name,
            subsidary_name: res?.data?.data[0]?.subsidary_name,
            enable_netsuite: res?.data?.data[0]?.enable_netsuite,
          })
          if (data.data.isError == false) {
            localStorage.setItem('NetSuiteToken', 'Bearer' + ' ' + data?.data?.data?.access_token)
          }
        }
      }
      // if (localStorage.getItem('role_Name') === 'employee') {
      if (numArray?.includes('employee')) {
        getEmployeeInfo(orgId)
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          navigate('/timesheetlist')
        } else {
          localStorage.setItem('refresh_Token', 'true')
          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              navigate('/timesheetlist')
            }, 1000)
          )
          // setShow(true)
          // props.setList(true)
        }
      }
      // else if (localStorage.getItem('role_Name') === 'timesheetadmin') {
      //   const token: any = await Apiservices.refreshToken()
      //   if (token && token.data && token.data.isError == false) {
      //     localStorage.setItem('refresh_Token', token.data.isError)
      //     navigate('/masters/manageconsultants')
      //   } else {
      //     localStorage.setItem('refresh_Token', 'true')
      //     navigate('/masters/manageconsultants')

      //     // setShow(false)
      //     // props.setList(false)
      //   }
      // }
      else if (
        // localStorage.getItem('role_Name') === 'superadmin' ||
        // localStorage.getItem('role_Name') === 'admin' ||
        // localStorage.getItem('role_Name') === 'timesheetadmin'
        numArray?.includes('superadmin') ||
        numArray?.includes('admin') ||
        numArray?.includes('timesheetadmin')
      ) {
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          return setTimeout(function () {
            navigate('/masters/manageconsultants')
          }, 1000)
        } else {
          localStorage.setItem('refresh_Token', 'true')
          navigate('/masters/manageconsultants')
          // return (
          //   toast.warn('QuickBooks not enabled. Please contact your admin'), //not required now
          //   {
          //     position: toast.POSITION.BOTTOM_LEFT,
          //   },
          //   setTimeout(function () {
          //     navigate('/masters/manageconsultants')
          //   }, 100)
          // )
          // setShow(false)
          // props.setList(false)
        }
      } else if (numArray?.includes('relationshipmanager')) {
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          return setTimeout(function () {
            navigate('/MyConsultant')
          }, 1000)
        } else {
          localStorage.setItem('refresh_Token', 'true')
          navigate('/MyConsultant')
        }
      }
      // else if (localStorage.getItem('role_Name') === 'accounts') {
      //   const token: any = await Apiservices.refreshToken()
      //   if (token && token.data && token.data.isError == false) {
      //     localStorage.setItem('refresh_Token', token.data.isError)
      //     return setTimeout(function () {
      //       navigate('/approver')
      //     }, 1000)
      //   } else {
      //     localStorage.setItem('refresh_Token', 'true')
      //     return (
      //       toast.warn('QuickBooks not enabled. Please contact your admin'),
      //       {
      //         position: toast.POSITION.BOTTOM_LEFT,
      //       },
      //       setTimeout(function () {
      //         navigate('/approver')
      //       }, 100)
      //     )
      //     // setShow(false)
      //     // props.setList(false)
      //   }
      // }
      else {
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          return setTimeout(function () {
            navigate('/approver')
          }, 1000)
        } else {
          localStorage.setItem('refresh_Token', 'true')
          navigate('/approver')

          // setShow(false)
          // props.setList(false)
        }
      }
    } else {
      // localStorage.clear()
      toast.error('Something went wrong')

      removeAll()
      cookies.remove('JWT-TOkEN')
      cookies.remove('JWT_TOKEN')
      cookies.remove('org_id')
      cookies.remove('torg_id')
      cookies.remove('profileView')

      cookies.remove('JWT-TOkEN', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('JWT_TOKEN', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('org_id', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('torg_id', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('profileView', {path: '/', domain: '.msrcosmos.com'})

      return (
        toast.error('Unauthorize access'),
        {
          position: toast.POSITION.BOTTOM_LEFT,
        },
        setTimeout(function () {
          navigate('/login')
        }, 1000)
      )
    }

    setTimeout(function () {
      setShowBackDrop(false)
    }, 2000)
    // setIsLoading(false)
  }

  // const logout = async () => {
  //   const data: any = await Apiservices.getUserLogout()
  //   localStorage.clear()
  //   return (
  //     toast.error('Session expired'),
  //     {
  //       position: toast.POSITION.BOTTOM_LEFT,
  //     },
  //     setTimeout(function () {
  //       navigate('/login')
  //     }, 1000)
  //   )
  // }
  return (
    <div className='container'>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CHOOSEORGANIZATION'})}</PageTitle>
      <BackDrop showBackDrop={showBackDrop} />

      <form>
        <div className=''>
          <div className='d-flex flex-center flex-wrap gap-10 '>
            {organizations.map((each: any, index: number) => {
              return (
                // <div className='col-md-4 col-xs-12' >
                //     <a href="#" className="btn btn-primary" >{each.organizationName} </a>
                // </div>
                <div className='' onClick={() => getUser(each)} key={index}>
                  <a
                    className={clsx(
                      'chooseOrganizationCard',
                      'chooseOrganizationCard' + `${index + 1}`
                    )}
                    href='#'
                  >
                    {each.organizationName}
                  </a>
                </div>
              )
            })}
          </div>
        </div>
        <ToastContainer />
      </form>
    </div>
  )
}

export default App
