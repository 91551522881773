import React, {useState, useEffect} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import AnnouncementPop from '../modal/AnnouncementPop'
import Cookies from 'universal-cookie'

export default function ModalPop(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [message, setMessage] = useState(false)
  const [showBackDrop, setShowBackDrop] = useState(true)
  const [isLoading, setIsLoading] = useState<any | []>(false)
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)
  const cookies = new Cookies()
  // const logout = async () => {
  //   const data: any = await Apiservices.getUserLogout()
  //   localStorage.clear()
  //   return (
  //     toast.error('Session expired'),
  //     {
  //       position: toast.POSITION.BOTTOM_LEFT,
  //     },
  //     setTimeout(function () {
  //       navigate('/login')
  //     }, 1000)
  //   )
  // }

  const getCookie = async () => {
    if (cookies.get('JWT-TOkEN') || cookies.get('JWT_TOKEN')) {
      const data1: any = await Apiservices.getSSO()
      if (data1 && data1.data && data1.data.statusCode == 200) {
        // cookies.set('JWT-TOkEN', data1.data.accessToken)
        // cookies.set('JWT_TOKEN', data1.data.accessToken)

        cookies.set('JWT-TOkEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
        cookies.set('JWT_TOKEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
      }
    }
  }
  useEffect(() => {
    console.log(window.location.hostname, 'bdgdbdygdy')

    getCookie()
  }, [])

  const handleOverride = async () => {
    // setIsLoading(true)
    const data: any = await Apiservices.getUserToken({
      email: props.emailForProp,
      password: props.passwordForProp,
      firstLogin: true,
      toolName: 'Timesheet Tracker',
    })
    // setIsLoading(false)
    if (data.statusCode == 200) {
      let organizationName = data.organizations
      let spaced = ''
      localStorage.setItem('token', 'Bearer' + ' ' + data.accessTokenResponse.accessToken)

      // cookies.set('JWT-TOkEN', data.accessTokenResponse.accessToken)
      // cookies.set('JWT_TOKEN', data.accessTokenResponse.accessToken)

      cookies.set('JWT-TOkEN', data.accessTokenResponse.accessToken, {
        path: '/',
        domain: '.msrcosmos.com',
      })
      cookies.set('JWT_TOKEN', data.accessTokenResponse.accessToken, {
        path: '/',
        domain: '.msrcosmos.com',
      })

      localStorage.setItem('user_email', data.email?.toLowerCase())
      // localStorage.setItem('org_list', JSON.stringify(data.organizations))
      let orglist: any = []

      let toolNameMoreThanOne = organizationName.filter((each: any) => each.toolRoles.length > 0)
      let timeSheetTrackerCount = 0
      let tosterCount = 0

      for (let each of organizationName) {
        if (each.toolRoles && each.toolRoles.length > 0) {
          for (let tools of each.toolRoles) {
            if (tools.toolName == 'Timesheet Tracker') {
              // toast.error('User does not exist')
              timeSheetTrackerCount += 1
              break
            }
          }
        }
      }

      if (timeSheetTrackerCount >= 1 && toolNameMoreThanOne.length > 0) {
        for (let each of organizationName) {
          for (let tools of each.toolRoles) {
            if (tools.toolName == 'Timesheet Tracker') {
              let OrgListArray: any = each
              orglist.push(OrgListArray)

              localStorage.setItem('org_list', JSON.stringify(orglist))

              if (organizationName?.length > 1 && timeSheetTrackerCount > 1) {
                localStorage.setItem('org_icon', 'true')
                // navigate('/OrganizationList')

                tosterCount = tosterCount + 1
                if (tosterCount == 1) {
                  toast.success('Login Successful')
                }

                setTimeout(function () {
                  navigate('/OrganizationList')
                }, 1000)
              } else {
                // toast.success('Login Successful')
                localStorage.setItem('role_Name', tools.roleName?.toLowerCase())
                localStorage.setItem('org_prefix', each.orgPrefix)
                localStorage.setItem('org_id', each.organizationId)
                cookies.set('org_id', each.organizationId, {path: '/', domain: '.msrcosmos.com'})
                cookies.set('torg_id', each.organizationId, {path: '/', domain: '.msrcosmos.com'})
                localStorage.setItem('org_name', each.organizationName)
                localStorage.setItem('tool_name', tools.toolName)
                getUserAuth(each.organizationId)

                break
              }
            }
          }
        }
      } else {
        toast.warn("User doesn't have timesheet tracker. Please contact your admin")
        navigate('/login')
      }
    } else if (data.statusCode == 500) {
      navigate('/login')
      toast.error("User doesn't have timesheet tracker")

      setMessage(true)
      setShow(false)
      props.close(false)
      props.setList(false)
      props.setTest(false)
      props.setSession(false)
      props.setShow(false)
    }
  }
  function removeAll() {
    let lRemove: any = [
      'token',
      'user_email',
      'org_list',
      'role_Name',
      'org_prefix',
      'org_id',
      'org_name',
      'tool_name',
      'ecdb_user_id',
      'userid',
      'Authorization',
      'NetSuiteToken',
      'enable_netsuite',
      'subsidary_name',
      'logged_in_id',
      'Announcement',
      'refresh_Token',
      'enable_quickbooks',
      'org_icon',
      'time_sheet_admin_screen_user_id',
      'MobileForceUpdateAccessbyEmail',
      'allowed_business_unit_id',
      'bu_list',
      'enable_unbilled',
      'invoice_access',
      'enable_email',
    ]
    for (let each of lRemove) {
      localStorage.removeItem(each)
    }
  }
  const handleClose = () => {
    setShow(false)
    props.close(false)
    props.setList(false)
    props.setTest(false)
    props.setSession(false)
    props.setShow(false)
    props.setHistoryOpenPerWeek(false)
    props.setHistoryOpenPerDay(false)
    props.setApproverHistoryOpenPerWeek(false)
    props.setApproverHistoryOpenPerDay(false)
    props.setViewOpen(false)
    props.setViewBlob(false)
    props.setSubmitOpen(false)
    props.setSubmitShow(false)
  }
  const AnnouncePop = () => {
    setAnnouncementOpenPoup(false)
  }
  const getUserAuth = async (orgId: any) => {
    let orgEmail = localStorage.getItem('user_email')
    let authtoken: any = localStorage.getItem('token')?.split(' ')
    let data: any = {}
    let roles: any = []
    var buRoles
    data.email = orgEmail
    data.organizationId = orgId
    data.ecdbToken = authtoken[1]
    const response: any = await Apiservices.tokenData(data)
    if (response.isError == false) {
      if (response?.userBUresponse?.length > 0) {
        for (let each of response.userBUresponse) {
          roles.push(each.roleName?.toLowerCase())
        }
        roles.push(response.user_data.role_name?.toLowerCase())
        buRoles = roles
      } else {
        buRoles = response.user_data.role_name?.toLowerCase()
      }
      localStorage.setItem('ecdb_user_id', response.user_data.ecdb_user_id)
      localStorage.setItem('enable_unbilled', response?.user_data?.enable_unbilled)
      localStorage.setItem('invoice_access', response?.user_data?.invoice_access)
      localStorage.setItem('userid', response.user_data.id)
      localStorage.setItem('Authorization', response.data)
      localStorage.setItem('logged_in_id', orgId)
      // localStorage.setItem('role_Name', response.user_data.role_name.toLowerCase())
      localStorage.setItem('role_Name', buRoles)
      localStorage.setItem('bu_list', JSON.stringify(response.userBUresponse))
      localStorage.setItem('allowed_business_unit_id', response.allowed_business_unit_id)
      let business: any = localStorage.getItem('role_Name')
      let numArray: any = business?.split(',')
      // getCookieAll()

      let get_org_id = localStorage.getItem('org_id')
      const res: any = await Apiservices.getOrganizationSetting({org_id: get_org_id})

      localStorage.setItem('enable_netsuite', res && res?.data?.data[0]?.enable_netsuite)
      localStorage.setItem('subsidary_name', res && res?.data?.data[0]?.subsidary_name)
      localStorage.setItem('enable_email', res && res?.data?.data[0]?.enable_email)
      if (res && res?.data?.data[0]?.enable_netsuite) {
        // if (numArray?.includes('admin')) {
        if (numArray?.includes('admin') || numArray?.includes('accounts')) {
          const data: any = await Apiservices.getAdminNetSuiteConfig({
            // subsidary_name: organization_name,
            subsidary_name: res?.data?.data[0]?.subsidary_name,
            enable_netsuite: res?.data?.data[0]?.enable_netsuite,
          })
          if (data.data.isError == false) {
            localStorage.setItem('NetSuiteToken', 'Bearer' + ' ' + data?.data?.data?.access_token)
          }
        }
      }
      // if (localStorage.getItem('role_Name') === 'employee') {
      if (numArray?.includes('employee')) {
        localStorage.setItem('Announcement', 'false')

        // navigate('/timesheetlist')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          // navigate('/timesheetlist')
          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              navigate('/timesheetlist')
            }, 1000)
          )
        } else {
          localStorage.setItem('refresh_Token', 'true')

          // toast.warn('QuickBooks not enabled. Please contact your admin')
          // navigate('/timesheetlist')
          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              navigate('/timesheetlist')
            }, 1000)
          )
          // setShow(true)
          // props.setList(true)
        }
      }
      // else if (localStorage.getItem('role_Name') === 'timesheetadmin') {
      //   const token: any = await Apiservices.refreshToken()
      //   if (token && token.data && token.data.isError == false) {
      //     localStorage.setItem('refresh_Token', token.data.isError)

      //     return (
      //       toast.success('Login Successfully'),
      //       {
      //         position: toast.POSITION.BOTTOM_LEFT,
      //       },
      //       setTimeout(function () {
      //         navigate('/masters/manageconsultants')
      //       }, 1000)
      //     )
      //   } else {
      //     localStorage.setItem('refresh_Token', 'true')

      //     return (
      //       toast.success('Login Successfully'),
      //       {
      //         position: toast.POSITION.BOTTOM_LEFT,
      //       },
      //       setTimeout(function () {
      //         navigate('/masters/manageconsultants')
      //       }, 1000)
      //     )
      //     // setShow(false)
      //     // props.setList(false)
      //   }
      // }
      else if (
        // localStorage.getItem('role_Name') === 'superadmin' ||
        // localStorage.getItem('role_Name') === 'admin' ||
        // localStorage.getItem('role_Name') === 'timesheetadmin'
        numArray?.includes('superadmin') ||
        numArray?.includes('admin') ||
        numArray?.includes('timesheetadmin')
      ) {
        localStorage.setItem('Announcement', 'false')
        // navigate('/masters/manageconsultants')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              navigate('/masters/manageconsultants')
            }, 1000)
          )
        } else {
          localStorage.setItem('refresh_Token', 'true')
          return (
            // toast.warn('QuickBooks not enabled. Please contact your admin'),  //not required now
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              navigate('/masters/manageconsultants')
            }, 1000)
          )
          // setShow(false)
          // props.setList(false)
        }
      } else if (numArray?.includes('relationshipmanager')) {
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              navigate('/MyConsultant')
            }, 1000)
          )
        } else {
          localStorage.setItem('refresh_Token', 'true')
          return (
            // toast.warn('QuickBooks not enabled. Please contact your admin'), //not required now
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              navigate('/MyConsultant')
            }, 1000)
          )
        }
      } else {
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              navigate('/approver')
            }, 1000)
          )
        } else {
          localStorage.setItem('refresh_Token', 'true')
          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              navigate('/approver')
            }, 1000)
          )
          // setShow(false)
          // props.setList(false)
        }
      }
    } else {
      // localStorage.clear()
      removeAll()
      cookies.remove('JWT-TOkEN')
      cookies.remove('JWT_TOKEN')
      cookies.remove('org_id')
      cookies.remove('torg_id')
      cookies.remove('profileView')

      cookies.remove('JWT-TOkEN', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('JWT_TOKEN', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('org_id', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('torg_id', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('profileView', {path: '/', domain: '.msrcosmos.com'})

      navigate('/login')
      props.setList(false)
      toast.error('Unauthorize access')
    }
  }
  const handleContinue = async () => {
    // setIsLoading(true)
    setShowBackDrop(true)

    const response: any = await Apiservices.getUserToken1({userEmail: props.emailForProp})
    // setIsLoading(false)

    if (response.data.statusCode == 200) {
      let organizationName = response.data.organizations
      let spaced = ''
      localStorage.setItem('token', 'Bearer' + ' ' + response.data.accessToken)

      // cookies.set('JWT-TOkEN', response.data.accessToken)
      // cookies.set('JWT_TOKEN', response.data.accessToken)

      cookies.set('JWT-TOkEN', response.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
      cookies.set('JWT_TOKEN', response.data.accessToken, {path: '/', domain: '.msrcosmos.com'})

      localStorage.setItem('user_email', response.data.email?.toLowerCase())
      // localStorage.setItem('org_list', JSON.stringify(data.organizations))
      let orglist: any = []

      let toolNameMoreThanOne = organizationName.filter((each: any) => each.toolRoles.length > 0)
      let timeSheetTrackerCount = 0
      let tosterCount = 0

      for (let each of organizationName) {
        if (each.toolRoles && each.toolRoles.length > 0) {
          for (let tools of each.toolRoles) {
            if (tools.toolName == 'Timesheet Tracker') {
              // toast.error('User does not exist')
              timeSheetTrackerCount += 1
              break
            }
          }
        }
      }

      if (timeSheetTrackerCount >= 1 && toolNameMoreThanOne.length > 0) {
        for (let each of organizationName) {
          for (let tools of each.toolRoles) {
            if (tools.toolName == 'Timesheet Tracker') {
              let OrgListArray: any = each
              orglist.push(OrgListArray)

              localStorage.setItem('org_list', JSON.stringify(orglist))

              if (organizationName?.length > 1 && timeSheetTrackerCount > 1) {
                localStorage.setItem('org_icon', 'true')

                tosterCount = tosterCount + 1
                if (tosterCount == 1) {
                  toast.success('Login Successful')
                }

                setTimeout(function () {
                  navigate('/OrganizationList')
                }, 1000)

                // navigate('/OrganizationList')
              } else {
                localStorage.setItem('role_Name', tools.roleName?.toLowerCase())
                localStorage.setItem('org_prefix', each.orgPrefix)
                localStorage.setItem('org_id', each.organizationId)
                cookies.set('org_id', each.organizationId, {path: '/', domain: '.msrcosmos.com'})
                cookies.set('torg_id', each.organizationId, {path: '/', domain: '.msrcosmos.com'})
                localStorage.setItem('org_name', each.organizationName)
                localStorage.setItem('tool_name', tools.toolName)
                // localStorage.setItem('Final Data', JSON.stringify(tools))
                // localStorage.setItem('Final Data1', JSON.stringify(each))
                getUserAuth(each.organizationId)

                break
              }
            }
          }
        }
      } else {
        toast.warn("User doesn't have timesheet tracker.Please contact your admin")
        navigate('/login')
      }
    }
    setTimeout(function () {
      setShowBackDrop(false)
    }, 2000)
  }
  // const getCookieAll = async () => {
  //   if (cookies.get('JWT-TOkEN') || cookies.get('JWT_TOKEN')) {
  //     const data1: any = await Apiservices.getSSO()
  //     if (data1 && data1.data && data1.data.statusCode == 200) {
  //       cookies.set('JWT-TOkEN', data1.data.accessToken)
  //       cookies.set('JWT_TOKEN', data1.data.accessToken)

  //       // cookies.set('JWT-TOkEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
  //       // cookies.set('JWT_TOKEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
  //     }
  //   }
  // }
  return (
    <>
      {announcement_open_popup ? (
        <AnnouncementPop
          close={AnnouncePop}
          closeModal={announcement_open_popup}
          setAnnouncementOpenPoup={setAnnouncementOpenPoup}
        />
      ) : (
        ''
      )}
      <BackDrop showBackDrop={showBackDrop} />

      {/* {isLoading ? (
        <BackDrop showBackDrop={true} />
      ) : ( */}
      <Modal
        size='sm'
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        {/* <Modal.Header closeButton> */}
        {props.view_open && (
          <div className='col-auto'>
            {/* {props.view_open && ( */}
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={props.cancel}
            ></button>
            {/* )} */}
          </div>
        )}
        {/* </Modal.Header> */}
        <Modal.Body>
          {props.session && !message && (
            <>
              <div className='text-center fw-semibold fs-5 text-gray-800'>
                You are already logged in elsewhere !
              </div>
              <p className='text-center text-gray-600  fs-6 text-transform-unset'>
                Please select Override option to log out from all sessions and launch a new one in
                this browser.
              </p>
            </>
          )}

          {props.submit_open && (
            <p className='text-center text-gray-800 text-transform-unset fs-6'>
              Are you sure to submit ?
            </p>
          )}
          {props.submit_all_open && (
            <p className='text-center text-gray-800 text-transform-unset fs-6'>
              Are you sure to submit ?
            </p>
          )}
          {props.quickbook_open_pop && (
            <p className='text-center text-gray-800 text-transform-unset fs-6'>
              Are you sure to submit ?
            </p>
          )}
          {props.test && <p>{props.invalidMessage}</p>}

          <div className='row justify-content-center  py-2'>
            {props.session && (
              <>
                {!message && (
                  <>
                    <div className='col-auto px-1'>
                      <button className='btn btn-sm btn-primary ' onClick={handleOverride}>
                        Override
                      </button>
                    </div>
                    <div className='col-auto px-1'>
                      <button className='btn btn-sm btn-success' onClick={handleContinue}>
                        Continue
                      </button>
                    </div>
                    <div className='col-auto px-1'>
                      <button className='btn btn-sm btn-light' onClick={handleClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                )}
                {/* {message && (
                  <div className='col-auto'>
                    <button className='btn btn-sm btn-light' onClick={handleClose}>
                      Ok
                    </button>
                  </div>
                )} */}
              </>
            )}
            {props.test && (
              <div className='col-auto'>
                <button className='btn btn-sm btn-light' onClick={handleClose}>
                  Ok
                </button>
              </div>
            )}

            {props.view_open && (
              <>
                <div className='col-auto'>
                  <img src={props.view_blob} width='100%' height='auto' />
                </div>
              </>
            )}

            {props.submit_open && (
              <>
                <div className='col-auto'>
                  <button
                    className='btn btn-sm btn-light'
                    onClick={(e) => props.handleSubmitted(e, props.index)}
                  >
                    Ok
                  </button>
                </div>
                <div className='col-auto'>
                  <button className='btn btn-sm btn-light' onClick={props.modalPop}>
                    Cancel
                  </button>
                </div>
              </>
            )}
            {props.submit_all_open && (
              <>
                <div className='col-auto'>
                  <button
                    className='btn btn-sm btn-light'
                    onClick={(e) => props.handleSubmittedAll(e, props.index)}
                  >
                    Ok
                  </button>
                </div>
                <div className='col-auto'>
                  <button className='btn btn-sm btn-light' onClick={props.modalAllPop}>
                    Cancel
                  </button>
                </div>
              </>
            )}

            {props.quickbook_open_pop && (
              <>
                <div className='col-auto'>
                  <button
                    className='btn btn-sm btn-light'
                    onClick={(e) => props.openPopup(e, props.index)}
                  >
                    Ok
                  </button>
                </div>
                <div className='col-auto'>
                  <button className='btn btn-sm btn-light' onClick={props.cancelPopup}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
      <ToastContainer />
    </>
  )
}
