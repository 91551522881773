import React, {useState, useRef, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import clsx from 'clsx'
import {default as Apiservices} from '../../../../common/index'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Container, Row, Col} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import EmployeePop from '../../../modules/apps/masters/modal/EmployeePop'
import {Tooltip} from '../../../modules/apps/timesheetentry/ComponentTooltip'
import Chart from 'react-apexcharts'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default function ProjectCountReport(props: any) {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)
  const [test, setTest] = useState(null)
  const [validatedescription, setValidateDescription] = useState<any>(false)

  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    props.view_open(false)
    props.setViewOpen(false)
  }
  const validationSchema = Yup.object().shape({
    start_date: Yup.string().required('Date Of Joining is required').nullable(),
  })

  type UserSubmitForm = {
    employee_type: string
    org_name: any
    select_Year: any
    project_type: any
    employee_status: string
    start_date: any
  }
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [loading, setLoading] = useState(true)
  const [flag, setFlag] = useState(false)
  const [Countflag, setCountFlag] = useState(false)
  const [ReportData, setReportData] = useState<any | []>([])
  const [search, setSearch] = useState('')
  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [order7, setOrder7] = useState('ASC')
  const [order8, setOrder8] = useState('ASC')
  const [order9, setOrder9] = useState('ASC')
  const [sort, setSort] = useState<any | []>(false)
  const [EmployeeStatus, setEmployeeStatus] = useState('')
  const [selectMonth, setSelectMonth] = useState<any>([])
  const [selectProjectType, setSelectProjectType] = useState<any>([])
  const [selectProjectStatus, setSelectProjectStatus] = useState<any>([])
  const [SelectProject, setSelectProject] = useState<any | []>([])
  const [countMonth, setCountMonth] = useState<any | []>()
  const [countBu, setCountBu] = useState<any | []>()
  const [countStatus, setCountStatus] = useState<any | []>()
  const [ProjectList, setProjectList] = useState<any | []>([])
  const [perPage, setPerPage] = useState(25)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [businessData, SetBusinessData] = useState(false)
  const [
    ProjectCountReportWithoutPaginationTotalData,
    setProjectCountReportWithoutPaginationTotalData,
  ] = useState<any | []>([])
  const [AllMonthOptions, setAllMonthOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
  ])
  const [AllProjectTypeOptions, setAllProjectTypeOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'Placement',
      value: '1',
    },
    {
      label: 'Bench',
      value: '2',
    },
    {
      label: 'Internal',
      value: '3',
    },
  ])
  const [AllProjectStatusOptions, setAllProjectStatusOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'In Progress',
      value: 'IN_PROGRESS',
    },
    {
      label: 'Initiated',
      value: 'INITIATED',
    },
    {
      label: 'Completed',
      value: 'COMPLETED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      label: 'Rejected',
      value: 'REJECTED',
    },
  ])
  const typeaheadRef1: any = useRef<HTMLInputElement>(null)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)
  const typeaheadRef6: any = useRef<HTMLInputElement>(null)
  const typeaheadRef7: any = useRef<HTMLInputElement>(null)

  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [years, setYears] = useState<any>([])
  const [selectYear, setSelectYear] = useState('')
  const [ProjectReportData, setProjectReportData] = useState<any | []>([])
  const [ProjectReportCountData, setProjectReportCountData] = useState<any | []>([])
  const [ProjectReportCountDataAllProjects, setProjectReportCountDataAllProjects] = useState<
    any | []
  >([])
  const [OrganizationReportData, setOrganizationReportData] = useState<any | []>([])
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [highlightedSeries, setHighlightedSeries] = useState<number | null>(null)
  const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const onSubmit = async (data: UserSubmitForm) => {
    // setShowBackDrop(true)
    // const employeeValid: any = await Apiservices.employeeId()
    // setShowBackDrop(false)
  }

  const options3: any = [
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
  ]
  const options4: any = [
    {
      label: 'Placement',
      value: '1',
    },
    {
      label: 'Bench',
      value: '2',
    },
    {
      label: 'Internal',
      value: '3',
    },
  ]

  const options5: any = [
    {
      label: 'In Progress',
      value: 'IN_PROGRESS',
    },
    {
      label: 'Initiated',
      value: 'INITIATED',
    },
    {
      label: 'Completed',
      value: 'COMPLETED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      label: 'Rejected',
      value: 'REJECTED',
    },
  ]
  useEffect(() => {
    let currYr: any = new Date().getFullYear()
    setSelectYear(currYr)
    let pastYr = 2013
    while (currYr >= pastYr) {
      // setYears([...currYr]);
      years.push(currYr)
      currYr -= 1
    }
  }, [])
  useEffect(() => {
    get_business_units_list()
  }, [])
  const get_business_units_list = async () => {
    // const res: any = await Apiservices.get_business_units_list()
    // if (res && res.status == 200) {
    //   if (res.data.data.length > 0) {
    //     // setBusinessUnitsList(res.data.data)
    //     let obj = {name: 'Select All', value: 'All'}
    //     setBusinessUnitsList([obj, ...res.data.data])
    //   }
    // }

    let y = JSON.parse(localStorage.getItem('bu_list') || '[]')

    let n = y.filter(
      (element: any) =>
        element.roleName?.toLowerCase() == 'accounts' ||
        element.roleName?.toLowerCase() == 'approver' ||
        // element.roleName?.toLowerCase() == 'timesheetadmin' ||
        element.roleName?.toLowerCase() == 'hr'
    )
    let business: any = localStorage.getItem('role_Name')
    let numArray: any = business?.split(',')
    let uniqueArr: any = []

    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        let obj = {name: 'Select All', value: 'All'}
        if (numArray?.includes('admin') || numArray?.includes('timesheetadmin')) {
          let x = res.data.data.filter((test: any) => uniqueArr.push(test))
          setBusinessUnitsList([obj, ...uniqueArr])
        } else {
          for (let each of res.data.data) {
            for (let item of n) {
              if (each.business_unit_id == item.buId) {
                uniqueArr.push(each)
              }
            }
          }
          setBusinessUnitsList([obj, ...uniqueArr])
        }
      }
    }
  }
  const handleClick = async () => {
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      setValidateDescription(true)
    } else {
      setValidateDescription(false)
    }
    function hoverAction(thisPoint: any, state: any) {
      const allSeries = thisPoint.series.chart.series,
        stackName = thisPoint.series.userOptions.stack,
        thisIndex = thisPoint.index

      allSeries.forEach(function (ser: any) {
        if (ser.options.stack === thisPoint.series.options.stack && thisPoint.series != ser) {
          ser.points[thisIndex].setState(state)
        }
      })
    }
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined ||
      !getValues('start_date') ||
      test == null
    ) {
      console.log('if')
    } else {
      setCountFlag(false)
      let business1 = BusinessUnit.map((val: any) => {
        return val.business_unit_id
      })
      let business2 = business1.filter((temp: any) => `${temp}`).join(',')

      let month1 = selectMonth.map((temp: any) => {
        return Number(temp.value)
      })
      month1.sort((a: any, b: any) => a - b)
      let month12 = month1.filter((temp: any) => `${temp}`).join(',')

      let month_sort = []
      for (let each of month1) {
        let x = options3.filter((temp: any) => temp.value == each)
        month_sort.push(x)
      }
      let month_sort_into_Array = []

      for (let each of month_sort) {
        for (let item of each) {
          month_sort_into_Array.push(item)
        }
      }
      let project_type1 = selectProjectType.map((temp: any) => {
        return Number(temp.value)
      })
      let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

      let project_status1 = selectProjectStatus.map((temp: any) => {
        return temp.value
      })
      let project_status2 = project_status1.filter((temp: any) => `${temp}`).join(',')

      let project1 = SelectProject.map((value: any) => {
        return Number(value.id)
      })
      let project2 = project1.filter((temp: any) => `${temp}`).join(',')
      let currentDate: any = new Date()
      // if (test) {
      //   setTest(test)
      // } else {
      //   setTest(currentDate)
      // }

      const article = {
        // year: test
        //   ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
        //   : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
        year: test ? moment(test, 'ddd MMM DD YYYY').format('YYYY') : null,
        month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        project_id: project2 ? project1 : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : null,
        project_status: project_status2 ? project_status1 : null,
        // record_type_status: EmployeeStatus ? [EmployeeStatus] : null,
      }
      setShowBackDrop(true)
      setFlag(true)

      const response: any = await Apiservices.get_yearly_active_projects_report(article)

      if (response.isError == false) {
        // setProjectReportData(response.data)
        let AllData = []
        if (response && response.data?.length > 0) {
          if (business1 && business1.length > 0) {
            for (let each of business1) {
              let x = response.data?.filter((temp: any) => temp.business_unit_id == each)
              AllData.push(x)
            }
          } else {
            AllData.push(response.data)
          }
        }
        let finalData = []
        let data3: any = []
        let data4: any = []

        for (let each of AllData) {
          let obj: any = {}
          let obj1: any = {}
          let obj2: any = {}

          let data1: any = []
          let data5: any = []
          let data6: any = []
          for (let item of each) {
            obj.name = item.business_unit_name
              ? item.business_unit_name
              : localStorage.getItem('org_name')

            obj1.name = item.business_unit_name
              ? item.business_unit_name
              : localStorage.getItem('org_name')

            obj2.name = item.business_unit_name
              ? item.business_unit_name
              : localStorage.getItem('org_name')

            obj.color = '#3CB043'
            obj1.color = '#0000ff'
            obj2.color = '#FFC300'

            obj.stack = 'Active'
            obj1.stack = 'join'
            obj2.stack = 'Exit'

            data1.push(item.total_active)
            data5.push(item.total_new_projects)
            data6.push(-item.total_completed)
          }
          // obj.name = 'Active'
          // obj1.name = 'Join'
          // obj2.name = 'Exit'
          console.log(data1, 'data1')
          // console.log(data5, 'data5')
          // console.log(data6, 'data6')

          obj.data = data1
          obj1.data = data5
          obj2.data = data6

          obj.showInLegend = false
          obj1.showInLegend = false
          obj2.showInLegend = false

          console.log(obj, 'obj')
          // console.log(obj1, 'obj1')
          // console.log(obj2, 'obj2')

          finalData.push(obj)
          finalData.push(obj1)
          finalData.push(obj2)
        }

        let AllMonths = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]

        for (let each of AllMonths) {
          let x1 = response.data?.filter((temp: any) => temp.monthName == each)
          data3.push(x1)
        }
        for (let item of data3) {
          for (let item1 of item) {
            data4.push(item1)
          }
        }
        setProjectReportData(data4)

        let businessData1: any = []

        AllData.forEach((res, index) => {
          if (res && res[index] && res[index].business_unit_id) {
            businessData1.push(res[index].business_unit_id)
          }
        })

        if (businessData1.length > 0) {
          SetBusinessData(true)
        } else {
          SetBusinessData(false)
        }
        setOrganizationReportData({
          options: {
            chart: {
              type: 'column',
              // height: '350',
            },
            title: {
              text: 'Project Count Graph',
              // align: 'left',
            },
            xAxis: {
              // categories: AllMonths,
              categories:
                selectMonth && selectMonth.length > 0
                  ? month_sort_into_Array.map((test: any) => test.label)
                  : AllMonths,
              title: {
                text: 'Month',
              },
            },
            yAxis: {
              allowDecimals: false,
              // min: 0,
              title: {
                text: 'Count Of Projects',
              },
            },
            credits: {
              enabled: false,
            },
            leegend: {
              enabled: false,
            },

            // tooltip: {
            //   format: '<b>{key}</b><br/>{series.name}: {y}<br/>' + 'Total: {point.stackTotal}',
            // },
            tooltip: {
              formatter: function (
                this: Highcharts.TooltipFormatterContextObject,
                tooltip: Highcharts.Tooltip
              ) {
                let thisPoint: any = this.point,
                  allSeries: any = this.series.chart.series,
                  stackName: any = this.series.userOptions.stack,
                  thisIndex = thisPoint.index,
                  // returnString = '<b>Stack name: </b>' + stackName + '<br/><b>' + this.x + '</b><br/>';
                  returnString: any = '<br/><b>' + this.x + '</b><br/>'
                allSeries.forEach(function (ser: any) {
                  if (ser.options.stack === thisPoint.series.options.stack) {
                    returnString += ser.name + ': ' + ser.points[thisIndex].y + '<br/>'
                  }
                })
                returnString += 'Total: ' + thisPoint.stackTotal
                return returnString
              },
            },
            // plotOptions: {
            //   column: {
            //     // borderRadius: '25%',
            //     stacking: 'normal',
            //   },
            // },
            plotOptions: {
              column: {
                // borderRadius: '25%',
                stacking: 'normal',
                states: {
                  inactive: {
                    enabled: false,
                  },
                },

                point: {
                  events: {
                    mouseOver: function () {
                      hoverAction(this, 'hover')
                    },
                    mouseOut: function () {
                      hoverAction(this, '')
                    },
                  },
                },
              },
            },
            // series: [
            //   {
            //     name: 'John',
            //     data: [5, 3, 4, 7, 2],
            //   },
            //   {
            //     name: 'Jane',
            //     data: [2, -2, -3, 2, 1],
            //   },
            //   {
            //     name: 'Joe',
            //     data: [3, 4, 4, -2, 5],
            //   },
            // ],
            // series: [
            //   {
            //     name: 'Norway',
            //     data: [148, 133, 124],
            //     stack: 'Europe',
            //   },
            //   {
            //     name: 'Germany',
            //     data: [102, 98, 65],
            //     stack: 'Europe',
            //   },
            //   {
            //     name: 'United States',
            //     data: [113, 122, 95],
            //     stack: 'North America',
            //   },
            //   {
            //     name: 'Canada',
            //     data: [77, 72, 80],
            //     stack: 'North America',
            //   },
            // ],
            // [
            //    { data: [3, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5]}
            // {data :  [4, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6]}
            // ]
            series: finalData,
          },
        })
      }
      setShowBackDrop(false)
    }
  }

  const ref: any = useRef<HTMLInputElement>(null)
  const handleChange1 = (val: any) => {
    setSearch(val)
    setPage(1)
    // {each.project_status
    //   ? each.project_status == 'IN_PROGRESS'
    //     ? 'In Progress'
    //     : each.project_status == 'INITIATED'
    //     ? 'Initiated'
    //     : each.project_status == 'CANCELLED'
    //     ? 'Cancelled'
    //     : each.project_status == 'COMPLETED'
    //     ? 'Completed'
    //     : each.project_status == 'REJECTED'
    //     ? 'Rejected'
    //     : '-'
    //   : '-'}

    let a1 =
      val?.toLowerCase() == 'Placement'
        ? 1
        : val?.toLowerCase() == 'Bench'
        ? 2
        : val?.toLowerCase() == 'Internal'
        ? 3
        : ''

    let filterSearchData =
      ProjectCountReportWithoutPaginationTotalData &&
      ProjectCountReportWithoutPaginationTotalData?.filter((each: any) => {
        const statusMatch =
          (each?.project_status === 'IN_PROGRESS' && val?.toLowerCase() == 'in progress') ||
          (each?.project_status === 'INITIATED' && val?.toLowerCase() == 'initiated') ||
          (each?.project_status === 'COMPLETED' && val?.toLowerCase() == 'completed') ||
          (each?.project_status === 'CANCELLED' && val?.toLowerCase() == 'cancelled') ||
          (each?.project_status === 'REJECTED' && val?.toLowerCase() == 'rejected')

        const placed =
          (each?.is_placement_project == 1 && val?.toLowerCase() == 'placement') ||
          (each?.is_placement_project == 2 && val?.toLowerCase() == 'bench') ||
          (each?.is_placement_project == 3 && val?.toLowerCase() == 'internal')

        return (
          each?.business_unit_name?.toLowerCase()?.includes(val?.toLowerCase()) ||
          each?.project_name?.toLowerCase()?.includes(val?.toLowerCase()) ||
          // each?.is_placement_project?.toLowerCase()?.includes(val?.toLowerCase()) ||
          placed ||
          each?.placement_type?.toLowerCase()?.includes(val?.toLowerCase()) ||
          each?.placement_code?.toLowerCase()?.includes(val?.toLowerCase()) ||
          each?.start_date?.toLowerCase()?.includes(val?.toLowerCase()) ||
          each?.end_date?.toLowerCase()?.includes(val?.toLowerCase()) ||
          statusMatch
          // each?.bill_rate?.toLowerCase()?.includes(val?.toLowerCase()) ||
          // each?.pay_rate?.toLowerCase()?.includes(val?.toLowerCase()) ||
          // each?.project_status?.toLowerCase()?.includes(val?.toLowerCase())
        )
      })
    let data_slice1 = filterSearchData.slice((1 - 1) * perPage, 1 * perPage)
    setProjectReportCountData(data_slice1)
    setPages(Math.ceil(filterSearchData?.length / perPage))
  }
  const resetData = async (e: any) => {
    setFlag(false)
    setCountFlag(false)
    setOrganizationReportData([])
    setValue('employee_type', '')
    setValue('start_date', null)
    setTest(null)
    typeaheadRef1.current.clear()
    setSelectMonth([])
    setSelectProject([])
    setBusinessUnit([])
    setSelectProjectType([])
    setEmployeeStatus('')
    setSelectProjectStatus([])
    typeaheadRef4.current.clear()

    let monthReset = AllMonthOptions.find((item: any) => item.value == 'All')
    if (!monthReset) {
      AllMonthOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllMonthOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })

    let monthReset1 = AllMonthOptions.find((item: any) => item.value == 'empty')
    if (monthReset1) {
      AllMonthOptions.splice(
        AllMonthOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectTypeReset) {
      AllProjectTypeOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
      // setAllProjectTypeOptions(AllProjectTypeOptions)
    }
    // AllProjectTypeOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })

    let projectTypeReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectTypeReset1) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectStatusReset = AllProjectStatusOptions.find((item: any) => item.value == 'All')
    if (!projectStatusReset) {
      AllProjectStatusOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllProjectStatusOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })

    let projectStatusReset1 = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
    if (projectStatusReset1) {
      AllProjectStatusOptions.splice(
        AllProjectStatusOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }

  const exporthandleClick = async (e: any) => {
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      setValidateDescription(true)
    } else {
      setValidateDescription(false)
    }
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined ||
      !getValues('start_date') ||
      test == null
    ) {
      console.log('if')
    } else {
      let project1 = SelectProject.map((value: any) => {
        return Number(value.id)
      })
      let project2 = project1.filter((temp: any) => `${temp}`).join(',')
      let month1 = selectMonth.map((temp: any) => {
        return Number(temp.value)
      })
      month1.sort((a: any, b: any) => a - b)
      let month12 = month1.filter((temp: any) => `${temp}`).join(',')
      let project_type1 = selectProjectType.map((temp: any) => {
        return Number(temp.value)
      })
      let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')
      let project_status1 = selectProjectStatus.map((temp: any) => {
        return temp.value
      })
      let project_status2 = project_status1.filter((temp: any) => `${temp}`).join(',')
      let business1 = BusinessUnit.map((val: any) => {
        return val.business_unit_id
      })
      let business2 = business1.filter((temp: any) => `${temp}`).join(',')
      setShowBackDrop(true)

      const article = {
        // year: test
        //   ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
        //   : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
        year: test ? moment(test, 'ddd MMM DD YYYY').format('YYYY') : null,
        month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        project_id: project2 ? project1 : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : null,
        project_status: project_status2 ? project_status1 : null,
        // record_type_status: EmployeeStatus ? [EmployeeStatus] : null,
      }

      const res1: any = await Apiservices.export_yearly_active_projects_report(article)
      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = res1?.data?.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()

      setShowBackDrop(false)
    }
  }
  let FilteredData = ReportData?.data?.filter(
    (each: any) =>
      each?.employee_id?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.employee_type?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.full_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.joining_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.leaving_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.emp_working_hours?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.total_hours?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.compliance_percentage?.toLowerCase()?.includes(search?.toLowerCase())
  )
  const singleDateSelection = async (date: any) => {
    setTest(date)
    setValue('start_date', date)
  }

  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate?.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const handleChange2 = (e: any) => {
    setEmployeeStatus(e.target.value)
  }
  const handleFilterConsultant1 = async (consult_Name: any) => {
    const response: any = await Apiservices.getprojectbyname({country_name: consult_Name})
    if (response.data.data !== null) {
      setProjectList(response && response.data && response.data.data ? response?.data?.data : '')
    }
  }
  const selectedProject = async (consult_name: any) => {
    setSelectProject(consult_name)
  }
  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)
    // getallprojects(mainTableData, selected, perPage)
    handleResend(countStatus, countMonth, countBu, selected, perPage, 'pages')
  }

  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      // setPage(1)
      // getallprojects(mainTableData, 1, value)
      // handleResend(countStatus, countMonth, countBu, 1, value, 'rows')
      handleResend(countStatus, countMonth, countBu, page, value, 'rows')
    }
  }

  const handleResend = async (
    status: any,
    month: any,
    bu: any,
    page: any,
    perPage: any,
    api_trigger: any
  ) => {
    setCountMonth(month)
    setCountBu(bu)
    setCountStatus(status)
    let x = options3.filter((month_temp: any) => month_temp.label == month)
    let y = BusinessUnitsList.filter((bu_temp: any) => bu_temp.name == bu)

    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')
    let project_status1 = selectProjectStatus.map((temp: any) => {
      return temp.value
    })
    let project_status2 = project_status1.filter((temp: any) => `${temp}`).join(',')
    let project1 = SelectProject.map((value: any) => {
      return Number(value.id)
    })
    let project2 = project1.filter((temp: any) => `${temp}`).join(',')

    const article = {
      // year: test
      //   ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
      //   : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      year: test ? moment(test, 'ddd MMM DD YYYY').format('YYYY') : null,
      month: x[0].value,
      project_id: project2 ? project1 : null,
      is_placement_project: project_type2 ? project_type1 : null,
      // business_unit_id: business2 ? business1 : null,
      business_unit_id: y?.length > 0 ? [y[0].business_unit_id] : null,
      project_status: project_status2 ? project_status1 : null,
      // record_type_status: EmployeeStatus ? [EmployeeStatus] : null,
      pagenumber: page,
      pagesize: Number(perPage),
    }
    // const response: any = await Apiservices.get_active_projects_report(status, article)
    // if (response.isError == false) {
    //   setCountFlag(true)
    //   setProjectReportCountData(response.data)
    //   setProjectReportCountDataAllProjects(response?.count)
    //   setPages(Math.ceil(response?.count / Number(perPage)))
    // }
    if (api_trigger == 'run') {
      setShowBackDrop(true)
      const response: any = await Apiservices.get_active_projects_report(status, article)
      if (response.isError == false) {
        setCountFlag(true)
        setProjectCountReportWithoutPaginationTotalData(response?.data)
        let data_slice = response.data.slice((1 - 1) * perPage, 1 * perPage)
        setProjectReportCountData(data_slice)
        setPage(1)
        setProjectReportCountDataAllProjects(response?.count)
        setPages(Math.ceil(response?.count / Number(perPage)))
      }
      setShowBackDrop(false)
    } else if (api_trigger == 'pages') {
      if (search && search?.length != 0) {
        let filterSearchData =
          ProjectCountReportWithoutPaginationTotalData &&
          ProjectCountReportWithoutPaginationTotalData?.filter(
            (each: any) =>
              each?.business_unit_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.project_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.placement_type?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.placement_code?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.start_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.end_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.bill_rate?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.pay_rate?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.project_status?.toLowerCase()?.includes(search?.toLowerCase())
          )

        let data_slice1 = filterSearchData.slice((page - 1) * perPage, page * perPage)
        setProjectReportCountData(data_slice1)
        setPage(page)
        setPages(Math.ceil(filterSearchData?.length / perPage))
      } else {
        let data_slice1 = ProjectCountReportWithoutPaginationTotalData.slice(
          (page - 1) * perPage,
          page * perPage
        )
        setPage(page)
        setProjectReportCountData(data_slice1)
      }
    } else if (api_trigger == 'rows') {
      if (search?.length > 0) {
        let filterSearchData =
          ProjectCountReportWithoutPaginationTotalData &&
          ProjectCountReportWithoutPaginationTotalData?.filter(
            (each: any) =>
              each?.business_unit_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.project_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.placement_type?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.placement_code?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.start_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.end_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.bill_rate?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.pay_rate?.toLowerCase()?.includes(search?.toLowerCase()) ||
              each?.project_status?.toLowerCase()?.includes(search?.toLowerCase())
          )
        let data_slice1 = filterSearchData.slice((1 - 1) * perPage, 1 * perPage)
        setProjectReportCountData(data_slice1)
        setPage(1)
        setPages(Math.ceil(filterSearchData?.length / perPage))
      } else {
        let data_slice1 = ProjectCountReportWithoutPaginationTotalData.slice(
          (1 - 1) * perPage,
          1 * perPage
        )
        setProjectReportCountData(data_slice1)
        setPage(1)
        setPages(Math.ceil(ProjectCountReportWithoutPaginationTotalData?.length / Number(perPage)))
      }
    }
  }

  const exporthandleClick1 = async (e: any) => {
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')
    let project_status1 = selectProjectStatus.map((temp: any) => {
      return temp.value
    })
    let project_status2 = project_status1.filter((temp: any) => `${temp}`).join(',')
    let project1 = SelectProject.map((value: any) => {
      return Number(value.id)
    })
    let project2 = project1.filter((temp: any) => `${temp}`).join(',')
    setShowBackDrop(true)
    let x = options3.filter((month_temp: any) => month_temp.label == countMonth)
    let y = BusinessUnitsList.filter((bu_temp: any) => bu_temp.name == countBu)

    const article = {
      // year: test
      //   ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
      //   : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      year: test ? moment(test, 'ddd MMM DD YYYY').format('YYYY') : null,
      month: x[0].value,
      project_id: project2 ? project1 : null,
      is_placement_project: project_type2 ? project_type1 : null,
      // business_unit_id: business2 ? business1 : null,
      business_unit_id: y?.length > 0 ? [y[0].business_unit_id] : null,
      project_status: project_status2 ? project_status1 : null,
      // record_type_status: EmployeeStatus ? [EmployeeStatus] : null,
    }

    const response: any = await Apiservices.export_active_projects_report(countStatus, article)
    if (response.isError == false) {
      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = response?.data?.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()
    }

    setShowBackDrop(false)
  }
  const handleMonth = (selected: any) => {
    // setSelectMonth(selected)
    if (selected?.length > 0) {
      setValidateDescription(false)
    }

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllMonthOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectMonth(x)
          AllMonthOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllMonthOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllMonthOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllMonthOptions(AllMonthOptions)
          }
        } else if (each.value == 'empty') {
          setSelectMonth([])
          let cde = AllMonthOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllMonthOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllMonthOptions(AllMonthOptions)
          }
          AllMonthOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectMonth(selected)
          let abc = AllMonthOptions.find((item: any) => item.value == 'empty')
          let cde = AllMonthOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllMonthOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllMonthOptions(AllMonthOptions)
          }
          if (!cde) {
            AllMonthOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllMonthOptions(AllMonthOptions)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllMonthOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllMonthOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectMonth(selected)
      AllMonthOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })
    }
  }
  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })
    }
  }

  const handleProjectType = (selected: any) => {
    setSelectProjectType(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllProjectTypeOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectProjectType(x)
          AllProjectTypeOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
        } else if (each.value == 'empty') {
          setSelectProjectType([])
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          AllProjectTypeOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectProjectType(selected)
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }

          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllProjectTypeOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllProjectTypeOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectProjectType(selected)
      AllProjectTypeOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })
    }
  }
  const handleProjectStatus = (selected: any) => {
    // setSelectProjectStatus(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllProjectStatusOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectProjectStatus(x)
          AllProjectStatusOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllProjectStatusOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }
        } else if (each.value == 'empty') {
          setSelectProjectStatus([])
          let cde = AllProjectStatusOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllProjectStatusOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllProjectStatusOptions(AllProjectStatusOptions)
          }
          AllProjectStatusOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectProjectStatus(selected)
          let abc = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
          let cde = AllProjectStatusOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllProjectStatusOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }
          if (!cde) {
            AllProjectStatusOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }

          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllProjectStatusOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllProjectStatusOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectProjectStatus(selected)
      AllProjectStatusOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })
    }
  }
  const sorting5 = (col: any) => {
    if (order5 === 'ASC') {
      const sorted = [...ProjectReportCountData]?.sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      )
      setProjectReportCountData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      const sorted = [...ProjectReportCountData]?.sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      )
      setProjectReportCountData(sorted)
      setOrder5('ASC')
      setSort(true)
    }
  }

  const sorting7 = (col: any) => {
    if (order7 === 'ASC') {
      const sorted = [...ProjectReportCountData]?.sort((a, b) => (a[col] > b[col] ? 1 : -1))
      setProjectReportCountData(sorted)
      setOrder7('DSC')
      setSort(false)
    }

    if (order7 === 'DSC') {
      const sorted = [...ProjectReportCountData]?.sort((a, b) => (a[col] < b[col] ? 1 : -1))
      setProjectReportCountData(sorted)
      setOrder7('ASC')
      setSort(true)
    }
  }

  const sorting6 = (col: any) => {
    if (order6 === 'ASC') {
      const sorted = [...ProjectReportCountData]?.sort((a: any, b: any) => {
        // const numA = parseInt(a[col].match(/\d+/), 10) || 0
        // const numB = parseInt(b[col].match(/\d+/), 10) || 0
        // if (numA === numB) {
        //   return a[col].localeCompare(b[col])
        // } else {
        //   return numA - numB
        // }

        // Remove spaces and convert to lowercase for case-insensitive comparison
        const lowerA = a[col]?.replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col]?.replace(/\s+/g, '').toLowerCase()
        return lowerA?.localeCompare(lowerB)
      })
      setProjectReportCountData(sorted)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      const sorted = [...ProjectReportCountData]?.sort((a: any, b: any) => {
        // const numA = parseInt(a[col].match(/\d+/), 10) || 0
        // const numB = parseInt(b[col].match(/\d+/), 10) || 0
        // if (numA === numB) {
        //   return b[col].localeCompare(a[col])
        // } else {
        //   return numB - numA
        // }
        const lowerA = a[col]?.replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col]?.replace(/\s+/g, '').toLowerCase()
        return lowerB?.localeCompare(lowerA)
      })

      setProjectReportCountData(sorted)
      setOrder6('ASC')
      setSort(true)
    }
  }

  const sorting8 = (col: any) => {
    if (order8 === 'ASC') {
      const sorted = [...ProjectReportCountData]?.sort((a: any, b: any) => {
        let a1 = a[col] == 1 ? 'Placement' : a[col] == 2 ? 'Bench' : a[col] == 3 ? 'Internal' : ''
        let b1 = b[col] == 1 ? 'Placement' : b[col] == 2 ? 'Bench' : b[col] == 3 ? 'Internal' : ''

        return a1?.toLowerCase() > b1?.toLowerCase() ? 1 : -1
      })
      setProjectReportCountData(sorted)
      setOrder8('DSC')
      setSort(false)
    }

    if (order8 === 'DSC') {
      const sorted = [...ProjectReportCountData]?.sort((a: any, b: any) => {
        let a1 = a[col] == 1 ? 'Placement' : a[col] == 2 ? 'Bench' : a[col] == 3 ? 'Internal' : ''
        let b1 = b[col] == 1 ? 'Placement' : b[col] == 2 ? 'Bench' : b[col] == 3 ? 'Internal' : ''

        return a1?.toLowerCase() < b1?.toLowerCase() ? 1 : -1
      })
      setProjectReportCountData(sorted)
      setOrder8('ASC')
      setSort(true)
    }
  }

  const sorting9 = (col: any) => {
    if (order9 === 'ASC') {
      const sorted = [...ProjectReportCountData]?.sort((a, b) => {
        let dateA = new Date(a[col]),
          dateB = new Date(b[col])
        if (dateB > dateA) {
          return -1
        } else {
          return 1
        }
      })

      setProjectReportCountData(sorted)
      setOrder9('DSC')
      setSort(false)
    }

    if (order9 === 'DSC') {
      const sorted = [...ProjectReportCountData]?.sort((a, b) => {
        let dateA = new Date(a[col]),
          dateB = new Date(b[col])
        if (dateB < dateA) {
          return -1
        } else {
          return 1
        }
      })

      setProjectReportCountData(sorted)
      setOrder9('ASC')
      setSort(true)
    }
  }

  const chart = chartComponentRef.current?.chart
  if (chart) {
    chart.series.forEach((series: any, index: any) => {
      series.update({
        opacity: highlightedSeries === null || highlightedSeries === index ? 1 : 0.2,
      })
    })
  }

  const seriesColors = ['green', 'blue', '#FFD700']

  const calculateTickInterval = (minY: any, maxY: any) => {
    const range = maxY - minY
    if (range <= 10) return 1
    if (range <= 50) return 5
    if (range <= 100) return 10
    if (range <= 500) return 50
    if (range <= 1000) return 100
    return Math.ceil(range / 10) // Default interval for larger ranges
  }

  const handleLegendClick = (index: number) => {
    // if (!businessData) {
    if (highlightedSeries === index) {
      // If the clicked series is already highlighted, reset the chart
      setHighlightedSeries(null)
      if (chartComponentRef.current) {
        chartComponentRef.current.chart.update({
          yAxis: {
            min: null,
            max: null,
            tickInterval: undefined, // Remove tick interval
          },
        })
      }
    } else {
      // Highlight the clicked series
      setHighlightedSeries(index)
      if (chartComponentRef.current) {
        const seriesData = chartComponentRef.current.chart.series[index].data
        const yValues: any = seriesData.map((point: any) => point.y)
        const minY = Math.min(...yValues)
        const maxY = Math.max(...yValues)

        // Calculate tick interval dynamically
        const tickInterval = calculateTickInterval(minY, maxY)

        // Set the y-axis range from minY - 1 to maxY + 1 with tick intervals
        chartComponentRef.current.chart.update({
          yAxis: {
            min: minY - 1, // Decrement minY by 1
            max: maxY + 1, // Increment maxY by 1
            tickInterval: tickInterval,
          },
        })
      }
    }
    // }
  }

  //   const handleLegendClick = (index: number) => {
  //   const chart = chartComponentRef.current?.chart;

  //   if (chart) {
  //     if (highlightedSeries === index) {
  //       // If the clicked series is already highlighted, reset the chart
  //       setHighlightedSeries(null);

  //       // Show all series again
  //       chart.series.forEach((series: any) => {
  //         series.update({
  //           visible: true,  // Show all series
  //           opacity: 1,     // Set full opacity
  //         });
  //       });

  //       // Reset y-axis range to fit all series data
  //       chart.update({
  //         yAxis: {
  //           min: null,      // Reset to automatic min
  //           max: null,      // Reset to automatic max
  //           tickInterval: undefined,  // Remove any forced interval
  //         },
  //       });
  //     } else {
  //       // Highlight the clicked series
  //       setHighlightedSeries(index);

  //       // Hide all other series except the clicked one
  //       chart.series.forEach((series: any, idx: number) => {
  //         if (idx === index) {
  //           series.update({
  //             visible: true,  // Show the clicked series
  //             opacity: 1,     // Full opacity for the clicked series
  //           });
  //         } else {
  //           series.update({
  //             visible: false, // Hide all other series
  //             opacity: 0,     // Set opacity to 0 for hidden series
  //           });
  //         }
  //       });

  //       // Optionally, update y-axis based on the clicked series data
  //       const seriesData = chart.series[index].data;
  //       const yValues: any = seriesData.map((point: any) => point.stackY);  // Use stackY for stacked data
  //       const minY = Math.min(...yValues);  // Get min y value
  //       const maxY = Math.max(...yValues);  // Get max y value

  //       // Update y-axis range for the clicked series
  //       chart.update({
  //         yAxis: {
  //           min: minY - 1,  // Add padding to the min value
  //           max: maxY + 1,  // Add padding to the max value
  //           tickInterval: undefined,  // Remove tick interval (if necessary)
  //         },
  //       });
  //     }
  //   }
  // };

  // const handleLegendClick = (index: number) => {
  //   const chart = chartComponentRef.current?.chart;

  //   if (chart) {
  //     if (highlightedSeries === index) {
  //       // If the clicked series is already highlighted, reset the chart
  //       setHighlightedSeries(null);

  //       // Show all series again
  //       chart.series.forEach((series: any) => {
  //         series.update({
  //           visible: true,  // Show all series
  //           opacity: 1,     // Set full opacity
  //         });
  //       });

  //       // Reset y-axis range to fit all series data
  //       chart.update({
  //         yAxis: {
  //           min: null,      // Reset to automatic min
  //           max: null,      // Reset to automatic max
  //           tickInterval: undefined,  // Remove any forced interval
  //         },
  //       });
  //     } else {
  //       // Highlight the clicked series
  //       setHighlightedSeries(index);

  //       // Hide all other series except the clicked one
  //       chart.series.forEach((series: any, idx: number) => {
  //         if (idx === index) {
  //           series.update({
  //             visible: true,  // Show the clicked series
  //             opacity: 1,     // Full opacity for the clicked series
  //           });
  //         } else {
  //           series.update({
  //             visible: false, // Hide all other series
  //             opacity: 0,     // Set opacity to 0 for hidden series
  //           });
  //         }
  //       });

  //       // Retrieve data from the clicked series
  //      // const seriesData = chart.series[index].data;
  //       const seriesData = chartComponentRef.current.chart.series[index].data
  //       console.log(seriesData,"seriesData")
  //       const yValues: any = seriesData.map((point: any) => point.stackY);  // Use stackY for stacked data
  //       console.log(yValues,"yValues")
  //       const minY = Math.min(...yValues);  // Get min y value
  //       const maxY = Math.max(...yValues);  // Get max y value

  //       // Calculate tick interval dynamically (similar to the second code)
  //      // const tickInterval = (maxY - minY) / 5; // Simple logic for tick interval (you can adjust this)

  //       const tickInterval = calculateTickInterval(minY, maxY)

  //       // Update y-axis range for the clicked series
  //       chart.update({
  //         yAxis: {
  //           min: minY - 1,  // Add padding to the min value
  //           max: maxY + 1,  // Add padding to the max value
  //           tickInterval: tickInterval,  // Dynamic tick interval
  //         },
  //       });
  //     }
  //   }
  // };

  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>Project Count Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <div className='d-flex justify-content-between align-items-center'>
            <div> </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BackDrop showBackDrop={showBackDrop} />
            {loading ? (
              <>
                {' '}
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-3'>
                    <div className='row gy-3 align-items-center py-2'>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label fw-semibold'>Select Year *</label>
                        <DatePicker
                          selected={test}
                          {...register('start_date', {
                            required: true,
                          })}
                          onChange={(date: any) => singleDateSelection(date)}
                          placeholderText='Select Year'
                          className={clsx('form-control')}
                          dateFormat='yyyy'
                          showYearPicker
                          // required
                        />
                        {errors.start_date &&
                          errors.start_date.type === 'required' &&
                          !getValues('start_date') && (
                            <span className='error text-danger mb-0'>Year is required</span>
                          )}
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg '>
                        <label className='form-label '>Select Month *</label>
                        <Typeahead
                          multiple
                          className='overFlowForTextField'
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            // setSelectMonth(selected)
                            handleMonth(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef1.current.toggleMenu()
                          }}
                          options={AllMonthOptions}
                          placeholder='Select Month'
                          ref={typeaheadRef1}
                          selected={selectMonth}
                          // inputProps={{
                          //   required: true,
                          // }}
                        />
                        {validatedescription && (
                          <span className='error text-danger mb-0'>Month is required</span>
                        )}
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Business Unit</label>
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            // setBusinessUnit(e)
                            handleBusinessUnit(e)
                            typeaheadRef4.current.toggleMenu()
                          }}
                          options={BusinessUnitsList}
                          className='overFlowForTextField'
                          ref={typeaheadRef4}
                          placeholder='Select Business Unit'
                          selected={BusinessUnit}
                          labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                          //   inputProps={{required: true}}
                        ></Typeahead>
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg '>
                        <label className='form-label '>Project Type</label>
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            // setSelectProjectType(selected)
                            handleProjectType(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef5.current.toggleMenu()
                          }}
                          options={AllProjectTypeOptions}
                          className='overFlowForTextField'
                          placeholder='Select Project Type'
                          ref={typeaheadRef5}
                          selected={selectProjectType}
                        />
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg '>
                        <label className='form-label '>Project Status</label>
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            // setSelectProjectStatus(selected)
                            handleProjectStatus(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef6.current.toggleMenu()
                          }}
                          options={AllProjectStatusOptions}
                          className='overFlowForTextField'
                          placeholder='Select Project Status'
                          ref={typeaheadRef6}
                          selected={selectProjectStatus}
                        />
                      </div>

                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Project Name</label>
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(e) => {
                            selectedProject(e)
                            typeaheadRef7.current.toggleMenu()
                          }}
                          onInputChange={(e) => handleFilterConsultant1(e)}
                          options={ProjectList}
                          className='overFlowForTextField'
                          ref={typeaheadRef7}
                          placeholder='Search Project'
                          selected={SelectProject}
                          labelKey={(ProjectList: any) => user(ProjectList.project_name)}
                        ></Typeahead>
                      </div>
                      {/* <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Status</label>
                        <select
                          value={EmployeeStatus}
                          className={clsx(
                            'form-select',
                            errors.employee_status ? 'is-invalid' : ''
                          )}
                          {...register('employee_status')}
                          onChange={handleChange2}
                        >
                          <option value='Active'>Active</option>
                          <option value='Inactive'>Inactive</option>
                          <option value=''>All</option>
                        </select>
                      </div> */}

                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          className='btn btn-sm btn-primary'
                          type='submit'
                          onClick={handleClick}
                        >
                          Run
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm cursor-pointer '
                          id='kt_toolbar_primary_button'
                          onClick={resetData}
                        >
                          Reset
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                          className='btn btn-light-primary   btn-sm'
                          onClick={exporthandleClick}
                        >
                          Export
                          {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>load</h1>
            )}
          </form>

          <div className='row justify-content-center'>
            {OrganizationReportData && OrganizationReportData?.options && (
              <div className='col-7'>
                <div className='pt-3'>
                  <div className='card card-custom card-flush'>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={OrganizationReportData.options}
                      ref={chartComponentRef}
                    />
                    <div className='lege'>
                      <ul
                        className='graphLegend1'
                        onClick={() => handleLegendClick(0)}
                        style={{cursor: 'pointer', fontSize: '1em'}}
                      >
                        <span
                          style={{
                            color: 'black',
                            fontWeight: highlightedSeries === 0 ? 'bold' : 'normal',
                          }}
                        >
                          <span
                            style={{
                              display: 'inline-block',
                              width: '10px',
                              height: '10px',
                              borderRadius: '50%',
                              backgroundColor: seriesColors[0],
                              marginRight: '8px',
                            }}
                          ></span>
                          Active
                        </span>
                      </ul>
                      <ul
                        className='graphLegend1'
                        onClick={() => handleLegendClick(1)}
                        style={{cursor: 'pointer', fontSize: '1em'}}
                      >
                        <span
                          style={{
                            color: 'black',
                            fontWeight: highlightedSeries === 1 ? 'bold' : 'normal',
                          }}
                        >
                          <span
                            style={{
                              display: 'inline-block',
                              width: '10px',
                              height: '10px',
                              borderRadius: '50%',
                              backgroundColor: seriesColors[1],
                              marginRight: '8px',
                            }}
                          ></span>
                          New
                        </span>
                      </ul>
                      <ul
                        className='graphLegend1'
                        onClick={() => handleLegendClick(2)}
                        style={{cursor: 'pointer', fontSize: '1em'}}
                      >
                        <span
                          style={{
                            color: 'black',
                            fontWeight: highlightedSeries === 2 ? 'bold' : 'normal',
                          }}
                        >
                          <span
                            style={{
                              display: 'inline-block',
                              width: '10px',
                              height: '10px',
                              borderRadius: '50%',
                              backgroundColor: seriesColors[2],
                              marginRight: '8px',
                            }}
                          ></span>
                          Completed
                        </span>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='col-5'>
              {ProjectReportData?.length > 0 && flag == true && (
                <>
                  <div className='pt-3'>
                    <div className='card card-custom card-flush '>
                      <div className='card-body p-3 py-3'>
                        <div className='table-responsive reports-table side-graph'>
                          <table
                            id='kt_table_users'
                            className='table table-row-bordered table-row-gray-300 gy-4'
                          >
                            <>
                              <thead>
                                <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                  <th className='  py-4'>Business Unit</th>
                                  <th className='  py-4'>Month</th>
                                  <th className='  py-4'>Active</th>
                                  <th className='  py-4'>New</th>
                                  <th className='  py-4'>Completed</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ProjectReportData?.length > 0 ? (
                                  <>
                                    {ProjectReportData?.map((each: any, index: any) => {
                                      return (
                                        <tr
                                          className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                          key={index}
                                        >
                                          <td>
                                            {each.business_unit_name
                                              ? each.business_unit_name
                                              : each.organization_name}
                                          </td>

                                          <td className=''>
                                            {each.monthName
                                              ? moment(each.monthName, 'MMMM').format('MMM')
                                              : '-'}{' '}
                                            {moment(test, 'ddd MMM DD YYYY').format('YYYY')}
                                          </td>
                                          <td className=''>
                                            <a
                                              onClick={() =>
                                                handleResend(
                                                  'Active',
                                                  each.monthName,
                                                  each.business_unit_name,
                                                  page,
                                                  perPage,
                                                  'run'
                                                )
                                              }
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                              {each.total_active}
                                            </a>
                                          </td>
                                          <td className=''>
                                            <a
                                              onClick={() =>
                                                handleResend(
                                                  'New',
                                                  each.monthName,
                                                  each.business_unit_name,
                                                  page,
                                                  perPage,
                                                  'run'
                                                )
                                              }
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                              {each.total_new_projects}
                                            </a>
                                          </td>
                                          <td className=''>
                                            <a
                                              onClick={() =>
                                                handleResend(
                                                  'Completed',
                                                  each.monthName,
                                                  each.business_unit_name,
                                                  page,
                                                  perPage,
                                                  'run'
                                                )
                                              }
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                              {each.total_completed}
                                            </a>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </>
                                ) : (
                                  ''
                                )}
                              </tbody>
                            </>
                          </table>
                          {(ProjectReportData?.length == null ||
                            ProjectReportData?.length == 0) && (
                            <div className='text-center  fw-bold text-gray-800'>
                              No Records found{' '}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-stack py-2'></div>
                </>
              )}
            </div>
          </div>
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note:Please select year, month & click on run to view the report.
            </div>
          )}

          {/* {OrganizationReportData && OrganizationReportData?.series && (
            <div className='py-1'>
              <div id='chart'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-1'>
                    <div className='row justify-content-center'>
                      <div className='col-11'>
                        <Chart
                          options={OrganizationReportData.options}
                          series={OrganizationReportData.series}
                          type='bar'
                          // width='100%'
                          height={450}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {Countflag == true && (
            <>
              <div className='row justify-content-between align-items-center'>
                <div className='col-auto'>
                  <h3 className='text-gray-900 fs-3 fw-semibold  py-1'>
                    {countStatus} Project Details
                  </h3>
                </div>
                <div className='col-auto justify-content-end'>
                  <button
                    // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                    className='btn btn-light-primary   btn-sm'
                    onClick={exporthandleClick1}
                  >
                    Export
                    {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                  </button>
                </div>
              </div>
              <div className='py-1'>
                <div className='pt-1'>
                  <div className='card card-custom card-flush '>
                    <div className='card-body p-3 py-3'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='text-gray-900 fs-5 fw-semibold text-transform-unset'>
                          Tabular Data{' '}
                        </div>
                        <div className='d-flex gap-3 '>
                          <div className='input-group mb-0'>
                            <input
                              type='text'
                              id='form1'
                              className='form-control smallTextField'
                              // value={search}
                              value={search}
                              // {...register('work_city')}
                              placeholder='Search'
                              onChange={(e) => handleChange1(e.target.value)}
                              // onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='table-responsive padding-down reportTab'>
                        <table
                          id='kt_table_users'
                          className='table table-row-bordered table-row-gray-300 gy-4'
                        >
                          <>
                            <thead>
                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                <th
                                  onClick={() => sorting6('business_unit_name')}
                                  className='  py-4'
                                >
                                  Business Unit{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('countMonth')} className='  py-4'>
                                  Month{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('project_name')} className='  py-4'>
                                  Project Name{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting8('is_placement_project')}
                                  className='  py-4'
                                >
                                  Project Type{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('placement_type')} className='  py-4'>
                                  Placement Type{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('placement_code')} className='  py-4'>
                                  Placement ID{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting9('start_date')} className='  py-4'>
                                  Start Date <span className='fs-9'>(DD-MMM-YYYY)</span>
                                  <span>
                                    {order9 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting9('end_date')} className='  py-4'>
                                  End Date <span className='fs-9'>(DD-MMM-YYYY)</span>
                                  <span>
                                    {order9 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>

                                <th onClick={() => sorting7('bill_rate')} className='  py-4'>
                                  Bill rate{' '}
                                  <span>
                                    {order7 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting7('pay_rate')} className='  py-4'>
                                  Pay rate{' '}
                                  <span>
                                    {order7 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('project_status')} className='  py-4'>
                                  Project Status{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                {countStatus == 'Completed' && (
                                  <>
                                    <th
                                      onClick={() => sorting5('project_status')}
                                      className='  py-4'
                                    >
                                      Consultant Status{' '}
                                      <span>
                                        {order5 === 'ASC' ? (
                                          <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                        ) : (
                                          <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                        )}
                                      </span>
                                    </th>

                                    <th onClick={() => sorting5('leaving_date')} className='  py-4'>
                                      Consultant Duration{' '}
                                      <span>
                                        {order5 === 'ASC' ? (
                                          <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                        ) : (
                                          <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                        )}
                                      </span>
                                    </th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {ProjectReportCountData?.length > 0 ? (
                                <>
                                  {ProjectReportCountData?.map((each: any, index: any) => {
                                    let leave = moment(each.leaving_date)
                                    let join = moment(each.joining_date)
                                    let duration1 = moment.duration(leave.diff(join))
                                    let dayss = duration1.asDays()
                                    const years = Math.floor(dayss / 365.25)
                                    const remaining_days = dayss % 365.25
                                    const months = Math.floor(remaining_days / 30.4375)
                                    const days = Math.round(remaining_days % 30.4375)
                                    return (
                                      <tr
                                        className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                        key={index}
                                      >
                                        <td>
                                          {each.business_unit_name
                                            ? each.business_unit_name
                                            : each.organization_name}
                                        </td>
                                        <td className=''>
                                          {countMonth
                                            ? moment(countMonth, 'MMMM').format('MMM')
                                            : '-'}{' '}
                                          {moment(test, 'ddd MMM DD YYYY').format('YYYY')}
                                        </td>
                                        <td className='text-capitalize'>
                                          {each.project_name ? each.project_name : '-'}
                                        </td>
                                        <td className=''>
                                          {each.is_placement_project == 1
                                            ? 'Placement'
                                            : each.is_placement_project == 2
                                            ? 'Bench'
                                            : 'Internal'}
                                        </td>

                                        <td className='text-capitalize'>
                                          {each.placement_type ? each.placement_type : '-'}
                                        </td>
                                        <td className='text-capitalize'>
                                          {each.placement_code ? each.placement_code : '-'}
                                        </td>
                                        <td className=''>
                                          {each.start_date
                                            ? moment(each.start_date).format('DD-MMM-YYYY')
                                            : '-'}
                                        </td>
                                        <td className=''>
                                          {each.end_date
                                            ? moment(each.end_date).format('DD-MMM-YYYY')
                                            : '-'}
                                        </td>
                                        <td className='text-capitalize'>
                                          {each.bill_rate ? each.bill_rate : '-'}
                                        </td>
                                        <td className='text-capitalize'>
                                          {each.pay_rate ? each.pay_rate : '-'}
                                        </td>

                                        <td className=''>
                                          {each.project_status
                                            ? each.project_status == 'IN_PROGRESS'
                                              ? 'In Progress'
                                              : each.project_status == 'INITIATED'
                                              ? 'Initiated'
                                              : each.project_status == 'CANCELLED'
                                              ? 'Cancelled'
                                              : each.project_status == 'COMPLETED'
                                              ? 'Completed'
                                              : each.project_status == 'REJECTED'
                                              ? 'Rejected'
                                              : '-'
                                            : '-'}
                                        </td>

                                        {countStatus == 'Completed' && (
                                          <>
                                            <td className=''>
                                              {each.user_status ? each.user_status : '-'}
                                            </td>
                                            <td className=''>
                                              <>
                                                {each.user_status == 'Active'
                                                  ? '-'
                                                  : ` ${
                                                      years == 1
                                                        ? `${years} year`
                                                        : `${years} years`
                                                    } ${
                                                      months == 1
                                                        ? `${months} month`
                                                        : ` ${months} months`
                                                    } ${
                                                      days == 1 ? `${days} day` : ` ${days} days`
                                                    }`}
                                              </>
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    )
                                  })}
                                </>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </>
                        </table>
                        {(ProjectReportCountData?.length == null ||
                          ProjectReportCountData?.length == 0) && (
                          <div className='text-center  fw-bold text-gray-800'>
                            No Records found{' '}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-stack py-2'>
                  {ProjectReportCountData?.length > 0 ? (
                    <div>
                      <Container className='p-0'>
                        <Row className='align-items-center'>
                          <Col xs='auto' className='pe-0'>
                            {' '}
                            <label className='form-label fw-semibold'>No. of projects</label>
                          </Col>

                          <Col>
                            <select
                              name='perPage'
                              className='form-select'
                              onChange={onChange}
                              value={perPage}
                            >
                              {/* <option value='10'>10</option> */}
                              <option value='25'>25</option>
                              <option value='50'>50</option>
                              <option value='75'>75</option>
                              <option value='100'>100</option>
                              {/* <option value={ProjectReportCountDataAllProjects}>All</option> */}
                            </select>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  ) : null}

                  <div className='pr-150'>
                    {ProjectReportCountData?.length > 0 ? (
                      <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pages}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-right'}
                        activeClassName={'active'}
                        breakLabel={'...'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        forcePage={page - 1}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
