/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import {ThemeModeType, useThemeMode} from './../../partials/layout/theme-mode/ThemeModeProvider'

import {KTSVG, toAbsoluteUrl} from '../../helpers'
import PlayStore from '../../../localMedia/playstore-icon.svg'
import AppStore from '../../../localMedia/appstore-icon.svg'
import MSRCosmosGroupLogin from '../../../localMedia/MSRcososGroup-logo.svg'
import MSRCosmosGroupLoginWhite from '../../../localMedia/FullMSRcososGroup-logo-white.svg'

const Footer: FC = () => {
  const {classes} = useLayout()
  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  return (
    <div className='footer py-2 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between minHeight50`}
      >
        {/* begin::Copyright */}
        <div className='text-dark d-none d-sm-none d-md-block'>
          {/* <span className='text-muted fw-bold me-2'> &copy; {new Date().getFullYear()}</span>
          <a href='#' className='text-gray-800 fw-semibold text-hover-primary'>
            MSRcosmos Group
          </a> */}
        </div>

        {/* end::Copyright */}
        <div className=''>
          <div className='d-flex align-items-center gap-3 pr136'>
            {/* {localStorage.getItem('role_Name') === 'employee' && ( */}
            {numArray?.includes('employee') && (
              <>
                <a
                  href='https://play.google.com/store/apps/details?id=com.msrcosmos.timesheets&pli=1'
                  target='_blank'
                >
                  <img src={PlayStore} className='footerLogo d-none d-sm-none d-md-block' />
                </a>
                <a href='https://apps.apple.com/us/app/msr-timesheets/id6451482238' target='_blank'>
                  <img src={AppStore} className='footerLogo d-none d-sm-none d-md-block' />
                </a>
              </>
            )}
            <span className='fw-semibold fs-5 text-gray-700 '>Powered By </span>
            <a href='#'>
              <img
                src={mode === 'light' ? MSRCosmosGroupLogin : MSRCosmosGroupLoginWhite}
                className='footerLogo'
              />
            </a>
          </div>
        </div>

        {/* begin::Nav */}
        {/* <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <a href='#' className='menu-link ps-0 pe-2'>
              About
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link pe-0 pe-2'>
              Contact
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link pe-0'>
              Purchase
            </a>
          </li>
        </ul> */}
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
