import React, {useState, useEffect, useRef} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
// import { KTCardBody } from '../../../../_metronic/helpers'
import {KTCardBody} from '../../../../../_metronic/helpers'
// import { DatePicker, Space } from 'antd'
import moment from 'moment'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
import clsx from 'clsx'
import EmployeePop from './EmployeePop'
// import { default as Apiservices } from '../../../../../src/common/index'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
// import DatePicker from 'react-datepicker'
import DatePicker, {Calendar, getAllDatesInRange} from 'react-multi-date-picker'
import 'react-datepicker/dist/react-datepicker.css'
import {useLocation} from 'react-router-dom'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import BackDrop from '../../../../modules/apps/timesheetentry/ComponentBackdrop'
export default function UsersLogsReports(props: any) {
  const methods = useForm()

  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)

  function handleShow(breakpoint: any) {
    setFullscreen(true)
    setShow(true)
  }
  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    props.view_open(false)
    props.setViewOpen(false)
  }
  // const { RangePicker } = DatePicker
  const [dates, setDates] = useState<any>([])
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required').nullable(),
  })
  type UserSubmitForm = {
    joining_date: Date
    work_country: string
    profileIdResponse: any
    leaving_date: string
    timesheet_approver: string
    searchcandidate: string
    qb_employee_name: string
    work_city: string
    qb_employee_id: number
    prefix: string
    record_type_status: string
    first_name: string
    name: string
    phone_number: string
    phone: string
    maxLength: any
    // adp_associate_id: number
    last_name: string
    user_status: string
    employeeId: any
    toolName: any
    orgId: any
    domain: string
    roleName: any
    org_name: any
    userStatus: any
    ecdb_user_id: number
    role: string
    email: string
    firstName: string
    full_name: string
    employee_type: string
    // user_status: string
    adp_associate_id: string
    status: string
    userId: any
    filteredMatch: any
    id: any
    employee_id: any
    candidateId: any
    org_id: any
    profileResponse: any
    work_state: string
    zipcode: string
    permanent_address: string
    work_location: string
    work_street_address: string
    work_address: string
    project_name: string
    project_type: string
    business_unit_id: any
  }
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })
  const [mainTableData, setMainTableData] = React.useState([])
  // const [dateRange, setDateRange] = useState([null, null])
  // const [startDate, endDate] = dateRange
  const [dateRange, setDateRange] = useState<any | []>([])
  const [MonthYearDynamicallyChange, setMonthYearDynamicallyChange] = useState<any>([])
  const [showBackDrop, setShowBackDrop] = useState(false)
  const intl = useIntl()
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState(false)
  const [prospectiveLocalIdData, setProspectiveLocalIdData] = useState<any[]>([])
  const [employeeTypeData, setEmployeeTypeData] = useState<any>()
  const [candidate, setCandidate] = useState<any>({})
  const [country, setCountry] = useState<any | []>([])
  const [stateName, setStateName] = useState<any | []>([])
  const [nameEmployee, setNameEmployee] = useState<any | []>([])
  const [title, setTitle] = useState<any | []>([])
  const [checkedEmail, setCheckedEmail] = useState<any | []>([])
  const [ecdbUserIdData, setEcdbUserIdData] = useState<any>()
  const [profileResponseId1, setProfileResponseId1] = useState<any | []>([])
  const [text, setText] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [disabled, setDisable] = useState(true)
  const [filteredMatch, setFilteredMatch] = useState<any>()
  const [filteredMatch1, setFilteredMatch1] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [resetvalues, setResetvalues] = useState(true)
  const [qb_disable_fields, setqbDisableFields] = useState(false)
  const [message, setMessage] = useState(false)
  const [resProspDetailsFromLocaldb, setResProspDetailsFromLocaldb] = useState(false)
  const [resEcdbDetailsFromLocaldb, setResEcdbDetailsFromLocaldb] = useState(false)
  const [searchCandidateDisable, setSearchCandidateDisable] = useState(false)
  const [flag, setFlag] = useState(false)
  const [statesId, setStatesId] = useState<any | []>([])
  const location = useLocation()
  const [selectedItem, setSelectedItem] = useState('')
  const ref: any = useRef<HTMLInputElement>(null)
  const [search_open, setSearch_Open] = useState(false)
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [candidateList1, setCandidateList1] = useState<any | []>([])
  const [select_consultant, setSelectconsultant] = useState<any | []>([])
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef7: any = useRef<HTMLInputElement>(null)
  const [DateValidation, setDateValidation] = useState(false)
  // const [error, setError] = useState(null);
  const [error, setError] = useState<any | []>([])
  const SuccessToast = (message: any) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
    })
  }
  const handleChange44 = (e: any) => {
    const name = e.target.name
    const value = e.target.value

    setFilteredMatch(e.target.value)
    setFilteredMatch1(e.target.value)
    setAccount({...account, [name]: value})
  }
  const ErrorToast = (message: any) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    })
  }

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email)
  }
  const [account, setAccount] = useState({
    // startdate: "",
    joining_date: '',
    leaving_date: '',
    // enddate: "",
    reason: '',
    leavetype: '',
  })
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })
  const [userId, setUserId] = useState([])
  const [prospUpdateId, setProspUpdateId] = useState()
  const [ECDBUpdateId, setECDBUpdateId] = useState<any>()

  const onSubmit = async (data: UserSubmitForm) => {
    setShowBackDrop(true)
    const employeeValid: any = await Apiservices.employeeId()
    let employeeIdData = employeeValid.data.data.employee_id

    setShowBackDrop(false)
  }

  const [value1, setValue1] = useState<any>([])
  const [value2, setValue2] = useState<any>([])
  const onChangeDate = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue1(newDate)
    props.setFilterDepartment(newDate)
  }
  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const onChangeDate1 = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue2(newDate)
    props.setFilterStatus(newDate)
  }
  useEffect(() => {
    get_business_units_list()
  }, [])
  const get_business_units_list = async () => {
    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        // setBusinessUnitsList(res.data.data)
        let obj = {name: 'Select All', value: 'All'}
        setBusinessUnitsList([obj, ...res.data.data])
      }
    }
  }
  const handleClick = async (e: any) => {
    setSearch_Open(false)
    setDateValidation(true)
    setFlag(true)
    setShowBackDrop(true)
    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')

    // const data: any = await Apiservices.userLogReportData({
    //     // pagenumber: currentPage,
    //     // pagesize: itemsPerPage,
    //     start_date: startDate,
    //     end_date: endDate,
    //     user_id: useStateCountryId
    // })
    // setMainTableData(data?.data?.data)
    if (dateRange?.length == 2) {
      setDateValidation(false)
      if (flag1) {
        const data: any = await Apiservices.userLogReportData({
          // pagenumber: currentPage,
          // pagesize: itemsPerPage,
          // start_date: startDate2,
          // end_date: endDate2,
          start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
          end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
          business_unit_id: business2 ? business2 : null,
          // user_id: useStateCountryId,
          // employee_type: selectedItem
        })
        setMainTableData(data?.data?.data)
      } else {
        const data: any = await Apiservices.userLogReportData({
          // pagenumber: currentPage,
          // pagesize: itemsPerPage,
          // start_date: startDate2,
          // end_date: endDate2,
          start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
          end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
          user_id: employee1 ? employee1 : null,
          business_unit_id: business2 ? business2 : null,
          // employee_type: selectedItem
        })
        setMainTableData(data?.data?.data)
      }
    }
    setShowBackDrop(false)
  }
  const [date, setDate] = useState(new Date())
  const [projectsData, setProjectsData] = useState<any | []>([])
  const [indexState, setindexState] = useState('')
  const [totalState, setTotalState] = useState('')
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)

  const searchHandleChange = async (value: any) => {
    setSearch_Open(true)
    setDateValidation(true)
    setSearch(value)
    setValue('business_unit_id', '')
    setBusinessUnit([])
    setUseStateCountryId([])
    // ref.current.clear()
    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')]
    if (dateRange?.length == 2) {
      setDateValidation(false)
      const data: any = await Apiservices.userLogReportData({
        // start_date: startDate2,
        // end_date: endDate2,
        start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
        end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
        keyword: value,
      })
      setMainTableData(data?.data?.data)
    }
  }
  const handleChange = (e: any) => {
    setSelectedItem(e.target.value)
  }
  const handleDescription = async (description: any, total: any, index: any) => {
    var today = moment(date)
    var startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD')
    var endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD')
    const res: any = await Apiservices.employeeWiseProject({
      start_date: startOfWeek,
      end_date: endOfWeek,
      user_id: description,
    })
    setProjectsData(res.data.data)

    setAnnouncementOpenPoup(true)
    setindexState(description)
    setTotalState(total)
  }
  const AnnouncePop = (value: any) => {
    setAnnouncementOpenPoup(false)
  }
  const [flag1, setFlag1] = useState(false)

  const [search, setSearch] = useState('')
  const [countries, setCountries] = useState<any | []>([])
  const [useStateCountryId, setUseStateCountryId] = useState<any | []>([])
  const [resetData1, setResetData] = useState<any | []>([])
  const resetData = async (e: any) => {
    setFlag1(true)
    setFlag(false)
    setMainTableData([])
    setDateValidation(false)
    setValue('work_city', '')
    setSelectconsultant([])
    setBusinessUnit([])
    setUseStateCountryId([])
    setValue('business_unit_id', '')
    // setDateRange([null])
    setDateRange(null)
    setMonthYearDynamicallyChange([])
    // ref.current.clear()

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    // const data: any = await Apiservices.employeereportViewData({
    //   // pagenumber: currentPage,
    //   // pagesize: itemsPerPage,
    //   start_date: dates,
    //   end_date: dates1,
    // })
    //     setMainTableData(data?.data?.data)
  }
  // const selectedCountries = async (state: any) => {
  //   setFlag1(false)
  //   // setCountries(state)
  //   setUseStateCountryId(state[0]?.id)
  //   // setResetData(state[0]?.full_name)
  // }

  // const handleCountries = async (country: any) => {
  //   if (country && country.length >= 2) {
  //     const response: any = await Apiservices.getconsultantbyname({country_name: country})
  //     console.log(response.data.data[0], 'fhgfjgjgf')
  //     setCountry(response && response.data && response.data.data ? response?.data?.data : '')
  //   }
  // }

  const handleFilterConsultant1 = async (consult_Name: any) => {
    if (consult_Name && consult_Name.length >= 2) {
      const response: any = await Apiservices.getconsultantbyname({country_name: consult_Name})
      setCandidateList1(response?.data?.data)
    }
  }
  const selectedConsultant1 = async (consult_name: any) => {
    setFlag1(false)
    setSelectconsultant(consult_name)
  }
  // const exporthandleClick = async (e: any) => {
  //   let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
  //   let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
  //   console.log('else', startDate2)
  //   // e.preventDefault();
  //   // console.log(flag3, "anilll2")
  //   // if (flag3 == true) {
  //   console.log('anilll2')
  //   const res: any = await Apiservices.exportAllUserLogs({
  //     start_date: startDate2,
  //     end_date: endDate2,
  //     user_id: useStateCountryId,
  //     keyword: search,
  //   })
  //   var link = document.createElement('a')
  //   // If you don't know the name or want to use
  //   // the webserver default set name = ''
  //   link.setAttribute('download', 'Downloaded File')
  //   link.href = res.data.data.blobUrl
  //   document.body.appendChild(link)
  //   link.click()
  //   link.remove()
  //   // }
  // }

  const exporthandleClick = async (e: any) => {
    setDateValidation(true)
    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    if (dateRange?.length == 2) {
      setDateValidation(false)
      if (search_open) {
        // if (flag3 == true) {
        const res: any = await Apiservices.exportAllUserLogs({
          // start_date: startDate2,
          // end_date: endDate2,
          start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
          end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
          // user_id: useStateCountryId,
          keyword: search,
          business_unit_id: business2 ? business2 : null,
        })
        var link = document.createElement('a')
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', 'Downloaded File')
        link.href = res.data.data.blobUrl
        document.body.appendChild(link)
        link.click()
        link.remove()
      } else {
        // if (flag3 == true) {
        const res: any = await Apiservices.exportAllUserLogs({
          // start_date: startDate2,
          // end_date: endDate2,
          start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
          end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
          user_id: employee1 ? employee1 : null,
          business_unit_id: business2 ? business2 : null,
          // keyword: search,
        })
        var link = document.createElement('a')
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', 'Downloaded File')
        link.href = res.data.data.blobUrl
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    }
  }
  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })
    }
  }
  const handleDateMulti = (date: any, val: any) => {
    let allDates: any = getAllDatesInRange(date, true)
    setDateRange(date)
    let monthYear: any = {
      d1: date[0]?.day < 10 ? '0' + date[0]?.day : date[0]?.day,
      d2: date[1]?.day < 10 ? '0' + date[1]?.day : date[1]?.day,
      // m1: date[0]?.month.number,
      // m1: date[0]?.month.name,
      m1: moment(date[0]?.month.name, 'MMMM').format('MM'),
      // m2: date[1]?.month.number,
      // m2: date[1]?.month.name,
      m2: moment(date[1]?.month.name, 'MMMM').format('MM'),
      y1: date[0]?.year,
      y2: date[1]?.year,
    }
    setDateValidation(false)
    setMonthYearDynamicallyChange(monthYear)
  }
  return (
    <>
      {announcement_open_popup ? (
        <EmployeePop
          close={AnnouncePop}
          indexState={indexState}
          totalState={totalState}
          role_name={'Admin'}
          setProjectsData={setProjectsData}
          projectsData={projectsData}
        />
      ) : (
        ''
      )}
      <BackDrop showBackDrop={showBackDrop} />
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        {/* <Modal.Header closeButton> */}
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>User Logs( User wise logs)</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        <Modal.Body className='p-3 bg-light'>
          <form onSubmit={handleSubmit(onSubmit)}>
            {loading ? (
              <>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-3'>
                    <div className='row gy-3 align-items-center py-2'>
                      {/* <div className='row gy-6 align-items-center py-4'> */}
                      <div className='col'>
                        <label className='form-label fw-semibold'>
                          Date Range <span className='fs-9'>(DD-MMM-YYYY)</span> *{' '}
                        </label>
                        {/* <DatePicker
                          placeholderText='Date Range'
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat='dd-MMM-yyyy'
                          onChange={(update: any) => {
                            setDateRange(update)
                          }}
                          isClearable={true}
                          monthsShown={2}
                          className={clsx('form-control')}
                        /> */}
                        <DatePicker
                          range
                          numberOfMonths={2}
                          dateSeparator=' - '
                          format='DD-MMM-YYYY'
                          // className={clsx('form-control')}
                          inputClass='form-control'
                          placeholder='Select Date Range'
                          value={dateRange}
                          onChange={(dateObjects: any) => {
                            handleDateMulti(dateObjects, true)
                          }}
                        />
                        {DateValidation && (
                          <span className='error text-danger mb-0'>Date Range is required</span>
                        )}
                        <div></div>
                        <div></div>
                      </div>

                      <div className='col'>
                        <label className='form-label '>Employee Name</label>
                        {/* <Typeahead
                          id='basic-typeahead-single'
                          {...register('work_country')}
                          onChange={(e) => selectedCountries(e)}
                          onInputChange={(e) => handleCountries(e)}
                          placeholder='Please Enter Employee Name'
                          options={country}
                          // selected={countries}
                          labelKey={(country: any) => user(country.full_name)}
                          ref={ref}
                        >
                          {({onClear, selected}) => (
                            <div className='rbt-aux'>
                              {!!selected.length && <ClearButton onClick={onClear} />}
                            </div>
                          )}
                        </Typeahead> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(e) => {
                            selectedConsultant1(e)
                            typeaheadRef7.current.toggleMenu()
                          }}
                          onInputChange={(e) => handleFilterConsultant1(e)}
                          options={candidateList1}
                          className='overFlowForTextField'
                          ref={typeaheadRef7}
                          placeholder='Search Consultant'
                          selected={select_consultant}
                          labelKey={(candidateList1: any) => user(candidateList1.full_name)}
                        >
                          {({onClear, selected}) => (
                            <div className='rbt-aux'>
                              {!!selected.length && <ClearButton onClick={onClear} />}
                            </div>
                          )}
                        </Typeahead>
                        <div className='invalid-feedback'>{errors.last_name?.message}</div>
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Business Unit</label>
                        {/* <select
                          // value={selectedItem}
                          className={clsx('form-select', errors.employee_type ? 'is-invalid' : '')}
                          {...register('business_unit_id')}
                          onChange={(e: any) => setValue('business_unit_id', e.target.value)}
                        >
                          <option value=''>Select Business Unit</option>
                          {BusinessUnitsList &&
                            BusinessUnitsList.length > 0 &&
                            BusinessUnitsList.map((option: any) => {
                              return (
                                <option value={option.business_unit_id} className='text-capitalize'>
                                  {option.name}
                                </option>
                              )
                            })}
                        </select> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            // setBusinessUnit(e)
                            handleBusinessUnit(e)
                            typeaheadRef4.current.toggleMenu()
                          }}
                          options={BusinessUnitsList}
                          className='overFlowForTextField'
                          ref={typeaheadRef4}
                          placeholder='Select Business Unit'
                          selected={BusinessUnit}
                          labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                          //   inputProps={{required: true}}
                        ></Typeahead>
                        <div className='invalid-feedback'>{errors.first_name?.message}</div>
                      </div>

                      <div className='col'>
                        <label className='form-label '>Search</label>
                        <input
                          type='text'
                          id='form1'
                          {...register('work_city')}
                          className='form-control'
                          // value={search}
                          placeholder='Search'
                          onChange={(e) => searchHandleChange(e.target.value)}
                        />
                        <div className='invalid-feedback'>{errors.last_name?.message}</div>
                      </div>
                      <div className='col-auto pt-8'>
                        <button className='btn btn-sm btn-primary' onClick={handleClick}>
                          Run
                        </button>
                      </div>
                      <div className='col-auto pt-8'>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm cursor-pointer '
                          id='kt_toolbar_primary_button'
                          onClick={resetData}
                        >
                          Reset
                        </button>
                      </div>
                      <div className='col-auto pt-8'>
                        <button
                          // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                          className='btn btn-light-primary   btn-sm'
                          onClick={exporthandleClick}
                        >
                          Export
                          {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>load</h1>
            )}
          </form>

          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select any one and click run to view the report
            </div>
          )}
          {flag == true && (
            <div className='pt-3'>
              <div className='card card-custom card-flush '>
                <div className='card-body p-3 py-3'>
                  <div className='table-responsive padding-down reportTab'>
                    <table
                      id='kt_table_users'
                      className='table table-row-bordered table-row-gray-300 gy-4'
                    >
                      <>
                        <thead>
                          <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                            <th className='  py-4'>S.No </th>
                            <th className='  py-4'>Business Unit </th>
                            <th className='  py-4'>Consultant Id </th>
                            <th className='  py-4'>Emp Name </th>
                            <th className='  py-4'>Last login </th>
                            <th className='  py-4'>last logout </th>
                            <th className='  py-4'>IP Address</th>
                            <th className='  py-4'>Browser/Mobile</th>
                            <th className='  py-4'>Device Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mainTableData?.length > 0 ? (
                            <>
                              {mainTableData.map((item: any, index) => {
                                let browserDetails
                                try {
                                  browserDetails = JSON.parse(item.browser_details)
                                } catch (error) {
                                  browserDetails = {}
                                }

                                return (
                                  <tr
                                    className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                    key={index}
                                  >
                                    <td className=''>{index + 1}</td>
                                    <td className=''>
                                      {item.business_unit_name ? item.business_unit_name : '-'}
                                    </td>
                                    <td className=''>
                                      {item.employee_id ? item.employee_id : '-'}
                                    </td>
                                    <td
                                      className=''
                                      style={{
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      {item.full_name ? item.full_name : '-'}
                                    </td>
                                    {item.login_time == null && (
                                      <td className=''>{item.login_time ? '-' : '-'}</td>
                                    )}
                                    {item.login_time !== null && (
                                      <td className=''>
                                        {moment(item.login_time).format('DD-MMM-YYYY HH:mm:ss ') +
                                          ' GMT'}
                                      </td>
                                    )}
                                    {item.logout_time == null && (
                                      <td className=''>{item.logout_time ? '-' : '-'}</td>
                                    )}
                                    {item.logout_time !== null && (
                                      <td className=''>
                                        {/* {moment(item.logout_time).format('DD/MM/YYYY HH:mm:ss A')} */}
                                        {moment(item.logout_time).format('DD-MMM-YYYY HH:mm:ss ') +
                                          ' GMT'}
                                      </td>
                                    )}

                                    <td className=''>{item.ip_address ? item.ip_address : '-'}</td>
                                    {/* <td className=''>
                                                                                {item.browser_details ? item.browser_details : '-'}
                                                                            </td> */}

                                    <td>
                                      {browserDetails?.name
                                        ? browserDetails?.name + '-' + browserDetails?.version
                                        : '-'}
                                    </td>

                                    <td>{browserDetails?.os ? browserDetails?.os : '-'}</td>
                                  </tr>
                                )
                              })}
                            </>
                          ) : (
                            ''
                          )}
                        </tbody>
                      </>
                    </table>
                    {(mainTableData?.length == null || mainTableData?.length == 0) && (
                      <div className='text-center  fw-bold text-gray-800'>No Records found </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
