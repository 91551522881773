import clsx from 'clsx'
import React, {FC, useState, useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {ChooseOrganizationHeader} from '../../../../_metronic/partials/layout/theme-mode/ChooseOrganizationHeader'

import {useLayout} from '../../core'
import {default as Apiservices} from '../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'

import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar = (props: any) => {
  const [user, setUser] = useState<any>()
  const [email, setEmail] = useState<any>(localStorage.getItem('user_email'))
  const [disableQuickBooks, setDisableQuickBooks] = useState(false)

  const docResultDataRes11 = props.docResultDataRes

  const getOrganizationSetting = async () => {
    let get_org_id = localStorage.getItem('org_id')
    const res: any = await Apiservices.getOrganizationSetting({org_id: get_org_id})
    if (res && res?.data?.isError == false) {
      if (res && res.data && res.data.data) {
        setDisableQuickBooks(
          res &&
            res?.data &&
            res?.data?.data &&
            res?.data?.data[0] &&
            res?.data?.data[0]?.enable_quickbooks
        )
        localStorage.setItem('enable_quickbooks', res && res?.data?.data[0]?.enable_quickbooks)
        localStorage.setItem('subsidary_name', res && res?.data?.data[0]?.subsidary_name)
        localStorage.setItem('enable_email', res && res?.data?.data[0]?.enable_email)
      }
    }
  }

  const getOrganizationSetting1 = async () => {
    let userEmail = localStorage.getItem('user_email')
    const res: any = await Apiservices.getMobileForceUpdateAccessbyEmail({
      email: userEmail,
    })
    if (res?.data?.data?.email) {
      localStorage.setItem('MobileForceUpdateAccessbyEmail', 'true')
    } else {
      localStorage.setItem('MobileForceUpdateAccessbyEmail', 'false')
    }

    // if (res?.data?.isError == false) {
    //   setDisableQuickBooks(res.data.data[0].enable_quickbooks)
    //   localStorage.setItem('enable_quickbooks', res.data.data[0].enable_quickbooks)
    // }
  }

  useEffect(() => {
    if (localStorage.getItem('org_id')) {
      getOrganizationSetting()
    }
    if (localStorage.getItem('user_email')) {
      if (localStorage.getItem('Authorization')) {
        getOrganizationSetting1()
      }
    }
  }, [])
  const userID = localStorage.getItem('userid')
  // const userEmail = localStorage.getItem('user_email')
  useEffect(() => {
    if (userID) {
      viewUser()
    }
  }, [])
  const viewUser = async () => {
    const data: any = await Apiservices.getuserbyid({id: userID})
    localStorage.setItem('business_unit_id', data?.data?.data?.business_unit_id)
    setUser(data?.data?.data?.full_name)
  }
  const gettingUser = async (user: any) => {
    setUser(user)
  }

  const {config} = useLayout()
  return (
    <>
      <div className='d-flex align-items-stretch flex-shrink-0'>
        {/* {(localStorage.getItem('role_Name') === 'admin' ||
          localStorage.getItem('role_Name') === 'accounts') && (
          <>
            {localStorage.getItem('refresh_Token') &&
              localStorage.getItem('refresh_Token') != 'false' && (
                <>
                  {localStorage.getItem('enable_quickbooks') &&
                    localStorage.getItem('enable_quickbooks') == 'true' && (
                      <>
                        <div className={clsx('d-flex align-items-center')}>
                          <div className='error text-danger mb-0 px-2'>Quick books not enabled</div>  //not required now
                        </div>
                      </>
                    )}
                </>
              )}
          </>
        )} */}
        {/* begin::Theme mode */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ChooseOrganizationHeader
            text={'text'}
            toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
            docResultDataRes11={docResultDataRes11}
            getLogoDetails={props.getLogoDetails}
            gettingUser={gettingUser}
            widgetReload={props.widgetReload}
          />
        </div>
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher
            toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
          />
        </div>
        {/* end::Theme mode */}
        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            {/* <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='metronic' /> */}
            <div className='userName bg-light fw-semibold fs-5 text-primary p-3 text-center rounded-3 text-uppercase '>
              {user
                ? user?.substring(0, 1) +
                  user?.substring(user.indexOf(' ') + 1, user.indexOf(' ') + 2)
                : email?.substring(0, 1)}
            </div>
          </div>
          <HeaderUserMenu user={user} widgetLogout={props.widgetLogout} />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
        {/* begin::Aside Toggler */}
        {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
      </div>
    </>
  )
}

export {Topbar}
