import React, {useState, useEffect, useMemo} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import {useForm} from 'react-hook-form'
import clsx from 'clsx'
import DatePicker1 from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import {useTable, ColumnInstance} from 'react-table'
import {ListProjects} from '../../../../modules/apps/user-management/users-list/table/columns/_columns'
import moment from 'moment'
import DatePicker, {DateObject} from 'react-multi-date-picker'
type FormData = {
  vacation_type: any
  start_date: any
}
export default function MonthlyHoursSetting(props: any) {
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => ListProjects, [])
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [selectYear, setSelectYear] = useState<any>(new Date())
  const [monthNames, setMonthNames] = useState<any>([])
  const isLoading = useQueryResponseLoading()
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const [MonthlyData, setMonthlyData] = useState<any | []>([])
  const [MonthlyData1, setMonthlyData1] = useState<any | []>([])

  const {
    register: register,
    setValue: setValue,
    reset: reset,
    getValues: getValues,
    handleSubmit: handleSubmit,
    formState: {errors: errors},
  } = useForm<FormData>()

  const handleClose = () => {
    setShow(false)
    setFullscreen(false)
    props.close(false)
    props.setShow(false)
  }
  useEffect(() => {
    getallmonthlyhours()
    setSelectYear(selectYear)
  }, [])

  const getallmonthlyhours = async () => {
    const AllMonths = [
      {
        month_name: 'January  ',
        month: '1',
      },
      {
        month_name: 'February ',
        month: '2',
      },
      {
        month_name: 'March    ',
        month: '3',
      },
      {
        month_name: 'April    ',
        month: '4',
      },
      {
        month_name: 'May      ',
        month: '5',
      },
      {
        month_name: 'June     ',
        month: '6',
      },
      {
        month_name: 'July     ',
        month: '7',
      },
      {
        month_name: 'August   ',
        month: '8',
      },
      {
        month_name: 'September',
        month: '9',
      },
      {
        month_name: 'October  ',
        month: '10',
      },
      {
        month_name: 'November ',
        month: '11',
      },
      {
        month_name: 'December ',
        month: '12',
      },
    ]
    setMonthNames([...AllMonths])
    const data: any = await Apiservices.get_all_monthly_hours_settings({
      year: moment(selectYear).format('YYYY'),
    })
    if (data && data.status == 200) {
      // setMonthlyData(data?.data?.data)
      let dummy_array: any = []
      for (let i = 0; i < AllMonths?.length; i++) {
        let dummy_data: any = {
          month_name: AllMonths[i].month_name,
          inactive_days: null,
          month: AllMonths[i].month,
        }
        dummy_array.push(dummy_data)
      }
      let Total_data = data?.data?.data?.length > 0 ? data?.data?.data : dummy_array

      let a1 = Total_data.map((test: any, index: any) => {
        let weekends: any = []
        // Start with the first day of the month
        let date = new Date(2025, Number(test.month) - 1, 1) // month - 1 because JavaScript months are 0-indexed
        // Get the last day of the month
        let lastDayOfMonth = new Date(2025, test.month, 0) // last day of the given month

        // Loop through all the days of the month
        while (date <= lastDayOfMonth) {
          if (date.getDay() === 0 || date.getDay() === 6) {
            const newDate = new Date(date) // Clone the current date
            newDate.setDate(newDate.getDate() + 1)
            // 6 = Saturday, 0 = Sunday
            // Add the date in YYYY-MM-DD format
            weekends.push(newDate.toISOString().split('T')[0])
          }
          // Move to the next day
          date.setDate(date.getDate() + 1)
        }
        return {
          month_name: test.month_name,
          inactive_days: test.inactive_days ? test.inactive_days : weekends,
        }
      })
      setMonthlyData(a1)
      let AllMonthList = data?.data?.data
      let ProjDetailsArray = []
      for (var i = 0; i < AllMonths?.length; i++) {
        let ProjObj: any = {
          month: AllMonths[i].month,
          inactive_days:
            AllMonthList && AllMonthList[i]?.inactive_days
              ? AllMonthList && AllMonthList[i]?.inactive_days
              : [],
        }
        ProjDetailsArray.push(ProjObj)
      }
      setMonthlyData1([...ProjDetailsArray])
    }
  }

  const handleProjectStartDate = async (e: any, date: any, month: any, index: any) => {
    MonthlyData1[index].inactive_days = date.validatedValue
    setMonthlyData1([...MonthlyData1])
  }
  const handleChange = async () => {
    let org_id = localStorage.getItem('org_id')
    const article = {
      org_id: [Number(org_id)],
      year: moment(selectYear).format('YYYY'),
      month_data: MonthlyData1,
    }
    const response: any = await Apiservices.reset_yearly_monthly_hours_setting(article)
    if (response.isError == false) {
      toast.success(response.message)
    }
  }
  const singleDateSelection = async (date: any) => {
    let Year: any = moment(date).format('YYYY')
    const AllMonths = [
      {
        month_name: 'January  ',
        month: '1',
      },
      {
        month_name: 'February ',
        month: '2',
      },
      {
        month_name: 'March    ',
        month: '3',
      },
      {
        month_name: 'April    ',
        month: '4',
      },
      {
        month_name: 'May      ',
        month: '5',
      },
      {
        month_name: 'June     ',
        month: '6',
      },
      {
        month_name: 'July     ',
        month: '7',
      },
      {
        month_name: 'August   ',
        month: '8',
      },
      {
        month_name: 'September',
        month: '9',
      },
      {
        month_name: 'October  ',
        month: '10',
      },
      {
        month_name: 'November ',
        month: '11',
      },
      {
        month_name: 'December ',
        month: '12',
      },
    ]
    setMonthNames([...AllMonths])
    setSelectYear(date)
    setMonthlyData([])
    const data: any = await Apiservices.get_all_monthly_hours_settings({
      year: moment(date).format('YYYY'),
    })
    if (data && data?.status == 200) {
      // setMonthlyData(data?.data?.data)
      let dummy_array: any = []
      for (let i = 0; i < AllMonths?.length; i++) {
        let dummy_data: any = {
          month_name: AllMonths[i].month_name,
          inactive_days: null,
          month: AllMonths[i].month,
        }
        dummy_array.push(dummy_data)
      }
      let Total_data = data?.data?.data?.length > 0 ? data?.data?.data : dummy_array

      let a1 = Total_data.map((test: any, index: any) => {
        let weekends: any = []
        // Start with the first day of the month
        let date = new Date(Year, Number(test.month) - 1, 1) // 0 - Jan 1st

        // Get the last day of the month
        let lastDayOfMonth = new Date(Year, Number(test.month), 0) // 0+1 - 1 -Jan 31st

        // Loop through all the days of the month
        while (date <= lastDayOfMonth) {
          if (date.getDay() === 0 || date.getDay() === 6) {
            // 6 = Saturday, 0 = Sunday
            const newDate = new Date(date) // Clone the current date
            //Adding next day (1 day getting update slow)
            newDate.setDate(newDate.getDate() + 1)
            // let a = date.toISOString().split('T')[0]
            weekends.push(newDate.toISOString().split('T')[0])
          }
          // Move to the next day
          date.setDate(date.getDate() + 1)
        }
        return {
          month_name: test.month_name,
          inactive_days: test.inactive_days ? test.inactive_days : weekends,
        }
      })
      setMonthlyData(a1)
      let AllMonthList = data.data.data
      let ProjDetailsArray = []
      for (var i = 0; i < AllMonths?.length; i++) {
        let ProjObj: any = {
          month: AllMonths[i].month,
          inactive_days:
            AllMonthList && AllMonthList[i]?.inactive_days
              ? AllMonthList && AllMonthList[i]?.inactive_days
              : [],
        }
        ProjDetailsArray.push(ProjObj)
      }
      setMonthlyData1([...ProjDetailsArray])
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // size='lg'
        // fullscreen={fullscreen}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        {props.view_open && (
          <div className='col-auto'>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={props.cancel}
            ></button>
          </div>
        )}
        <Modal.Header>
          <Modal.Title>Monthly Hours Setting</Modal.Title>
          <button
            type='button'
            className='btn-close'
            onClick={props.monthlySettingClose}
            data-bs-dismiss='modal'
            aria-label='Close'
          ></button>
        </Modal.Header>
        <Modal.Body>
          <>
            {/* <p className='text-center text-gray-800 fs-5 fw-bold text-transform-unset'>
            Monthly Hours Setting
          </p> */}

            <div className=' d-flex justify-content-start align-items-center'>
              <label className='form-label pe-2 '> Year</label>
              <div className='w-25'>
                <DatePicker1
                  selected={selectYear}
                  onChange={(date: any) => singleDateSelection(date)}
                  placeholderText='Select Year'
                  className={clsx('form-control ')}
                  dateFormat='yyyy'
                  showYearPicker
                  required
                />
              </div>
            </div>

            <div className='text-gray-600 fw-semibold text-transform-unset pt-3'>
              <span className=' fw-regular text-gray-800 fw-bold text-nowrap'>Note : </span>
              Please Select Inactive Days in each month
            </div>
            <div className='table-responsive pt-3'>
              <table
                id='kt_table_users'
                className='table table-row-bordered table-row-gray-200 gy-2 gs-4 '
                {...getTableProps()}
              >
                <tbody className='' {...getTableBodyProps()}>
                  {monthNames && monthNames?.length > 0
                    ? // ? MonthlyData.map((user: any, index: number) => {
                      monthNames.map((user: any, index: number) => {
                        let proj =
                          MonthlyData?.length > 0 &&
                          MonthlyData.filter((test: any) => test.month_name == user.month_name)
                        return (
                          <tr
                            key={index}
                            className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                          >
                            <td className=''>{user.month_name ? user.month_name : '-'}</td>
                            <td className=''>
                              <DatePicker
                                multiple
                                value={proj && proj[0] && proj[0]?.inactive_days}
                                currentDate={
                                  new DateObject({
                                    year: new Date(selectYear).getFullYear(),
                                    month: user.month,
                                    day: 1,
                                    format: 'YYYY-MM-DD',
                                  })
                                }
                                onChange={(e: any, update: any) => {
                                  handleProjectStartDate(e, update, user.month, index)
                                }}
                                inputClass='form-control'
                                minDate={
                                  `${new Date(selectYear).getFullYear()}-` + `${user.month}` + '-1'
                                }
                                maxDate={
                                  `${new Date(selectYear).getFullYear()}-` +
                                  `${user.month}` +
                                  `-${new Date(2023, user.month, 0).getDate()}`
                                }
                                format='YYYY-MM-DD'
                                arrow={false}
                                months={user.month}
                              />
                            </td>
                          </tr>
                        )
                      })
                    : ''}
                </tbody>
              </table>
            </div>
            <div className='d-flex justify-content-end  py-1 '>
              <div className='d-flex gap-3'>
                <button
                  type='submit'
                  className='btn btn-sm btn-primary'
                  onClick={() => handleChange()}
                >
                  Submit
                </button>
              </div>
              <div>
                <button className='btn btn-sm btn-danger' onClick={props.monthlySettingClose}>
                  Cancel
                </button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  )
}
