import React, {useMemo, useState, useEffect, useContext, useRef} from 'react'
import ThingsContext from '../../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../../modules/apps/masters/commonicons/ValidateContext'
import {default as Apiservices} from '../../../../common/index'
import {useIntl} from 'react-intl'
import ReactPaginate from 'react-paginate'
import {useForm} from 'react-hook-form'
import {Container, Row, Col} from 'react-bootstrap'
import 'reactjs-popup/dist/index.css'
import clsx from 'clsx'
import {KTCardBody} from '../../../../_metronic/helpers'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import moment from 'moment'
import {UsersListPagination} from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Chart from 'react-apexcharts'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Tooltip} from '../../../modules/apps/timesheetentry/ComponentTooltip'
import Modal from 'react-bootstrap/Modal'

type FormData = {
  client: string
  dba: string
  ccdb_id: string
  qb_client_name: string
  qb_client_id: string
  id: number
  user_id: number
  user: number
  select_Year: any
  org_name: any
  employee_type: string
  project_type: string
  work_city: string
}

const UtilizationComplianceReports = (props: any) => {
  const {
    register,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>()

  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [order8, setOrder8] = useState('ASC')
  const [order10, setOrder10] = useState('ASC')
  const [order12, setOrder12] = useState('ASC')
  const [order7, setOrder7] = useState('ASC')
  const [sort, setSort] = useState<any | []>(false)
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)
  const intl = useIntl()
  const [validatedescription, setValidateDescription] = useState<any>(false)
  const [perPage, setPerPage] = useState<any>(10)
  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    // props.view_open(false)
    // props.setViewOpen(false)
  }
  const [page, setPage] = useState(1)
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [pages, setPages] = useState(1)
  // const [selected, setSelected] = useState([])
  const [selectConsultant, setSelectConsultant] = useState([])
  const [selectConsultant1, setSelectConsultant1] = useState([])
  const [select_consultant, setSelectconsultant] = useState<any | []>([])
  const typeaheadRef: any = useRef<HTMLInputElement>(null)
  const typeaheadRef1: any = useRef<HTMLInputElement>(null)
  const typeaheadRef2: any = useRef<HTMLInputElement>(null)
  const typeaheadRef3: any = useRef<HTMLInputElement>(null)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)
  const typeaheadRef7: any = useRef<HTMLInputElement>(null)
  const typeaheadRef8: any = useRef<HTMLInputElement>(null)
  const [ComplianceBusinessUnit, setComplianceBusinessUnit] = useState<any | []>([])
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnitsList2, setBusinessUnitsList2] = useState<any | []>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [country1, setCountry1] = useState<any | []>([])
  const [candidateList, setCandidateList] = useState<any | []>([])
  // ref.current.close()
  const [ProjectReportCountData, setProjectReportCountData] = useState<any | []>([])
  const [ProjectReportProjectsData, setProjectReportProjectsData] = useState<any | []>([])
  const [search, setSearch] = useState('')
  const [date, setDate] = useState('')
  const [selectYear, setSelectYear] = useState<any>(new Date())
  const [selectYear1, setSelectYear1] = useState<any>(new Date())
  const [OrganizationReportData, setOrganizationReportData] = useState<any | []>([])
  const [ProjectReportData, setProjectReportData] = useState<any | []>([])
  const [ProjectReportWithoutPaginationTotalData, setProjectReportWithoutPaginationTotalData] =
    useState<any | []>([])
  const [org_name, setOrgName] = useState('')
  const [candidate, setCandidate] = useState<any | []>([])
  const [selectMonth, setSelectMonth] = useState<any>([])
  const [countries1, setCountries1] = useState<any | []>([])
  const [flag, setFlag] = useState(false)
  const [selectProjectType, setSelectProjectType] = useState<any>([])
  const [AllOptions, setAllOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ])

  const [AllOptions1, setAllOptions1] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
    {
      label: 'Deselect All',
      value: 'empty',
    },
  ])
  const [AllMonthOptions, setAllMonthOptions] = useState<any | []>([
    // {
    //   label: 'Select All',
    //   value: 'All',
    // },
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
    {
      label: 'Deselect All',
      value: 'empty',
    },
  ])
  const [AllProjectTypeOptions, setAllProjectTypeOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'Placement',
      value: '1',
    },
    {
      label: 'Bench',
      value: '2',
    },
  ])
  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }

  const tokenHeader1: any = useContext(ValidContext)
  const renderThings1 = async () => {
    tokenHeader1.getValidate()
  }

  useEffect(() => {
    renderThings()
    AllOrgList()
    // handleConsultant()
    setSelectYear(selectYear)
    // setSelectYear1(selectYear1)
    setSelectYear1(new Date(moment().subtract(1, 'month').format('YYYY')))
    // setSelectYear(new Date(moment().subtract(1, 'month').format('YYYY')))
    const MonthNumberDefault = [
      {
        label: moment(new Date()).subtract(1, 'month').format('MMMM'),
        value: moment(new Date()).subtract(1, 'month').format('M'),
      },
    ]
    setSelectMonth(MonthNumberDefault)
    // let currYr: any = new Date().getFullYear()
    // setSelectYear(currYr)
    // let pastYr = 2013
    // while (currYr >= pastYr) {
    //   years.push(currYr)
    //   currYr -= 1
  }, [])
  useEffect(() => {
    // get_business_units_list()
    getAllProjects()
  }, [])
  const getAllProjects = async () => {
    const res: any = await Apiservices.getprojectbyname({})
    // if (res.data.isError == false) {
    //   console.log(res.data.data.length, 'res')
    //   setProjectReportCountData(res.data.data.length)
    // }
  }
  const get_business_units_list = async () => {
    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      setBusinessUnitsList(res.data.data)
    }
  }
  const handleConsultant = async (consult_Name?: any) => {
    const response: any = await Apiservices.getconsultantbyname()
    setCandidate(response?.data?.data)
  }
  const AllOrgList = async () => {
    setShowBackDrop(true)
    let a = AllOptions1.filter(
      (consult: any) => consult.value == 'W2' || consult.value == 'C2C' || consult.value == '1099'
    )
    setSelectConsultant1(a)
    // const res: any = await Apiservices.get_business_units_list()
    // if (res && res.status == 200) {
    //   if (res.data.data.length > 0) {
    //     // setBusinessUnitsList(res.data.data)
    //     let obj = {name: 'Deselect All', value: 'empty'}
    //     let obj2 = {name: 'Select All', value: 'All'}

    //     setBusinessUnitsList([obj, ...res.data.data])
    //     setBusinessUnitsList2([obj2, ...res.data.data])
    //   }
    // }

    let y = JSON.parse(localStorage.getItem('bu_list') || '[]')

    let n = y.filter(
      (element: any) =>
        element.roleName?.toLowerCase() == 'accounts' ||
        element.roleName?.toLowerCase() == 'approver'
      // element.roleName?.toLowerCase() == 'timesheetadmin'
    )
    let business: any = localStorage.getItem('role_Name')
    let numArray: any = business?.split(',')
    let uniqueArr: any = []

    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        let obj = {name: 'Deselect All', value: 'empty'}
        let obj2 = {name: 'Select All', value: 'All'}

        if (numArray?.includes('admin') || numArray?.includes('timesheetadmin')) {
          let x = res.data.data.filter((test: any) => uniqueArr.push(test))
          setBusinessUnitsList([obj, ...uniqueArr])
          setBusinessUnitsList2([obj2, ...uniqueArr])
        } else {
          for (let each of res.data.data) {
            for (let item of n) {
              if (each.business_unit_id == item.buId) {
                uniqueArr.push(each)
              }
            }
          }
          setBusinessUnitsList([obj, ...uniqueArr])
          setBusinessUnitsList2([obj2, ...uniqueArr])
        }
      }
    }

    // let business1 = res?.data?.data?.map((val: any) => {
    //   return val.business_unit_id
    // })
    let business1 = uniqueArr?.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1?.filter((temp: any) => `${temp}`).join(',')

    let stat1 = a.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    // setComplianceBusinessUnit(res?.data?.data)
    setComplianceBusinessUnit(uniqueArr)

    const article1 = {
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      // year: selectYear ? moment(selectYear).subtract(1, 'month').format('YYYY') : '',
      month: [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: null,
      user_id: null, // optional
      project_id: null, // optional
      is_placement_project: null,
      business_unit_id: null,
    }

    const article2 = {
      // year: selectYear ? moment(selectYear).format('YYYY') : '',
      // year: selectYear ? moment(selectYear).subtract(1, 'month').format('YYYY') : '',
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      // business_unit_id: null,
      business_unit_id: business2 ? business1 : '',
      employee_type: stat2 ? stat1 : null,
    }
    const response1: any = await Apiservices.get_yearly_utilization_report(article2) //for graph
    // const response: any = await Apiservices.get_project_wise_utilization_report(article1) //for table
    // if (response && response.isError == false) {
    //   setProjectReportData(response.data)
    // }
    if (response1 && response1.isError == false) {
      // let orgReport = response1.data //with business data
      let test = response1.total_data // without business unit
      let AllData = [test]

      if (response1 && response1.data?.length > 0) {
        for (let each of business1) {
          let x = response1.data.filter((temp: any) => temp.business_unit_id == each)
          AllData.push(x)
        }
      }
      let finalData = []
      for (let each of AllData) {
        let obj: any = {}
        let data1: any = []
        for (let item of each) {
          obj.name = item.business_unit_name
            ? item.business_unit_name
            : localStorage.getItem('org_name')
          obj.color = item.color ? item.color : '#0000ff'
          data1.push(item.monthly_utilization_percent)
        }
        obj.data = data1
        finalData.push(obj)
      }
      let AllMonths = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      setOrganizationReportData({
        // options: {
        //   chart: {
        //     height: 350,
        //     type: 'line',
        //     toolbar: {
        //       show: false,
        //     },
        //     zoom: {
        //       enabled: false,
        //     },
        //   },
        //     legend: {
        //     position: 'bottom',
        //     horizontalAlign: 'center',
        //     offsetX: 40,
        //     itemMargin: {
        //       horizontal: 10,
        //     },
        //   },
        //   xaxis: {
        //     title: {
        //       text: 'Month',
        //       floating: false,
        //     },
        //     categories: AllMonths,
        //   },
        //   // dataLabels: {
        //   //   enabled: true,
        //   // },

        //   dataLabels: {
        //     enabled: true,
        //     enabledOnSeries: undefined,
        //     formatter: function (val: any, opts: any) {
        //       return val
        //     },
        //     style: {
        //       fontSize: '10px',
        //       fontFamily: 'Helvetica, Arial, sans-serif',
        //       fontWeight: 'bold',
        //       colors: undefined,
        //     },
        //   },
        //   stroke: {
        //     curve: 'straight',
        //   },
        //   markers: {
        //     size: 1,
        //     left:-10
        //   },

        //   // title: {
        //   //   text: 'Utilization graphs',
        //   //   align: 'left',
        //   // },
        //   yaxis: {
        //     title: {
        //       text: '% Utilization',
        //       style: {
        //         fontSize: '15px',
        //         fontWeight: 600,
        //       },
        //     },
        //   },
        //   tooltip: {
        //     theme: false,
        //   },
        // },
        // series: [
        //   {
        //     name: '% Utilization',
        //     type: 'line',
        //     data: orgReport.map((item: any) => item.monthly_utilization_percent),
        //   },
        // ],
        // options: {
        //   chart: {
        //     height: 350,
        //     type: 'line',
        //     toolbar: {
        //       show: false,
        //     },
        //     zoom: {
        //       enabled: false,
        //     },
        //   },
        //   legend: {
        //     position: 'bottom',
        //     horizontalAlign: 'center',
        //     offsetX: 40,
        //     itemMargin: {
        //       horizontal: 10,
        //     }
        //   },
        //   xaxis: {
        //     title: {
        //       text: 'Month',
        //       floating: false,
        //     },
        //     categories: AllMonths,
        //   },
        //   dataLabels: {
        //     enabled: true,
        //     enabledOnSeries: undefined,
        //     formatter: function (val:any, opts:any) {
        //       return val;
        //     },
        //     style: {
        //       fontSize: '10px',
        //       fontFamily: 'Helvetica, Arial, sans-serif',
        //       fontWeight: 'bold',
        //       colors: undefined,
        //     },
        //   },
        //   stroke: {
        //     curve: 'straight',
        //   },
        //   markers: {
        //     size: 12, // Set the size of the markers
        //     colors: ['#0000FF'], // Set the background color of the markers
        //     strokeColors: '#FFFFFF', // Set the border color of the markers
        //     strokeWidth: 0, // Set the border width of the markers
        //     radius: 10, // Set the border radius of the markers
        //     left: -5, // Move the markers to the left by -5px
        //     hover: {
        //       size: 7, // Size of the marker when hovered
        //     }
        //   },
        //   yaxis: {
        //     title: {
        //       text: '% Utilization',
        //       style: {
        //         fontSize: '15px',
        //         fontWeight: 600,
        //       },
        //     },
        //   },
        //   tooltip: {
        //     theme: false,
        //   },
        // },

        options: {
          chart: {
            height: 350,
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 40,
            itemMargin: {
              horizontal: 12,
            },
            markers: {
              width: 10,
              height: 10,
              offsetX: -7, // Move the markers to the left
            },
          },
          xaxis: {
            title: {
              text: 'Month',
              floating: false,
            },
            categories: AllMonths,
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            formatter: function (val: any, opts: any) {
              return val
            },
            style: {
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 'bold',
              colors: undefined,
            },
          },
          stroke: {
            curve: 'straight',
          },
          yaxis: {
            title: {
              text: '% Utilization',
              style: {
                fontSize: '15px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            theme: false,
          },
        },
        series: finalData,
      })
    }
    setShowBackDrop(false)
  }

  const yearSelect = (year: any) => {
    setSelectYear(year)
    setDate('')
  }
  //To get all organizations
  // const OrganizationList = async () => {
  //   const data: any = await Apiservices.getOrganizationsList()
  //   if (data?.data?.isError == false) {
  //     setOrgList(data.data.data)
  //   }
  // }

  const handleClick = async () => {
    // setFlag(true)
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')

    let stat1 = selectConsultant1.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    const MonthNumberDefault = [
      {
        label: moment(new Date()).subtract(1, 'month').format('MMMM'),
        value: moment(new Date()).subtract(1, 'month').format('M'),
      },
    ]
    let business1 = ComplianceBusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    setSelectMonth(MonthNumberDefault)
    // setBusinessUnit(ComplianceBusinessUnit)
    setSelectConsultant([])
    setValue('project_type', '')
    setSelectconsultant([])
    setCountries1([])

    setShowBackDrop(true)
    const article1 = {
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      month: [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: null,
      user_id: null, // optional
      project_id: null, // optional
      is_placement_project: null,
      business_unit_id: business2 ? business1 : '',
    }
    const article2 = {
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      business_unit_id: business2 ? business1 : '',
      employee_type: stat2 ? stat1 : null,
    }
    const response1: any = await Apiservices.get_yearly_utilization_report(article2) //for graph
    // const response: any = await Apiservices.get_project_wise_utilization_report(article1) //for table

    // if (response && response.isError == false) {
    //   setProjectReportData(response.data)
    // }
    if (response1 && response1.isError == false) {
      // let orgReport = response1.data //with business data
      let test = response1.total_data // without business unit
      let AllData = [test]

      if (response1 && response1.data?.length > 0) {
        for (let each of business1) {
          let x = response1.data.filter((temp: any) => temp.business_unit_id == each)
          AllData.push(x)
        }
      }
      let finalData = []
      for (let each of AllData) {
        let obj: any = {}
        let data1: any = []
        for (let item of each) {
          obj.name = item.business_unit_name
            ? item.business_unit_name
            : localStorage.getItem('org_name')
          obj.color = item.color ? item.color : '#0000ff'
          data1.push(item.monthly_utilization_percent)
        }
        obj.data = data1
        finalData.push(obj)
      }
      let AllMonths = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]

      setOrganizationReportData({
        options: {
          chart: {
            height: 350,
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 40,
            itemMargin: {
              horizontal: 12,
            },
            markers: {
              width: 10,
              height: 10,
              offsetX: -7, // Move the markers to the left
            },
          },
          xaxis: {
            title: {
              text: 'Month',
              floating: false,
            },
            categories: AllMonths,
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            formatter: function (val: any, opts: any) {
              return val
            },
            style: {
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 'bold',
              colors: undefined,
            },
          },
          stroke: {
            curve: 'straight',
          },
          yaxis: {
            title: {
              text: '% Utilization',
              style: {
                fontSize: '15px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            theme: false,
          },
        },
        // series: [
        //   {
        //     name: '% Utilization',
        //     type: 'line',
        //     data: orgReport.map((item: any) => item.monthly_utilization_percent),
        // },
        // ],

        series: finalData,
      })
    }
    setShowBackDrop(false)
  }
  const resetData1 = async (e: any) => {
    setFlag(false)
    setValue('employee_type', '') // --
    setSelectConsultant1([]) // consultant
    setSelectConsultant([])
    setComplianceBusinessUnit([]) //business
    setBusinessUnit([])
    setOrganizationReportData([])
    setSelectYear(null) //year
    setSelectconsultant([]) // emp
    setSelectMonth([]) // Month
    setSelectProjectType([]) // proj type
    setProjectReportData([])
    // typeaheadRef.current.clear()
    // typeaheadRef1.current.clear() // Month
    setCountries1([]) //project
    let consultantReset = AllOptions1.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions1.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let consultantReset1 = AllOptions1.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions1.splice(
        AllOptions1.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let consultantReset2 = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset2) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    let consultantReset3 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset3) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset2 = BusinessUnitsList2.find((item: any) => item.value == 'All')
    if (!businessReset2) {
      BusinessUnitsList2.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let businessReset3 = BusinessUnitsList2.find((item: any) => item.value == 'empty')
    if (businessReset3) {
      BusinessUnitsList2.splice(
        BusinessUnitsList2.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let monthReset2 = AllMonthOptions.find((item: any) => item.value == 'empty')
    if (monthReset2) {
      AllMonthOptions.splice(
        AllMonthOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectReset1) {
      AllProjectTypeOptions.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let projectReset2 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectReset2) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }

  const resetData2 = async (e: any) => {
    setFlag(false)
    setSearch('')
    setValue('employee_type', '') // --
    setValue('work_city', '')
    setSelectConsultant([])
    setBusinessUnit([])
    setSelectYear1(null) //year
    setSelectconsultant([]) // emp
    setSelectMonth([]) // Month
    setSelectProjectType([]) // proj type
    setProjectReportData([])
    // typeaheadRef.current.clear()
    // typeaheadRef1.current.clear() // Month
    setCountries1([]) //project

    let consultantReset2 = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset2) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    let consultantReset3 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset3) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset2 = BusinessUnitsList2.find((item: any) => item.value == 'All')
    if (!businessReset2) {
      BusinessUnitsList2.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let businessReset3 = BusinessUnitsList2.find((item: any) => item.value == 'empty')
    if (businessReset3) {
      BusinessUnitsList2.splice(
        BusinessUnitsList2.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let monthReset2 = AllMonthOptions.find((item: any) => item.value == 'empty')
    if (monthReset2) {
      AllMonthOptions.splice(
        AllMonthOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectReset1) {
      AllProjectTypeOptions.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let projectReset2 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectReset2) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }
  const handleChange1 = async (value: any) => {
    setSearch(value)
    // setSearch_Open(true)
    // setSelectConsultant([])
    // setBusinessUnit([])
    // setSelectProjectType([])
    // setSelectconsultant([])
    // setCountries1([])

    let consultantReset2 = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset2) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    let consultantReset3 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset3) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset2 = BusinessUnitsList2.find((item: any) => item.value == 'All')
    if (!businessReset2) {
      BusinessUnitsList2.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let businessReset3 = BusinessUnitsList2.find((item: any) => item.value == 'empty')
    if (businessReset3) {
      BusinessUnitsList2.splice(
        BusinessUnitsList2.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let monthReset2 = AllMonthOptions.find((item: any) => item.value == 'empty')
    if (monthReset2) {
      AllMonthOptions.splice(
        AllMonthOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectReset1) {
      AllProjectTypeOptions.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let projectReset2 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectReset2) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)

    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    // const article2 = {
    //   year: selectYear ? moment(selectYear).format('YYYY') : '',
    //   month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
    //   employee_type: stat2 ? stat1 : null,
    //   user_id: employee1 ? employee : null, // optional
    //   project_id: project1 ? project : null, // optional
    //   is_placement_project: project_type2 ? project_type1 : null,
    //   keyword: value,
    //   business_unit_id: business2 ? business1 : '',
    //   pagenumber: null,
    //   pagesize: null,
    // }

    // if (perPage == null || perPage == '') {
    //   const res1: any = await Apiservices.get_overall_active_projects_count_in_selected_months(
    //     article2
    //   )
    //   if (res1.isError == false) {
    //     setProjectReportCountData(res1.count[0].count)
    //     var count_All_id = res1.count[0].count
    //     setProjectReportProjectsData(res1.data)
    //     var project_All_id = res1.data
    //   }
    // }
    // const article1 = {
    //   year: selectYear ? moment(selectYear).format('YYYY') : '',
    //   month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
    //   employee_type: stat2 ? stat1 : null,
    //   user_id: employee1 ? employee : null, // optional
    //   project_id: project1 ? project : project_All_id ? project_All_id : null, // optional
    //   is_placement_project: project_type2 ? project_type1 : null,
    //   keyword: value,
    //   business_unit_id: business2 ? business1 : '',
    //   pagenumber: page,
    //   // pagesize: count > 0 ? Number(count) : Number(perPage),
    //   pagesize: perPage == null || perPage == '' ? Number(count_All_id) : Number(perPage),
    // }
    // const response: any = await Apiservices.get_project_wise_utilization_report(article1) //for table
    // if (response && response.isError == false) {
    //   setProjectReportData(response.data)
    //   setFlag(true)
    //   // setPages(Math.ceil(response.count[0].count / Number(perPage)))
    //   if (perPage == null || perPage == '') {
    //     setPages(Math.ceil(response.count[0].count / Number(count_All_id)))
    //   } else {
    //     setPages(Math.ceil(response.count[0].count / Number(perPage)))
    //   }
    // }
    const article1 = {
      // year: selectYear ? moment(selectYear).format('YYYY') : '',
      year: selectYear1 ? moment(selectYear1).format('YYYY') : '',
      month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: stat2 ? stat1 : null,
      user_id: employee1 ? employee : null, // optional
      project_id: project1 ? project : null, // optional
      is_placement_project: project_type2 ? project_type1 : null,
      keyword: value,
      business_unit_id: business2 ? business1 : '',
      pagenumber: 1,
      // pagesize: count > 0 ? Number(count) : Number(perPage),
      // pagesize: perPage == null || perPage == '' ? Number(count_All_id) : Number(perPage),
    }
    const response: any = await Apiservices.get_project_wise_utilization_report(article1) //for table
    if (response && response.isError == false) {
      setProjectReportWithoutPaginationTotalData(response.data)
      let data_slice1 = response.data.slice((1 - 1) * perPage, 1 * perPage)
      setProjectReportData(data_slice1)
      // setProjectReportData(response.data)
      setPage(1)
      setFlag(true)
      // setPages(Math.ceil(response.count[0].count / Number(perPage)))
      // if (perPage == null || perPage == '') {
      //   setPages(Math.ceil(response.count[0].count / Number(count_All_id)))
      // } else {
      setPages(Math.ceil(response.count[0].count / Number(perPage)))
      // }
    }
  }
  const options2: any = [
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    // {
    //   label: 'Internal',
    //   value: 'Internal',
    // },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ]

  const options3: any = [
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
  ]

  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const singleDateSelection = async (date: any) => {
    setSelectYear(date)
    setDate('')
  }
  const singleDateSelection1 = async (date: any) => {
    setSelectYear1(date)
    setDate('')
  }
  const handleOrgName = (org: any) => {
    setValue('org_name', org)
    setOrgName(org)
  }

  // const handleProjectType = (e: any) => {
  //   setValue('project_type', e.target.value)
  // }
  const handleProjectType = (selected: any) => {
    setSelectProjectType(selected)
    console.log(selected, 'selected')
    if (selected.length > 0) {
      console.log('if')
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllProjectTypeOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectProjectType(x)
          AllProjectTypeOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
        } else if (each.value == 'empty') {
          setSelectProjectType([])
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          AllProjectTypeOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectProjectType(selected)
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }

          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllProjectTypeOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllProjectTypeOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      console.log('else')

      setSelectProjectType(selected)
      AllProjectTypeOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
      if (!projectTypeReset) {
        AllProjectTypeOptions.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }

  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)
    // getallprojects(ProjectReportData, selected, perPage,'')
    handleClick1(selected, perPage, true, 'pages')
  }

  const handleRowChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      // handleClick1(1, value, false, 'rows')
      handleClick1(page, value, false, 'rows')
    }
  }

  // const handleClick1 = async () => {
  const handleClick1 = async (page: any, perPage: any, change: boolean, api_trigger: any) => {
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      setValidateDescription(true)
      setProjectReportData([])
      setFlag(false)
    } else {
      setValidateDescription(false)
    }

    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)

    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      console.log('if')
    } else {
      const article2 = {
        // year: selectYear ? moment(selectYear).format('YYYY') : '',
        year: selectYear1 ? moment(selectYear1).format('YYYY') : '',
        month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : null,
        user_id: employee1 ? employee : null, // optional
        project_id: project1 ? project : null, // optional
        is_placement_project: project_type2 ? project_type1 : null,
        keyword: search ? search : null,
        business_unit_id: business2 ? business1 : '',
        pagenumber: null,
        pagesize: null,
      }

      // if (perPage == null || perPage == '') {
      //   const res1: any = await Apiservices.get_overall_active_projects_count_in_selected_months(
      //     article2
      //   )
      //   if (res1.isError == false) {
      //     setProjectReportCountData(res1.count[0].count)
      //     var count_All_id = res1.count[0].count
      //     setProjectReportProjectsData(res1.data)
      //     var project_All_id = res1.data
      //   }
      // }
      // const article1 = {
      //   year: selectYear ? moment(selectYear).format('YYYY') : '',
      //   month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
      //   employee_type: stat2 ? stat1 : null,
      //   user_id: employee1 ? employee : null, // optional
      //   project_id: project1 ? project : project_All_id ? project_All_id : null, // optional
      //   is_placement_project: project_type2 ? project_type1 : null,
      //   keyword: search ? search : null,
      //   business_unit_id: business2 ? business1 : '',
      //   pagenumber: page,
      //   // pagesize: count > 0 ? Number(count) : Number(perPage),
      //   pagesize: perPage == null || perPage == '' ? Number(count_All_id) : Number(perPage),
      // }
      // const response: any = await Apiservices.get_project_wise_utilization_report(article1) //for table
      // if (response && response.isError == false) {
      //   setProjectReportData(response.data)
      //   setFlag(true)
      //   // setPages(Math.ceil(response.count[0].count / Number(perPage)))
      //   if (perPage == null || perPage == '') {
      //     setPages(Math.ceil(response.count[0].count / Number(count_All_id)))
      //   } else {
      //     setPages(Math.ceil(response.count[0].count / Number(perPage)))
      //   }
      // }
      const article1 = {
        // year: selectYear ? moment(selectYear).format('YYYY') : '',
        year: selectYear1 ? moment(selectYear1).format('YYYY') : '',
        month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : null,
        user_id: employee1 ? employee : null, // optional
        project_id: project1 ? project : null, // optional
        is_placement_project: project_type2 ? project_type1 : null,
        keyword: search ? search : null,
        business_unit_id: business2 ? business1 : '',
        pagenumber: change ? page : 1,
        // pagesize: count > 0 ? Number(count) : Number(perPage),
        // pagesize:
        //   perPage == null || perPage == ''
        //     ? ProjectReportWithoutPaginationTotalData?.length
        //     : Number(perPage),
      }
      if (api_trigger == 'run') {
        setShowBackDrop(true)
        const response: any = await Apiservices.get_project_wise_utilization_report(article1) //for table
        if (response && response.isError == false) {
          setProjectReportWithoutPaginationTotalData(response.data)
          let data_slice = response.data.slice((1 - 1) * perPage, 1 * perPage)
          setProjectReportData(data_slice)
          // setPage(change ? page : 1)
          setPage(1)
          setFlag(true)
          setPages(Math.ceil(response.count[0].count / Number(perPage)))
        }
        setShowBackDrop(false)
      } else if (api_trigger == 'pages') {
        let data_slice1 = ProjectReportWithoutPaginationTotalData.slice(
          (page - 1) * perPage,
          page * perPage
        )
        setPage(page)
        setProjectReportData(data_slice1)
      } else if (api_trigger == 'rows') {
        let data_slice1 = ProjectReportWithoutPaginationTotalData.slice(
          (1 - 1) * perPage,
          1 * perPage
        )
        setProjectReportData(data_slice1)
        setPage(1)
        setPages(Math.ceil(ProjectReportWithoutPaginationTotalData?.length / Number(perPage)))
      }
    }
  }
  const exporthandleClick = async (e: any) => {
    let business1 = ComplianceBusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let stat1 = selectConsultant1.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    setShowBackDrop(true)
    const article2 = {
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      business_unit_id: business2 ? business1 : '',
      employee_type: stat2 ? stat1 : null,
    }
    const response: any = await Apiservices.export_yearly_utilization_report(article2)
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = response?.data?.blobUrl
    document.body.appendChild(link)
    link.click()
    link.remove()
    setShowBackDrop(false)
  }

  const exporthandleClick1 = async (e: any) => {
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      setValidateDescription(true)
      setProjectReportData([])
      setFlag(false)
    } else {
      setValidateDescription(false)
    }

    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)

    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      console.log('if')
    } else {
      setShowBackDrop(true)
      let article2 = {
        // year: selectYear ? moment(selectYear).format('YYYY') : '',
        year: selectYear1 ? moment(selectYear1).format('YYYY') : '',
        month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : '',
        user_id: employee1 ? employee : null,
        project_id: project1 ? project : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : null,
      }
      // if (perPage == null || perPage == '') {
      const res1: any = await Apiservices.get_overall_active_projects_count_in_selected_months(
        article2
      )
      if (res1.isError == false) {
        setProjectReportCountData(res1.count[0].count)
        var count_All_id = res1.count[0].count
        setProjectReportProjectsData(res1.data)
        var project_All_id = res1.data
      }
      // }

      const article1 = {
        // year: selectYear ? moment(selectYear).format('YYYY') : '',
        year: selectYear1 ? moment(selectYear1).format('YYYY') : '',
        month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : null,
        user_id: employee1 ? employee : null, // optional
        project_id: project1 ? project : project_All_id ? project_All_id : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : '',
      }

      const response: any = await Apiservices.export_project_wise_utilization_report(article1)
      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = response?.data?.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()
      setShowBackDrop(false)
    }
  }
  const handleCountries1 = async (country1: any) => {
    // if (country1 && country1.length >= 2) {
    const response: any = await Apiservices.getprojectbyname({country_name: country1})
    if (response?.data?.data !== null) {
      setCountry1(response && response.data && response.data.data ? response?.data?.data : '')
    }
    // }
  }

  const selectedCountries1 = async (state: any) => {
    setCountries1(state)
  }
  const handleFilterConsultant = async (consult_Name: any) => {
    // let filteredConsultant = candidate?.filter((each: any) =>
    //   each?.full_name?.toLowerCase()?.includes(consult_Name?.toLowerCase())
    // )
    // setCandidateList(filteredConsultant)
    const response: any = await Apiservices.getconsultantbyname()
    setCandidateList(response?.data?.data)
  }

  const selectedConsultant = async (consult_name: any) => {
    setSelectconsultant(consult_name)
  }
  // const handleBusinessUnit = (select: any) => {
  //   setComplianceBusinessUnit(select)
  //   setBusinessUnit(select)
  // }

  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setComplianceBusinessUnit(x)
          // setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setComplianceBusinessUnit([])
          // setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          // BusinessUnitsList.pop({
          //   name: 'Deselect All',
          //   value: 'empty',
          // })
          let cde1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (cde1) {
            BusinessUnitsList.splice(
              BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
              1
            )
          }
        } else {
          setComplianceBusinessUnit(selected)
          // setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setComplianceBusinessUnit(selected)
      // setBusinessUnit(selected)
      // BusinessUnitsList.pop({
      //   label: 'Deselect All',
      //   value: 'empty',
      // })
      let cde1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
      if (cde1) {
        BusinessUnitsList.splice(
          BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
          1
        )
      }

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }

  const handleBusinessUnit2 = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList2.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList2.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList2.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList2.push({
              name: 'Deselect All',
              value: 'empty',
            })
            // setBusinessUnitsList(BusinessUnitsList2)
            setBusinessUnitsList2(BusinessUnitsList2)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList2.find((item: any) => item.value == 'All')

          if (!cde) {
            BusinessUnitsList2.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList2)
          }
          // BusinessUnitsList2.pop({
          //   name: 'Deselect All',
          //   value: 'empty',
          // })
          let cde1 = BusinessUnitsList2.find((item: any) => item.value == 'empty')
          if (cde1) {
            BusinessUnitsList2.splice(
              BusinessUnitsList2.findIndex((item: any) => item.value == 'empty'),
              1
            )
          }
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList2.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList2.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList2.push({
              name: 'Deselect All',
              value: 'empty',
            })
            // setBusinessUnitsList(BusinessUnitsList2)
            setBusinessUnitsList2(BusinessUnitsList2)
          }
          if (!cde) {
            BusinessUnitsList2.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList2)
            setBusinessUnitsList2(BusinessUnitsList2)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList2.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList2.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      // BusinessUnitsList2.pop({
      //   label: 'Deselect All',
      //   value: 'empty',
      // })
      let cde1 = BusinessUnitsList2.find((item: any) => item.value == 'empty')
      if (cde1) {
        BusinessUnitsList2.splice(
          BusinessUnitsList2.findIndex((item: any) => item.value == 'empty'),
          1
        )
      }

      let businessReset = BusinessUnitsList2.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList2.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const handleConsultantType = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelectConsultant(x)
          AllOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }
        } else if (each.value == 'empty') {
          setSelectConsultant([])
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions(AllOptions)
          }
          AllOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectConsultant(selected)
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            // setAllOptions(AllOptions)
          }
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions(AllOptions)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectConsultant(selected)
      AllOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let consultantReset = AllOptions.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelectConsultant(selected)
  }

  const handleConsultantType1 = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions1.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelectConsultant1(x)
          AllOptions1.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions1.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions1.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions1(AllOptions1)
          }
        } else if (each.value == 'empty') {
          setSelectConsultant1([])
          let cde = AllOptions1.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions1.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions1(AllOptions1)
          }

          let cde1 = AllOptions1.find((item: any) => item.value == 'empty')
          if (cde1) {
            AllOptions1.splice(
              AllOptions1.findIndex((item: any) => item.value == 'empty'),
              1
            )
          }
        } else {
          setSelectConsultant1(selected)
          let abc = AllOptions1.find((item: any) => item.value == 'empty')
          let cde = AllOptions1.find((item: any) => item.value == 'All')
          if (!abc) {
            AllOptions1.push({
              label: 'Deselect All',
              value: 'empty',
            })
            // setAllOptions1(AllOptions1)
          }
          if (!cde) {
            AllOptions1.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions1(AllOptions1)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions1.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions1.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectConsultant1(selected)
      let cde1 = AllOptions1.find((item: any) => item.value == 'empty')
      if (cde1) {
        AllOptions1.splice(
          AllOptions1.findIndex((item: any) => item.value == 'empty'),
          1
        )
      }
      let consultantReset = AllOptions1.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions1.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelectConsultant1(selected)
  }
  const sorting5 = (col: any) => {
    console.log(ProjectReportData)

    if (order5 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => (a[col] > b[col] ? 1 : -1))
      console.log(sorted, 'asc')

      setProjectReportData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      console.log(ProjectReportData)
      const sorted = [...ProjectReportData]?.sort((a, b) => (a[col] < b[col] ? 1 : -1))

      console.log(sorted, 'dsc')

      setProjectReportData(sorted)

      setOrder5('ASC')
      setSort(true)
    }
  }

  const sorting7 = (col1: any, col2: any) => {
    if (order7 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() > val2?.toLowerCase() ? 1 : -1
      })

      setProjectReportData(sorted)
      setOrder7('DSC')
      setSort(false)
    }

    if (order7 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() < val2?.toLowerCase() ? 1 : -1
      })

      setProjectReportData(sorted)
      setOrder7('ASC')
      setSort(true)
    }
  }

  const sorting6 = (col: any) => {
    if (order6 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        const lowerA = a[col]?.replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col]?.replace(/\s+/g, '').toLowerCase()
        return lowerA?.localeCompare(lowerB)
      })

      setProjectReportData(sorted)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        const lowerA = a[col]?.replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col]?.replace(/\s+/g, '').toLowerCase()
        return lowerB?.localeCompare(lowerA)
      })

      setProjectReportData(sorted)
      setOrder6('ASC')
      setSort(true)
    }
  }

  const sorting10 = (col: any, col1: any, col2: any) => {
    //direct_customer,end_customer,client
    if (order10 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let a1 = a[col] ? a[col1] : a[col2]
        let b1 = b[col] ? b[col1] : b[col2]

        return a1?.toLowerCase() > b1?.toLowerCase() ? 1 : -1
      })
      setProjectReportData(sorted)
      setOrder10('DSC')
      setSort(false)
    }

    if (order10 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let a1 = a[col] ? a[col1] : a[col2]
        let b1 = b[col] ? b[col1] : b[col2]
        return a1?.toLowerCase() < b1?.toLowerCase() ? 1 : -1
      })
      setProjectReportData(sorted)
      setOrder10('ASC')
      setSort(true)
    }
  }
  const sorting12 = (col: any, col1: any, col2: any) => {
    if (order12 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => {
        let a1 = a[col] == 'CANCELLED' ? a[col1] : a[col2]
        let b1 = b[col] == 'CANCELLED' ? b[col1] : b[col2]

        let dateA = new Date(a1),
          dateB = new Date(b1)
        if (dateB > dateA) {
          return -1
        } else {
          return 1
        }
      })

      setProjectReportData(sorted)
      setOrder12('DSC')
      setSort(false)
    }

    if (order12 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => {
        let a1 = a[col] == 'CANCELLED' ? a[col1] : a[col2]
        let b1 = b[col] == 'CANCELLED' ? b[col1] : b[col2]

        let dateA = new Date(a1),
          dateB = new Date(b1)
        if (dateB < dateA) {
          return -1
        } else {
          return 1
        }
      })

      setProjectReportData(sorted)
      setOrder12('ASC')
      setSort(true)
    }
  }
  const sorting8 = (col: any) => {
    if (order8 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => a[col] - b[col])

      setProjectReportData(sorted)
      setOrder8('DSC')
      setSort(false)
    }

    if (order8 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => b[col] - a[col])
      setProjectReportData(sorted)
      setOrder8('ASC')
      setSort(true)
    }
  }
  const handleMonth = (selected: any) => {
    if (selected?.length > 0) {
      setValidateDescription(false)
    }
    // setSelectMonth(selected)
    if (selected.length > 0) {
      if (selected.length < 4 || selected.find((item: any) => item.value == 'empty')) {
        for (let each of selected) {
          // if (each.value == 'All') {
          //   let x = AllMonthOptions.filter(
          //     (temp: any) => temp.value != 'All' && temp.value != 'empty'
          //   )
          //   setSelectMonth(x)
          //   AllMonthOptions.shift({
          //     label: 'Select All',
          //     value: 'All',
          //   })
          //   let abc = AllMonthOptions.find((item: any) => item.value == 'empty')
          //   if (!abc) {
          //     AllMonthOptions.push({
          //       label: 'Deselect All',
          //       value: 'empty',
          //     })
          //     // setAllMonthOptions(AllMonthOptions)
          //   }
          // }
          if (each.value == 'empty') {
            setSelectMonth([])
            let cde = AllMonthOptions.find((item: any) => item.value == 'All')
            // if (!cde) {
            //   AllMonthOptions.unshift({
            //     label: 'Select All',
            //     value: 'All',
            //   })
            //   // setAllMonthOptions(AllMonthOptions)
            // }
            AllMonthOptions.pop({
              label: 'Deselect All',
              value: 'empty',
            })
          } else {
            setSelectMonth(selected)
            let abc = AllMonthOptions.find((item: any) => item.value == 'empty')
            let cde = AllMonthOptions.find((item: any) => item.value == 'All')

            if (!abc) {
              AllMonthOptions.push({
                label: 'Deselect All',
                value: 'empty',
              })
              // setAllMonthOptions(AllMonthOptions)
            }

            // if (!cde) {
            //   AllMonthOptions.unshift({
            //     label: 'Select All',
            //     value: 'All',
            //   })
            //   setAllMonthOptions(AllMonthOptions)
            // }
            let check_drop = selected.find((item: any) => item.value == 'All')
            let check_drop1 = selected.find((item: any) => item.value == 'empty')

            if (!check_drop && !check_drop1) {
              let test = AllMonthOptions.filter(
                (id: any) => !selected.some((x: any) => x.value == id.value)
              )
              if (test.length == 2) {
                let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
                // AllMonthOptions.shift({
                //   label: 'Select All',
                //   value: 'All',
                // })
              }
            }
          }
        }
      }
    } else {
      setSelectMonth(selected)
      let monthReset1 = AllMonthOptions.find((item: any) => item.value == 'empty')
      if (monthReset1) {
        AllMonthOptions.pop({
          label: 'Deselect All',
          value: 'empty',
        })
      }
      let monthReset = AllMonthOptions.find((item: any) => item.value == 'All')
      // if (!monthReset) {
      //   AllMonthOptions.unshift({
      //     label: 'Select All',
      //     value: 'All',
      //   })
      // }
    }
  }

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.UTILIZATIONREPORTS'})}</PageTitle> */}
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>Utilization Report </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <div className='d-flex justify-content-between align-items-center'>
            <div> </div>
          </div>
          <BackDrop showBackDrop={showBackDrop} />
          <div className='py-1'>
            <div className='card card-custom card-flush '>
              <div className='card-body  py-1 px-5'>
                <div className='row gy-3 align-items-center py-2'>
                  <div className='col-xs-12 col-md-3 col-lg-3 '>
                    <label className='form-label '>Select Year</label>
                    {/* <select name='selectYear' onChange={yearSelect} value={selectYear}> */}
                    {/* <select
                className={clsx('form-select', errors.select_Year ? 'is-invalid' : '')}
                value={selectYear}
                {...register('select_Year', {
                  required: true,
                  onChange: (e) => {
                    yearSelect(e.target.value)
                  },
                })}
              >
                <option value=''>Select Year</option>
                {years &&
                  years.length > 0 &&
                  years.map((value: any) => {
                    return <option value={value}>{value}</option>
                  })}
              </select> */}
                    <DatePicker
                      selected={selectYear}
                      onChange={(date: any) => singleDateSelection(date)}
                      placeholderText='Select Year'
                      className={clsx('form-control')}
                      dateFormat='yyyy'
                      showYearPicker
                      required
                    />
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg-3'>
                    <label className='form-label '>Consultant Type</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        handleConsultantType1(selected)
                        // setSelectConsultant1(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef.current.toggleMenu()
                      }}
                      options={AllOptions1}
                      className='overFlowForTextField'
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef}
                      selected={selectConsultant1}
                    />
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Business Unit</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(select: any) => {
                        handleBusinessUnit(select)
                        typeaheadRef8.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef8}
                      placeholder='Select Business Unit'
                      selected={ComplianceBusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    ></Typeahead> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        // setBusinessUnit(e)
                        handleBusinessUnit(e)
                        typeaheadRef8.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      className='overFlowForTextField'
                      ref={typeaheadRef8}
                      placeholder='Select Business Unit'
                      selected={ComplianceBusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    ></Typeahead>
                  </div>

                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      onClick={() => handleClick()}
                    >
                      Run
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={resetData1}
                    >
                      Reset
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      onClick={exporthandleClick}
                    >
                      Export
                      {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {OrganizationReportData && OrganizationReportData?.series && (
            <div className='py-1'>
              <div id='chart'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-1'>
                    <div className='row justify-content-center'>
                      <div className='col-11'>
                        <Chart
                          options={OrganizationReportData.options}
                          series={OrganizationReportData.series}
                          type='line'
                          // width='100%'
                          height={450}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='py-1'>
            <h3 className='text-gray-900 fs-3 fw-semibold  py-2'> Utilization Data</h3>
            <div className='card card-custom card-flush '>
              <div className='card-body  py-1 px-5'>
                <div className='row gy-3 align-items-center py-2'>
                  <div className='col-xs-12 col-md-3 col-lg '>
                    <label className='form-label '> Year</label>
                    <DatePicker
                      selected={selectYear1}
                      onChange={(date: any) => singleDateSelection1(date)}
                      placeholderText='Select Year'
                      className={clsx('form-control')}
                      // disabled={true}
                      dateFormat='yyyy'
                      showYearPicker
                      required
                    />
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg '>
                    <label className='form-label '>Select Month</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        setSelectMonth(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef1.current.toggleMenu()
                      }}
                      options={options3}
                      placeholder='Select Month'
                      ref={typeaheadRef1}
                      selected={selectMonth}
                    /> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        // setSelectMonth(selected)
                        handleMonth(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef1.current.toggleMenu()
                      }}
                      options={AllMonthOptions}
                      placeholder='Select Month'
                      ref={typeaheadRef1}
                      selected={selectMonth}
                      // inputProps={{
                      //   required: true,
                      // }}
                    />
                    {validatedescription && (
                      <span className='error text-danger mb-0'>Month is required</span>
                    )}
                    <div className='invalid-feedback'>{errors.employee_type?.message}</div>
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Consultant Type</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        setSelectConsultant(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef2.current.toggleMenu()
                      }}
                      options={options2}
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef2}
                      selected={selectConsultant}
                    /> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        handleConsultantType(selected)
                        // setSelectConsultant(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef2.current.toggleMenu()
                      }}
                      options={AllOptions}
                      className='overFlowForTextField'
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef2}
                      selected={selectConsultant}
                    />
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Business Unit</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        setBusinessUnit(e)
                        typeaheadRef7.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef7}
                      placeholder='Select Business Unit'
                      selected={BusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    >
                    </Typeahead> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        // setBusinessUnit(e)
                        handleBusinessUnit2(e)
                        typeaheadRef7.current.toggleMenu()
                      }}
                      options={BusinessUnitsList2}
                      className='overFlowForTextField'
                      ref={typeaheadRef7}
                      placeholder='Select Business Unit'
                      selected={BusinessUnit}
                      labelKey={(BusinessUnitsList2: any) => BusinessUnitsList2.name}
                      //   inputProps={{required: true}}
                    ></Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Project Type </label>
                    {/* <select
                      // value={selectedItem}
                      {...register('project_type')}
                      onChange={handleProjectType}
                      className={clsx('form-select')}
                    >
                      <option value=''>Select Project Type</option>
                      <option value='1'>Placement</option>
                      <option value='2'>Bench Project</option>
                    </select> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        // setSelectProjectType(selected)
                        handleProjectType(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef5.current.toggleMenu()
                      }}
                      options={AllProjectTypeOptions}
                      className='overFlowForTextField'
                      placeholder='Select Project Type'
                      ref={typeaheadRef5}
                      selected={selectProjectType}
                    />

                    {/* <div className='invalid-feedback'>{errors.user_status?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Employee Name</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        selectedConsultant(e)
                        typeaheadRef3.current.toggleMenu()
                      }}
                      onInputChange={(e) => handleFilterConsultant(e)}
                      options={candidateList}
                      className='overFlowForTextField'
                      ref={typeaheadRef3}
                      placeholder='Search Employee'
                      selected={select_consultant}
                      labelKey={(candidateList: any) => user(candidateList.full_name)}
                      //   inputProps={{required: true}}
                    >
                      {/* {({onClear, selected}) => (
                    <div className='rbt-aux'>
                      {!!selected.length && (
                        <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                      )}
                    </div>
                  )} */}
                    </Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Project Name</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(e) => {
                        selectedCountries1(e)
                        typeaheadRef4.current.toggleMenu()
                      }}
                      onInputChange={(e) => {
                        handleCountries1(e)
                      }}
                      placeholder='Please Enter Project Name'
                      ref={typeaheadRef4}
                      className='overFlowForTextField'
                      options={country1}
                      selected={countries1}
                      labelKey={(country1: any) => country1.project_name}
                    >
                      {/* {({onClear, selected}) => (
                            <div className='rbt-aux'>
                              {!!selected.length && <ClearButton onClick={onClear} />}
                            </div>
                          )} */}
                    </Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>

                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      // onClick={() => handleClick1()}
                      onClick={() => handleClick1(page, perPage, false, 'run')}
                    >
                      Run
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={resetData2}
                    >
                      Reset
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      onClick={exporthandleClick1}
                    >
                      Export
                      {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {flag == true && (
            <>
              <KTCardBody className='py-1 '>
                <div className='pt-3'>
                  <div className='card card-custom card-flush '>
                    <div className='card-body p-3 py-3'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='text-gray-900 fs-5 fw-semibold text-transform-unset'>
                          Tabular Data{' '}
                        </div>
                        <div className='d-flex gap-3 '>
                          <div className='input-group mb-0'>
                            <input
                              type='text'
                              id='form1'
                              className='form-control smallTextField'
                              // value={search}
                              {...register('work_city')}
                              placeholder='Search'
                              onChange={(e) => handleChange1(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='table-responsive padding-down reportTab'>
                        <table
                          id='kt_table_users'
                          className='table table-row-bordered table-row-gray-300 gy-4'
                        >
                          <>
                            <thead>
                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                <th
                                  onClick={() => sorting6('user_business_unit_name')}
                                  className='  py-4'
                                >
                                  Business Unit{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting6('employee_id')} className='  py-4'>
                                  Consultant ID{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('employee_type')} className='  py-4'>
                                  Consultant Type{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting7('display_name', 'full_name')}
                                  className='  py-4'
                                >
                                  Consultant Name{' '}
                                  <span>
                                    {order7 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting6('placement_code')} className='  py-4'>
                                  Placement Code{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting5('is_placement_project')}
                                  className='  py-4'
                                >
                                  Project Type{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('project_name')} className='  py-4'>
                                  Project Name{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() =>
                                    sorting10(
                                      'direct_customer_engagement',
                                      'end_customer',
                                      'client_name'
                                    )
                                  }
                                  className='  py-4'
                                >
                                  Client Name{' '}
                                  <span>
                                    {order10 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('project_status')} className='  py-4'>
                                  Project Status{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('monthName')} className='  py-4'>
                                  Month{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting5('joining_start_date')}
                                  className='  py-4'
                                >
                                  Start Date <span className='fs-9'>(DD-MMM-YYYY)</span>{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() =>
                                    sorting12('project_status', 'cancel_date', 'end_date')
                                  }
                                  className='  py-4'
                                >
                                  End Date <span className='fs-9'>(DD-MMM-YYYY)</span>{' '}
                                  <span>
                                    {order12 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting8('total_expected_hours')}
                                  className='  py-4'
                                >
                                  Expected Hours{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting8('total_billed_hours')}
                                  className='  py-4'
                                >
                                  Billed Hours{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting8('total_bench_hours_minutes')}
                                  className='  py-4'
                                >
                                  Bench Hours{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting8('total_leave_hours_minutes')}
                                  className='  py-4'
                                >
                                  Leave Hours{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                {/* <th className='  py-4'>Utilization % </th> */}

                                <th
                                  onClick={() => sorting8('utilization_percent')}
                                  className='  py-4'
                                >
                                  Utilization %{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                  <Tooltip text={'(Billed Hours*100)/Expected Hours'}>
                                    <span>
                                      <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                    </span>
                                  </Tooltip>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {ProjectReportData?.length > 0 ? (
                                <>
                                  {ProjectReportData?.map((each: any, index: any) => (
                                    <tr
                                      className='namepage text-dark fw-semibold  fs-6 '
                                      key={index}
                                    >
                                      <td className=''>
                                        {each.user_business_unit_name
                                          ? each.user_business_unit_name
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.employee_id ? each.employee_id : '-'}
                                      </td>
                                      <td className=''>
                                        {each.employee_type ? each.employee_type : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {each.display_name
                                          ? each.display_name
                                          : each.full_name
                                          ? each.full_name
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.placement_code ? each.placement_code : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {/* {each.is_placement_project != null
                                    ? each.is_placement_project == false
                                      ? 'Bench'
                                      : 'Placement'
                                    : '-'} */}
                                        {each.is_placement_project
                                          ? each.is_placement_project == 1
                                            ? 'Placement'
                                            : each.is_placement_project == 2
                                            ? 'Bench'
                                            : each.is_placement_project == 3
                                            ? 'Internal'
                                            : '-'
                                          : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {each.project_name ? each.project_name : '-'}
                                      </td>
                                      <td className=''>
                                        {each.direct_customer_engagement == true
                                          ? each.end_customer
                                            ? each.end_customer
                                            : '-'
                                          : each.client_name
                                          ? each.client_name
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.project_status
                                          ? each.project_status == 'IN_PROGRESS'
                                            ? 'In Progress'
                                            : each.project_status == 'INITIATED'
                                            ? 'Initiated'
                                            : each.project_status == 'CANCELLED'
                                            ? 'Cancelled'
                                            : each.project_status == 'COMPLETED'
                                            ? 'Completed'
                                            : each.project_status == 'REJECTED'
                                            ? 'Rejected'
                                            : '-'
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.monthName
                                          ? moment(each.monthName, 'MMMM').format('MMM')
                                          : '-'}{' '}
                                        {moment(selectYear1).format('YYYY')}
                                      </td>
                                      <td className=''>
                                        {each.joining_start_date
                                          ? moment(each.joining_start_date).format('DD-MMM-YYYY')
                                          : '-'}
                                      </td>
                                      {/* <td className=''>
                                  {each.end_date
                                    ? moment(each.end_date).format('DD-MMM-YYYY')
                                    : '-'}
                                </td> */}

                                      <td className='text-nowrap'>
                                        {each.project_status
                                          ? each.project_status?.toLowerCase() == 'cancelled'
                                            ? each.cancel_date
                                              ? moment(each.cancel_date).format('DD-MMM-YYYY')
                                              : '-'
                                            : each.project_status?.toLowerCase() == 'completed'
                                            ? each.end_date
                                              ? moment(each.end_date).format('DD-MMM-YYYY')
                                              : '-'
                                            : '-'
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.total_expected_hours
                                          ? each.total_expected_hours
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.total_billed_hours ? each.total_billed_hours : '-'}
                                      </td>
                                      <td className=''>
                                        {each.total_bench_hours_minutes
                                          ? each.total_bench_hours_minutes
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.total_leave_hours_minutes
                                          ? each.total_leave_hours_minutes
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.utilization_percent ? each.utilization_percent : '-'}
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </>
                        </table>
                        {(ProjectReportData?.length == null || ProjectReportData?.length == 0) && (
                          <div className='text-center  fw-bold text-gray-800'>
                            No Records found{' '}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <UsersListPagination />
              </KTCardBody>
              <div className='d-flex flex-stack py-2'>
                {/* {ProjectReportData?.length > 0 ? ( */}
                <div>
                  <Container className='p-0'>
                    <Row className='align-items-center'>
                      <Col xs='auto' className='pe-0'>
                        {' '}
                        <label className='form-label fw-semibold'>No. of projects</label>
                      </Col>

                      <Col>
                        <select
                          name='perPage'
                          className='form-select'
                          onChange={handleRowChange}
                          value={perPage}
                        >
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          {/* <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='75'>75</option> */}
                          {/* <option value={ProjectReportCountData}>All</option> */}
                          {/* <option value=''>All</option> */}
                        </select>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* ) : null} */}

                <div className='pr-150'>
                  {/* {ProjectReportData?.length > 0 ? ( */}
                  <div className='pb-20'>
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      pageCount={pages}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination justify-content-right'}
                      activeClassName={'active'}
                      breakLabel={'...'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      forcePage={page - 1}
                    />
                  </div>
                  {/* ) : null} */}
                </div>
              </div>
            </>
          )}
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select any one and click run to view the result
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UtilizationComplianceReports
